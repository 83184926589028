import { Box, Button, Hidden, Image, Text, useBreakpointValue } from 'native-base';
import { FC } from 'react';
import { RootTabScreenProps } from 'types';
import icon from 'assets/images/icon.png';
import { useTranslation } from 'react-i18next';
import { Platform, Linking } from 'react-native';

const styles = {
  innerBox: {
    justifyContent: 'center',
    flexGrow: '1',
  },
  logo: {
    alignSelf: 'center',
  },
  header: {
    alignSelf: 'center',
    fontSize: '2.5xl',
    color: 'styleSheet.overCast',
  },
  text: {
    maxW: ['85%', '85%', '85%', '50%', '50%'],
    textAlign: 'center' as const,
    alignSelf: 'center',
    mt: '16px',
  },
  button: {
    variant: 'primaryMobile',
    borderRadius: '8px',
    _text: {
      fontFamily: 'body600',
      color: 'styleSheet.canvas',
      fontSize: '2md',
    },
    w: ['100%', '100%', '45%', '35%'],
    alignSelf: 'center' as const,
    mt: '32px',
    _pressed: {
      bg: 'styleSheet.shadow',
      opacity: '0.6',
      _text: { color: 'styleSheet.canvas' },
    },
    _hover: {
      bg: 'styleSheet.darkStain',
      _text: { color: 'styleSheet.shadow' },
    },
    _focus: {
      bg: 'styleSheet.shadow',
    },
  },
};

const ContactScreen: FC<RootTabScreenProps<'Contact'>> = () => {
  const { t } = useTranslation();
  const handleCall = () => {
    let phoneNumber = '';
    if (Platform.OS === 'android') {
      phoneNumber = 'tel:${8332188756}';
    } else {
      phoneNumber = 'telprompt:${8332188756}';
    }
    Linking.openURL(phoneNumber);
  };

  return (
    <Box safeAreaTop bg="bgPage" flexGrow="1" p={4}>
      <Box {...styles.innerBox}>
        <Image source={icon} size="lg" alt="summer logo" {...styles.logo} />
        <Text {...styles.header}>{t('CONTACT.HOW_CAN_WE')}</Text>
        <Text {...styles.text}>{t('CONTACT.IF_YOU_NEED')}</Text>
        <Hidden from={'lg'}>
          <Button {...styles.button} onPress={handleCall}>
            {t('CONTACT.CALL')}
          </Button>
        </Hidden>
      </Box>
    </Box>
  );
};

export default ContactScreen;
