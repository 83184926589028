import * as Types from '../../../generated/types.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ReadSubscriptionQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type ReadSubscriptionQuery = { __typename?: 'Query', readSubscription: { __typename?: 'ContractResponse', status: { __typename?: 'DatabaseStatus', code: number, message: string }, data?: { __typename?: 'UserContract', homeId?: string | null, initialPayment?: number | null, nextPaymentDate?: string | null, totalSubscriptionPaid?: number | null, monthlySubscription?: number | null, subscriptionPaidYear1?: number | null, subscriptionPaidYear2?: number | null, subscriptionPaidYear3?: number | null, subscriptionTotal?: number | null, totalSubscriptionAmount?: number | null, thumbnail?: string | null, type?: string | null } | null } };


export const ReadSubscriptionDocument = gql`
    query readSubscription($id: String!) {
  readSubscription(id: $id) {
    status {
      code
      message
    }
    data {
      homeId
      initialPayment
      nextPaymentDate
      totalSubscriptionPaid
      monthlySubscription
      subscriptionPaidYear1
      subscriptionPaidYear2
      subscriptionPaidYear3
      subscriptionTotal
      totalSubscriptionAmount
      thumbnail
      type
    }
  }
}
    `;

/**
 * __useReadSubscriptionQuery__
 *
 * To run a query within a React component, call `useReadSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadSubscriptionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReadSubscriptionQuery(baseOptions: Apollo.QueryHookOptions<ReadSubscriptionQuery, ReadSubscriptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadSubscriptionQuery, ReadSubscriptionQueryVariables>(ReadSubscriptionDocument, options);
      }
export function useReadSubscriptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadSubscriptionQuery, ReadSubscriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadSubscriptionQuery, ReadSubscriptionQueryVariables>(ReadSubscriptionDocument, options);
        }
export type ReadSubscriptionQueryHookResult = ReturnType<typeof useReadSubscriptionQuery>;
export type ReadSubscriptionLazyQueryHookResult = ReturnType<typeof useReadSubscriptionLazyQuery>;
export type ReadSubscriptionQueryResult = Apollo.QueryResult<ReadSubscriptionQuery, ReadSubscriptionQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    