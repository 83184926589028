import { createContext } from 'react';
import { noop } from 'lodash';

export type ToastInitialState = {
  errorMsg: string | null;
  visibility: boolean;
  setVisibility: (state: boolean) => void;
  setErrorMsg: (msg: string | null) => void;
};

export const ErrorToastContext = createContext<ToastInitialState>({
  errorMsg: '',
  visibility: false,
  setVisibility: noop,
  setErrorMsg: noop,
});
