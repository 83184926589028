import { Alert, VStack, HStack, Text, IconButton, CloseIcon } from 'native-base';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const styles = {
  icon: {
    mt: '1',
  },
  alert: {
    w: '100%',
  },
  vstack: {
    w: '100%',
  },
  hstack: {
    justifyContent: 'space-between',
  },
  text: {
    fontFamily: 'body',
    fontWeight: '400',
    fontSize: '2md',
    color: 'styleSheet.shadow',
  },
  closeIcon: {
    size: '3',
  },
};

interface IAlertBox {
  setShow: (s: boolean) => void;
  setDisabled: (s: boolean) => void;
  show: boolean | null | undefined;
}

const AlertBox: FC<IAlertBox> = ({ show, setShow, setDisabled }) => {
  const { t } = useTranslation();

  const handleCloseIcon = () => {
    setShow(false);
    setDisabled(false);
  };
  return (
    <Alert {...styles.alert} status={'error'} isOpen={show}>
      <VStack {...styles.vstack}>
        <HStack {...styles.hstack}>
          <HStack space={2}>
            <Alert.Icon {...styles.icon} />
            <Text {...styles.text}>{t('CONFIRMATION.RESERVATION_ERROR')}</Text>
          </HStack>
          <IconButton
            onPress={handleCloseIcon}
            variant="unstyled"
            _focus={{
              borderWidth: 0,
            }}
            icon={<CloseIcon {...styles.closeIcon} />}
            _icon={{
              color: 'styleSheet.shadow',
            }}
          />
        </HStack>
      </VStack>
    </Alert>
  );
};

export default AlertBox;
