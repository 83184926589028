import * as Types from '../../../generated/types.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAllReservationsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllReservationsQuery = { __typename?: 'Query', getAllReservations: { __typename?: 'getAllReservationResponse', status: { __typename?: 'GuestyStatus', code: number, message: string }, data?: Array<{ __typename?: 'GuestyReservation', _id?: string | null, status?: string | null, checkIn?: string | null, checkOut?: string | null, checkInDateLocalized?: string | null, checkOutDateLocalized?: string | null, listing?: { __typename?: 'GuestyListing', _id?: string | null, title?: string | null, picture?: { __typename?: 'Picture', thumbnail?: string | null } | null, address?: { __typename?: 'Address', full?: string | null } | null } | null }> | null } };


export const GetAllReservationsDocument = gql`
    query getAllReservations {
  getAllReservations {
    status {
      code
      message
    }
    data {
      _id
      status
      checkIn
      checkOut
      checkInDateLocalized
      checkOutDateLocalized
      listing {
        _id
        title
        picture {
          thumbnail
        }
        address {
          full
        }
      }
    }
  }
}
    `;

/**
 * __useGetAllReservationsQuery__
 *
 * To run a query within a React component, call `useGetAllReservationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllReservationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllReservationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllReservationsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllReservationsQuery, GetAllReservationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllReservationsQuery, GetAllReservationsQueryVariables>(GetAllReservationsDocument, options);
      }
export function useGetAllReservationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllReservationsQuery, GetAllReservationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllReservationsQuery, GetAllReservationsQueryVariables>(GetAllReservationsDocument, options);
        }
export type GetAllReservationsQueryHookResult = ReturnType<typeof useGetAllReservationsQuery>;
export type GetAllReservationsLazyQueryHookResult = ReturnType<typeof useGetAllReservationsLazyQuery>;
export type GetAllReservationsQueryResult = Apollo.QueryResult<GetAllReservationsQuery, GetAllReservationsQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    