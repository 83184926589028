import * as Types from '../../../generated/types.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserOnboardingMutationVariables = Types.Exact<{
  destination: Types.Scalars['String'];
  emailAddress: Types.Scalars['String'];
  firstName: Types.Scalars['String'];
  lastName: Types.Scalars['String'];
  phoneNumber: Types.Scalars['String'];
  termsCondition: Types.Scalars['Boolean'];
}>;


export type UserOnboardingMutation = { __typename?: 'Mutation', userOnboarding: { __typename?: 'UserOnboardingOutput', status: { __typename?: 'CoreStatus', code: number, message: string } } };


export const UserOnboardingDocument = gql`
    mutation userOnboarding($destination: String!, $emailAddress: String!, $firstName: String!, $lastName: String!, $phoneNumber: String!, $termsCondition: Boolean!) {
  userOnboarding(
    destination: $destination
    emailAddress: $emailAddress
    firstName: $firstName
    lastName: $lastName
    phoneNumber: $phoneNumber
    termsCondition: $termsCondition
  ) {
    status {
      code
      message
    }
  }
}
    `;
export type UserOnboardingMutationFn = Apollo.MutationFunction<UserOnboardingMutation, UserOnboardingMutationVariables>;

/**
 * __useUserOnboardingMutation__
 *
 * To run a mutation, you first call `useUserOnboardingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserOnboardingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userOnboardingMutation, { data, loading, error }] = useUserOnboardingMutation({
 *   variables: {
 *      destination: // value for 'destination'
 *      emailAddress: // value for 'emailAddress'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      phoneNumber: // value for 'phoneNumber'
 *      termsCondition: // value for 'termsCondition'
 *   },
 * });
 */
export function useUserOnboardingMutation(baseOptions?: Apollo.MutationHookOptions<UserOnboardingMutation, UserOnboardingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserOnboardingMutation, UserOnboardingMutationVariables>(UserOnboardingDocument, options);
      }
export type UserOnboardingMutationHookResult = ReturnType<typeof useUserOnboardingMutation>;
export type UserOnboardingMutationResult = Apollo.MutationResult<UserOnboardingMutation>;
export type UserOnboardingMutationOptions = Apollo.BaseMutationOptions<UserOnboardingMutation, UserOnboardingMutationVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    