import BookCalendar from 'components/Calendars/BookCalendar';
import { useContext, useEffect, useState, FC } from 'react';
import { RootStackScreenProps } from 'types';
import { HStack, Box, Text, CloseIcon, Pressable, Divider, VStack } from 'native-base';
import { useTranslation } from 'react-i18next';
import CTAButton from 'components/libs/button/CTAButton';
import layout from '../../constants/Layout';
import moment from 'moment';
import { NavigationContext } from 'contexts/NavigationContext';
import { CalendarProvider } from 'providers/CalendarProvider';

const customWidth = layout.window.width * 0.085;

const styles = {
  box: {
    backgroundColor: 'styleSheet.canvas',
    ml: '16px',
    mr: '16px',
    justifyContent: 'center',
  },
  boxHstack: {
    w: '100%',
    mt: '31px',
    justifyContent: 'space-between',
  },
  title: {
    color: 'pdp.shadow',
    fontSize: '2md',
    fontFamily: 'body600',
    alignSelf: 'center',
  },
  icon: {
    color: 'pdp.shadow',
    alignSelf: 'center',
    size: '4',
  },

  text: {
    position: 'absolute' as const,
    left: '40%',
    color: 'white',
    fontSize: 'sm',
    alignSelf: 'center',
  },

  stickyBar: {
    position: 'absolute' as const,
    bg: 'styleSheet.shadow',
    left: '-16px',
    w: '108%',
    h: '75px',
    zIndex: 1,
    _pressed: {
      bg: 'styleSheet.overCast',
      _text: { color: 'styleSheet.canvas' },
    },
    _text: {
      fontFamily: 'body600',
      color: 'styleSheet.canvas',
      fontSize: '2md',
    },
  },

  buttonBox: {
    position: 'absolute' as const,
    bottom: 0,
    bg: 'black',
    left: '-16px',
    w: '108%',
    h: '75px',
  },

  checkIn: {
    fontSize: 'sm',
    fontFamily: 'body600',
    color: 'styleSheet.shadow',
    opacity: '0.55',
  },

  checkOut: {
    fontSize: 'sm',
    fontFamily: 'body600',

    color: 'styleSheet.shadow',
    opacity: '0.55',
  },

  hstack: {
    backgroundColor: 'styleSheet.canvas',
    alignItems: 'center',
    justifyContent: 'space-around',
    mt: '14px',
    mb: '14px',
    mr: `${customWidth}px`,
    ml: `${customWidth}px`,
  },

  dateBox: {
    backgroundColor: 'styleSheet.canvas',
    borderBottomWidth: '2',
    borderBottomColor: 'borderColor',
  },

  pressable: {
    h: '40px',
    w: '50px',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  divider: {
    variant: 'primary',
    w: '110%',
    ml: '-17px',
    mt: '20px',
  },
  HDivider: {
    variant: 'primary',
  },
  vstack: {
    alignItems: 'center',
  },
  textDate: {
    fontSize: '2md',
    fontFamily: 'body',
    fontWeight: '400',
    color: 'styleSheet.overCast',
  },
  upperHstack: {
    w: '100%',
    mt: '31px',
    justifyContent: 'space-between',
  },
  topView: {
    bg: 'styleSheet.canvas',
    h: '100%',
    w: '100%',
  },
};

const BookingCalendarModalScreen: FC<RootStackScreenProps<'BookingCalendar'>> = ({ route }) => {
  const { useNavigation } = useContext(NavigationContext);
  const [checkInDate, setCheckIn] = useState<string | null>(null);
  const [checkOutDate, setCheckOut] = useState<string | null>(null);

  useEffect(() => {
    if (!!route.params?.checkInDate && !!route.params?.checkOutDate) {
      setCheckIn(route.params?.checkInDate || '');
      setCheckOut(route.params?.checkOutDate || '');
    }
  }, [route.params?.checkInDate, route.params?.checkOutDate]);

  const { t } = useTranslation();

  const checkoutText =
    !!checkInDate && !!checkOutDate ? (
      <VStack {...styles.vstack}>
        <Text {...styles.checkOut}>{t('BOOK_A_STAY.CHECK_OUT')}</Text>
        <Text {...styles.textDate}>{moment(checkOutDate).format('ddd, MMM D')}</Text>
      </VStack>
    ) : !!checkInDate ? (
      <VStack {...styles.vstack}>
        <Text {...styles.checkOut}>{t('BOOK_A_STAY.CHECK_OUT')}</Text>
        <Text {...styles.textDate}>{t('SELECT_DATE')}</Text>
      </VStack>
    ) : (
      <Text {...styles.textDate}>{t('BOOK_A_STAY.CHECK_OUT')}</Text>
    );

  return (
    <Box {...styles.topView} safeAreaTop safeAreaBottom>
      <Box {...styles.box}>
        <HStack {...styles.upperHstack}>
          <Text {...styles.title}>{t('SELECT_DATES')}</Text>
          <Pressable onPress={() => useNavigation()} {...styles.pressable}>
            <CloseIcon {...styles.icon} />
          </Pressable>
        </HStack>
        <Divider {...styles.divider} />
      </Box>
      <Box {...styles.dateBox}>
        <HStack {...styles.hstack}>
          <VStack {...styles.vstack}>
            <Text {...styles.checkIn}>{t('BOOK_A_STAY.CHECK_IN')}</Text>
            <Text {...styles.textDate}>
              {!!checkInDate ? moment(checkInDate).format('ddd, MMM D') : t('SELECT_DATE')}
            </Text>
          </VStack>
          <Divider orientation="vertical" {...styles.HDivider} />
          {checkoutText}
        </HStack>
      </Box>
      <CalendarProvider>
        <BookCalendar
          pastScrollRange={0}
          futureScrollRange={12}
          onSelectedDatesChanged={(checkInDate1, checkOutDate1) => {
            setCheckIn(!!checkInDate1 ? moment(checkInDate1).format('YYYY-MM-DD') : null);
            setCheckOut(!!checkOutDate1 ? moment(checkOutDate1).format('YYYY-MM-DD') : null);
          }}
          selectedDates={[checkInDate, checkOutDate].filter((d) => !!d) as string[]}
        />
      </CalendarProvider>
      {!!checkInDate && (
        <Box {...styles.buttonBox}>
          <CTAButton
            children={t('ADD_DATES')}
            {...styles.stickyBar}
            onPress={() =>
              useNavigation('BookAStay', {
                checkInDate,
                checkOutDate,
              })
            }
          />
        </Box>
      )}
    </Box>
  );
};

export default BookingCalendarModalScreen;
