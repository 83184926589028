import { ComponentProps, FC } from 'react';
import { Box } from 'native-base';

const styles = {
  bg: 'styleSheet.lightStain',
  borderRadius: '12px',
  w: '100%',
};

type CardProps = ComponentProps<typeof Box>;

const Card: FC<CardProps> = (props) => {
  const { children, ...styleProps } = props;
  const mergedStyles = {
    ...styles,
    ...styleProps,
  };

  return (
    <Box {...mergedStyles}>
      {children}
    </Box>
  );
};

export default Card;
