import axios from 'axios';
import qs from 'qs';
import Constants from 'expo-constants';
import { Platform } from 'react-native';
import { SentryService } from '../../src/services/SentryService';

export const getLoginQuery: any = () => {
  const authRequestConfig: any = {
    redirect_uri:
      Platform.OS === 'web' ? Constants?.expoConfig?.extra?.redirectURIWeb : Constants?.expoConfig?.extra?.redirectURI,
    returnTo:
      Platform.OS === 'web' ? Constants?.expoConfig?.extra?.redirectURIWeb : Constants?.expoConfig?.extra?.redirectURI,
    client_id: Constants?.expoConfig?.extra?.auth0ClientId,
    response_type: 'code',
    scope: ['profile', 'offline_access'].join(' '),
    nonce: 'nonce',
  };

  const qs = new URLSearchParams(authRequestConfig);

  return {
    authorizationEndpoint: `https://${Constants?.expoConfig?.extra?.auth0Domain}/authorize?${qs.toString()}`,
  };
};

export const getLogoutQuery: any = () => {
  const authRequestConfig: any = {
    returnTo:
      Platform.OS === 'web'
        ? Constants?.expoConfig?.extra?.redirectURIWebLogout
        : Constants?.expoConfig?.extra?.redirectURI,
  };

  const qs = new URLSearchParams(authRequestConfig);

  return {
    logoutEndpoint: `https://${Constants?.expoConfig?.extra?.auth0Domain}/v2/logout?${qs.toString()}`,
  };
};

export const getAuth0Token = async (grantType: 'authorization_code' | 'refresh_token', code: string) => {
  const data: any = {
    grant_type: grantType,
    client_id: Constants?.expoConfig?.extra?.auth0ClientId,
  };

  if (grantType === 'authorization_code') {
    data.code = code;
    data.redirect_uri =
      Platform.OS === 'web' ? Constants?.expoConfig?.extra?.redirectURIWeb : Constants?.expoConfig?.extra?.redirectURI;
  } else if (grantType === 'refresh_token') {
    data.refresh_token = code;
  }

  const config = {
    method: 'post',
    url: `https://${Constants?.expoConfig?.extra?.auth0Domain}/oauth/token`,
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
    },
    data: qs.stringify(data),
  };

  try {
    const response = await axios(config);
    return {
      accessToken: response.data.access_token,
      refreshToken: response.data.refresh_token,
      expiresIn: response.data.expires_in,
    };
  } catch (e) {
    if (__DEV__) console.log(JSON.stringify(e));
    SentryService.captureException(e);
    throw 'Unable to retrieve token';
  }
};
