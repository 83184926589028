import { noop } from 'lodash';
import { createContext } from 'react';
import { GetUserSubscriptionsQuery } from 'screens/Booking/queries/getUserSubscriptions.generated';

export type GetUserSubscriptionsType = GetUserSubscriptionsQuery['getUserSubscriptions']['data'];

export type MultiSubscriptionsContextProps = {
  subscriptionsList: subscriptionList[] | null | undefined;
  isLoading: boolean;
};

export type subscriptionList = {
  memberId?: string | null | undefined;
  subscriptionId?: string | null | undefined;
  picture?: string | null | undefined;
  nickName?: string | null | undefined;
  remainingBalance?: string | null | undefined;
};

export const MultiSubscriptionsContext = createContext<MultiSubscriptionsContextProps>({
  subscriptionsList: [],
  isLoading: false,
});
