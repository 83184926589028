import { Box, Button, Text } from 'native-base';
import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { ListingsContext } from 'contexts/ListingsContext';
import { NavigationContext } from 'contexts/NavigationContext';

const styles = {
  button: {
    variant: 'primaryMobile',
    _text: {
      fontFamily: 'body600',
      color: 'styleSheet.canvas',
      fontSize: '2md',
    },
    w: ['100%', '100%', '100%', '85%', '85%'],
    mt: ['24px', '24px', '32px', '32px', '32px'],
  },
  box: {
    alignItems: 'center' as const,
    justifyContent: 'center' as const,
    w: ['100%', '100%', '100%', '40%', '40%'],
  },
  selectedHome: {
    fontFamily: ['body600', 'body600', 'body', 'body', 'body'],
    color: 'styleSheet.shadow',
    fontSize: ['2.5xl', '2.5xl', '5.5xl', '5.5xl', '5.5xl'],
    mt: ['40px', '40px', '56px', null, null],
  },
};

export interface IHomeRevenueDetails {
  homeId: string;
  selectedHome: string | null;
}

const HomeRevenueDetails: FC<IHomeRevenueDetails> = ({
  homeId,
  selectedHome,
}) => {
  const { t } = useTranslation();
  const { filterGuests } = useContext(ListingsContext);
  const { useNavigation } = useContext(NavigationContext);

  const handlePress = () => {
    useNavigation('PropertyDetailsNavigator', {
      screen: 'PropertyDetail',
      params: { listingId: homeId, filterGuests },
    });
  };

  return (
    <Box {...styles.box}>
      <Text {...styles.selectedHome}>{selectedHome}</Text>
      <Button {...styles.button} onPress={handlePress}>
        {t('HOME.HOME_DETAILS')}
      </Button>
    </Box>
  );
};

export default HomeRevenueDetails;
