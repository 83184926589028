import { DefaultTheme } from '@react-navigation/native';
import { extendTheme } from 'native-base';
import fontSizes from './fontSizes';
import components from './components';
import colors from './colors';
import fontConfig from './fontConfig';
import fonts from './fonts';

// https://docs.nativebase.io/default-theme
export const AppTheme = extendTheme({
  colors: colors,
  fontSizes: fontSizes,
  components: components,
  fontConfig: fontConfig,
  fonts: fonts,
  config: {
    initialColorMode: 'light',
  },
});

// Extend internal NativeBase theme to get autocomplete.
type AppThemeType = typeof AppTheme;
declare module 'native-base' {
  interface ICustomTheme extends AppThemeType {}
}

export const NavigationTheme = {
  ...DefaultTheme,
  dark: false,
  colors: {
    ...DefaultTheme.colors,
    primary: AppTheme.colors.primary[500],
    background: AppTheme.colors.background,
    card: AppTheme.colors.card,
    text: AppTheme.colors.textColor,
    border: AppTheme.colors.border,
    notification: AppTheme.colors.notification,
  },
};
