import { View, Box, Text } from 'native-base';
import { BookingDetailContext } from 'contexts/BookingDetailContext';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { FC, useContext } from 'react';

const styles = {
  box: {
    bg: 'styleSheet.lightStain',
    borderRadius: '12px',
    pl: '26px',
    mb: '16px',
    flexDirection: ['column' as const, 'column' as const, 'column' as const, 'row' as const, 'row' as const],
  },

  linkButton: {
    position: 'absolute' as const,
    right: '16px',
    top: '16px',
    w: '131px',
    bg: 'styleSheet.shadow',
    _text: {
      color: 'styleSheet.canvas',
      fontSize: 'sm',
      fontFamily: 'body',
    },
    _pressed: {
      bg: 'styleSheet.shadow',
      opacity: '0.6',
      _text: { color: 'styleSheet.canvas' },
    },
    _hover: {
      bg: 'styleSheet.darkStain',
      _text: { color: 'styleSheet.shadow' },
    },
    _focus: {
      bg: 'styleSheet.shadow',
    },
    borderRadius: '8px',
    zIndex: 1,
  },

  text: {
    mt: '24px',
    mb: '8px',
    fontFamily: 'body',
    opacity: '0.7',
    fontSize: 'sm',
    color: 'styleSheet.shadow',
  },

  date: {
    fontFamily: 'body',
    fontSize: '1xl',
    color: 'styleSheet.shadow',
  },

  dateText: {
    mt: '2px',
    mb: '8px',
    fontFamily: 'body',
    fontSize: 'sm',
    color: 'styleSheet.shadow',
  },
  checkoutView: {
    ml: ['0px', '0px', '0px', '0px', '48px'],
  },
  dateTextCheckout: {
    mt: '2px',
    mb: '24px',
    fontFamily: 'body',
    fontSize: 'sm',
    color: 'styleSheet.shadow',
  },
};

interface BookingDatesProps {}

const BookingDates: FC<BookingDatesProps> = () => {
  const { reservation } = useContext(BookingDetailContext);

  //TODO: the code below can be used when BE is able to update the dats
  // const { setEditedDates, editedDates } = React.useContext(BookingContext);
  // useEffect(() => {
  //   setEditedDates([reservation?.checkIn as string, reservation?.checkOut as string]);
  // }, []);

  const { t } = useTranslation();

  const CheckIn = moment(reservation?.checkInDateLocalized);
  const CheckOut = moment(reservation?.checkOutDateLocalized);

  return (
    <View>
      <Box {...styles.box}>
        {/* <Button children={t('CHANGE_DATES')} onPress={onBookingDate} {...styles.linkButton} /> */}
        <View>
          <Text {...styles.text}>{t('CONFIRMATION.CHECK_IN')}</Text>
          <Text {...styles.date}>{CheckIn.format('ddd, MMM D')}</Text>
          <Text {...styles.dateText}>{t('CHECK_IN_AFTER')}</Text>
        </View>
        <View {...styles.checkoutView}>
          <Text {...styles.text}>{t('CONFIRMATION.CHECK_OUT')}</Text>
          <Text {...styles.date}>{CheckOut.format('ddd, MMM D')}</Text>
          <Text {...styles.dateTextCheckout}>{t('CHECK_OUT_BY')}</Text>
        </View>
      </Box>
    </View>
  );
};

export default BookingDates;
