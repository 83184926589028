import { noop } from 'lodash';
import { createContext } from 'react';
import { GetAllReservationsQuery } from 'screens/Booking/queries/getAllReservations.generated';

// @ts-ignore
export type ReservationsType = GetAllReservationsQuery['getAllReservations'];

export type StaysContextProps = {
  reservations: ReservationsType | null | undefined;
  isLoading: boolean;
  refetchData: () => void;
};

//context
export const StaysContext = createContext<StaysContextProps>({
  reservations: noop,
  isLoading: false,
  refetchData: noop,
} as any);
