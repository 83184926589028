import { Box, Text } from 'native-base';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const styles = {
  box: {
    bg: 'styleSheet.lightStain',
    borderRadius: '12px',
    pl: '26px',
    pr: '26px',
  },

  text: {
    mt: '24px',
    color: 'styleSheet.lightOverCast',
    fontFamily: 'body600',
    mb: '8px',
    fontSize: 'sm',
  },
  area: {
    mb: '24px',
    color: 'styleSheet.shadow',
    fontFamily: 'body',
    fontWeight: '400',
    fontSize: 'sm',
  },

  innerText: {
    color: 'styleSheet.shadow',
    lineHeight: '30px',
    fontFamily: 'body600',
    fontSize: '1xl',
    mb: '2px',
  },
};

interface IHouseTitleBoxProps {
  homeTitle?: string | null;
  areaTitle?: string | null;
}

const HouseTitleBox: FC<IHouseTitleBoxProps> = ({ homeTitle, areaTitle }) => {
  const { t } = useTranslation();

  return (
    <Box {...styles.box}>
      <Text {...styles.text}>{t('CONFIRMATION.HOME')}</Text>
      <Text {...styles.innerText}>{homeTitle}</Text>
      <Text {...styles.area}>{areaTitle}</Text>
    </Box>
  );
};

export default HouseTitleBox;
