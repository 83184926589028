import { FC, useEffect } from 'react';
import { SubscriptionContext } from 'contexts/SubscriptionContext';
import { usePersistentValue } from 'hooks/usePersistentValue';
import {
  useReadSubscriptionBalanceBySubscriptionIdLazyQuery,
} from 'screens/Booking/queries/readSubscriptionBalanceBySubscriptionId.generated';

interface SubscriptionProviderProps {
  children: React.ReactNode;
}

export const SubscriptionProvider: FC<SubscriptionProviderProps> = ({ children }) => {
  const [storedSubscriptionId, , isStoredSubscriptionIdLoaded] = usePersistentValue<string | null>(
    'storedSubscriptionId',
    null
  );

  const [getSubscriptionBalance, { data, loading, refetch, called }] =
    useReadSubscriptionBalanceBySubscriptionIdLazyQuery({
      variables: {
        subscriptionId: storedSubscriptionId || '',
      },
    });

  useEffect(() => {
    if (isStoredSubscriptionIdLoaded && storedSubscriptionId) {
      getSubscriptionBalance();
    }
  }, [isStoredSubscriptionIdLoaded, storedSubscriptionId]);

  return (
    <SubscriptionContext.Provider
      value={{
        subscriptionBalance: data?.readSubscriptionBalanceBySubscriptionId?.data,
        isLoading: !called || loading,
        refetch,
      }}>
      {children}
    </SubscriptionContext.Provider>
  );
};
