import { createContext } from 'react';

import { GetAllCitiesQuery } from 'screens/Booking/queries/getAllCities.generated';

// @ts-ignore
export type CityType = GetAllCitiesQuery['getAllCities']['data'];

export interface City {
  city?: string | null;
  country?: string | null;
  full?: string | null;
  homeId?: string | null;
  neighborhood?: string | null;
  nickname?: string | null;
  state?: string | null;
  street?: string | null;
  title?: string | null;
}

interface CitiesContextProps {
  cities: CityType;
  isLoading: boolean;
}

export const CitiesContext = createContext<CitiesContextProps>({} as any);
