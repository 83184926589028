import { FC, useEffect, useState } from 'react';
import { CustomInputProps } from 'src/styles/input';
import { View, Text, HStack } from 'native-base';
import { BaseInput } from './baseInput';
import { getErrorStyle } from '../../styles/baseStyles';
import { useTranslation } from 'react-i18next';
import { RequiredIcon } from './RequiredIcon';

const customErrorDefault = {
  fontSize: 'sm',
  fontWeight: '400',
  fontFamily: 'body',
  color: 'notification',
};
const styles = {
  labelFocused: {
    mt: ['-66px', '-66px', '-65px', '-68px'],
    fontSize: 'sm',
    fontWeight: '400',
    fontFamily: 'body',
    color: 'styleSheet.shadow',
    opacity: '0.5',
    ml: '16px',
  },
  labelNotFocused: {
    display: 'none',
  },
  errorContainer: {
    position: 'absolute' as const,
    right: '16px',
    top: ['26px', '26px', '30px', '30px'],
  },
  requiredError: {
    ...customErrorDefault,
    ml: '5px',
    mt: ['0px', '-1px', '0px', '-3px'],
  },
  customError: {
    ...customErrorDefault,
    ml: '5px',
    mt: ['0px', '-1px', '0px', '-3px'],
  },
  noError: {
    display: 'none',
  },
};
const viewStyles = {
  position: 'absolute',
};

export const CustomTextInput: FC<CustomInputProps> = ({
  label,
  style,
  fieldName,
  handleFieldChange,
  keyboardType,
  errorMsg,
  autoCap,
  placeholder,
  validateField,
  backgroundColor,
  initialValue,
}) => {
  const { t } = useTranslation();

  const [isActive, setActive] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');
  const [isError, setIsError] = useState<boolean>(false);
  const [defaultErrorMsg, setErrorMsg] = useState<string>(t('INPUT_DEFAULT_ERROR'));
  const [customErrorMsg, setCustomErrorMsg] = useState<string>();
  const [formattedValue, setFormattedValue] = useState<string>('');
  const isPhoneOrEmailField = fieldName === 'phoneNumber' || fieldName === 'emailAddress';

  const handleFocus = () => {
    setActive(true);
  };
  const handleChange = (v: string) => {
    validateField(fieldName);
    if (fieldName === 'phoneNumber') {
      if (v.length === 14 || !errorMsg) {
        setCustomErrorMsg('');
        setIsError(false);
      } else {
        setCustomErrorMsg(errorMsg);
      }
      handleFieldChange(`${fieldName}`, formatInput(v, fieldName));
    } else {
      handleFieldChange(`${fieldName}`, v);
    }
    setValue(v);
  };

  useEffect(() => {
    if (initialValue) {
      setValue(initialValue);
    }
  }, []);

  useEffect(() => {
    if (errorMsg !== undefined) {
      setIsError(true);
      setErrorMsg(`${errorMsg}`);
      setCustomErrorMsg(`${errorMsg}`);
    }
  }, [errorMsg]);

  const formatInput = (input: string, fieldName: string) => {
    if (input.length > 14) {
      validateField(fieldName);
      return formattedValue;
    }
    let inputT = input.replace(/\D/g, '');
    let numbers = inputT;
    let char = { 0: '(', 3: ') ', 6: '-' };
    inputT = '';
    if (numbers.length >= 3) {
      for (var i = 0; i < numbers.length; i++) {
        //@ts-ignore
        inputT += (char[i] || '') + numbers[i];
      }
      setFormattedValue(inputT);
      return inputT;
    } else {
      setFormattedValue(numbers);
      return numbers;
    }
  };
  const handleBlur = () => {
    if (fieldName === 'phoneNumber') {
      if (formattedValue.length < 14 && !validatePhone(formattedValue)) {
        setIsError(true);
        setActive(true);
        validateField(fieldName);
        setCustomErrorMsg(t('INVALID_PHONE'));
      } else {
        setIsError(false);
        setActive(false);
        validateField(fieldName);
        setCustomErrorMsg('');
      }
    } else if (fieldName === 'emailAddress') {
      if (validateEmail(value)) {
        setIsError(false);
        setActive(false);
        validateField(fieldName);
        setCustomErrorMsg('');
      } else {
        setIsError(true);
        setActive(true);
        validateField(fieldName);
        setCustomErrorMsg(t('INVALID_EMAIL_ERROR'));
      }
    } else {
      if (value.length > 0 && !errorMsg) {
        validateField(fieldName);
        setActive(true);
        setIsError(false);
      } else if (value.length === 0) {
        setActive(false);
        validateField(fieldName);
      }
    }
  };

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const validatePhone = (phoneNumber) => {
    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (phoneNumber.match(phoneno)) {
      return true;
    } else {
      return false;
    }
  };
  const labelStyle = isActive || value.length > 0 ? styles.labelFocused : styles.labelNotFocused;

  const errorStyle =
    isError && errorMsg === 'Required'
      ? styles.requiredError
      : isError && errorMsg !== 'Required'
      ? styles.customError
      : styles.noError;

  return (
    <View>
      <BaseInput
        onFocus={handleFocus}
        onBlur={handleBlur}
        isFocused={isActive}
        style={style}
        keyboardType={keyboardType}
        value={fieldName === 'phoneNumber' && formattedValue.length >= 2 ? formattedValue : value}
        onChangeText={(v) => handleChange(v)}
        error={isError}
        placeholder={!isActive ? placeholder : ''}
        placeholderTextColor={isActive ? 'textStay' : 'customBlack'}
        autoCapitalize={autoCap}
        backgroundColor={backgroundColor}
      />
      <Text {...labelStyle}>{label}</Text>

      <HStack {...styles.errorContainer}>
        <RequiredIcon
          style={getErrorStyle(isPhoneOrEmailField ? customErrorMsg : errorMsg)}
          size={13}
          color="rgb(255, 69, 58)"
        />
        <Text {...errorStyle}>{isPhoneOrEmailField ? customErrorMsg : errorMsg}</Text>
      </HStack>
    </View>
  );
};
