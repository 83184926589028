import PropertyDetailHeader from 'components/Home/PropertyDetailHeader';
import FinancialOverviews from 'components/SubscriptionDetails/FinancialOverview';
import { HomeEquityContext } from 'contexts/HomeEquityContext';
import { SubscriptionContext } from 'contexts/SubscriptionContext';
import { Box, View, ScrollView, Hidden, Text, VStack, useTheme } from 'native-base';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import separator from 'utils/separator';

const styles = {
  mainView: {
    bg: 'styleSheet.canvas',
    h: '100%',
    w: '100%',
  },
  hl: {
    w: ['90%', '90%', '90%', '40%', '40%'],
    borderWidth: '1px',
    borderColor: 'styleSheet.overCast',
    alignSelf: 'center' as const,
    opacity: '0.15',
    my: ['40px', '40px', '56px', 0, 0],
  },
  hstack: {
    alignItems: 'center' as const,
    justifyContent: 'center' as const,
    w: '100%',
  },
  icon: {
    color: 'pdp.shadow',
    alignSelf: 'center' as const,
    size: '8',
  },
  pressable: {
    position: 'absolute' as const,
    left: '0',
  },
  title: {
    color: 'pdp.shadow',
    fontSize: ['2md', '2md', '1xl', '1xl', '1xl'],
    fontFamily: 'body600',
  },
  overviewBox: {
    w: '100%',
  },
  mainBox: {
    flexDirection: ['column' as const, 'column' as const, 'column' as const, 'row' as const, 'row' as const],
    w: '100%',
    justifyContent: 'center' as const,
    mt: [null, null, null, '64px', '64px'],
  },
  vl: {
    h: '100%',
    borderWidth: '1px',
    borderColor: 'styleSheet.overCast',
    alignSelf: 'center' as const,
    opacity: '0.15',
    mx: [0, 0, 0, '80px', '80px'],
  },
  pageHeader: {
    alignSelf: 'center' as const,
    fontFamily: 'body600',
    fontSize: '1xl',
  },
  chartWrapper: {
    alignItems: 'center' as const,
    space: ['24px', '24px', '32px', '32px', '32px'],
  },
  chartTitle: {
    color: 'styleSheet.shadow',
    fontFamily: 'body600',
    fontSize: '2md',
  },
  scrollView: {
    pt: ['32px', '32px', 0, 0, 0],
  },
};

type AnnualSubscriptionScreenProps = {};

const AnnualSubscriptionScreen = ({}: AnnualSubscriptionScreenProps) => {
  const { subscriptionBalance } = useContext(SubscriptionContext);
  const { equityDetails } = useContext(HomeEquityContext);
  const usedAmount = useMemo(() => subscriptionBalance?.usedAmount, [subscriptionBalance]);
  const remainingBalance = useMemo(() => subscriptionBalance?.remainingAmount, [subscriptionBalance]);
  const startingBalance = useMemo(() => subscriptionBalance?.totalAmount, [subscriptionBalance]);
  const amountPaid = useMemo(() => equityDetails?.totalSubscriptionPaid, [equityDetails]);
  const monthlyPayment = useMemo(() => equityDetails?.monthlySubscription, [equityDetails]);
  const nextPayment = useMemo(() => equityDetails?.nextPaymentDate, [equityDetails]);

  const { t } = useTranslation();
  const theme = useTheme();

  const remainingData = Math.round(remainingBalance as number).toString();
  const startingBalanceData = Math.round(startingBalance as number).toString();
  const usedData = Math.round(usedAmount as number).toString();
  const monthlyPaymentData = Math.round(monthlyPayment as number).toString();
  const amountPaidData = Math.round(amountPaid as number).toString();

  const usageChartLabel = t('MULTI_HOME.REMAINING');
  const usageChartAmount = remainingBalance;
  const usageGraphColors = [theme.colors.styleSheet.lightOverCast, theme.colors.styleSheet.winter];
  const usageGraphData = [usedData, remainingData];
  const usageSecondaryValues = [
    {
      label: t('FINANCIALS.STARTING_BALANCE'),
      value: `$${separator(startingBalanceData)}`
    },
    {
      label: t('FINANCIALS.USED_AMOUNT'),
      value: `$${separator(usedData)}`
    },
    {
      label: t('FINANCIALS.REMAINING_BALANCE'),
      value: `$${separator(remainingData)}`
    },
  ];

  const paidChartLabel = t('PAID');
  const paidChartAmount = amountPaid;
  const paidGraphColors = [theme.colors.styleSheet.lightOverCast, theme.colors.styleSheet.spring];
  const paidGraphData = [amountPaidData, remainingData];
  const nextPaymentDate = nextPayment ? moment(nextPayment).format('MMM DD, yyyy') : 'N/A';
  const paidSecondaryValues = [
    {
      label: t('FINANCIALS.AMOUNT_PAID'),
      value: `$${separator(amountPaidData)}`
    },
    {
      label: t('FINANCIALS.MONTHLY_PAYMENT'),
      value: `$${separator(monthlyPaymentData)}`
    },
    {
      label: t('FINANCIALS.NEXT_PAYMENT'),
      value: `${nextPaymentDate}`
    },
  ];

  return (
    <Box safeAreaTop {...styles.mainView}>
      <PropertyDetailHeader />
      <ScrollView {...styles.scrollView}>
        <Hidden till={'lg'}>
          <Text {...styles.pageHeader}>{t('FINANCIALS.ANNUAL_SUBSCRIPTION')}</Text>
        </Hidden>
        <Box {...styles.mainBox}>
          <VStack {...styles.chartWrapper}>
            <Text {...styles.chartTitle}>
              {t('FINANCIALS.SUBSCRIPTION_USAGE')}
            </Text>
            <FinancialOverviews
              condensed
              graphicColors={usageGraphColors}
              graphicData={usageGraphData}
              mainAmount={usageChartAmount!}
              mainLabel={usageChartLabel}
              secondaryValues={usageSecondaryValues}
            />
          </VStack>

          <Hidden from={'lg'}>
            <View {...styles.hl} />
          </Hidden>
          <Hidden till={'lg'}>
            <View {...styles.vl} />
          </Hidden>

          {/* TODO: Replace values used here with ones that are more accurate. See https://github.com/Live-Summer/app-client/pull/458 */}
          <VStack {...styles.chartWrapper}>
            <Text {...styles.chartTitle}>
              {t('FINANCIALS.SUBSCRIPTION_AMOUNT_PAID')}
            </Text>
            <FinancialOverviews
              condensed
              graphicColors={paidGraphColors}
              graphicData={paidGraphData}
              mainAmount={paidChartAmount!}
              mainLabel={paidChartLabel}
              secondaryValues={paidSecondaryValues}
            />
          </VStack>
        </Box>
      </ScrollView>
    </Box>
  );
};

export default AnnualSubscriptionScreen;
