import { FC } from 'react';
import { Box, Text, Button } from 'native-base';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const styles = {
  box: {
    bg: 'styleSheet.lightStain',
    borderRadius: '12px',
    pl: '26px',
    mt: '16px',
  },

  linkButton: {
    position: 'absolute' as const,
    right: '16px',
    top: '16px',
    w: '131px',
    bg: 'styleSheet.lightShadow',
    _text: {
      color: 'styleSheet.canvas',
      fontSize: 'sm',
      fontFamily: 'body',
      fontWeight: '400',
    },
    _pressed: {
      bg: 'styleSheet.shadow',
      opacity: '0.6',
      _text: { color: 'styleSheet.canvas' },
    },
    _hover: {
      bg: 'styleSheet.darkStain',
      _text: { color: 'styleSheet.shadow' },
    },
    _focus: {
      bg: 'styleSheet.lightShadow',
    },
    borderRadius: '8px',
    zIndex: 1,
  },

  text: {
    mt: '24px',
    mb: '8px',
    fontFamily: 'body600',
    fontSize: 'sm',
    color: 'styleSheet.lightOverCast',
  },

  date: {
    fontFamily: 'body',
    fontWeight: '400',
    fontSize: '1xl',
    color: 'styleSheet.shadow',
  },

  dateText: {
    mt: '2px',
    mb: '8px',
    fontFamily: 'body',
    fontWeight: '400',
    fontSize: 'sm',
    color: 'styleSheet.shadow',
  },
  dateTextCheckout: {
    mt: '2px',
    mb: '24px',
    fontFamily: 'body',
    fontWeight: '400',
    fontSize: 'sm',
    color: 'styleSheet.shadow',
  },
};

interface IDateConfirmationBox {
  Checkin: string;
  Checkout: string;
  onPress: () => void;
}

const DateConfirmationBox: FC<IDateConfirmationBox> = ({ onPress, Checkin, Checkout }) => {
  const { t } = useTranslation();
  const CheckIn = moment(Checkin);
  const CheckOut = moment(Checkout);

  return (
    <Box {...styles.box}>
      <Button children={t('CHANGE_DATES')} onPress={onPress} {...styles.linkButton} />
      <Text {...styles.text}>{t('CONFIRMATION.CHECK_IN')}</Text>
      <Text {...styles.date}>{CheckIn.format('ddd, MMMM D')}</Text>
      <Text {...styles.dateText}>{t('CHECK_IN_AFTER')}</Text>
      <Text {...styles.text}>{t('CONFIRMATION.CHECK_OUT')}</Text>
      <Text {...styles.date}>{CheckOut.format('ddd, MMMM D')}</Text>
      <Text {...styles.dateTextCheckout}>{t('CHECK_OUT_BY')}</Text>
    </Box>
  );
};

export default DateConfirmationBox;
