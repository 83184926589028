import { Text, Box, HStack, VStack, Hidden, Button } from 'native-base';
import CTAButton from 'components/libs/button/CTAButton';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useGetPriceCalculationQuery } from '../../screens/Booking/queries/getPriceCalculation.generated';
import { Loading } from 'components/Loading';
import { FC } from 'react';

const styles = {
  box: {
    position: 'absolute' as const,
    bottom: '0',
    bg: 'bgPage',
    h: '87px',
    w: '100%',
    borderTopWidth: ['1', '1', '1', '0', '0'],
    borderColor: 'rgba(49, 44, 44, 0.15);',
  },
  innerBox: {
    ml: ['16px', '0px', '92px', '0px', '0px'],
    mr: ['16px', '0px', '80px', '0px', '0px'],
  },
  hstack: {
    alignItems: 'center',
    mt: '16px',
    w: '100%',
    justifyContent: 'space-between',
  },
  calenderButton: {
    h: '54px',
    bg: 'pdp.shadow',
    mt: '16px',

    borderRadius: '8px',
    _text: {
      color: 'styleSheet.canvas',
      fontSize: '2md',
    },
    _pressed: {
      bg: 'styleSheet.shadow',
      opacity: '0.6',
      _text: { color: 'styleSheet.canvas' },
    },
    _hover: {
      bg: 'styleSheet.darkStain',
      _text: { color: 'styleSheet.shadow' },
    },
    _focus: {
      bg: 'styleSheet.shadow',
    },
  },
  dateButton: {
    h: ['54px', '54px', '54px', '70px', '70px'],
    bg: 'pdp.shadow',
    borderRadius: '8px',
    w: ['50%', '50%', '50%', '100%', '100%'],
    _text: {
      color: 'styleSheet.canvas',
      fontSize: '2md',
      fontFamily: 'body600',
    },
    _pressed: {
      bg: 'styleSheet.shadow',
      opacity: '0.6',
      _text: { color: 'styleSheet.canvas' },
    },
    _hover: {
      bg: 'styleSheet.darkStain',
      _text: { color: 'styleSheet.shadow' },
    },
    _focus: {
      bg: 'styleSheet.shadow',
    },
  },

  dateButtonDisabled: {
    h: '54px',
    bg: 'pdp.shadow',
    opacity: '0.5',
    borderRadius: '8px',
    w: ['50%', '50%', '50%', '100%', '100%'],
    _text: {
      color: 'styleSheet.canvas',
      fontSize: '2md',
      fontFamily: 'body600',
    },
  },

  total: {
    color: 'pdp.shadow',
    fontSize: '2md',
    fontWeight: '400',
  },
  date: {
    color: 'pdp.shadow',
    fontSize: 'sm',
    fontFamily: 'body600',
    opacity: '0.55',
  },
};

interface CTABooking {
  onSeeAvailableDates: () => void;
  onBooking: () => void;
  checkInDate: string | null;
  checkOutDate: string | null;
  listingId: string;
  disabled: boolean;
}

const CTABooking: FC<CTABooking> = ({
  onBooking,
  onSeeAvailableDates,
  checkInDate,
  checkOutDate,
  listingId,
  disabled,
}) => {
  const { t } = useTranslation();
  const btnStyle = disabled ? styles.dateButtonDisabled : styles.dateButton;

  const { data: price, loading } = useGetPriceCalculationQuery({
    variables: {
      from: checkInDate!,
      guestyListingId: listingId!,
      to: checkOutDate!,
    },
    skip: !checkInDate || !checkOutDate,
  });

  const fmtDateLbl = (date: string) => moment(date).format('MMM D');

  const datesLabel = () =>
    !!checkInDate && !!checkOutDate ? `${fmtDateLbl(checkInDate)} - ${fmtDateLbl(checkOutDate)}` : '';

  const CTAKey = !!checkOutDate ? 'PDP_GUEST.BOOK_HOME' : 'PROPERTY_DETAIL.SEE_AVAILABLE_DATES';
  const priceElement = (
    <>
      <Text {...styles.total}>
        ${price?.getPriceCalculation.data?.totalPriceWithTaxAndDiscount} {t('PROPERTY_DETAIL.TOTAL')}
      </Text>
      <Text {...styles.date}>{datesLabel()}</Text>
    </>
  );

  const content = !!checkOutDate ? (
    <HStack {...styles.hstack}>
      <Hidden from={'lg'}>
        <VStack>{loading ? <Loading /> : priceElement}</VStack>
      </Hidden>
      <Button onPress={onBooking} disabled={disabled} id="Book" {...btnStyle}>
        {t(CTAKey)}
      </Button>
    </HStack>
  ) : (
    <CTAButton onPress={onSeeAvailableDates} {...styles.calenderButton}>
      {t(CTAKey)}
    </CTAButton>
  );

  return (
    <Box {...styles.box}>
      <Box {...styles.innerBox}>{content}</Box>
    </Box>
  );
};

export default CTABooking;
