import * as Types from '../../generated/types.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetListingCalendar2QueryVariables = Types.Exact<{
  listingId: Types.Scalars['String'];
  from: Types.Scalars['String'];
  to: Types.Scalars['String'];
}>;


export type GetListingCalendar2Query = { __typename?: 'Query', getListingCalendar: { __typename?: 'ListingCalendarResponse', status: { __typename?: 'GuestyStatus', code: number, message: string }, data?: Array<{ __typename?: 'ListingCalendar', date: string, listingId: string, currency: string, price: number, isBasePrice: boolean, minNights: number, isBaseMinNights: boolean, status: string, blocks: { __typename?: 'Blocks', m: boolean, r: boolean, b: boolean, bd: boolean, sr: boolean, abl: boolean, a: boolean, bw: boolean, o: boolean, pt: boolean } }> | null } };


export const GetListingCalendar2Document = gql`
    query getListingCalendar2($listingId: String!, $from: String!, $to: String!) {
  getListingCalendar(guestyListingId: $listingId, from: $from, to: $to) {
    status {
      code
      message
    }
    data {
      date
      listingId
      currency
      price
      isBasePrice
      minNights
      isBaseMinNights
      status
      blocks {
        m
        r
        b
        bd
        sr
        abl
        a
        bw
        o
        pt
      }
    }
  }
}
    `;

/**
 * __useGetListingCalendar2Query__
 *
 * To run a query within a React component, call `useGetListingCalendar2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetListingCalendar2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListingCalendar2Query({
 *   variables: {
 *      listingId: // value for 'listingId'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useGetListingCalendar2Query(baseOptions: Apollo.QueryHookOptions<GetListingCalendar2Query, GetListingCalendar2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetListingCalendar2Query, GetListingCalendar2QueryVariables>(GetListingCalendar2Document, options);
      }
export function useGetListingCalendar2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetListingCalendar2Query, GetListingCalendar2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetListingCalendar2Query, GetListingCalendar2QueryVariables>(GetListingCalendar2Document, options);
        }
export type GetListingCalendar2QueryHookResult = ReturnType<typeof useGetListingCalendar2Query>;
export type GetListingCalendar2LazyQueryHookResult = ReturnType<typeof useGetListingCalendar2LazyQuery>;
export type GetListingCalendar2QueryResult = Apollo.QueryResult<GetListingCalendar2Query, GetListingCalendar2QueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    