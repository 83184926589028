import { useContext } from 'react';
import { Pressable, Box, HStack, Text, Divider, Hidden } from 'native-base';
import { NavigationContext } from 'contexts/NavigationContext';
import { useTranslation } from 'react-i18next';
import BackArrow from 'components/ConfirmationPage/BackArrow';
import BackIcon from '../../assets/icons/Back-Arrow.svg';

const styles = {
  pressable: {
    alignSelf: 'center' as const,
    w: '32px',
    h: '32px',
    position: 'absolute' as const,
    left: '16px',
  },
  header: {
    fontSize: ['2md', '2md', '1xl', '1xl', '1xl'],
    fontFamily: 'body600',
    color: 'styleSheet.shadow',
    alignSelf: 'center' as const,
  },
  top: {
    justifyContent: 'center' as const,
    w: '100%',
    pt: '26px',
    pb: '26px',
  },
  divider: {
    w: '100%',
    color: 'styleSheet.darkStain',
    borderWidth: 1.5,
    opacity: '0.15',
  },
  headerBox: {
    mt: '24px',
  },
};

const HomeEquityHeader = () => {
  const { useNavigation } = useContext(NavigationContext);
  const { t } = useTranslation();

  return (
    <Box>
      <Hidden from={'md'}>
        <Box>
          <HStack {...styles.top}>
            <Pressable onPress={() => useNavigation('MyHomeScreen')} {...styles.pressable}>
              <BackIcon />
            </Pressable>
            <Text {...styles.header}>{t('HOME.HOME_EQUITY')}</Text>
          </HStack>
          <Divider {...styles.divider} />
        </Box>
      </Hidden>
      <Hidden till={'md'}>
        <Box {...styles.headerBox}>
          <BackArrow />
          <Text {...styles.header}>{t('HOME.HOME_EQUITY')}</Text>
        </Box>
      </Hidden>
    </Box>
  );
};

export default HomeEquityHeader;
