import { Box, HStack, Text } from 'native-base';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import separator from 'utils/separator';

const styles = {
  header: {
    fontSize: '2md',
    fontFamily: 'body600',
    color: 'styleSheet.shadow',
  },
  subText: {
    fontSize: 'sm',
    fontFamily: 'body',
    color: 'styleSheet.shadow',
  },
  subTextAsAmount: {
    fontSize: 'sm',
    fontFamily: 'body',
    color: 'styleSheet.shadow',
    position: 'absolute' as const,
    right: 0,
  },
  hstackSubscriptionDetail: {
    mt: '12px',
  },
  subscriptionPaymentDetails: {
    mt: '24px',
  },
};

interface ISubscriptionPaymentProps {
  totalForYear1: string | null;
  totalForYear2: string | null;
  totalForYear3: string | null;
  totalSubscription: string | null;
}

const SubscriptionPayment: FC<ISubscriptionPaymentProps> = ({
  totalForYear1,
  totalForYear2,
  totalForYear3,
  totalSubscription,
}) => {
  const { t } = useTranslation();

  return (
    <Box {...styles.subscriptionPaymentDetails}>
      <Text {...styles.header}>{t('HOME.SUBSCRIPTION_PAYMENT')}</Text>
      <HStack {...styles.hstackSubscriptionDetail}>
        <Text {...styles.subText}>{t('HOME.YEAR1_TOTAL')}</Text>
        <Text {...styles.subTextAsAmount}>{`$${separator(totalForYear1!)}`}</Text>
      </HStack>

      <HStack {...styles.hstackSubscriptionDetail}>
        <Text {...styles.subText}>{t('HOME.YEAR2_TOTAL')}</Text>
        <Text {...styles.subTextAsAmount}>{`$${separator(totalForYear2!)}`}</Text>
      </HStack>

      <HStack {...styles.hstackSubscriptionDetail}>
        <Text {...styles.subText}>{t('HOME.YEAR3_TOTAL')}</Text>
        <Text {...styles.subTextAsAmount}>{`$${separator(totalForYear3!)}`}</Text>
      </HStack>

      <HStack {...styles.hstackSubscriptionDetail}>
        <Text {...styles.subText}>{t('HOME.SUBSCRIPTION_TOTAL')}</Text>
        <Text {...styles.subTextAsAmount}>{`$${separator(totalSubscription!)}`}</Text>
      </HStack>
    </Box>
  );
};

export default SubscriptionPayment;
