import { FC, useContext, useMemo } from 'react';
import { Box, Button, Text } from 'native-base';
import { BookingDetailContext } from 'contexts/BookingDetailContext';
import { useTranslation } from 'react-i18next';

const styles = {
  box: {
    bg: 'styleSheet.lightStain',
    borderRadius: '12px',
    pl: '26px',
  },
  linkButton: {
    position: 'absolute' as const,
    right: '16px',
    top: '16px',
    w: '131px',
    bg: 'styleSheet.shadow',
    _text: {
      color: 'styleSheet.canvas',
      fontSize: 'sm',
      fontFamily: 'body',
    },
    _pressed: {
      bg: 'styleSheet.shadow',
      opacity: '0.6',
      _text: { color: 'styleSheet.canvas' },
    },
    _hover: {
      bg: 'styleSheet.darkStain',
      _text: { color: 'styleSheet.shadow' },
    },
    _focus: {
      bg: 'styleSheet.shadow',
    },
    borderRadius: '8px',
    zIndex: 1,
  },

  text: {
    mt: '24px',
    mb: '8px',
    fontFamily: 'body',
    opacity: '0.7',
    fontSize: 'sm',
    color: 'styleSheet.shadow',
  },

  innerText: {
    fontFamily: 'body',
    fontSize: '1xl',
    color: 'styleSheet.shadow',
  },

  detail: {
    mt: '2px',
    mb: '24px',
    fontFamily: 'body',
    fontSize: 'sm',
    color: 'styleSheet.shadow',
  },
};

interface BookingGuestsProps {
  onGuestHandler: () => void;
  past?: boolean;
}

const BookingGuests: FC<BookingGuestsProps> = ({ onGuestHandler, past = false }) => {
  const { t } = useTranslation();
  const { guests } = useContext(BookingDetailContext);

  const { guestsNumber, adults, children, infants, pets } = useMemo(() => {
    if (!guests) {
      return {
        guestsNumber: 0,
        adults: 0,
        children: 0,
        infants: 0,
        pets: 0,
      };
    }
    return {
      guestsNumber: (guests.adults || 0) + (guests.children || 0),
      adults: t('PDP_GUEST.ADULT', { count: guests.adults }),

      children: t('PDP_GUEST.CHILD', { count: guests.children }),

      infants: t('PDP_GUEST.INFANT', { count: guests.infants }),

      pets: t('PDP_GUEST.PET', { count: guests.pets }),
    };
  }, [guests]);

  const guestCount = [adults, children, infants, pets].filter((value) => !!value).join(', ');

  const content =
    guests?.adults === 1 && guests?.children === 0 && guests?.infants === 0 && guests?.pets === 0 ? (
      <Text {...styles.detail}>{t('LET_US_KNOW')}</Text>
    ) : (
      <Text {...styles.detail}>{guestCount}</Text>
    );

  return (
    <Box {...styles.box}>
      {!past && <Button children={t('CHANGE_GUESTS')} onPress={onGuestHandler} {...styles.linkButton} />}
      <Text {...styles.text}>{t('CONFIRMATION.GUESTS')}</Text>
      <Text {...styles.innerText}>
        {guestsNumber === 1 ? `${t('GUEST_NUMBER', { guestsNumber })}` : `${t('GUESTS_NUMBER', { guestsNumber })}`}
      </Text>
      {content}
    </Box>
  );
};

export default BookingGuests;
