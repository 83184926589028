import { Image } from 'native-base';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import Layout from 'constants/Layout';

const windowHeight = Layout.window.height;
interface ImageSliderProps {
  imagesArray: string[];
  setIndex: (e: number) => void;
  staticImage?: boolean;
}

const styles = {
  imageHeight: {
    w: ['100%', '100%', '100%', '100%'],
    h: ['93%', '93%', '93%', '100%'],
    ml: ['0px', '0px', '0px', '90px'],
    borderRadius: '15px',
  },
  slideImageStyle: {
    width: '900px',
    height: windowHeight > 799 ? '550px' : '350px',
    aspectRatio: 'auto',
  },
};

const WebImageSlider = ({ imagesArray, setIndex = () => {}, staticImage = false }: ImageSliderProps) => {
  if (staticImage) {
    return <Image {...styles.imageHeight} source={{ uri: imagesArray[0] }} alt="main pic" />;
  }

  return (
    <div style={{ textAlign: 'center' }}>
      <Slide autoplay={false} onChange={(oldIndex, newIndex) => setIndex(newIndex + 1)}>
        {imagesArray.map((slideImage, index) => {
          return (
            <div className="each-slide" key={index}>
              <img src={slideImage} style={styles.slideImageStyle} />
            </div>
          );
        })}
      </Slide>
    </div>
  );
};

export default WebImageSlider;
