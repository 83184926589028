import { Box, Hidden, HStack, Text } from 'native-base';
import { MinusIcon, PlusIcon } from 'components/libs/icon/Icons';
import { useTranslation } from 'react-i18next';

const styles = {
  hstack: {
    alignItems: 'center',
  },

  innerHstack: {
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '110px',
    position: 'absolute' as const,
    right: 0,
  },

  text: {
    fontSize: '2md',
    fontWeight: '400',
    fontFamily: 'body',
    color: 'pdp.shadow',
    pt: [null, null, null, '10px', '10px'],
    pb: [null, null, null, '10px', '10px'],
  },

  number: {
    fontSize: 'lg',
    fontWeight: '400',
    fontFamily: 'body',
  },
  iconBox: {
    borderRadius: '16px',
    borderColor: 'styleSheet.shadow',
    borderWidth: '1px',
    w: '32px',
    h: '32px',
    bg: 'bgPage',
    alignItems: 'center',
    justifyContent: 'center',
  },
  subText: {
    fontSize: 'sm',
    fontFamily: 'body600',
    color: 'styleSheet.shadow',
    opacity: '0.55',
    ml: '16px',
  },
};

interface IGuestAddBarProps {
  guestNumber?: number;
  translationKey: string;
  guest: number;
  addHandler: (v: number) => void;
  removeHandler: (v: number) => void;
  opacity?: number;
  minusOpacity?: number;
}

const GuestAddBar = ({
  guestNumber,
  translationKey,
  guest,
  addHandler,
  removeHandler,
  opacity = 1,
  minusOpacity = 1,
  ...props
}: IGuestAddBarProps) => {
  const { t } = useTranslation();

  return (
    <HStack {...styles.hstack} {...props}>
      <Text {...styles.text}>{t(translationKey)}</Text>
      {translationKey === 'CHILDREN' ? (
        <Hidden till={'lg'}>
          <Text {...styles.subText}>{t('AGES')}</Text>
        </Hidden>
      ) : null}

      {translationKey === 'INFANTS' ? (
        <Hidden till={'lg'}>
          <Text {...styles.subText}>{t('UNDER2')}</Text>
        </Hidden>
      ) : null}

      <HStack {...styles.innerHstack}>
        <Box {...styles.iconBox} opacity={minusOpacity}>
          <MinusIcon size={20} color="#312C2C" onPress={removeHandler} />
        </Box>
        <Text {...styles.number}>{guest}</Text>
        <Box {...styles.iconBox} opacity={opacity}>
          <PlusIcon size={20} color="#312C2C" onPress={addHandler} />
        </Box>
      </HStack>
    </HStack>
  );
};

export default GuestAddBar;
