/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import { FontAwesome } from '@expo/vector-icons';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as React from 'react';
import { Platform } from 'react-native';
import { RootStackParamList } from '../../types';
import { NavigationTheme } from 'constants/Theme';
import { AuthContext } from 'contexts/authContext';
import { NavigationProvider } from 'providers/NavigationProvider';
import { useContext } from 'react';
import LinkingConfiguration from 'navigation/LinkingConfiguration';
import MultiAccHomePicker from 'screens/Booking/MultiAccHomePicker';
import { MultiSubscriptionsContext } from 'contexts/MultiSubscriptionsContext';
import { Loading } from 'components/Loading';
import { useTranslation } from 'react-i18next';
import { LoginNavigator } from './LoginNavigator';
import { ProfileTabNavigator } from './ProfileTabNavigator';

let storageSubscriptionID: string | null;

const AppNavigationContainer = () => {
  const { isUserAuthenticated, storedSubscriptionId } = useContext(AuthContext);
  storageSubscriptionID = storedSubscriptionId;
  const { subscriptionsList } = useContext(MultiSubscriptionsContext);

  return (
    <NavigationContainer theme={NavigationTheme} linking={LinkingConfiguration}>
      <NavigationProvider>
        {isUserAuthenticated ? (
          subscriptionsList?.length! >= 2 ? (
            <MultiAcountGate />
          ) : subscriptionsList?.length! == 1 ? (
            <ProfileTabNavigator />
          ) : (
            <Loading />
          )
        ) : (
          <LoginNavigator />
        )}
      </NavigationProvider>
    </NavigationContainer>
  );
};

export default function Navigation() {
  const { isReady } = useContext(AuthContext);
  return !isReady ? null : <AppNavigationContainer />;
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */

const DEFAUL_OPTIONS = {
  headerShown: false,
};

export const getOptions = (title: string, options = {}) => {
  return {
    title,
    ...DEFAUL_OPTIONS,
    ...options,
  };
};

const Stack = createNativeStackNavigator<RootStackParamList>();

//TODO: add auth0 for auth navigation when available

/**
 * A bottom tab navigator displays tab buttons on the bottom of the display to switch screens.
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */

const TAB_DEFAUL_OPTIONS = {
  tabBarIcon: ({ color }: { color: string }) => <TabBarIcon name="code" color={color} />,
  headerShown: false,
};

export const getTabOptions = (title: string, options = {}) => {
  return {
    title,
    ...TAB_DEFAUL_OPTIONS,
    ...options,
  };
};

function MultiAcountGate() {
  const { t } = useTranslation();
  return (
    <Stack.Navigator
      initialRouteName={
        storageSubscriptionID === '' || storageSubscriptionID === null ? 'MultAccHomePicker' : 'ProfileTabNavigator'
      }>
      <Stack.Screen
        name="MultAccHomePicker"
        component={MultiAccHomePicker}
        options={getOptions(t('GO_SUMMER') + t('MULTI_HOME.TITLE'))}
      />
      <Stack.Screen name="ProfileTabNavigator" component={ProfileTabNavigator} options={getOptions('')} />
    </Stack.Navigator>
  );
}
/**
 * You can explore the built-in icon families and icons on the web at https://icons.expo.fyi/
 */
function TabBarIcon(props: { name: React.ComponentProps<typeof FontAwesome>['name']; color: string }) {
  return <FontAwesome size={30} style={{ marginBottom: -3 }} {...props} />;
}
