import { FunctionComponent, useContext, useRef, useState, useEffect } from 'react';
import { AppState, AppStateStatus } from 'react-native';
import { useTranslation } from 'react-i18next';
import { AppVersionUpdateContext } from 'contexts/AppVersionUpdateContext';

interface AppVersionUpdateProps {
  children: React.ReactNode;
}

export const AppVersionUpdate: FunctionComponent<AppVersionUpdateProps> = ({ children }) => {
  const appState = useRef(AppState.currentState);
  const { lookForAppStoreUpdate } = useContext(AppVersionUpdateContext);
  const { t } = useTranslation();
  const [appStateVisible, setAppStateVisible] = useState(appState.current);

  useEffect(() => {
    AppState.addEventListener('change', handleAppStateChange);
    lookForAppStoreUpdate();

    return () => {
      AppState.removeEventListener('change', handleAppStateChange);
    };
  }, []);

  const handleAppStateChange = (nextAppState: AppStateStatus) => {
    if (appState.current.match(/inactive|background/) && nextAppState === 'active') {
      if (__DEV__) console.log('App has come to the foreground!');
      lookForAppStoreUpdate();
    }
    appState.current = nextAppState;
    if (__DEV__) console.log(`AppState ${appState.current}`);
    setAppStateVisible(appState.current);
  };

  return <>{children}</>;
};
