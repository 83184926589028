import { Heading as NativeBaseHeading, IHeadingProps } from 'native-base';

const Heading = ({ children, ...props }: IHeadingProps) => {
  return <NativeBaseHeading {...props}>{children}</NativeBaseHeading>;
};

export const H1 = (props: IHeadingProps) => {
  return <Heading size="lg" {...props} />;
};

export const H2 = (props: IHeadingProps) => {
  return <Heading {...props} size="md" />;
};

export const H3 = (props: IHeadingProps) => {
  return <Heading {...props} size="sm" />;
};

export default Heading;
