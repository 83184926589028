import * as Types from '../../../generated/types.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateReservationMutationVariables = Types.Exact<{
  data: Types.ReservationInputMapping;
}>;


export type CreateReservationMutation = { __typename?: 'Mutation', createReservation: { __typename?: 'GuestyReservationResponse', status: { __typename?: 'GuestyStatus', code: number, message: string }, data?: { __typename?: 'GuestyReservation', _id?: string | null, daysInAdvance?: number | null, accountId?: string | null, listingId?: string | null, guestId?: string | null, status?: string | null, confirmationCode?: string | null, guestsCount?: number | null, source?: string | null, checkIn?: string | null, checkOut?: string | null, nightsCount?: number | null, confirmedAt?: string | null, createdAt?: string | null, listing?: { __typename?: 'GuestyListing', _id?: string | null, title?: string | null, picture?: { __typename?: 'Picture', thumbnail?: string | null } | null, address?: { __typename?: 'Address', full?: string | null } | null } | null, guest?: { __typename?: 'Guest', email?: string | null, fullName?: string | null, phone?: string | null } | null, integration?: { __typename?: 'Integration', _id?: string | null, platform?: string | null } | null } | null } };


export const CreateReservationDocument = gql`
    mutation createReservation($data: ReservationInputMapping!) {
  createReservation(data: $data) {
    status {
      code
      message
    }
    data {
      _id
      daysInAdvance
      accountId
      listingId
      guestId
      status
      confirmationCode
      guestsCount
      source
      checkIn
      checkOut
      nightsCount
      confirmedAt
      createdAt
      listing {
        _id
        title
        picture {
          thumbnail
        }
        address {
          full
        }
      }
      guest {
        email
        fullName
        phone
      }
      integration {
        _id
        platform
      }
    }
  }
}
    `;
export type CreateReservationMutationFn = Apollo.MutationFunction<CreateReservationMutation, CreateReservationMutationVariables>;

/**
 * __useCreateReservationMutation__
 *
 * To run a mutation, you first call `useCreateReservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReservationMutation, { data, loading, error }] = useCreateReservationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateReservationMutation(baseOptions?: Apollo.MutationHookOptions<CreateReservationMutation, CreateReservationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReservationMutation, CreateReservationMutationVariables>(CreateReservationDocument, options);
      }
export type CreateReservationMutationHookResult = ReturnType<typeof useCreateReservationMutation>;
export type CreateReservationMutationResult = Apollo.MutationResult<CreateReservationMutation>;
export type CreateReservationMutationOptions = Apollo.BaseMutationOptions<CreateReservationMutation, CreateReservationMutationVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    