const fontSizes = {
  '2xs': 10,
  xs: 12,
  sm: 14,
  md: 16,
  '2md': 17,
  lg: 18,
  xl: 20,
  '1xl': 22,
  '2xl': 24,
  '2.5xl': 28,
  '3xl': 30,
  '4xl': 32,
  '5xl': 36,
  '5.5xl': 44,
  '6xl': 48,
  '7xl': 60,
  '7.5xl': 64,
  '8xl': 72,
  '9xl': 96,
  '10xl': 128,
};

export default fontSizes;
