import { createNativeStackNavigator } from '@react-navigation/native-stack';
import HomeEquityScreen from 'screens/Home/HomeEquityScreen';
import { PropertyDetailProvider } from 'providers/PropertyDetailProvider';
import { ReservationProvider } from 'providers/ReservationProvider';
import MyHomeScreen from 'screens/Home/MyHomeScreen';
import AnnualSubscriptionScreen from 'screens/Home/AnnualSubscriptionScreen';
import { ListingStackParamList, RootStackParamList } from 'types';
import { getOptions } from '.';
import HomeMultiPropertiesScreen from 'screens/Home/HomeMultiPropertiesScreen';
import { useTranslation } from 'react-i18next';
import { ListingNavigator } from './ListingNavigator';

const MyHome = createNativeStackNavigator<RootStackParamList>();
const Listing = createNativeStackNavigator<ListingStackParamList>();

export function MyHomeStackNavigator() {
  const { t } = useTranslation();
  return (
    <PropertyDetailProvider>
      <ReservationProvider>
        <MyHome.Navigator initialRouteName="MyHomeScreen">
          <MyHome.Screen
            name="MyHomeScreen"
            component={MyHomeScreen}
            options={getOptions(t('GO_SUMMER') + t('HOME.MY_HOME'))}
          />
          <MyHome.Screen
            name="HomeEquityScreen"
            component={HomeEquityScreen}
            options={getOptions(t('GO_SUMMER') + t('HOME.HOME_EQUITY'))}
          />
          <MyHome.Screen
            name="HomeMultiPropertiesScreen"
            component={HomeMultiPropertiesScreen}
            options={getOptions(t('GO_SUMMER') + t('MULTI_HOME.TITLE'))}
          />

            <Listing.Screen name="ListingNavigator" component={ListingNavigator} options={getOptions('')} />
            <MyHome.Screen
              name="AnnualSubscriptionScreen"
              component={AnnualSubscriptionScreen}
              options={getOptions(t('GO_SUMMER') + t('HOME.ANNUAL_SUBSCRIPTION_USAGE'))}
            />
          </MyHome.Navigator>
      </ReservationProvider>
    </PropertyDetailProvider>
  );
}
