import { FC, useCallback, useContext } from 'react';
import React from 'react';
import { Modal, VStack, Text, Button, View, Divider, useToast } from 'native-base';
import { useTranslation } from 'react-i18next';
import TermsConditionWebview from '../../SignUp/TermsConditionWebview';
import { useUpdateUserMutation } from 'screens/Booking/queries/updateUser.generated';
import moment from 'moment';
import { Loading } from '../../Loading';
import { NavigationContext } from 'contexts/NavigationContext';

const modalStyles = {
  agreementText: {
    color: 'styleSheet.shadow',
    fontSize: '2md',
    fontWeight: '400',
    fontFamily: 'body',
  },
  header: {
    color: 'styleSheet.shadow',
    fontSize: '2.5xl',
    fontWeight: '400',
    fontFamily: 'body',
  },
  body: {
    ml: '24px',
    mr: '24px',
    mt: '40px',
    mb: '40px',
    justifyContent: 'center',
  },
  termText: {
    fontSize: '2md',
    fontWeight: '400',
    fontFamily: 'body',
    color: 'hyperlink',
  },
  text: {
    mt: '20px',
    lineHeight: 25,
  },
  agreeButton: {
    w: '100%',
    h: '54px',
    mt: '40px',
    backgroundColor: 'styleSheet.shadow',
    borderRadius: '8px',
    textAlign: 'center',
    _text: {
      fontSize: '2md',
      fontFamily: 'body600',
      color: 'styleSheet.canvas',
    },
  },
  deagreeText: {
    fontSize: '2md',
    fontWeight: '400',
    fontFamily: 'body',
    color: 'styleSheet.shadow',
    mt: '24px',
  },
  modal: {
    width: '90%',
    backgroundColor: 'styleSheet.canvas',
  },
  view: {
    alignItems: 'center',
  },
  divider: {
    borderWidth: '0.5px',
    borderColor: 'styleSheet.shadow',
    mt: '-2px',
    width: '78px',
    ml: '2px',
  },
};

interface IModalProps {
  open: boolean | undefined;
  close: () => void;
  memberId: string;
  email: string | null | undefined;
  firstName: string | null | undefined;
  lastName: string | null | undefined;
}

interface IUpdateUserDataProps {
  id: string;
  isFirstLogin: boolean;
  consentDate: string;
  email: string | null | undefined;
  firstName: string | null | undefined;
  lastName: string | null | undefined;
}

const FirstLoginTermsConModal: FC<IModalProps> = ({ open, close, memberId, email, firstName, lastName }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [uri, setUri] = React.useState<string>('');
  const [title, setTitle] = React.useState<string>('');
  const [updateUserData, { loading: updatingUserData, error: updateUserDataError }] = useUpdateUserMutation();
  const { useNavigation } = useContext(NavigationContext);
  const toast = useToast();

  const handleUpdateUserData = useCallback(
    (values: IUpdateUserDataProps) => {
      updateUserData({
        variables: {
          data: {
            ...values,
          },
        },
        onCompleted: () => {
          close();
        },
        onError: () => {
          toast.show({
            description: updateUserDataError,
          });
        },
      });
    },
    [updateUserData]
  );

  const handleIDisagree = () => {
    close();
    useNavigation('Logout');
  };

  if (updatingUserData) return <Loading />;

  return (
    <Modal isOpen={open} animationPreset="slide">
      <Modal.Content {...modalStyles.modal}>
        <Modal.Body>
          <VStack {...modalStyles.body}>
            <Text {...modalStyles.header}>{t('TERM_AND_CONDITION.TERM_CONDITION')}</Text>
            <Text {...modalStyles.text}>
              <Text {...modalStyles.agreementText}>{t('TERM_AND_CONDITION.FIRST_LOGIN_MSG')}</Text>
              <Text
                {...modalStyles.termText}
                underline
                onPress={() => {
                  setTitle(t('TERM_AND_CONDITION.CONSENT'));
                  setShowModal(true);
                  setUri('https://www.gosummer.com/legal-pages/consent-to-electronic-communications');
                }}>
                {t('TERM_AND_CONDITION.CONSENT')}
                {t('TERM_AND_CONDITION.COMMA')}
              </Text>
              <Text {...modalStyles.agreementText}> {t('TERM_AND_CONDITION.OUR')}</Text>
              <Text
                {...modalStyles.termText}
                underline
                testID="button1"
                onPress={() => {
                  setShowModal(true);
                  setUri('https://www.gosummer.com/legal-pages/terms-of-use');
                  setTitle(t('TERM_AND_CONDITION.TERM_OF_USE'));
                }}>
                {t('TERM_AND_CONDITION.TERM_OF_USE')}
                {t('TERM_AND_CONDITION.COMMA')}
              </Text>
              <Text {...modalStyles.agreementText}> {t('TERM_AND_CONDITION.AND_OUR')}</Text>
              <Text
                {...modalStyles.termText}
                underline
                onPress={() => {
                  setShowModal(true);
                  setUri('https://www.gosummer.com/legal-pages/privacy-policy');
                  setTitle(t('TERM_AND_CONDITION.PRIVACY_POLICY'));
                }}>
                {t('TERM_AND_CONDITION.PRIVACY_POLICY')}
              </Text>
            </Text>

            <Button
              {...modalStyles.agreeButton}
              onPress={() =>
                handleUpdateUserData({
                  id: memberId,
                  isFirstLogin: false,
                  consentDate: moment(new Date()).format('DD/MM/YYYY'),
                  email: email,
                  firstName: firstName,
                  lastName: lastName,
                })
              }>
              {t('TERM_AND_CONDITION.I_AGREE')}
            </Button>
            <View {...modalStyles.view}>
              <Text {...modalStyles.deagreeText} onPress={handleIDisagree}>
                {' '}
                {t('TERM_AND_CONDITION.I_DISAGREE')}
              </Text>
              <Divider {...modalStyles.divider} />
            </View>
          </VStack>
        </Modal.Body>
      </Modal.Content>

      <TermsConditionWebview
        open={showModal}
        close={() => {
          setShowModal(false);
        }}
        uri={uri}
        title={title}
      />
    </Modal>
  );
};

export default FirstLoginTermsConModal;
