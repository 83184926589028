import { useContext } from 'react';
import { Avatar, Box, HStack, Pressable, Text } from 'native-base';
import { useTranslation } from 'react-i18next';

import SwitchIcon from 'assets/icons/Switch.svg';
import { NavigationContext } from 'contexts/NavigationContext';
import { NativeBaseStyles } from 'src/types/common';

const styles: NativeBaseStyles = {
  contentContainer: {
    alignItems: 'center',
  },
  seeHomeDetails: {
    color: [
      'styleSheet.canvas',
      'styleSheet.canvas',
      'styleSheet.canvas',
      'styleSheet.lightShadow',
      'styleSheet.lightShadow',
    ],
    fontFamily: 'body600',
    fontSize: 'sm',
    pl: '8px',
    py: '6px',
  },
  avatar: {
    bg: 'styleSheet.lightShadow',
  },
  wrapper: {
    alignItems: 'baseline',
  },
};

const SwitchHome = () => {
  const { t } = useTranslation();
  const { useNavigation } = useContext(NavigationContext);

  const handleSeeHomeDetails = () => {
    useNavigation('HomeMultiPropertiesScreen');
  };

  return (
    <Box {...styles.wrapper}>
      <Pressable onPress={handleSeeHomeDetails}>
        <HStack {...styles.contentContainer}>
          <Avatar size="sm" {...styles.avatar}>
            <SwitchIcon />
          </Avatar>
          <Text {...styles.seeHomeDetails}>{t('PROFILE.SWITCH_HOMES')}</Text>
        </HStack>
      </Pressable>
    </Box>
  );
};

export default SwitchHome;
