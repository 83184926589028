const colors = {
  // Add new color
  styleSheet: {
    canvas: '#FFFEF3',
    summer: '#FFF59E',
    shadow: '#312C2C',
    lightStain: '#F0EDE1',
    darkStain: '#D3CEC5',
    overCast: '#5C5753',
    lightOverCast: '#85817B',
    lightShadow: '#454140',
    fall: '#E0B4A1',
    winter: '#8DBBC5',
    spring: '#C4AFD1',
    darkFall: '#D89274',
    darkWinter: '#66A1AE',
    black: '#000000',
    hover: '#E8E4D8',
  },
  pdp: {
    shadow: '#312C2C',
    buttonBg: '#F0EDE2',
    border: 'rgba(49, 44, 44, 0.7)',
  },

  calendar: {
    day: {
      text: {
        default: '#5C5753',
        marked: '#FFFEF3',
        disabled: '#D3CEC5',
      },
      marked: '#FDF4A8',
      periodEnds: '#302C2C',
      default: 'transparent',
      low: '#80A66A',
      normal: '#EEBC6C',
      high: '#D65D3F',
    },
    monthTextColor: '#302C2C',
    disableButtonColor: '#989590',
    weekTextColor: 'rgb(198, 196, 195)',
  },
  menu: {
    focused: '#1C1E53',
    notFocused: '#1C1E53',
  },
  customBlack: '#302C2C', //Black color in mocks
  customWhite: '#FFFFFF',
  h2: '#D2CEC6',

  textColor: '#111',
  tint: '#f2f2f2',
  border: '#C7C7CCFF',
  background: '#f2f2f2ff',
  bgHeader: '#566B72',
  bgPage: '#FFFEF4',
  bgSmallButton: '#ECE8E3',
  bgButton: '#535353',
  bgBox: '#F0F0F0',
  summer: '#FDF4A8',
  bgModalHeader: '#485C71',
  buttonText: '#6D5D50',
  tabIconDefault: '#ccc',
  tabIconSelected: '#2f95dc',
  notification: 'rgb(255, 69, 58)',
  card: 'rgb(255, 255, 255)',
  bgButtonSubscription: 'rgba(49, 44, 44, 0.15)',
  donutChart1: '#F4DDBD',
  donutChart2: '#312C2C',
  donutChart3: '#E0B4A1',
  backIconDefault: '#EFEFEF',
  borderColor: '#D2D2D2',
  //colors for stays item listing
  bgStayBox: '#F0EDE1',
  textStay: '#312C2C',
  hyperlink: '#3E58C0',
};

export default colors;
