import { RootStackScreenProps } from '../../../types';
import { SceneMap, TabBar, TabView } from 'react-native-tab-view';
import { Box, Text } from 'native-base';
import UpcomingStays from '../../components/Stays/UpcomingStays';
import PastStays from '../../components/Stays/PastStays';
import { StatusBar } from 'react-native';
import { useTranslation } from 'react-i18next';
import { FC, useState } from 'react';
import Layout from 'constants/Layout';

const windowWidth = Layout.window.width;

const styles = {
  box: {
    bgColor: 'bgPage',
    w: '100%',
    h: '100%',
    mt: [0, '20px', '80px', '80px', '80px', '80px'],
  },
  container: {
    marginTop: StatusBar.currentHeight,
    mt: '100px',
  },
  tabview: {
    backgroundColor: 'transparent',
    shadowOpacity: 0,
  },
  indicator: {
    backgroundColor: '#D3CEC5',
    height: 2,
  },
  tabBox: {
    w: ['100%', '100%', '100%', '35%', '25%'],
    alignSelf: [null, null, null, 'center' as const, 'center' as const],
    mt: [null, null, null, '60px', '60px'],
    mb: [null, null, null, '68px', '68px'],
  },
};

const Stays: FC<RootStackScreenProps<'Stays'>> = () => {
  const { t } = useTranslation();
  const initialLayout = { width: windowWidth };
  const [index, setIndex] = useState(0);
  const [routes] = useState([
    { key: 'upcoming', title: t('UPCOMING_STAYS') },
    { key: 'past', title: t('PAST_STAYS') },
  ]);
  const renderScene = SceneMap({
    upcoming: UpcomingStays,
    past: PastStays,
  });
  return (
    <Box {...styles.box} safeAreaTop>
      <TabView
        navigationState={{ index, routes }}
        renderTabBar={(props) => (
          <Box {...styles.tabBox}>
            <TabBar
              {...props}
              renderLabel={({ route, focused, color }) => (
                <Text
                  style={{
                    color: focused ? '#312C2C' : '#d2d1c8',
                    fontWeight: '800',
                    fontSize: 17,
                  }}>
                  {route.title}
                </Text>
              )}
              style={styles.tabview}
              indicatorStyle={styles.indicator}
            />
          </Box>
        )}
        renderScene={renderScene}
        onIndexChange={setIndex}
        initialLayout={initialLayout}
        style={styles.container}
      />
    </Box>
  );
};

export default Stays;
