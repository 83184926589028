import React, { useContext } from 'react';
import { TouchableWithoutFeedback } from 'react-native';
import { Box, Circle, Text } from 'native-base';
import { AppTheme } from 'constants/Theme';
import { DayProps } from 'react-native-calendars/src/calendar/day';
import { CalendarContext, DayValue } from 'contexts/CalendarContext';

export interface DayValueProps {
  value: number;
  level: 'low' | 'normal' | 'high';
}

const CalendarDay: React.ComponentType<DayProps> = (props) => {
  const { dayValues } = useContext(CalendarContext);

  const [dayValue, setDayValue] = React.useState<DayValue | null>(null);

  const { state, marking, onPress, onLongPress, accessibilityLabel, testID } = props;

  const date = (props as any).date;

  React.useEffect(() => {
    setDayValue(dayValues && date && dayValues[date.dateString]);
  }, [date, dayValues]);

  const boxContainerBgColor =
    marking?.startingDay || marking?.endingDay
      ? AppTheme.colors.calendar.day.periodEnds
      : AppTheme.colors.calendar.day.default;
  const boxPartLeftContainerBgColor =
    marking && !marking?.startingDay ? AppTheme.colors.calendar.day.marked : AppTheme.colors.calendar.day.default;
  const boxPartRightContainerBgColor =
    marking && !marking?.endingDay ? AppTheme.colors.calendar.day.marked : AppTheme.colors.calendar.day.default;

  const textColor =
    dayValue?.disabled || state === 'disabled'
      ? AppTheme.colors.calendar.day.text.disabled
      : marking?.startingDay || marking?.endingDay
      ? AppTheme.colors.calendar.day.text.marked
      : AppTheme.colors.calendar.day.text.default;
  const priceColor = marking
    ? AppTheme.colors.calendar.day.text.marked
    : dayValue?.level === 'low'
    ? AppTheme.colors.calendar.day.low
    : dayValue?.level === 'normal'
    ? AppTheme.colors.calendar.day.normal
    : dayValue?.level === 'high'
    ? AppTheme.colors.calendar.day.high
    : AppTheme.colors.calendar.day.text.default;
  return (
    <TouchableWithoutFeedback
      testID={testID}
      onPress={() => onPress?.(date)}
      onLongPress={() => onLongPress?.(date)}
      disabled={marking?.disableTouchEvent}
      accessible
      accessibilityRole={marking?.disableTouchEvent ? undefined : 'button'}
      accessibilityLabel={accessibilityLabel}>
      <Box {...styles.box}>
        <Box {...styles.partLeft} bg={boxPartLeftContainerBgColor} />
        <Box {...styles.partRight} bg={boxPartRightContainerBgColor} />
        <Circle bg={boxContainerBgColor} {...styles.circle}>
          <Text {...styles.number} color={textColor}>
            {String(props.children)}
          </Text>
          {!marking && dayValue?.value && !dayValue?.disabled ? (
            <Text {...styles.price} color={priceColor}>
              {dayValue ? `$${dayValue?.value}` : ' '}
            </Text>
          ) : null}
        </Circle>
      </Box>
    </TouchableWithoutFeedback>
  );
};

const styles = {
  partLeft: {
    width: '50%',
    height: ['45', '45', '36', '36', '36'],
    position: 'absolute' as const,
    marginRight: '2%',
  },
  partRight: {
    marginLeft: '50%',
    width: '50%',
    height: ['45', '45', '36', '36', '36'],
    position: 'absolute' as const,
  },
  box: {
    width: '100%',
    height: ['45', '45', '36', '36', '36'],
  },
  circle: {
    width: ['95%', '95%', '90%', '90%', '90%'],
    height: ['45', '45', '36', '36', '36'],
  },
  number: {
    alignSelf: 'center',
    fontSize: 16,
    fontWeight: '600',
  },
  price: {
    alignSelf: 'center',
    fontSize: 12,
    bottom: 0,
  },
};

export default CalendarDay;
