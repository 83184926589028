import { Box, HStack, Text, Divider } from 'native-base';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import separator from 'utils/separator';

const styles = {
  header: {
    fontSize: '2md',
    fontFamily: 'body600',
    color: 'styleSheet.shadow',
  },
  subText: {
    fontSize: 'sm',
    fontFamily: 'body',
    color: 'styleSheet.shadow',
  },
  subTextAsAmount: {
    fontSize: 'sm',
    fontFamily: 'body',
    color: 'styleSheet.shadow',
    position: 'absolute' as const,
    right: 0,
  },
  hstack: {
    mt: '16px',
  },
  divider: {
    w: '100%',
    color: 'styleSheet.darkStain',
    borderWidth: 1,
    opacity: '0.15',
    mt: '24px',
  },
};

interface IInitialPaymentProps {
  initialPayment: string;
}

const InitialPayment: FC<IInitialPaymentProps> = ({ initialPayment }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Text {...styles.header}>{t('HOME.INITIAL_PAYMENT')}</Text>
      <HStack {...styles.hstack}>
        <Text {...styles.subText}>{t('HOME.PURCHASE_PRICE')}</Text>
        <Text {...styles.subTextAsAmount}>{`$${separator(initialPayment)}`}</Text>
      </HStack>
      <Divider {...styles.divider} />
    </Box>
  );
};

export default InitialPayment;
