import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { StaysProvider } from 'providers/StaysProvider';
import { useTranslation } from 'react-i18next';
import Stays from 'screens/Stays/Stays';
import { RootStackParamList } from 'types';
import { getOptions } from '.';
import { StayDetailsNavigator } from './StayDetailsNavigator';

const Stay = createNativeStackNavigator<RootStackParamList>();

export function StayNavigator() {
  const { t } = useTranslation();
  return (
    <StaysProvider>
      <Stay.Navigator initialRouteName="Stays">
        <Stay.Screen name="Stays" component={Stays} options={getOptions(t('GO_SUMMER') + t('STAYS'))} />
        <Stay.Screen name="StayDetailsNavigator" component={StayDetailsNavigator} options={getOptions('')} />
      </Stay.Navigator>
    </StaysProvider>
  );
}
