import { FC, useContext, useEffect, useState } from 'react';
import { useGetUserQuery } from 'screens/Booking/queries/getUser.generated';
import { UserContext } from 'contexts/UserContext';
import { SentryService } from '../services/SentryService';
import { AuthContext } from 'contexts/authContext';

interface UserProviderProps {
  children: React.ReactNode;
}

const profileInfoInitialState = {
  name: true,
  email: true,
  phone: true,
  switch: true,
  gender: true,
  dateOfBirth: true,
  address: true,
};

export const UserProvider: FC<UserProviderProps> = ({ children }) => {
  const { isReady, isUserAuthenticated } = useContext(AuthContext)
  const { data, loading, refetch } = useGetUserQuery({ skip: !isReady || !isUserAuthenticated });
  const [profileInfo, setProfileInfo] = useState(profileInfoInitialState);

  useEffect(() => {
    if (!!data?.getUser?.data) {
      SentryService.setUser({
        id: data.getUser.data.id,
        email: data.getUser.data.email || '',
      });
    }
  }, [data]);

  return (
    <UserContext.Provider
      value={{
        profileInfo,
        setProfileInfo,
        user: data?.getUser?.data,
        isLoading: loading,
        refetch
      }}>
      {children}
    </UserContext.Provider>
  );
};
