import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { Box, Button, Hidden, NativeBaseProvider, ScrollView, useBreakpointValue, View } from 'native-base';
import { RootStackScreenProps } from '../../../types';
import { useTranslation } from 'react-i18next';
import { Loading } from 'components/Loading';
import { BookingDetailContext } from 'contexts/BookingDetailContext';
import BookingDates from 'components/Stays/BookingDates';
import BookingGuests from 'components/Stays/BookingGuests';
import BookingConfirmationCode from 'components/Stays/BookingConfirmationCode';
import HomeDetails from 'components/Stays/HomeDetails';
import SubscriptionOverview from 'components/SubscriptionDetails/SubscriptionOverview';
import CancelationPolicy from 'components/ConfirmationPage/CancelationPolicy';
import PaymentDetails from 'components/Stays/PaymentDetails';
import BackButtonHeader from 'components/Stays/BackButtonHeader';
import { H3 } from 'components/libs/heading/Heading';
import Modal from 'react-native-modal';
import EditGuestModal from '../../components/Stays/EditGuestModal';
import { AppTheme } from 'constants/Theme';
import CancelBookingMainModal from '../../components/Stays/modals/CancelBookingMainModal';
import { NavigationContext } from 'contexts/NavigationContext';

import Layout from 'constants/Layout';

const windowWidth = Layout.window.width;
const windowHeight = Layout.window.height;

const styles = {
  innerBox: {
    pl: '16px',
    pr: '16px',
    pb: ['0px', '0px', '40px', '0px', '40px'],
  },
  button: {
    h: ['54px', '54px', '54px', '54px', '70px'],
    w: ['100%', '100%', '60%', '100%', '100%'],
    bg: 'styleSheet.shadow',
    _text: {
      color: 'styleSheet.canvas',
      fontSize: '2md',
    },
    _pressed: {
      bg: 'styleSheet.shadow',
      opacity: '0.6',
      _text: { color: 'styleSheet.canvas' },
    },
    _hover: {
      bg: 'styleSheet.darkStain',
      _text: { color: 'styleSheet.shadow' },
    },
    _focus: {
      bg: 'styleSheet.shadow',
    },
    mt: ['0px', '0px', '48px', '48px', '64px'],
    alignSelf: 'center' as const,
  },
  mainView: {
    flexDirection: ['column' as const, 'column' as const, 'column' as const, 'row' as const, 'row' as const],
    w: ['100%', '100%', '100%', windowWidth - windowWidth * 0.35, windowWidth - windowWidth * 0.25],
    alignSelf: 'center' as const,
  },
  detailView: {
    w: ['100%', '100%', '60%', '60%', '60%'],
    mt: '24px',
    pr: ['0px', '0px', '0px', '80px', '100px'],
    alignSelf: 'center' as const,
  },
  paymentView: {
    w: ['100%', '100%', '60%', '40%', '40%'],
    alignSelf: [null, null, 'center' as const, null, null],
  },
  h3: {
    fontSize: '1xl',
    color: 'styleSheet.shadow',
    fontFamily: 'body',
    mt: ['24px', '24px', '24px', '24px', '16px'],
    mb: '24px',
    ml: ['0px', '0px', '0px', '0px', '2%'],
  },
  container: {
    h: '100%',
    w: '100%',
    bg: 'styleSheet.canvas',
  },
  scrollview: {
    h: '100%',
    w: '100%',
  },
  overLay: {
    position: 'absolute' as const,
    w: windowWidth,
    h: windowHeight,
    top: '10%',
    borderRadius: 20,
    borderWidth: '1px',
    borderColor: 'styleSheet.canvas',
    zIndex: 100,
  },
};

const BookingDetail: FC<RootStackScreenProps<'BookingDetail'>> = ({ navigation, route }) => {
  let breakpointTypeValue = useBreakpointValue({ base: 1, sm: 2, md: 3, lg: 4, xl: 5 });
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showCancelBookingModal, setCancelBookingModal] = useState<boolean>(false);
  const reservationId = route.params?.reservationId;
  const { useNavigation } = useContext(NavigationContext);

  const { setReservationId, reservation, isLoading } = useContext(BookingDetailContext);

  useEffect(() => {
    setReservationId(route.params?.reservationId);
  }, [route.params?.reservationId]);

  const [isTextDisplay, setIsTextDisplay] = useState<boolean>(true);

  const usedAmount = useMemo(() => reservation?.subscriptionUsedAmount, [reservation]);
  const currentBooking = useMemo(() => reservation?.currentBookingAmount, [reservation]);
  const remainingBalance = useMemo(() => reservation?.subscriptionRemainingBalance, [reservation]);
  const confirmationCode = useMemo(() => reservation?.confirmationCode!, [reservation]);

  const handleUpdateGuest = () => {
    if (breakpointTypeValue == 3 || breakpointTypeValue == 4 || breakpointTypeValue == 5) {
      setShowModal(true);
    } else {
      setShowModal(false);
      navigation.navigate('EditGuestModal', {
        reservation: reservation!,
      });
    }
  };

  const handleCancelBooking = () => {
    if (breakpointTypeValue == 3 || breakpointTypeValue == 4 || breakpointTypeValue == 5) {
      setCancelBookingModal(true);
    } else {
      setCancelBookingModal(false);
      useNavigation('CancelBookingMainModal', { reservationId });
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Box safeAreaTop {...styles.container}>
      <Hidden till={'md'}>
        <Box display={showModal ? 'flex' : 'none'} {...styles.overLay}>
          <NativeBaseProvider theme={AppTheme}>
            <Modal isVisible={showModal}>
              <EditGuestModal setShowModal={setShowModal} />
            </Modal>
          </NativeBaseProvider>
        </Box>
      </Hidden>

      <Hidden till={'md'}>
        <Box display={showCancelBookingModal ? 'flex' : 'none'}>
          <NativeBaseProvider theme={AppTheme}>
            <Modal isVisible={showCancelBookingModal}>
              <CancelBookingMainModal reservationID={reservationId} setCancelBookingModal={setCancelBookingModal} />
            </Modal>
          </NativeBaseProvider>
        </Box>
      </Hidden>

      <ScrollView {...styles.scrollview}>
        <Box>
          <Box {...styles.innerBox}>
            <BackButtonHeader onBackButton={() => navigation.goBack()} />
            <View {...styles.mainView}>
              <View {...styles.detailView}>
                <H3 {...styles.h3}>{t('BDP.BOOKING_DETAILS')}</H3>
                <BookingDates />
                <BookingGuests onGuestHandler={handleUpdateGuest} />
                <BookingConfirmationCode confirmationCode={confirmationCode} />
                <HomeDetails />
                <SubscriptionOverview
                  usedAmount={usedAmount}
                  currentBooking={currentBooking}
                  remainingBalance={remainingBalance}
                  text={isTextDisplay}
                  setTextDisplay={setIsTextDisplay}
                />
                <CancelationPolicy />

                <Hidden till={'lg'}>
                  <Button children={t('BDP.CANCEL_BOOKING')} onPress={handleCancelBooking} {...styles.button} />
                </Hidden>
              </View>

              <Box {...styles.paymentView}>
                <PaymentDetails />
              </Box>
            </View>

            <Hidden from={'lg'}>
              <Button children={t('BDP.CANCEL_BOOKING')} onPress={handleCancelBooking} {...styles.button} />
            </Hidden>
          </Box>
        </Box>
      </ScrollView>
    </Box>
  );
};

export default BookingDetail;
