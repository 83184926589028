import { FC, useState, useEffect } from 'react';
import { AuthContext } from 'contexts/authContext';
import JWTDecode from 'jwt-decode';
import { getAuth0Token } from 'utils/Auth0Utils';
import moment from 'moment';
import { usePersistentValue } from 'hooks/usePersistentValue';
import { SentryService } from '../services/SentryService';

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(true);
  const [isReady, setIsReady] = useState(false);
  const [auth0Id, setAuth0Id] = useState<string | null>(null);
  const [auth0Token, setAuth0Token, isAuthTokenLoaded] = usePersistentValue<string | null>('auth0Token', null);
  const [auth0RefreshToken, setAuth0RefreshToken] = usePersistentValue<string | null>('auth0RefreshToken', null);
  const [auth0TokenExpiresAt, setAuth0TokenExpiresAt] = usePersistentValue<Date | null>(
    'auth0TokenExpiresAt',
    null,
    (d) => d?.toISOString() || null,
    (s) => new Date(s)
  );
  const [storedSubscriptionId, setstoredSubscriptionId] = usePersistentValue<string | null>(
    'storedSubscriptionId',
    null
  );
  const [storedSelectedHome, setstoredSelectedHome] = usePersistentValue<string | null>('storedSelectedHome', null);

  const doesTokenNeedsRefresh = () => {
    const expiresIn = moment(auth0TokenExpiresAt).diff(new Date(), 'minutes');
    return expiresIn < 2;
  };

  // Check if user is authenticated
  useEffect(() => {
    setIsUserAuthenticated(!!auth0Id && !!auth0Token);
  }, [auth0Id, auth0Token]);

  // At the start of the app, check is token needs refreshing
  useEffect(() => {
    if (!auth0TokenExpiresAt || !auth0RefreshToken) return;

    if (doesTokenNeedsRefresh()) {
      refreshAuthToken();
    } else {
      setIsReady(true);
    }
  }, [auth0TokenExpiresAt, auth0RefreshToken]);

  // Extract auth0Id from token
  useEffect(() => {
    if (!!auth0Token) {
      const decodedResponse: any = JWTDecode(auth0Token);
      setAuth0Id(decodedResponse['sub']);
    }
  }, [auth0Token]);

  useEffect(() => {
    if (!auth0Token && isAuthTokenLoaded) {
      setIsReady(true);
    }
  }, [isAuthTokenLoaded]);
  const processAuth0Code = async (code: string) => {
    if (__DEV__) console.log('Will process auth code');
    try {
      const { accessToken, refreshToken, expiresIn } = await getAuth0Token('authorization_code', code);
      const expiresAt = moment().add(expiresIn, 'seconds');
      setAuth0Token(accessToken);
      setAuth0RefreshToken(refreshToken);
      setAuth0TokenExpiresAt(expiresAt);
    } catch (e) {
      clearAuth0Token();
      throw e;
    }
  };

  const clearAuth0Token = () => {
    setAuth0Id(null);
    setAuth0Token(null);
    setAuth0RefreshToken(null);
    setAuth0TokenExpiresAt(null);
    setIsUserAuthenticated(false);
    setstoredSubscriptionId(null);
    setstoredSelectedHome(null);
  };

  const refreshAuthToken = async () => {
    console.log('Will refresh auth token');
    try {
      if (!auth0RefreshToken) {
        throw new Error('No auth0 refresh token');
      }
      const { accessToken, refreshToken, expiresIn } = await getAuth0Token('refresh_token', auth0RefreshToken);
      const expiresAt = moment().add('seconds', expiresIn).toDate();

      setAuth0Token(accessToken);
      setAuth0TokenExpiresAt(expiresAt);
      if (refreshToken) {
        setAuth0RefreshToken(refreshToken);
      }
    } catch (e) {
      setIsReady(true);
      clearAuth0Token();
      SentryService.captureException(e);
      throw e;
    }
  };

  const getValidAuth0Token = async () => {
    if (doesTokenNeedsRefresh()) {
      await refreshAuthToken();
    }
    return auth0Token;
  };

  return (
    <AuthContext.Provider
      value={{
        isUserAuthenticated,
        isReady,
        auth0Id,
        getAuth0Token: getValidAuth0Token,
        processAuth0Code,
        clearAuth0Token,
        storedSubscriptionId,
      }}>
      {children}
    </AuthContext.Provider>
  );
};
