import { PropertyDetailContext } from 'contexts/PropertyDetailContext';
import { Box, Text, Button, Pressable } from 'native-base';
import { useContext, FC } from 'react';
import { useTranslation } from 'react-i18next';

const styles = {
  box: {
    bg: 'bgPage',
    mb: '110px',
    ml: ['0px', '0px', '80px', '0px'],
    mr: ['0px', '0px', '80px', '0px'],
  },
  title: {
    mt: '48px',
    color: 'pdp.shadow',
    fontSize: '1xl',
    fontFamily: 'body600',
    mb: '24px',
  },
  description: {
    color: 'pdp.shadow',
    fontSize: '2md',
    lineHeight: '25.5px',
    numberOfLines: 3,
    mb: '24px',
  },
  button: {
    borderWidth: '1',
    opacity: '0.7',
    bg: 'styleSheet.canvas',
    h: '46px',
    borderRadius: '8px',
    borderColor: 'pdp.border',
    _text: {
      color: 'pdp.shadow',
      fontSize: 'sm',
    },
    _pressed: {
      bg: 'styleSheet.shadow',
      opacity: '0.6',
      _text: { color: 'styleSheet.canvas' },
    },
    _hover: {
      bg: 'styleSheet.overCast',
      _text: { color: 'styleSheet.lightStain' },
    },
    _focus: {
      bg: 'styleSheet.canvas',
    },
    w: ['100%', '100%', '135px', '135px'],
  },
  buttonClickZone: {
    w: ['100%', '100%', '151px', '151px', '151px'],
    p: '8px',
  },
};

interface ILocationProps {
  onAboutPress: () => void;
}

const Location: FC<ILocationProps> = ({ onAboutPress }) => {
  const { t } = useTranslation();
  const { listing } = useContext(PropertyDetailContext);

  return (
    <Box {...styles.box}>
      {listing?.publicDescription?.neighborhood && (
        <Box>
          <Text {...styles.title}>
            {t('PROPERTY_DETAIL.ABOUT')} {listing?.address?.city}, {listing?.address?.state}
          </Text>
          <Text {...styles.description}>{listing?.publicDescription?.neighborhood}</Text>
          <Pressable onPress={onAboutPress} {...styles.buttonClickZone}>
            <Button onPress={onAboutPress} {...styles.button}>
              {t('PROPERTY_DETAIL.READ_MORE')}
            </Button>
          </Pressable>
        </Box>
      )}
    </Box>
  );
};

export default Location;
