import { createNativeStackNavigator } from '@react-navigation/native-stack';
import LogoutWebview from 'components/Login/LogoutWebview';
import { CitiesProvider } from 'providers/CitiesProvider';
import { useTranslation } from 'react-i18next';
import BookAStay from 'screens/Booking/BookAStay';
import BookingCalendarModalScreen from 'screens/Booking/CalendarModalScreen';
import AnnualSubscriptionScreen from 'screens/Home/AnnualSubscriptionScreen';
import { BookingStackParamList } from 'types';
import { getOptions } from '.';
import { ListingNavigator } from './ListingNavigator';

const Booking = createNativeStackNavigator<BookingStackParamList>();

export function BookingNavigator() {
  const { t } = useTranslation();
  return (
    <CitiesProvider>
      <Booking.Navigator initialRouteName="BookAStay">
        <Booking.Screen
          name="BookAStay"
          component={BookAStay}
          options={getOptions(t('GO_SUMMER') + t('BOOK_STAYS_TODAY'))}
        />
        <Booking.Screen
          name="Logout"
          component={LogoutWebview}
          options={getOptions(t('GO_SUMMER') + t('PROFILE.LOGOUT'))}
        />
        <Booking.Screen name="ListingNavigator" component={ListingNavigator} options={getOptions('')} />
        <Booking.Screen
          name="AnnualSubscriptionScreen"
          component={AnnualSubscriptionScreen}
          options={getOptions(t('GO_SUMMER') + t('HOME.ANNUAL_SUBSCRIPTION_USAGE'))}
        />
        <Booking.Group screenOptions={{ presentation: 'fullScreenModal', animation: 'slide_from_bottom' }}>
          <Booking.Screen
            name="BookingCalendarModalScreen"
            component={BookingCalendarModalScreen}
            options={getOptions(t('GO_SUMMER') + t('CONFIRMATION.BOOKING_CAL'))}
          />
        </Booking.Group>
      </Booking.Navigator>
    </CitiesProvider>
  );
}
