import { useContext, useEffect, useState } from 'react';
import { useBreakpointValue, Hidden, Box } from 'native-base';
import { CanvasLogo } from 'components/iconsXml/canvasLogo';
import AppUpdateModal from 'components/LandingPage/modal/AppVersionUpdateModal';
import { AppVersionUpdateContext } from 'contexts/AppVersionUpdateContext';
import DownloadAppMsgModal from 'components/LandingPage/modal/DownloadAppMsgModal';
import { Platform } from 'react-native';
import LandingButtonsSection from 'components/Landing/LandingButtonsSection';
import LandingMsg from 'components/Landing/LandingMsg';
import { ShadowLogo } from 'components/iconsXml/shadowLogo';
import Layout from 'constants/Layout';

const windowWidth = Layout.window.width;
const windowHeight = Layout.window.height;

const styles = {
  mainView: {
    position: 'absolute' as const,
    top: '4%',
    left: '7%',
    right: '7%',
  },
  fullSize: {
    position: 'absolute' as const,
    bottom: [null, null, '40%', '10%', '10%'],
    h: [null, null, '40%', '55%', '40%'],
    justifyContent: 'center' as const,
  },
  contentBox: {
    height: windowHeight,
    ml: [null, null, '73px', '118px', '118px'],
    mt: '20px',
  },
  mobileView: {
    h: Platform.OS == 'web' ? '100vh' : windowHeight,
    w: Platform.OS == 'web' ? '100vw' : windowWidth,
  },
  topContent: {
    position: 'absolute' as const,
    left: '32px',
    right: '32px',
  },
};

export const LandingScreenContent = () => {
  let breakpointTypeValue = useBreakpointValue({ base: 1, sm: 2, md: 3, lg: 4, xl: 5 });
  const [showDownloadMsg, setShowDonloadMsg] = useState(false);
  const { updateAvailable } = useContext(AppVersionUpdateContext);

  useEffect(() => {
    if (Platform.OS == 'web') {
      if (breakpointTypeValue == 1 || breakpointTypeValue == 2) {
        setShowDonloadMsg(true);
      } else {
        setShowDonloadMsg(false);
      }
    }
  }, [breakpointTypeValue]);

  return (
    <Box>
      <Hidden from={'md'}>
        <Box {...styles.mobileView}>
          <Box {...styles.topContent}>
            <CanvasLogo />
            <LandingMsg />
          </Box>
          <LandingButtonsSection />
        </Box>
      </Hidden>

      <Hidden till={'md'}>
        <Box {...styles.contentBox}>
          <ShadowLogo />
          <Box {...styles.fullSize}>
            <LandingMsg />
            <LandingButtonsSection />
          </Box>
        </Box>
      </Hidden>

      <AppUpdateModal isOpen={updateAvailable} />
      <DownloadAppMsgModal
        isOpen={showDownloadMsg}
        close={() => {
          setShowDonloadMsg(false);
        }}
      />
    </Box>
  );
};
