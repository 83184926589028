import { FC, useContext } from 'react';
import {
  ArrowBackIcon,
  Box,
  ChevronLeftIcon,
  Divider,
  HStack,
  Image,
  Pressable,
  ScrollView,
  Text,
  View,
  VStack,
} from 'native-base';
import { useTranslation } from 'react-i18next';
import GuestAddBar from 'components/Guests/GuestAddBar';
import CTAButton from 'components/libs/button/CTAButton';
import { ReservationContext } from 'contexts/reservationContext';
import { NavigationContext } from 'contexts/NavigationContext';
import { H2 } from 'components/libs/heading/Heading';
import icon from 'assets/images/icon.png';
import Layout from 'constants/Layout';
import { ParamListBase, RouteProp, useRoute } from '@react-navigation/native';

const styles = {
  box: {
    backgroundColor: 'styleSheet.canvas',
    h: '100%',
  },
  page: {
    ml: '16px',
    mr: '16px',
  },
  icon: {
    overflow: 'hidden',
    color: 'styleSheet.canvas',
    size: '6',
    alignSelf: 'center',
  },
  iconCircle: {
    borderRadius: 20,
    bg: 'styleSheet.shadow',
    h: '40px',
    w: '40px',
    justifyContent: 'center',
    position: 'absolute' as const,
    left: '0',
    mt: '16px',
  },
  iconHstack: {
    justifyContent: 'center',
  },
  logo: {
    mt: '16px',
  },
  h2: {
    fontSize: '2.5xl',
    fontWeight: '400',
    fontFamily: 'body',
    color: 'styleSheet.shadow',
  },
  textBox: {
    mb: '38px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  addBar: {
    mt: '23px',
  },
  text: {
    fontSize: 'sm',
    fontFamily: 'body600',
    mb: '16px',
    color: 'styleSheet.shadow',
    opacity: '0.55',
  },
  divider: {
    mt: '38px',
    color: 'styleSheet.shadow',
    borderWidth: '1px',
    opacity: '0.15',
  },
  description: {
    fontSize: 'sm',
    fontWeight: '400',
    fontFamily: 'body',
    color: 'styleSheet.shadow',
  },
  textBoxDes: {
    mt: '20px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  stickyBar: {
    position: 'absolute' as const,
    bottom: '0',
    zIndex: 1,
    w: '100%',
    h: '87px',
    bg: 'styleSheet.canvas',
    borderTopWidth: '1',
    borderColor: 'borderColor',
    justifyContent: 'center',
    alignItems: 'center',
  },
  linkButton: {
    color: 'styleSheet.shadow',
    fontWeight: '400',
    fontFamily: 'body',
    fontSize: '2md',
  },
  view: {
    w: '150px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dividerlink: {
    w: '95px',
    color: 'styleSheet.shadow',
    borderWidth: '0.5px',
  },

  continueButton: {
    variant: 'primaryMobile',
    w: '186px',
    h: '54px',
    borderRadius: '8px',
    _text: {
      fontFamily: 'body600',
      fontSize: '2md',
    },
  },
  pressable: {
    justifyContent: 'center',
    p: '8px',
  },
  error: {
    alignItems: 'center',
    justifyContent: 'center',
    bg: 'styleSheet.lightStain',
    borderRadius: '8px',
    mt: '35px',
    w: '100%',
    h: '38px',
  },

  textError: {
    color: 'styleSheet.shadow',
    fontSize: 'sm',
    fontFamily: 'body600',
    opacity: '0.7',
  },
  innerHstack: {
    justifyContent: 'space-around',
    w: '100%',
  },
};

const AddGuest: FC = () => {
  const { useNavigation } = useContext(NavigationContext);

  const { t } = useTranslation();

  const {
    guests,
    addAdultHandler,
    addChildrenHandler,
    addInfantHandler,
    addPetHandler,
    removeChildrenHandler,
    removeInfantHandler,
    removePetHandler,
    removeAdultHandler,
  } = useContext(ReservationContext);

  const guestCount = guests.adults + guests.children;

  const opacity = guestCount === 6 ? 0.4 : 1;
  const minusOpacity = guests.adults === 1 ? 0.4 : 1;
  const petOpacity = guests.pets === 2 ? 0.4 : 1;
  const errorOpacity = guestCount === 6 ? 1 : 0;
  const smallScreen = Layout.window.height < 844 ? '100px' : '0px';

  return (
    <Box {...styles.box} safeArea>
      <ScrollView>
        <View {...styles.page}>
          <Pressable onPress={() => useNavigation()} {...styles.pressable} testID="chevron">
            <ChevronLeftIcon {...styles.icon} />
          </Pressable>
          <HStack {...styles.iconHstack}>
            <View {...styles.iconCircle}>
              <Pressable onPress={() => useNavigation()} {...styles.pressable} testID="arrow">
                <ArrowBackIcon {...styles.icon} />
              </Pressable>
            </View>
            <Image source={icon} size="lg" alt="summer logo" {...styles.logo} />
          </HStack>
          <Box {...styles.textBox}>
            <H2 {...styles.h2}>{t('COMING_WITH_YOU')}</H2>
            <H2 {...styles.h2}>{t('WITH_YOU')}</H2>
          </Box>
          <GuestAddBar
            guest={guests.adults}
            translationKey="ADULTS"
            addHandler={addAdultHandler}
            removeHandler={removeAdultHandler}
            opacity={opacity}
            minusOpacity={minusOpacity}
          />
          <GuestAddBar
            guest={guests.children}
            addHandler={addChildrenHandler}
            removeHandler={removeChildrenHandler}
            translationKey="CHILDREN"
            opacity={opacity}
            {...styles.addBar}
          />
          <Text {...styles.text}>{t('AGES')}</Text>
          <GuestAddBar
            guest={guests.infants}
            addHandler={addInfantHandler}
            removeHandler={removeInfantHandler}
            translationKey="INFANTS"
          />
          <Text {...styles.text}>{t('UNDER2')}</Text>
          <GuestAddBar
            guest={guests.pets}
            addHandler={addPetHandler}
            removeHandler={removePetHandler}
            translationKey="PETS"
            opacity={petOpacity}
          />
          <Divider {...styles.divider} />
          <Box {...styles.error} opacity={errorOpacity}>
            <Text {...styles.textError}>{t('THIS_HOME_HAS')}</Text>
          </Box>
          <Box {...styles.textBoxDes} mb={smallScreen}>
            <Text {...styles.description}>{t('ADD_GUEST_DESCRIPTION1')}</Text>
            <Text {...styles.description}>{t('YOU_BOOK_THIS_TRIP')}</Text>
          </Box>
        </View>
      </ScrollView>
      <View {...styles.stickyBar}>
        <HStack {...styles.innerHstack}>
          <VStack {...styles.view}>
            <Text
              children={t('SKIP_FOR_NOW')}
              onPress={() => {
                useNavigation('ListingResults');
              }}
              {...styles.linkButton}
            />
            <Divider {...styles.dividerlink} />
          </VStack>
          <CTAButton
            children={t('CONTINUE')}
            onPress={() => {
              useNavigation('ListingResults');
            }}
            {...styles.continueButton}
          />
        </HStack>
      </View>
    </Box>
  );
};

export default AddGuest;
