import { useContext } from 'react';
import { Box, Image, Text } from 'native-base';
import CTAButton from 'components/libs/button/CTAButton';
import { useTranslation } from 'react-i18next';
import { NavigationContext } from 'contexts/NavigationContext';
import { RootStackScreenProps } from 'types';
import icon from 'assets/images/icon.png';

const styles = {
  container: {
    bg: 'styleSheet.canvas',
    height: '100%',
  },
  logo: {
    alignSelf: 'center',
    mt: ['48px', '48px', '150px', '200px'],
  },
  bookingText: {
    fontSize: ['2.5xl', '2.5xl', '5.5xl', '5.5xl'],
    fontWeight: '400',
    fontFamily: 'body',
    color: 'styleSheet.overCast',
    alignSelf: 'center',
    mb: ['12px', '12px', '16px', '16px'],
  },
  page: {
    alignItems: 'center',
    ml: '16px',
    mr: '16px',
  },
  box: {
    alignItems: 'center',
  },
  text: {
    fontSize: '2md',
    fontWeight: '400',
    fontFamily: 'body',
    color: 'pdp.shadow',
    textAlign: 'center' as const,
    maxW: [null, null, '480px', '480px'],
  },
  button: {
    width: ['100%', '100%', '40%', '20%'],
    height: '70px',
    bgColor: 'pdp.shadow',
    borderRadius: '8px',
    alignSelf: 'center',
    marginTop: ['40px', '40px', '56px', '56px'],
    _pressed: {
      bg: 'styleSheet.shadow',
      opacity: '0.6',
      _text: { color: 'styleSheet.canvas' },
    },
    _hover: {
      bg: 'styleSheet.darkStain',
      _text: { color: 'styleSheet.shadow' },
    },
    _focus: {
      bg: 'styleSheet.shadow',
    },
  },
  tripText: {
    color: 'styleSheet.canvas',
    fontSize: '2md',
    fontFamily: 'body600',
  },
};
const ThankYouScreen: React.FC<RootStackScreenProps<'ThankYou'>> = () => {
  const { t } = useTranslation();
  const { useNavigation } = useContext(NavigationContext);

  // const { share } = useShare();
  // const message = 'Booking detail from backend';

  return (
    <Box safeAreaTop {...styles.container}>
      <Box {...styles.page}>
        <Image source={icon} size="lg" alt="summer logo" {...styles.logo} />
        <Text {...styles.bookingText}>{t('BOOKING_CONFIRMED')}</Text>
        <Box {...styles.box}>
          <Text {...styles.text}>{t('A_CONFIRMATION_HAS_SENT')}</Text>
          {/* <Text {...styles.text}>
            {email}. {t('YOU_CAN_ALSO_FIND')}
          </Text>
          <Text {...styles.text}>{t('TRIP_DETAILS_IN')}</Text> */}
        </Box>

        {/*<CTAButton onPress={() => share(message)} {...styles.button}>*/}
        {/*  <Text {...styles.tripText}>{t('SHARE_TRIP_DETAILS')}</Text>*/}
        {/*</CTAButton>*/}

        <CTAButton
          {...styles.button}
          onPress={() => {
            useNavigation('Booking', {
              screen: 'BookAStay',
            });
          }}>
          <Text {...styles.tripText}>{t('BOOK_ANOTHER_STAY')}</Text>
        </CTAButton>
      </Box>
    </Box>
  );
};

export default ThankYouScreen;
