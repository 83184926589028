import { Text, Box, HStack } from 'native-base';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Constants from 'expo-constants';

const styles = {
  view: {
    w: ['100%', '100%', '70%', '60%', '60%'],
    alignSelf: 'center' as const,
  },
  versionDetails: {
    alignSelf: 'center' as const,
    mt: '12px',
  },
  version: {
    fontSize: 'sm',
    fontFamily: 'body',
    color: 'styleSheet.overCast',
  },
};

const VersionDetails: FC = () => {
  const { t } = useTranslation();
  const appVersion = Constants.expoConfig?.version;

  const [versionTapCount, setVersionTapCount] = useState(0);
  const handleVersionTapCount = useCallback(() => {
    setVersionTapCount((c) => c + 1);
  }, []);
  useEffect(() => {
    if (versionTapCount >= 10) {
      setVersionTapCount(0);
    }
  }, [versionTapCount]);

  return (
    <Box {...styles.view}>
      <HStack {...styles.versionDetails}>
        <Text {...styles.version}>{t('PROFILE.APP_VERSION')}</Text>
        <Text {...styles.version}>{appVersion}</Text>
      </HStack>
    </Box>
  );
};
export default VersionDetails;
