import AsyncStorage from '@react-native-async-storage/async-storage';
import { useEffect, useState } from 'react';

const _serialize = (v) => (!!v ? `${v}` : null);
const _deserialize = (v) => v;

export const usePersistentValue = <T>(
  name: string,
  defaultValue: T,
  serialize: (v: T) => string | null = _serialize,
  deserialize: (data: string) => T = _deserialize
): [T, (T) => void, boolean] => {
  const [value, setValue] = useState<T>(defaultValue);
  const [isLoaded, setIsLoaded] = useState(false);

  const onSetValueSuccess = (newValue: T) => {
    setValue(newValue);
    setIsLoaded(false);
  };

  useEffect(() => {
    AsyncStorage.getItem(name).then((data) => {
      if (!!data) {
        setValue(deserialize(data));
      }
      setIsLoaded(true);
    });
  }, [name, isLoaded]);

  const _setValue = (v: T) => {
    const serializedValue = serialize(v);
    if (!!serializedValue) {
      AsyncStorage.setItem(name, serializedValue).then(() => {
        onSetValueSuccess(v);
      });
    } else {
      AsyncStorage.removeItem(name).then(() => {
        onSetValueSuccess(v);
      });
    }
  };

  return [value, _setValue, isLoaded];
};
