import { FC } from 'react';
import { View, Box, Button, Text, useClipboard, useToast, HStack } from 'native-base';
import { useTranslation } from 'react-i18next';
import { CheckMarkIcon } from 'components/libs/icon/Icons';
import Layout from 'constants/Layout';

const windowWidth = Layout.window.width;

const styles = {
  box: {
    bg: 'styleSheet.lightStain',
    borderRadius: '12px',
    pl: '26px',
    mt: '16px',
    mb: '48px',
  },
  linkButton: {
    position: 'absolute' as const,
    right: '16px',
    top: '16px',
    w: '131px',
    bg: 'styleSheet.shadow',
    _text: {
      color: 'styleSheet.canvas',
      fontSize: 'sm',
      fontFamily: 'body',
    },
    _pressed: {
      bg: 'styleSheet.shadow',
      opacity: '0.6',
      _text: { color: 'styleSheet.canvas' },
    },
    _hover: {
      bg: 'styleSheet.darkStain',
      _text: { color: 'styleSheet.shadow' },
    },
    _focus: {
      bg: 'styleSheet.shadow',
    },
    borderRadius: '8px',
    zIndex: 1,
  },
  text: {
    mt: '24px',
    mb: '8px',
    fontFamily: 'body',
    opacity: '0.7',
    fontSize: 'sm',
    color: 'styleSheet.shadow',
  },
  innerText: {
    fontFamily: 'body',
    fontSize: '1xl',
    color: 'styleSheet.shadow',
    mb: '24px',
  },
  clipBoardBox: {
    bg: 'styleSheet.lightShadow',
    pt: '12px',
    pb: '12px',
    width: windowWidth,
    height: '50px',
    alignItems: 'center' as const,
    justifyContent: 'center' as const,
  },
  clipBoardText: {
    color: 'styleSheet.canvas',
    fontSize: '2md',
    fontFamily: 'body',
    ml: '6px',
  },
};
interface BookingConfirmationCodeProps {
  confirmationCode: string;
}

const BookingConfirmationCode: FC<BookingConfirmationCodeProps> = (props) => {
  const { t } = useTranslation();
  const { onCopy } = useClipboard();
  const toast = useToast();

  const readFromClipboard = () => {
    onCopy(props.confirmationCode);
    toast.show({
      placement: 'top',
      render: () => {
        return (
          <HStack {...styles.clipBoardBox}>
            <CheckMarkIcon size={24} color="#D3CEC5" />
            <Text {...styles.clipBoardText}>{t('BDP.COPY_CODE_CLIPBOARD')}</Text>
          </HStack>
        );
      },
    });
  };

  return (
    <View>
      <Box {...styles.box}>
        <Button children={t('BDP.COPY_CODE')} onPress={readFromClipboard} {...styles.linkButton} />
        <Text {...styles.text}>{t('BDP.CONFIRMATION_CODE')}</Text>
        <Text {...styles.innerText}>{props.confirmationCode}</Text>
      </Box>
    </View>
  );
};

export default BookingConfirmationCode;
