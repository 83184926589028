import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { ListingProvider } from 'providers/ListingsProvider';
import { useTranslation } from 'react-i18next';
import BookingCalendarScreen from 'screens/Booking/BookingCalendarScreen';
import { ListingResults } from 'screens/Booking/ListingResults';
import { BookingStackParamList, ListingStackParamList } from 'types';
import { getOptions } from '.';
import { PropertyDetailsNavigator } from './PropertyDetailsNavigator';

const Listing = createNativeStackNavigator<ListingStackParamList>();
const Booking = createNativeStackNavigator<BookingStackParamList>();

export function ListingNavigator() {
  const { t } = useTranslation();
  return (
    <ListingProvider>
      <Listing.Navigator initialRouteName="ListingResults">
        <Listing.Screen
          name="ListingResults"
          component={ListingResults}
          options={getOptions(t('GO_SUMMER') + t('LISTINGS_RESULTS.TITLE'))}
        />
        <Listing.Screen name="PropertyDetailsNavigator" component={PropertyDetailsNavigator} options={getOptions('')} />
        <Booking.Group screenOptions={{ presentation: 'fullScreenModal', animation: 'slide_from_bottom' }}>
          <Listing.Screen
            name="BookingCalendar"
            component={BookingCalendarScreen}
            options={getOptions(t('GO_SUMMER') + t('CONFIRMATION.BOOKING_CAL'))}
          />
        </Booking.Group>
      </Listing.Navigator>
    </ListingProvider>
  );
}
