import { Button, IButtonProps } from 'native-base';
import { memo } from 'react';

//TODO: fix the style when final design is avalable

const CTAButton = ({ children, onPress = () => {}, ...props }: IButtonProps) => {
  return (
    <Button onPress={onPress} {...props}>
      {children}
    </Button>
  );
};

export default memo(CTAButton);
