import { FC, useState, useEffect } from 'react';
import { CalendarContext, DayValue } from 'contexts/CalendarContext';
import moment from 'moment';
import { useGetListingCalendar2Query } from 'providers/queries/getListingCalendar.generated';

const CALENDAR_DATE_DATA_FORMAT = 'YYYY-MM-DD';

interface CalendarProviderProps {
  listingId?: string;
  children: React.ReactNode;
}
export const CalendarProvider: FC<CalendarProviderProps> = ({ children, listingId }) => {
  const [dayValues, setDayValues] = useState<DayValue>({});
  const [periodAvailableStart] = useState(moment());
  const [periodAvailableEnd] = useState(moment().add(12, 'months'));

  const { data } = useGetListingCalendar2Query({
    variables: {
      from: periodAvailableStart.format(CALENDAR_DATE_DATA_FORMAT),
      to: periodAvailableEnd.format(CALENDAR_DATE_DATA_FORMAT),
      listingId: listingId || '',
    },
    skip: !listingId,
  });

  useEffect(() => {
    const getListingCalendarData = data?.getListingCalendar?.data;
    if (!getListingCalendarData) {
      setDayValues({});
    } else {
      const dayValue = getListingCalendarData.reduce<DayValue>((result, data) => {
        result[data.date] = {
          value: data.price,
          level: 'normal',
          disabled: data.status !== 'available',
          minNights: data.minNights
        };
        return result;
      }, {});
      setDayValues(dayValue);
    }
  }, [data]);

  const isSelectionValid = (from: Date, to: Date): boolean => {
    if (!from) return true;

    const fromStr = moment(from).format(CALENDAR_DATE_DATA_FORMAT);
    const toStr = moment(to).format(CALENDAR_DATE_DATA_FORMAT);

    return !data?.getListingCalendar?.data?.find((d) => {
      if (d.status === 'available') {
        return false;
      }
      if (!to) {
        if (fromStr === d.date) {
          return true;
        }
      } else {
        return d.date >= fromStr && d.date <= toStr;
      }
    });
  };

  return (
    <CalendarContext.Provider
      value={{
        periodAvailableStart,
        periodAvailableEnd,
        calendarListings: data?.getListingCalendar?.data,
        dayValues,
        isSelectionValid,
      }}>
      {children}
    </CalendarContext.Provider>
  );
};
