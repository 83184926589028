import { useMemo } from 'react';
import { Box, IBoxProps, Text, VStack } from 'native-base';
import PieChart from './PieChart';

const styles = {
  vstack: {
    position: 'absolute' as const,
    alignItems: 'center' as const,
    height: '100%',
    justifyContent: 'center' as const,
    zIndex: 1,
    width: '100%',
  },
  text: {
    color: 'styleSheet.overCast',
    fontWeight: '400',
    fontFamily: 'body',
    fontSize: 'sm',
  },
  amount: {
    fontFamily: 'body600',
    fontSize: '1xl',
  },
  box: (heightAndWidth: string) => ({
    height: heightAndWidth,
    width: heightAndWidth,
  }),
};

interface IDonutChartProps extends IBoxProps {
  amount: number;
  graphicColors: string[];
  graphicData: string[];
  heightAndWidth?: string;
  label: string;
  textColor?: string;
}

const DonutChart = ({
  amount,
  graphicColors,
  graphicData,
  heightAndWidth = '150px',
  label,
  textColor = 'styleSheet.shadow',
}: IDonutChartProps) => {
  const amountStr = useMemo(() => Math.round(amount).toLocaleString(), [amount]);
  return (
    <Box {...styles.box(heightAndWidth)}>
      <VStack {...styles.vstack}>
        <Text {...styles.amount} color={textColor}>
          ${amountStr}
        </Text>
        <Text {...styles.text} color={textColor}>
          {label}
        </Text>
      </VStack>
      <PieChart graphicColors={graphicColors} graphicData={graphicData} />
    </Box>
  );
};

export default DonutChart;
