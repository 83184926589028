import { createContext } from 'react';
import { noop } from 'lodash';

export type VersionUpdateInitialState = {
  updateAvailable: boolean;
  lookForAppStoreUpdate: () => void;
  updateApp: () => void;
};

export const AppVersionUpdateContext = createContext<VersionUpdateInitialState>({
  updateAvailable: false,
  lookForAppStoreUpdate: noop,
  updateApp: noop,
});
