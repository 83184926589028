import { Box, Select, Text } from 'native-base';
import { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import DownArrowIcon from '../../assets/icons/Down-arrow.svg';

const dropdownStyle = {
  borderColor: 'styleSheet.darkStain',
  borderBottomColor: 'styleSheet.darkStain',
  borderTopColor: 'styleSheet.darkStain',
  borderLeftColor: 'styleSheet.darkStain',
  borderRightColor: 'styleSheet.darkStain',
  borderRadius: 12,
  borderWidth: 1,
  mt: 3,
};

const styles = {
  dropdownIcon: {
    position: 'absolute' as const,
    right: 15,
    bottom: 3.5,
    size: [5, 5, 8, 8, 8],
  },
  box: {
    flexDirection: ['column' as const, 'column' as const, 'column' as const, 'row' as const, 'row' as const],
  },
  dropdownBox: {
    ...dropdownStyle,
  },
  hstack: {
    ...dropdownStyle,
    ml: [0, 0, 0, '12px', '12px'],
  },
  datePickerText: {
    fontFamily: 'body600',
    color: 'styleSheet.shadow',
    fontSize: ['sm', 'sm', '2md', '2md', '2md'],
    opacity: '0.55',
    pt: '8px',
    pl: '20px',
  },
  dropdown: {
    fontSize: ['sm', 'sm', '2md', '2md', '2md'],
    borderWidth: 0,
    pl: '20px',
    pt: '-14px',
    _hover: { bgColor: 'styleSheet.canvas' },
    mr: ['-5px', '-5px', '-5px', '5px', '5px'],
  },
};

interface WebDOBProps {
  setBirthdate: (s: string) => void;
  birthdate: string | null | undefined;
}

const BirthDatePickerWeb = ({ setBirthdate, birthdate }: WebDOBProps) => {
  const { t } = useTranslation();
  var selectedDOB = new Date(birthdate!);
  var defaultMonth = moment(selectedDOB, 'YYY-MM-DD').format('MMMM');

  const months = [
    t('PROFILE.MONTHS.JANUARY'),
    t('PROFILE.MONTHS.FEBRUARY'),
    t('PROFILE.MONTHS.MARCH'),
    t('PROFILE.MONTHS.APRIL'),
    t('PROFILE.MONTHS.MAY'),
    t('PROFILE.MONTHS.JUNE'),
    t('PROFILE.MONTHS.JULY'),
    t('PROFILE.MONTHS.AUGUST'),
    t('PROFILE.MONTHS.SEPTEMBER'),
    t('PROFILE.MONTHS.OCTOBER'),
    t('PROFILE.MONTHS.NOVEMBER'),
    t('PROFILE.MONTHS.DECEMBER'),
  ];
  const [endDate, setEndDate] = useState(moment(selectedDOB).endOf('month').format('D'));
  const [selectedMonth, setSelectedMonth] = useState(months[months.indexOf(defaultMonth)]);
  const [selectedYear, setSelectedYear] = useState(moment(selectedDOB).year().toString());
  const [selectedDay, setSelectedDay] = useState(moment(selectedDOB).date().toString());

  useEffect(() => {
    setBirthdate(selectedMonth + ' ' + selectedDay + ', ' + selectedYear);
  }, [selectedDay, selectedMonth, selectedYear]);

  //generated list of years from today till past 45 years
  const getyears = () => {
    const year = new Date().getFullYear() - 25;
    return Array(year - (year - 100))
      .fill('')
      .map((v, idx) => year - idx) as Array<number>;
  };

  const years = getyears();

  //generated list of dates from 1 to enddate of month
  const listOfDates = useMemo(() => Array.from({ length: Number(endDate) }, (v, k) => k + 1), [selectedMonth]);

  const handleMonthSelect = (e) => {
    setSelectedMonth(e);
    setEndDate(
      moment([selectedYear, months.indexOf(e)])
        .endOf('month')
        .format('D')
    );
  };
  const handleYearSelect = (e) => {
    setSelectedYear(e);
    setEndDate(moment([e, selectedMonth]).endOf('month').format('D'));
  };

  return (
    <Box {...styles.box}>
      <Box {...styles.dropdownBox}>
        <Text {...styles.datePickerText}>{t('PROFILE.MONTH')}</Text>
        <Select
          selectedValue={selectedMonth}
          placeholder={moment(new Date()).format('MMMM')}
          placeholderTextColor="styleSheet.shadow"
          dropdownIcon={
            <Box {...styles.dropdownIcon}>
              <img src={DownArrowIcon as string} />
            </Box>
          }
          {...styles.dropdown}
          onValueChange={(itemValue) => handleMonthSelect(itemValue)}>
          {months.map((item) => (
            <Select.Item key={item} label={item.toString()} value={item.toString()} />
          ))}
        </Select>
      </Box>

      <Box {...styles.hstack}>
        <Text {...styles.datePickerText}>{t('PROFILE.DAY')}</Text>
        <Select
          selectedValue={selectedDay}
          placeholder={moment(new Date()).format('D')}
          placeholderTextColor="styleSheet.shadow"
          dropdownIcon={
            <Box {...styles.dropdownIcon}>
              <img src={DownArrowIcon as string} />
            </Box>
          }
          {...styles.dropdown}
          onValueChange={(itemValue) => setSelectedDay(itemValue)}>
          {listOfDates.map((item) => (
            <Select.Item key={item} label={item.toString()} value={item.toString()} />
          ))}
        </Select>
      </Box>

      <Box {...styles.hstack}>
        <Text {...styles.datePickerText}>{t('PROFILE.YEAR')}</Text>
        <Select
          selectedValue={selectedYear}
          placeholder={(new Date().getFullYear() - 25).toString()}
          placeholderTextColor="styleSheet.shadow"
          dropdownIcon={
            <Box {...styles.dropdownIcon}>
              <img src={DownArrowIcon as string} />
            </Box>
          }
          {...styles.dropdown}
          onValueChange={(itemValue) => handleYearSelect(itemValue)}>
          {years.map((item) => (
            <Select.Item key={item} label={item.toString()} value={item.toString()} />
          ))}
        </Select>
      </Box>
    </Box>
  );
};

export default BirthDatePickerWeb;
