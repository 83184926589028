import { UserContext } from 'contexts/UserContext';
import { Avatar, Text } from 'native-base';
import { FC, useContext } from 'react';

const styles = {
  avtarForInitial: {
    bg: 'styleSheet.lightShadow',
    alignSelf: 'center' as const,
    mt: '40px',
  },
  initials: {
    color: 'styleSheet.canvas',
    fontSize: '4xl',
    fontFamily: 'body',
  },
};
const InitialAvtar: FC = () => {
  const { user } = useContext(UserContext);

  return (
    <Avatar size="xl" {...styles.avtarForInitial}>
      <Text {...styles.initials}>
        {user?.given_name?.charAt(0).toUpperCase()}
        {user?.family_name?.charAt(0).toUpperCase()}
      </Text>
    </Avatar>
  );
};
export default InitialAvtar;
