import { Box, Divider, Pressable, Text } from 'native-base';
import DateInput from './DateInput';
import BookCalendar from 'components/Calendars/BookCalendar';
import moment from 'moment';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface CalendarProps {
  isFromStickyBar?: boolean;
  isOpen?: boolean;
  setIsOpen: (e: boolean) => void;
  isOpenCal: boolean;
  setIsOpenCal: (e: boolean) => void;
  checkIn: string | null;
  checkOut: string | null;
  popoverWidth: number;
  setCheckOut: (s: string) => void;
  setCheckIn: (s: string) => void;
}

const styles = {
  pressable: {
    h: '75px',
    w: '100%',
  },
  datesHeaderTitle: {
    color: 'styleSheet.overCast',
    fontSize: 'sm',
    fontFamily: 'body',
    ml: ['0px', '10px', '0px', '0px'],
    pl: '16px',
    opacity: '0.55',
  },
  datesText: {
    fontSize: '17px',
    fontFamily: 'body',
    color: 'styleSheet.shadow',
    fontWeight: '400',
    pl: '16px',
  },
  calendar: (isFromStickyBar: boolean) => ({
    mt: isFromStickyBar ? '30px' : '10px',
    mb: isFromStickyBar ? '30px' : '32px',
    mr: isFromStickyBar ? '30px' : '0px',
    ml: isFromStickyBar ? '30px' : '0px',
  }),
  calendarBoxSticky: (isFromStickyBar: boolean, popoverWidth: number) => ({
    ...calendarStyle(isFromStickyBar),
    position: 'absolute' as const,
    zIndex: 1,
    top: -15.5,
    width: popoverWidth,
    right: [null, null, -150, null, null],
  }),
  calendarBoxLanding: (isFromStickyBar: boolean, popoverWidth: number) => ({
    ...calendarStyle(isFromStickyBar),
    position: 'absolute' as const,
    zIndex: 1,
    top: 77,
    right: '-2px',
    width: popoverWidth,
  }),
  dividerTop: {
    w: '95%',
    borderColor: 'styleSheet.darkStain',
    borderWidth: '1px',
    alignSelf: 'center' as const,
    mt: '12px',
  },
};

const calendarStyle = (isFromStickyBar: boolean) => ({
  bg: 'styleSheet.canvas',
  borderWidth: 2,
  borderColor: 'styleSheet.darkStain',
  borderBottomRightRadius: 10,
  borderBottomLeftRadius: 10,
  borderTopWidth: isFromStickyBar ? 1.5 : 0,
  borderTopRightRadius: isFromStickyBar ? 10 : 0,
  borderTopLeftRadius: isFromStickyBar ? 10 : 0,
  paddingRight: isFromStickyBar ? 1 : '12px',
  paddingLeft: isFromStickyBar ? 1 : '12px',
  paddingTop: isFromStickyBar ? 0 : '12px',
});

const CalendarPopupLarger: FC<CalendarProps> = (props) => {
  const { t } = useTranslation();

  const calendarMainStyle = props.isFromStickyBar
    ? styles.calendarBoxSticky(props.isFromStickyBar, props.popoverWidth)
    : styles.calendarBoxLanding(props.isFromStickyBar!, props.popoverWidth);

  const handlePress = () => {
    props.setIsOpenCal(!props.isOpenCal);
    props.setIsOpen(false);
  };

  return (
    <Box>
      {props.isFromStickyBar! ? (
        <Pressable onPress={handlePress}>
          <Box>
            <Text {...styles.datesHeaderTitle}>{t('BOOK_A_STAY.DATES')}</Text>
            <Text {...styles.datesText}>
              {props.checkIn !== '' && props.checkOut !== ''
                ? `${moment(props.checkIn).format('ddd, MMM DD')} - ${moment(props.checkOut).format('ddd, MMM DD')}`
                : t('LISTINGS_RESULTS.SELECT_A_DATE')}
            </Text>
          </Box>
        </Pressable>
      ) : (
        <Pressable {...styles.pressable} onPress={handlePress}>
          <DateInput checkIn={props.checkIn} checkOut={props.checkOut} />
        </Pressable>
      )}
      {props.isOpenCal ? (
        <Box {...calendarMainStyle}>
          {props.isFromStickyBar ? (
            <Pressable onPress={handlePress}>
              <Box style={{ marginTop: '18px' }}>
                <Text {...styles.datesHeaderTitle}>{t('BOOK_A_STAY.DATES')}</Text>
                <Text {...styles.datesText}>
                  {props.checkIn !== '' && props.checkOut !== ''
                    ? `${moment(props.checkIn).format('ddd, MMM DD')} - ${moment(props.checkOut).format('ddd, MMM DD')}`
                    : t('LISTINGS_RESULTS.SELECT_A_DATE')}
                </Text>
              </Box>
              <Divider {...styles.dividerTop} />
            </Pressable>
          ) : null}

          <Box {...styles.calendar(props.isFromStickyBar!)}>
            <BookCalendar
              isLargeScreen={true}
              isFromBooking={true}
              weekVerticalMargin={1.5}
              setCalendarWidth={props.isFromStickyBar ? props.popoverWidth / 2.3 : props.popoverWidth / 2.2}
              pastScrollRange={0}
              futureScrollRange={12}
              selectedDates={[props.checkIn, props.checkOut].filter((d) => !!d) as string[]}
              onSelectedDatesChanged={(checkInDate1, checkOutDate1) => {
                if (checkInDate1 && checkOutDate1) {
                  props.setCheckIn(!!checkInDate1 ? moment(checkInDate1).format('YYYY-MM-DD') : '');
                  props.setCheckOut(!!checkOutDate1 ? moment(checkOutDate1).format('YYYY-MM-DD') : '');
                }
              }}
            />
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};

export default CalendarPopupLarger;
