import { NavigationContext } from 'contexts/NavigationContext';
import { Text, Button } from 'native-base';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

const styles = {
  signupButton: {
    w: [null, null, '35%', '40%', '40%'],
    height: '70px',
    bgColor: 'styleSheet.summer',
    borderRadius: '8px',
    textAlign: 'center' as const,
  },
  signupText: {
    color: 'styleSheet.lightShadow',
    fontFamily: 'body600',
    fontSize: '2md',
  },
};

const SignupButton = () => {
  const { t } = useTranslation();
  const { useNavigation } = useContext(NavigationContext);
  return (
    <Button {...styles.signupButton} onPress={() => useNavigation('SignUp')}>
      <Text {...styles.signupText}>{t('SIGN_UP')}</Text>
    </Button>
  );
};

export default SignupButton;
