import { Box, Hidden } from 'native-base';
import { FC, ReactNode } from 'react';
import { Platform } from 'react-native';
import LandingPageBg from './LandingBg';
import Layout from 'constants/Layout';

const windowWidth = Layout.window.width;
const windowHeight = Layout.window.height;

const styles = {
  mainBox: {
    h: '100%',
  },
  box: {
    w: '100%',
    h: '100%',
    flexDirection: [null, null, 'column' as const, 'row' as const, 'row' as const],
  },
  contentViewForWeb: {
    w: [null, null, '100%', '60%', '60%'],
    h: [null, null, '60%', '100%', '100%'],
    bg: 'styleSheet.lightStain',
  },
  image: {
    w: ['100%', '100%', '100%', '40%', '40%'],
    h: ['100%', '100%', '40%', '100%', '100%'],
  },
  mobileView: {
    h: Platform.OS == 'web' ? '100vh' : windowHeight,
    w: Platform.OS == 'web' ? '100vw' : windowWidth,
  },
  contentViewForMobile: {
    position: 'absolute' as const,
    top: '4%',
    zIndex: 1,
  },
  contentForSignup: {
    w: [null, null, '100%', '60%', '60%'],
    h: '100%',
    bg: 'styleSheet.lightStain',
  },
  imageForSignup: {
    w: [null, null, null, '40%', '40%'],
    h: [null, null, null, '100%', '100%'],
  },
};

interface ILandingBoard {
  children?: ReactNode;
  landing?: boolean;
  isSignUp?: boolean;
}

const LandingBoard: FC<ILandingBoard> = ({ children, landing, isSignUp }) => {
  return (
    <Box {...styles.mainBox}>
      {isSignUp ? (
        <Box {...styles.box}>
          <Box {...styles.contentForSignup}>{children}</Box>
          <Hidden till="md">
            <Box {...styles.imageForSignup}>
              <LandingPageBg />
            </Box>
          </Hidden>
        </Box>
      ) : (
        <Box>
          <Hidden till={'md'}>
            <Box {...styles.box}>
              <Box {...styles.contentViewForWeb}>{children}</Box>
              <Box {...styles.image}>
                <LandingPageBg />
              </Box>
            </Box>
          </Hidden>

          <Hidden from={'md'}>
            <Box {...styles.mobileView}>
              {landing ? <LandingPageBg /> : null}
              <Box {...styles.contentViewForMobile}>{children}</Box>
            </Box>
          </Hidden>
        </Box>
      )}
    </Box>
  );
};
export default LandingBoard;
