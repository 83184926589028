import { Formik } from 'formik';
import { Box, Button, HStack, KeyboardAvoidingView, View, VStack, Text, Pressable, Input } from 'native-base';
import * as yup from 'yup';
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUpdateUserMutation } from 'screens/Booking/queries/updateUser.generated';
import { ProfileInfoTypes, UserContext } from 'contexts/UserContext';
import LoadingToast from './LoadingToast';
import { RequiredIcon } from 'components/SignUp/RequiredIcon';

const styles = {
  formGroupName: (error) => ({
    mt: '18px',
    h: '75px',
    w: '100%',
    mr: ['0px', '0px', '0px', '10px', '10px'],
    borderRadius: '12px',
    borderWidth: '1',
    borderTopColor: error ? 'notification' : 'styleSheet.darkStain',
    borderBottomColor: error ? 'notification' : 'styleSheet.darkStain',
    borderLeftColor: error ? 'notification' : 'styleSheet.darkStain',
    borderRightColor: error ? 'notification' : 'styleSheet.darkStain',
    pt: '8px',
    pb: '8px',
    pl: '20px',
  }),
  formGroupButton: {
    mt: '18px',
  },
  applyButton: {
    h: '70px',
    w: ['100%', '100%', '100%', '25%', '25%'],
    mt: '16px',
    backgroundColor: 'styleSheet.shadow',
    borderRadius: '8px',
    textAlign: 'center',
  },
  applyButtonText: {
    color: 'styleSheet.canvas',
    fontSize: '2md',
    fontFamily: 'body600',
  },
  textKey: {
    textAlign: 'justify' as const,
    fontSize: ['sm', 'sm', '2md', '2md', '2md'],
    fontFamily: 'body600',
    color: 'styleSheet.shadow',
    opacity: '0.55',
  },
  hr: {
    borderWidth: '0.5px',
    borderColor: 'styleSheet.lightShadow',
    mt: '-2px',
  },
  linkButton: {
    fontFamily: 'body',
    fontWeight: '400',
    color: 'styleSheet.shadow',
    fontSize: '2md',
  },
  hstack: {
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  fieldText: {
    fontFamily: 'body600',
    color: 'styleSheet.shadow',
    fontSize: 'sm',
    opacity: '0.55',
    mt: [1, 1, 2, 2, 2],
  },
  text: {
    pt: [1, 1, 0, 0, 0],
    pl: 0,
    borderWidth: 1,
    borderColor: 'styleSheet.canvas',
    _focus: {
      borderColor: 'styleSheet.canvas',
    },
    fontSize: '2md',
    fontFamily: 'body',
    color: 'styleSheet.shadow',
    w: '98%',
  },
  error: {
    fontSize: 'sm',
    fontWeight: '400',
    fontFamily: 'body',
    color: 'notification',
    ml: '6px',
  },
  hstackError: {
    position: 'absolute' as const,
    right: 5,
    alignItems: 'center' as const,
    pb: ['25px', '25px', '25px', '30px', '30px'],
  },
};

type ProfilePhoneNumberEditProps = {
  setEditMode: Dispatch<SetStateAction<boolean>>;
  profileInfo: ProfileInfoTypes;
};

const ProfilePhoneNumberEdit = ({ setEditMode, profileInfo }: ProfilePhoneNumberEditProps) => {
  const { t } = useTranslation();
  const [updateUserData, { loading: updatingUserData, error: updateUserDataError }] = useUpdateUserMutation();
  const { user, refetch } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState<Boolean>(false);
  const initialPhoneNumberValue = { phoneNumber: user?.phoneNumber };

  useEffect(() => {
    if (!updatingUserData && isLoading) {
      setIsLoading(false);
      setEditMode(false);
    }
  }, [updatingUserData]);

  interface IUpdateUserDataProps {
    phoneNumber: string | null | undefined;
  }

  const handleUpdateUserData = async (values: IUpdateUserDataProps) => {
    await updateUserData({
      variables: {
        data: {
          ...values,
        },
      },
      onCompleted: () => {
        refetch();
        close();
      },
      onError: () => {
        if (__DEV__) console.log('Unable to update phone number. Error: ', updateUserDataError);
      },
    });
    [updateUserData];
  };

  const editPhoneNumberValidationSchema = yup.object().shape({
    phoneNumber: yup.string().required(t('INPUT_DEFAULT_ERROR')).min(10, t('PROFILE.INVALID_NUMBER')),
  });

  return (
    <KeyboardAvoidingView>
      <Formik
        initialValues={initialPhoneNumberValue}
        onSubmit={(value) => {
          setIsLoading(true);
          handleUpdateUserData(value);
        }}
        validationSchema={editPhoneNumberValidationSchema}>
        {({ handleChange, handleBlur, handleSubmit, values, errors }) => (
          <VStack>
            <HStack {...styles.hstack}>
              <Text {...styles.textKey}>{t('PROFILE.EDIT_PHONE_NUMBER')}</Text>
              <Pressable onPress={() => setEditMode(false)} isDisabled={!profileInfo.phone} testID="cancel_pressed">
                <Text {...styles.linkButton}>{t('PROFILE.CANCEL')}</Text>
                <View {...styles.hr} />
              </Pressable>
            </HStack>
            <Box safeAreaBottom>
              <VStack>
                <Box {...styles.formGroupName(errors.phoneNumber)}>
                  <Text {...styles.fieldText}>{t('PHONE_NUMBER_PLACEHOLDER')}</Text>
                  <HStack>
                    <Input
                      placeholder={t('PHONE_NUMBER_PLACEHOLDER')}
                      onChangeText={handleChange('phoneNumber')}
                      onBlur={handleBlur('phoneNumber')}
                      value={values.phoneNumber}
                      _hover={{ bg: 'styleSheet.canvas' }}
                      {...styles.text}
                    />
                    {errors.phoneNumber && (
                      <HStack {...styles.hstackError} style={{ alignSelf: 'center' as const }}>
                        <RequiredIcon size={13} color="rgb(255, 69, 58)" />
                        <Text {...styles.error}>{errors.phoneNumber}</Text>
                      </HStack>
                    )}
                  </HStack>
                </Box>

                <View {...styles.formGroupButton}>
                  {isLoading ? (
                    <LoadingToast />
                  ) : (
                    <Button
                      //@ts-ignore
                      {...styles.applyButton}
                      onPress={handleSubmit}>
                      <Text {...styles.applyButtonText}>{t('PROFILE.SAVE_CHANGES')}</Text>
                    </Button>
                  )}
                </View>
              </VStack>
            </Box>
          </VStack>
        )}
      </Formik>
    </KeyboardAvoidingView>
  );
};

export default ProfilePhoneNumberEdit;
