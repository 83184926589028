import { NavigationContext } from 'contexts/NavigationContext';
import { UserContext } from 'contexts/UserContext';
import { usePersistentValue } from 'hooks/usePersistentValue';
import { Box, VStack, HStack, Text, Button, Divider } from 'native-base';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

const styles = {
  textKey: {
    textAlign: 'justify' as const,
    fontSize: ['sm', 'sm', '2md', '2md', '2md'],
    fontFamily: 'body600',
    color: 'styleSheet.shadow',
    opacity: '0.55',
  },
  textValue: {
    fontSize: '2md',
    fontFamily: 'body',
    color: 'styleSheet.shadow',
  },
  divider: {
    borderWidth: 0.5,
    borderColor: 'styleSheet.darkStain',
    mt: '16px',
  },
  switchHomes: {
    position: 'absolute' as const,
    right: 0,
    bg: 'styleSheet.shadow',
    _text: {
      color: 'styleSheet.canvas',
      fontSize: 'sm',
      fontFamily: 'body',
    },
    _pressed: {
      bg: 'styleSheet.shadow',
      opacity: '0.6',
      _text: { color: 'styleSheet.canvas' },
    },
    _hover: {
      bg: 'styleSheet.darkStain',
      _text: { color: 'styleSheet.shadow' },
    },
    _focus: {
      bg: 'styleSheet.shadow',
    },
    borderRadius: '8px',
    alignSelf: 'center' as const,
  },
  boxForSwitchHome: {
    w: ['60%', '65%', '80%', '80%', '80%'],
  },

  box: (v: boolean) => ({
    opacity: v ? 1 : 0.5,
    mt: '16px',
  }),
};

const SwitchBox = () => {
  const { t } = useTranslation();
  const { profileInfo } = useContext(UserContext);
  const { useNavigation } = useContext(NavigationContext);
  const [storedSelectedHome, setstoredSelectedHome] = usePersistentValue<string | null>('storedSelectedHome', null);

  const handleSwitchHomes = () => {
    useNavigation('MultAccHomePicker');
  };

  return (
    <Box {...styles.box(profileInfo.switch)}>
      <VStack>
        <HStack>
          <Box {...styles.boxForSwitchHome}>
            <Text {...styles.textKey}>{t('PROFILE.SUMMER_HOMES')}</Text>
            <Text {...styles.textValue}>{storedSelectedHome}</Text>
          </Box>
          <Button children={t('PROFILE.SWITCH_HOMES')} onPress={handleSwitchHomes} {...styles.switchHomes} />
        </HStack>
      </VStack>
      <Divider {...styles.divider} />
    </Box>
  );
};

export default SwitchBox;
