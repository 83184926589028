import { FC } from 'react';
import { AntDesign } from '@expo/vector-icons';

interface RequiredIconProps {
  style?: any;
  size: number;
  color: string;
}
export const RequiredIcon: FC<RequiredIconProps> = ({ style, size, color }) => {
  return <AntDesign name="exclamationcircleo" size={size} color={color} style={style} />;
};
