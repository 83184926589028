import * as Types from '../../../generated/types.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAllCitiesQueryVariables = Types.Exact<{
  subscriptionId: Types.Scalars['String'];
}>;


export type GetAllCitiesQuery = { __typename?: 'Query', getAllCities: { __typename?: 'GetAllCitiesResponse', status: { __typename?: 'GuestyStatus', code: number, message: string }, data?: Array<{ __typename?: 'GetAllCities', city?: string | null, country?: string | null, full?: string | null, homeId?: string | null, neighborhood?: string | null, nickname?: string | null, state?: string | null, street?: string | null, title?: string | null }> | null } };


export const GetAllCitiesDocument = gql`
    query getAllCities($subscriptionId: String!) {
  getAllCities(subscriptionId: $subscriptionId) {
    status {
      code
      message
    }
    data {
      city
      country
      full
      homeId
      neighborhood
      nickname
      state
      street
      title
    }
  }
}
    `;

/**
 * __useGetAllCitiesQuery__
 *
 * To run a query within a React component, call `useGetAllCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCitiesQuery({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *   },
 * });
 */
export function useGetAllCitiesQuery(baseOptions: Apollo.QueryHookOptions<GetAllCitiesQuery, GetAllCitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllCitiesQuery, GetAllCitiesQueryVariables>(GetAllCitiesDocument, options);
      }
export function useGetAllCitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllCitiesQuery, GetAllCitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllCitiesQuery, GetAllCitiesQueryVariables>(GetAllCitiesDocument, options);
        }
export type GetAllCitiesQueryHookResult = ReturnType<typeof useGetAllCitiesQuery>;
export type GetAllCitiesLazyQueryHookResult = ReturnType<typeof useGetAllCitiesLazyQuery>;
export type GetAllCitiesQueryResult = Apollo.QueryResult<GetAllCitiesQuery, GetAllCitiesQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    