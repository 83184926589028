import { FC, useContext, useEffect } from 'react';
import { WebView, WebViewNavigation } from 'react-native-webview';
import { getLogoutQuery } from 'utils/Auth0Utils';
import { AuthContext } from 'contexts/authContext';
import Constants from 'expo-constants';
import { Linking, Platform } from 'react-native';

const LogoutWebview: FC = () => {
  const { clearAuth0Token } = useContext(AuthContext);
  const { logoutEndpoint } = getLogoutQuery();
  // on the web the useEffect will take care of logging out the user as the WebView is not used
  useEffect(() => {
    if (Platform.OS === 'web') {
      Linking.openURL(logoutEndpoint);
      clearAuth0Token();
    }
  }, []);

  //beware here that this function will only be called when running on a native device i.e using the webview
  const onNavigationStateChange = (event: WebViewNavigation) => {
    if (event.url.startsWith(Constants?.expoConfig?.extra?.redirectURI)) {
      clearAuth0Token();
    }
  };

  return <WebView onNavigationStateChange={onNavigationStateChange} source={{ uri: logoutEndpoint }} />;
};

export default LogoutWebview;
