import { Text } from 'native-base';
import { useTranslation } from 'react-i18next';

const styles = {
  text: {
    color: ['styleSheet.canvas', 'styleSheet.canvas', 'styleSheet.shadow', 'styleSheet.shadow', 'styleSheet.shadow'],
    fontFamily: 'body',
    fontSize: ['5xl', '5xl', '6xl', '6xl', '6xl'],
    lineHeight: ['44px', '50px', '58px', '60px', '60px'],
    mr: ['40px', '40px', '180px', '209px', '209px'],
    mt: ['24px', '24px', null, null, null],
  },
};

const LandingMsg = () => {
  const { t } = useTranslation();
  return <Text {...styles.text}>{t('LANDING_PAGE_MESSAGE')}</Text>;
};

export default LandingMsg;
