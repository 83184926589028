import { createNativeStackNavigator } from '@react-navigation/native-stack';
import EditGuestModal from 'components/Stays/EditGuestModal';
import CancelBookingMainModal from 'components/Stays/modals/CancelBookingMainModal';
import { BookingDetailProvider } from 'providers/BookingDetailProvider';
import { ReservationProvider } from 'providers/ReservationProvider';
import { useTranslation } from 'react-i18next';
import BookingDetail from 'screens/Stays/BookingDetail';
import PastBookingDetail from 'screens/Stays/PastBookingDetail';
import { RootStackParamList } from 'types';
import { getOptions } from '.';

const Stay = createNativeStackNavigator<RootStackParamList>();

export function StayDetailsNavigator() {
  const { t } = useTranslation();
  return (
    <BookingDetailProvider>
      <ReservationProvider>
        <Stay.Navigator initialRouteName="BookingDetail">
          <Stay.Screen
            name="BookingDetail"
            component={BookingDetail}
            options={getOptions(t('GO_SUMMER') + t('BDP.BOOKING_DETAILS'))}
          />
          <Stay.Screen
            name="PastBookingDetail"
            component={PastBookingDetail}
            options={getOptions(t('GO_SUMMER') + t('BDP.PAST_BOOKING_DETAILS'))}
          />
          <Stay.Group screenOptions={{ presentation: 'fullScreenModal', animation: 'slide_from_bottom' }}>
            <Stay.Screen
              name="EditGuestModal"
              component={EditGuestModal}
              options={getOptions(t('GO_SUMMER') + t('GUESTS'))}
            />
            <Stay.Screen
              name="CancelBookingMainModal"
              component={CancelBookingMainModal}
              options={getOptions(t('GO_SUMMER') + t('BDP.CANCEL_BOOKING'))}
            />
          </Stay.Group>
        </Stay.Navigator>
      </ReservationProvider>
    </BookingDetailProvider>
  );
}
