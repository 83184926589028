import { UserContext } from 'contexts/UserContext';
import { Box, Text, HStack, VStack, Pressable, View } from 'native-base';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UpdateAddressBox from './UpdateAddressBox';

const styles = {
  textKey: {
    textAlign: 'justify' as const,
    fontSize: '2md',
    fontFamily: 'body600',
    color: 'styleSheet.shadow',
    opacity: '0.55',
  },
  textValue: {
    fontSize: '2md',
    fontFamily: 'body',
    color: 'styleSheet.shadow',
    w: ['65%', '65%', '85%', '85%', '85%'],
  },
  hr: {
    borderWidth: '0.5px',
    borderColor: 'styleSheet.lightShadow',
    mt: '-2px',
  },
  linkButton: {
    fontFamily: 'body',
    color: 'styleSheet.shadow',
    fontSize: '2md',
  },
  hstack: {
    alignItems: 'center' as const,
    justifyContent: 'space-between' as const,
  },
  box: (v: boolean) => ({
    opacity: v ? 1 : 0.5,
    mt: '16px',
  }),

  addresspressed: {
    position: 'absolute' as const,
    right: 0,
  },
};

const ProfileAddressBox = () => {
  const { t } = useTranslation();
  const { user, profileInfo, setProfileInfo } = useContext(UserContext);
  const [editAddress, setEditAddress] = useState<boolean>(false);
  const addressStyle = editAddress ? styles.linkButton : styles.textKey;

  const handleEdit = () => {
    setProfileInfo((prev) => {
      return { ...prev, email: false, phone: false, switch: false, gender: false, dateOfBirth: false, name: false };
    });
    setEditAddress(true);
  };

  const handleVisibility = () => {
    setEditAddress(false);
    setProfileInfo((prev) => {
      return { ...prev, email: true, phone: true, switch: true, gender: true, dateOfBirth: true, name: true };
    });
  };

  return (
    <Box {...styles.box(profileInfo.address)}>
      <HStack {...styles.hstack}>
        <VStack>
          {!editAddress ? (
            <Box>
              <Text {...addressStyle}>{t('PROFILE.ADDRESS')}</Text>
              <Text {...styles.textValue}>
                {user?.street}, {user?.city}, {user?.state}, {user?.country}, {user?.zipcode}
              </Text>
            </Box>
          ) : (
            <HStack>
              <Text {...styles.linkButton}>{t('PROFILE.EDIT')}</Text>
              <Text {...addressStyle} style={{ marginLeft: 2 }}>
                {t('PROFILE.ADDRESS')}
              </Text>
            </HStack>
          )}
        </VStack>

        {!editAddress ? (
          <Pressable onPress={handleEdit} isDisabled={!profileInfo.address} {...styles.addresspressed}>
            <Text {...styles.linkButton}>{t('PROFILE.EDIT')}</Text>
            <View {...styles.hr} />
          </Pressable>
        ) : (
          <Pressable onPress={handleVisibility} {...styles.addresspressed}>
            <Text {...styles.linkButton}>{t('PROFILE.CANCEL')}</Text>
            <View {...styles.hr} />
          </Pressable>
        )}
      </HStack>

      {editAddress ? (
        <View>
          <UpdateAddressBox
            streetAddress={user?.street}
            city={user?.city}
            zipcode={user?.zipcode}
            state={user?.state}
            country={user?.country}
            setEditAddress={setEditAddress}
          />
        </View>
      ) : null}
    </Box>
  );
};

export default ProfileAddressBox;
