import { createContext } from 'react';

export const LOGIN_IMAGE_SRC_SET = [
  'https://livesummer-public-assets-20220808234240612900000001.s3.amazonaws.com/login-image-chairs.jpg',
  'https://livesummer-public-assets-20220808234240612900000001.s3.amazonaws.com/login-image-sunset.jpg',
  'https://livesummer-public-assets-20220808234240612900000001.s3.amazonaws.com/login-image-tree.jpg',
] as const;

export type LoginBgImageContextInitialState = {
  bgImageSrc: typeof LOGIN_IMAGE_SRC_SET[number];
  setBgImageSrc: (src: string) => void,
};

export const LoginBgImageContext = createContext<LoginBgImageContextInitialState>({
  bgImageSrc: LOGIN_IMAGE_SRC_SET[0],
  setBgImageSrc: () => {},
});
