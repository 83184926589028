import { createContext } from 'react';
import { Moment } from 'moment';
import { GetListingCalendar2Query } from '../providers/queries/getListingCalendar.generated';

// @ts-ignore
export type GetListingCalendarType = GetListingCalendar2Query['getListingCalendar']['data'];

export interface DayValueProps {
  value: number;
  level: 'low' | 'normal' | 'high';
  disabled: boolean
  minNights: number
}

export interface DayValue {
  [key: string]: DayValueProps;
}

export type CalendarContextProps = {
  periodAvailableStart: Moment
  periodAvailableEnd: Moment
  calendarListings?: GetListingCalendarType
  dayValues: DayValue;
  isSelectionValid(from: Date, to: Date):boolean
};

//context
export const CalendarContext = createContext<CalendarContextProps>({
} as any);
