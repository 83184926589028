import { FC, useEffect, useMemo, useState } from 'react';
import { ListingType } from 'contexts/PropertyDetailContext';
import { BookingDetailContext, ReservationType } from 'contexts/BookingDetailContext';
import { useGetReservationWithListingQuery } from 'screens/Stays/queries/getReservationWithListing.generated';
import { IGuests } from 'providers/ReservationProvider';

interface BookingDetailProviderProps {
  children: React.ReactNode;
}

export const BookingDetailProvider: FC<BookingDetailProviderProps> = ({ children }) => {
  const [reservation, setReservation] = useState<ReservationType | null>(null);
  const [reservationId, setReservationId] = useState<string | null>(null);
  const [listing, setListing] = useState<ListingType | null>(null);

  const { data, loading } = useGetReservationWithListingQuery({
    variables: { guestyReservationId: reservationId! },
    skip: !reservationId,
  });

  useEffect(() => {
    if (!!data) {
      setReservation(data.getReservation?.data);
      setListing(data.getReservation?.data?.listing);
    }
  }, [data]);

  const guests = useMemo<IGuests>(() => {
    const getCustomNumericValue = (key: string) =>
      Number((reservation?.customFields || []).find((field) => field.key === key)?.value || 0);

    return {
      adults: getCustomNumericValue('DND_adults'),
      children: getCustomNumericValue('DND_kids'),
      infants: getCustomNumericValue('DND_infants'),
      pets: getCustomNumericValue('DND_pets'),
    };
  }, [reservation]);

  return (
    <BookingDetailContext.Provider
      value={{
        reservation,
        reservationId,
        setReservationId,
        isLoading: loading,
        listing,
        guests,
      }}>
      {children}
    </BookingDetailContext.Provider>
  );
};
