import { Box, Hidden, Image } from 'native-base';
import { FC, useContext } from 'react';
import { LoginBgImageContext } from 'contexts/LoginBgImageContext';

const styles = {
  overlay: {
    position: 'absolute' as const,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: 'black',
    opacity: 0.3,
    width: '100%',
    height: '100%',
  },
  box: {
    width: '100%',
    height: '100%',
  },
};

const LandingPageBg: FC = () => {
  const { bgImageSrc } = useContext(LoginBgImageContext);
  return (
    <Box {...styles.box}>
      <Image {...styles.box} resizeMode="cover" alt="LandingPageBg" source={{ uri: bgImageSrc }} />
      <Hidden from={'md'}>
        <Box {...styles.overlay}></Box>
      </Hidden>
    </Box>
  );
};
export default LandingPageBg;
