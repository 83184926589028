import { NavigationContext } from 'contexts/NavigationContext';
import { Text, Button } from 'native-base';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

const styles = {
  loginButton: {
    w: [null, null, '35%', '40%', '40%'],
    height: '70px',
    borderRadius: '8px',
    bgColor: 'styleSheet.shadow',
    textAlign: 'center' as const,
    mb: ['24px', '24px', '24px', null, null],
    ml: [null, null, '34px', '34px', '34px'],
  },
  loginText: {
    color: 'bgStayBox',
    fontFamily: 'body600',
    fontSize: '2md',
  },
};

const LoginButton = () => {
  const { t } = useTranslation();
  const { useNavigation } = useContext(NavigationContext);

  return (
    <Button {...styles.loginButton} onPress={() => useNavigation('Login')}>
      <Text {...styles.loginText}>{t('LOGIN')}</Text>
    </Button>
  );
};

export default LoginButton;
