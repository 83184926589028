import HomePickerHeader from 'components/Home/HomePickerHeader';
import ListOfMultiHomes from 'components/MultiAccountHome/ListOfMultiHomes';
import { Box, ScrollView } from 'native-base';
import { useTranslation } from 'react-i18next';

const styles = {
  mainView: {
    bg: 'styleSheet.canvas',
    h: '100%',
    w: '100%',
  },
};

const HomeMultiPropertiesScreen = () => {
  const { t } = useTranslation();

  return (
    <Box safeAreaTop {...styles.mainView}>
      <HomePickerHeader />
      <ScrollView>
        <ListOfMultiHomes isFromMyHome={true} />
      </ScrollView>
    </Box>
  );
};

export default HomeMultiPropertiesScreen;
