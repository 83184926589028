import { Pressable, Avatar } from 'native-base';
import { FC } from 'react';
import BackIcon from '../../assets/icons/Back-Arrow-White.svg';

const styles = {
  iconCircle: {
    mt: ['16px', '16px', '85px', '104px', '104px'],
    ml: ['0px', '0px', '3%', '5%', '5%'],
  },
  pressable: {
    justifyContent: 'center' as const,
  },
  avatar: {
    bg: 'styleSheet.shadow',
    size: 'md',
  },
};

interface BackButtonHeaderProps {
  onBackButton: () => void;
}

const BackButtonHeader: FC<BackButtonHeaderProps> = ({ onBackButton }) => {
  return (
    <Pressable {...styles.iconCircle} onPress={onBackButton} testID="backButtonId">
      <Avatar {...styles.avatar}>
        <BackIcon />
      </Avatar>
    </Pressable>
  );
};

export default BackButtonHeader;
