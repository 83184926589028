import { UserContext } from 'contexts/UserContext';
import { Box, VStack, Text, Divider } from 'native-base';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

const styles = {
  textKey: {
    textAlign: 'justify' as const,
    fontSize: ['sm', 'sm', '2md', '2md', '2md'],
    fontFamily: 'body600',
    color: 'styleSheet.shadow',
    opacity: '0.55',
  },
  textValue: {
    fontSize: '2md',
    fontFamily: 'body',
    color: 'styleSheet.shadow',
  },
  divider: {
    borderWidth: 0.5,
    borderColor: 'styleSheet.darkStain',
    mt: '16px',
  },
  box: (v: boolean) => ({
    opacity: v ? 1 : 0.5,
    mt: '16px',
  }),
};

const ProfileEmailBox = () => {
  const { t } = useTranslation();
  const { user, profileInfo } = useContext(UserContext);
  return (
    <Box {...styles.box(profileInfo.email)}>
      <VStack>
        <Text {...styles.textKey}>{t('PROFILE.EMAIL')}</Text>
        <Text {...styles.textValue}>{user?.email}</Text>
      </VStack>
      <Divider {...styles.divider} />
    </Box>
  );
};

export default ProfileEmailBox;
