import { Input, InputGroup, Divider, Hidden, View, Text } from 'native-base';
import { Platform } from 'react-native';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const styles = {
  leftInput: {
    height: Platform.OS === 'ios' ? '30px' : '35px',
    borderWidth: '0',
    fontSize: '2md',
    fontFamily: 'body',
    color: 'styleSheet.overCast',
    placeholderTextColor: 'styleSheet.overCast',
    ml: '8px',
    _hover: {
      bg: 'styleSheet.canvas',
    },
    pb: ['9px', '9px', '10px', '14px', '14px'],
  },
  rightInput: {
    ml: '8px',
    height: Platform.OS === 'ios' ? '30px' : '35px',
    borderWidth: '0',
    fontSize: '2md',
    fontFamily: 'body',
    color: 'styleSheet.overCast',
    placeholderTextColor: 'styleSheet.overCast',
    _hover: {
      bg: 'transparent',
    },
    pb: ['9px', '9px', '10px', '14px', '14px'],
  },
  divider: {
    borderWidth: '1',
    borderColor: 'styleSheet.darkStain',
    height: '90%',
    top: 2,
    bottom: 2,
    position: 'absolute' as const,
    left: ['0px', '0px', '0px', '-4px', '-4px'],
  },
  checkIn: {
    fontSize: 'sm',
    color: 'styleSheet.lightShadow',
    opacity: '0.55',
    ml: '15px',
    pt: '4px',
    fontFamily: 'body',
    fontWeight: '400',
  },
  checkOut: {
    fontSize: 'sm',
    color: 'styleSheet.lightShadow',
    opacity: '0.55',
    ml: '15px',
    pt: '4px',
    fontFamily: 'body',
    fontWeight: '400',
  },
};

interface IDateInputProps {
  checkIn: string | null;
  checkOut: string | null;
  onPressIn?: () => void;
}

//TODO: we might need to change it base on a date library

const DateInput = ({ checkIn, checkOut, onPressIn }: IDateInputProps) => {
  const { t } = useTranslation();

  return (
    <>
      <InputGroup>
        <Hidden till={'md'}>
          <Divider orientation="vertical" {...styles.divider} />
        </Hidden>

        <View style={{ marginTop: 10, width: '50%' }}>
          <Text {...styles.checkIn}>{t('BOOK_A_STAY.CHECK_IN')}</Text>
          <Input
            showSoftInputOnFocus={false}
            placeholder={t('SELECT_DATE')}
            value={!checkIn ? checkIn : moment(checkIn).format('ddd, MMM DD')}
            onPressIn={onPressIn}
            editable={Platform.OS === 'ios' ? false : true}
            {...styles.leftInput}
          />
        </View>

        <InputGroup>
          <Divider orientation="vertical" {...styles.divider} />
          <View style={{ marginTop: 10, width: '100%' }}>
            <Text {...styles.checkOut}>{t('BOOK_A_STAY.CHECK_OUT')}</Text>
            <Input
              showSoftInputOnFocus={false}
              placeholder={t('SELECT_DATE')}
              value={!checkOut ? checkOut : moment(checkOut).format('ddd, MMM DD')}
              onPressIn={onPressIn}
              editable={Platform.OS === 'ios' ? false : true}
              {...styles.rightInput}
            />
          </View>
        </InputGroup>
      </InputGroup>
    </>
  );
};

export default DateInput;
