import { FC, useState } from 'react';
import { ImageSourceOption, LoginBgImageContext, LOGIN_IMAGE_SRC_SET } from 'contexts/LoginBgImageContext';

interface LoginBgImageProviderProps {
  children: React.ReactNode;
}

export const LoginBgImageProvider: FC<LoginBgImageProviderProps> = ({ children }) => {
  const [bgImageSrc, setBgImageSrc] = useState<ImageSourceOption>(
    LOGIN_IMAGE_SRC_SET[Math.floor(Math.random() * LOGIN_IMAGE_SRC_SET.length)]
  );

  return (
    <LoginBgImageContext.Provider
      value={{
        bgImageSrc,
        setBgImageSrc,
      }}
    >
      {children}
    </LoginBgImageContext.Provider>
  );
};
