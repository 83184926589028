const components = {
  Button: {
    variants: {
      primaryMobile: () => {
        return {
          borderRadius: '12px',
          h: '70px',
          _text: {
            fontSize: 'sm',
            color: 'styleSheet.canvas',
          },
          bg: 'styleSheet.shadow',
          _pressed: {
            opacity: '0.5',
          },
          _disabled: {
            opacity: '0.5',
          },
        };
      },
      secondaryMobile: () => {
        return {
          borderRadius: '12px',
          h: '56px',
          w: '214px',
          _text: {
            fontSize: 'sm',
            color: 'styleSheet.shadow',
          },
          bg: 'styleSheet.summer',
          _pressed: {
            opacity: '0.6',
          },
          _disabled: {
            opacity: '0.6',
          },
        };
      },
      smallMobile: () => {
        return {
          borderRadius: '8px',
          h: '56px',
          _text: {
            fontSize: 'sm',
            color: 'styleSheet.overCast',
          },
          bg: 'styleSheet.canvas',
          _pressed: {
            opacity: '0.6',
          },
          _disabled: {
            opacity: '0.6',
          },
        };
      },
    },
  },
  Divider: {
    variants: {
      primary: () => {
        return {
          borderWidth: '1',
          borderColor: 'styleSheet.darkStain',
        };
      },
    },
  },
  Text: {
    baseStyle: {
      fontSize: '2md',
    },
  },
};

export default components;
