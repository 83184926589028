import { FC } from 'react';
import { Modal, Text, Button, View, Divider } from 'native-base';
import { useTranslation } from 'react-i18next';

const styles = {
  content: {
    w: '90%',
    backgroundColor: 'styleSheet.canvas',
    borderTopRightRadius: '16px',
    borderTopLeftRadius: '16px',
    borderBottomRightRadius: '16px',
    borderBottomLeftRadius: '16px',
  },
  body: {
    ml: '24px',
    mr: '24px',
    mt: '40px',
    mb: '40px',
  },
  title: {
    fontFamily: 'body',
    fontSize: '2.5xl',
    lineHeight: '30.8px',
  },
  message: {
    fontFamily: 'body',
    fontSize: '2md',
    color: 'styleSheet.shadow',
    mt: '20px',
    lineHeight: 25,
  },
  button: {
    bg: 'styleSheet.shadow',
    w: '100%',
    h: '54px',
    mt: '40px',
    borderRadius: '8px',
    textAlign: 'center',
    _text: {
      fontSize: '2md',
      fontFamily: 'body600',
      color: 'styleSheet.canvas',
    },
    _pressed: {
      bg: 'styleSheet.shadow',
      opacity: '0.6',
      _text: { color: 'styleSheet.canvas' },
    },
    _hover: {
      bg: 'styleSheet.darkStain',
      _text: { color: 'styleSheet.shadow' },
    },
    _focus: {
      bg: 'styleSheet.shadow',
    },
  },
  view: {
    alignItems: 'center',
    mt: '30px',
  },
  divider: {
    borderWidth: '0.5px',
    borderColor: 'styleSheet.shadow',
    width: '115px',
  },
  cancelText: {
    fontSize: '2md',
    fontWeight: '400',
    fontFamily: 'body',
    color: 'styleSheet.shadow',
  },
};

interface DownloadAppMsgModalProps {
  isOpen: boolean;
  close: () => void;
}

const DownloadAppMsgModal: FC<DownloadAppMsgModalProps> = ({ isOpen, close }) => {
  const { t } = useTranslation();

  const handleDownloadNow = () => {
    let userAgent = navigator.userAgent;
    if (userAgent.match(/iPad/i) || userAgent.match(/Mac/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPod/i)) {
      window.location.replace('https://apps.apple.com/ca/app/summer-homes/id1616176012');
    } else if (userAgent.match(/Android/i)) {
      window.location.replace('https://play.google.com/store/apps/details?id=com.livesummer.app');
    } else {
      close();
    }
  };

  return (
    <Modal isOpen={isOpen} animationPreset="slide">
      <Modal.Content {...styles.content}>
        <Modal.Body>
          <View {...styles.body}>
            <Text {...styles.title}>{t('DOWNLOAD_APP.DOWNLOAD')}</Text>
            <Text {...styles.message}>{t('DOWNLOAD_APP.MSG')}</Text>

            <Button {...styles.button} onPress={handleDownloadNow}>
              {t('DOWNLOAD_APP.DOWNLOAD_NOW')}
            </Button>

            <View {...styles.view}>
              <Text
                {...styles.cancelText}
                onPress={() => {
                  close();
                }}>
                {' '}
                {t('DOWNLOAD_APP.DOWNLOAD_LATER')}
              </Text>
              <Divider {...styles.divider} />
            </View>
          </View>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};
export default DownloadAppMsgModal;
