import { View, Box, Image, Text, HStack } from 'native-base';
import { BookingDetailContext } from 'contexts/BookingDetailContext';
import { H3 } from 'components/libs/heading/Heading';
import { useTranslation } from 'react-i18next';
import AspectRatio from 'components/AspectRatio';
import { FC, useContext } from 'react';

const styles = {
  box: {
    borderRadius: '12px',
    overflow: 'hidden' as const,
    bg: 'styleSheet.lightStain',
  },
  h3: {
    fontSize: '1xl',
    color: 'styleSheet.shadow',
    fontFamily: 'body',
    mb: '16px',
  },
  image: {
    h: '170px',
    mb: '24px',
  },
  innerBox: {
    pl: '16px',
    pr: '16px',
    mt: '24px',
  },
  text: {
    fontSize: 'sm',
    color: 'styleSheet.overCast',
    fontFamily: 'body',
  },
  textBox: {
    w: '50%',
    mb: '24px',
  },
  hstack: {
    justifyContent: 'space-between' as const,
  },
  imageRatio: {
    w: '100%',
    ratio: [16 / 10, 16 / 10, 18 / 10, 20 / 10],
  },
};

interface HomeDetailsProps {}

const HomeDetails: FC<HomeDetailsProps> = () => {
  const { t } = useTranslation();
  const { listing } = useContext(BookingDetailContext);

  let uri;
  let id = 'id';
  if (listing?.pictures?.[0].original) {
    uri = listing?.pictures?.[0].original;
  }
  if (listing?._id) {
    id = listing?._id;
  }

  return (
    <View>
      <H3 {...styles.h3}>{t('BDP.HOME_DETAILS')}</H3>
      <Box {...styles.box}>
        <AspectRatio {...styles.imageRatio}>
          <Image source={{ uri: uri }} alt={id} resizeMode="cover" />
        </AspectRatio>
        <Box {...styles.innerBox}>
          <H3 {...styles.h3}>{listing?.title}</H3>
          <HStack {...styles.hstack}>
            <Box {...styles.textBox}>
              <Text {...styles.text}>{listing?.address?.full}</Text>
            </Box>
          </HStack>
        </Box>
      </Box>
    </View>
  );
};

export default HomeDetails;
