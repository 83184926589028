import { CitiesContext } from 'contexts/CitiesContext';
import { usePersistentValue } from 'hooks/usePersistentValue';
import { FC, useMemo } from 'react';
import { GetAllCitiesQuery, useGetAllCitiesQuery } from 'screens/Booking/queries/getAllCities.generated';

interface CitiesProviderProps {
  children: React.ReactNode;
}

const dedupeCities = (listings: GetAllCitiesQuery['getAllCities']['data']) => {
  if (!listings) {
    return [];
  }

  const seenCities = new Set<string>();
  return listings.filter((listing) => {
    if (!listing.nickname) {
      return false;
    }

    const shouldAddCity = !seenCities.has(listing.nickname);
    seenCities.add(listing.nickname);
    return shouldAddCity;
  });
};

export const CitiesProvider: FC<CitiesProviderProps> = ({ children }) => {
  const [storedSubscriptionId, setstoredSubscriptionId] = usePersistentValue<string | null>(
    'storedSubscriptionId',
    null
  );
  const { data, loading } = useGetAllCitiesQuery({
    variables: {
      subscriptionId: storedSubscriptionId!,
    },
    skip: !storedSubscriptionId,
  });

  const cities = useMemo(() => {
    const rawCities = data?.getAllCities.data;
    if (!rawCities) {
      return null;
    }

    // First listing corresponds to current user's home.
    const notMyHomes = rawCities.slice(1);
    const dedupedCities = dedupeCities(notMyHomes);

    return [rawCities[0], ...dedupedCities];
  }, [data]);

  return (
    <CitiesContext.Provider
      value={{
        cities,
        isLoading: loading,
      }}>
      {children}
    </CitiesContext.Provider>
  );
};
