import { FC, useEffect, useState } from 'react';
import { View, Text } from 'native-base';
import { GuestyListing } from 'src/generated/types';
import { ListingCard } from './ListingCard';
import { useTranslation } from 'react-i18next';
import Layout from 'constants/Layout';

const windowHeight = Layout.window.height;

const styles = {
  otherHomesContainer: (myHome: boolean) => ({
    w: '100%',
    h: ['100%', '100%', '100%', windowHeight, null],
    paddingVertical: '5%',
    position: 'relative' as const,
    top: myHome ? ['48px', '48px', '64px', '100px', '100px'] : ['48px', '48px', '64px', null, null],
  }),
  otherHomesText: {
    fontSize: '2.5xl',
    color: 'textStay',
    textAlign: 'left' as const,
    position: 'absolute' as const,
    lineHeight: '31.11px',
    h: '40px',
    left: ['3%', '3%', '5%', '11%', '16%'],
  },
  otherHomesTextAndSortingContainer: {
    flexDirection: 'row' as const,
    w: '100%',
    position: 'relative' as const,
    alignItems: ['center' as const, 'center' as const, null, null, null],
    top: ['0.5%', '0.5%', null, null, null],
  },
  mainView: {
    top: '24px',
    flexDirection: ['column' as const, 'column' as const, 'row' as const, 'row' as const, 'row' as const],
    flexWrap: ['nowrap' as const, 'nowrap' as const, 'wrap' as const, 'wrap' as const, 'wrap' as const],
    ml: ['0px', '0px', '5%', '10%', '15%'],
    mr: ['0px', '0px', '5%', '10%', '10%'],
  },
};

interface IOtherHomesProps {
  listingResults: GuestyListing[] | undefined;
  ownerListings: GuestyListing[] | undefined;
}

export const AvailableHomes: FC<IOtherHomesProps> = ({ listingResults, ownerListings }) => {
  const { t } = useTranslation();
  const [widthDims, setWidthDims] = useState(0);
  const [myHome, setMyHome] = useState(false);

  useEffect(() => {
    if (ownerListings && ownerListings.length > 0) {
      setMyHome(true);
    }
  }, [ownerListings]);

  if (
    listingResults?.filter((l: GuestyListing) => !l.isOwner) &&
    listingResults?.filter((l: GuestyListing) => !l.isOwner).length > 0
  ) {
    return (
      <View {...styles.otherHomesContainer(myHome)}>
        <View {...styles.otherHomesTextAndSortingContainer}>
          <Text {...styles.otherHomesText}> {t('AVAILABLE_HOMES')} </Text>
        </View>
        <View
          {...styles.mainView}
          onLayout={(event) => {
            const { width } = event.nativeEvent.layout;
            setWidthDims(width);
          }}>
          {listingResults
            ?.filter((l: GuestyListing) => !l.isOwner)
            .map((listing: GuestyListing) => (
              <ListingCard listing={listing} key={listing?._id} containerWidth={widthDims} />
            ))}
        </View>
      </View>
    );
  } else return null;
};
