import { Box, Button, Pressable, Text, HStack, Divider, VStack, View, useBreakpointValue } from 'native-base';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import GuestAddBar from 'components/Guests/GuestAddBar';
import { ListingsContext } from 'contexts/ListingsContext';
import DownArrowIcon from '../../assets/icons/Down-arrow.svg';
import UpArrowIcon from '../../assets/icons/Up-Arrow.svg';

const styles = {
  pressable: {
    h: ['150px', '150px', '80px', '75px', '75px'],
    w: '100%',
  },
  headerText: {
    position: 'absolute' as const,
    fontSize: 'sm',
    top: ['10px', '10px', '14px', '13px', '13px'],
    left: '16px',
    color: 'styleSheet.lightShadow',
    fontFamily: 'body',
    fontWeight: '400',
    opacity: '0.55',
  },
  icon: {
    position: 'absolute' as const,
    right: 5,
    top: '20px',
  },
  text: {
    pt: '15px',
    fontSize: '2md',
    fontFamily: 'body',
    fontWeight: '400',
    color: 'styleSheet.shadow',
  },
  hstack: {
    mt: '20px',
    ml: '15px',
    flex: 1,
    justifyContent: 'flex-start',
    overflow: 'hidden',
    space: 2,
  },
  buttonStack: {
    mb: '20px',
    space: '1',
  },
  button: {
    bg: 'styleSheet.canvas',
    _text: {
      color: 'styleSheet.overCast',
      fontSize: 'md',
      fontFamily: 'body',
      fontWeight: '400',
      textDecorationLine: 'underline',
    },
    _pressed: {
      bg: 'styleSheet.canvas',
      opacity: '0.3',
    },
    _hover: {
      bg: 'styleSheet.canvas',
    },
    _focus: {
      bg: 'styleSheet.canvas',
    },
  },
  checkbox: {
    mb: '10px',
    bg: 'styleSheet.canvas',
    _checked: {
      opacity: '0.7',
      bg: 'styleSheet.shadow',
      borderColor: 'styleSheet.shadow',
    },
    _text: {
      color: 'styleSheet.overCast',
      fontSize: 'md',
      fontFamily: 'body',
      fontWeight: '400',
    },
  },
  footerButton: {
    alignSelf: 'center',
    w: '90%',
    h: '50px',
    bg: 'styleSheet.shadow',
    _text: {
      fontSize: 'sm',
      fontFamily: 'body',
      fontWeight: '400',
      color: 'styleSheet.canvas',
    },
    _pressed: {
      bg: 'styleSheet.shadow',
      opacity: '0.3',
    },
    _hover: {
      bg: 'styleSheet.shadow',
    },
    _focus: {
      bg: 'styleSheet.shadow',
    },
    mt: '30px',
    mb: '20px',
  },
  divider: {
    borderWidth: '1',
    borderColor: 'styleSheet.darkStain',
    width: '100%',
  },
  dropdown: (isFromStickyBar: boolean, widthDims: number) => ({
    position: 'absolute' as const,
    top: isFromStickyBar ? -1 : 76,
    right: '10px',
    width: widthDims,
    bg: 'styleSheet.canvas',
    borderWidth: 2,
    borderColor: 'styleSheet.darkStain',
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    borderTopWidth: isFromStickyBar ? 2 : 0,
    borderTopRightRadius: isFromStickyBar ? 10 : 0,
    borderTopLeftRadius: isFromStickyBar ? 10 : 0,
    paddingRight: isFromStickyBar ? 1 : 2,
    paddingLeft: isFromStickyBar ? 1 : 2,
    marginBottom: 50,
  }),
  dividerTop: {
    w: '95%',
    borderColor: 'styleSheet.darkStain',
    borderWidth: '1px',
    alignSelf: 'center' as const,
    mt: '12px',
  },

  dividerForBigScreen: {
    w: '100%',
    mt: ['24px', '24px', '24px', '25px'],
    color: 'styleSheet.darkStain',
    borderWidth: '0.5',
    opacity: '0.15',
  },
  dividerForInfants: {
    w: '100%',
    mt: ['0px', '0px', '20px', '10px'],
    mb: ['0px', '0px', '15px', '10px'],
    color: 'styleSheet.darkStain',
    borderWidth: '0.5',
    opacity: '0.15',
  },
  vstack: {
    mt: ['38px', '38px', '38px', '8px'],
    px: [null, null, '10px', '10px', '10px'],
  },

  addBar: {
    mt: ['23px', '23px', '20px', '18px'],
  },
  textInfo: {
    fontSize: 'sm',
    fontFamily: 'body600',
    mb: '10px',
    color: 'styleSheet.shadow',
    opacity: '0.55',
  },
  view: {
    mb: '18px',
  },
  bottonText: {
    color: 'pdp.shadow',
    fontSize: 'sm',
    fontWeight: '400',
    fontFamily: 'body',
    alignSelf: 'center',
  },
  textBox: {
    mt: '30px',
  },
  CTAButton: {
    h: '54px',
    bg: 'styleSheet.shadow',
    mt: '30px',
    mb: '20px',
    w: '94%',
    alignSelf: 'center',
    _hover: {
      bg: 'styleSheet.lightShadow',
    },
    _pressed: {
      bg: 'styleSheet.lightShadow',
    },
    _focus: {
      bg: 'styleSheet.lightShadow',
    },
  },
  addBarInfants: {
    mt: ['0px', '0px', '16px', '16px', '16px'],
  },
};
interface DropDownWebProps {
  isFromStickyBar?: boolean;
  isOpen: boolean;
  setIsOpen: (e: boolean) => void;
  setIsOpenCal: (e: boolean) => void;
  setIsDropDownOpen: (e: boolean) => void;
}

const GuestBoxDropdown = ({
  isOpen,
  setIsOpen,
  isFromStickyBar,
  setIsOpenCal,
  setIsDropDownOpen,
}: DropDownWebProps) => {
  const { t } = useTranslation();
  const [widthDims, setWidthDims] = useState(0);
  let breakpointTypeValue = useBreakpointValue({ base: 1, sm: 2, md: 3, lg: 4, xl: 5 });

  const {
    filterGuests,
    addAdultHandler,
    removeAdultHandler,
    addChildrenHandler,
    removeChildrenHandler,
    removeInfantHandler,
    removePetHandler,
    addInfantHandler,
    addPetHandler,
    setGuestsComposition,
  } = useContext(ListingsContext);

  const guestCount = filterGuests.adults + filterGuests.children;
  const opacity = guestCount === 20 ? 0.4 : 1;
  const minusOpacity = filterGuests.adults === 1 ? 0.4 : 1;
  const petOpacity = filterGuests.pets === 2 ? 0.4 : 1;

  const adults = filterGuests.adults != 0 ? t('PDP_GUEST.ADULT', { count: filterGuests.adults }) : '';

  const childrens = filterGuests.children != 0 ? t('PDP_GUEST.CHILD', { count: filterGuests.children }) : '';

  const infants = filterGuests.infants != 0 ? t('PDP_GUEST.INFANT', { count: filterGuests.infants }) : '';

  const pets = filterGuests.pets != 0 ? t('PDP_GUEST.PET', { count: filterGuests.pets }) : '';

  const guests = [adults, childrens, infants, pets].filter((value) => !!value).join(', ');

  const renderText = () => {
    return (
      <HStack {...styles.hstack}>
        <Text {...styles.text}>{guests}</Text>
      </HStack>
    );
  };

  const handlePress = () => {
    setIsOpen(!isOpen);
    setIsOpenCal(false);
    setIsDropDownOpen(false);
  };

  const onFilterHandler = () => {
    setGuestsComposition(filterGuests);
    setIsOpen(false);
  };

  const setGuestBoxWidth = (width: number) => {
    if (breakpointTypeValue == 4 || breakpointTypeValue == 5) setWidthDims(width);
    if (breakpointTypeValue == 3) setWidthDims(width + width / 2);
  };

  return (
    <Box>
      <Pressable
        {...styles.pressable}
        onPress={handlePress}
        onLayout={(event) => {
          const { width } = event.nativeEvent.layout;
          setGuestBoxWidth(width);
        }}>
        <Box {...styles.icon}>
          <DownArrowIcon height={30} width={30} />
        </Box>

        <Text {...styles.headerText}>{t('PROPERTY_DETAIL.GUEST')}</Text>
        {renderText()}
      </Pressable>

      {isOpen ? (
        <Box {...styles.dropdown(isFromStickyBar!, widthDims)}>
          <Pressable onPress={handlePress} style={{ marginBottom: '30px' }}>
            <Box>
              <Box {...styles.icon}>
                <UpArrowIcon />
              </Box>

              <Text {...styles.headerText}>{t('PROPERTY_DETAIL.GUEST')}</Text>
              {renderText()}
            </Box>
            <Divider {...styles.dividerTop} />
          </Pressable>
          <View {...styles.view}>
            <VStack {...styles.vstack}>
              <GuestAddBar
                guest={filterGuests.adults}
                translationKey="ADULTS"
                addHandler={addAdultHandler}
                removeHandler={removeAdultHandler}
                opacity={opacity}
                minusOpacity={minusOpacity}
              />
              <GuestAddBar
                guest={filterGuests.children}
                addHandler={addChildrenHandler}
                removeHandler={removeChildrenHandler}
                translationKey="CHILDREN"
                opacity={opacity}
                {...styles.addBar}
              />

              <GuestAddBar
                guest={filterGuests.infants}
                addHandler={addInfantHandler}
                removeHandler={removeInfantHandler}
                translationKey="INFANTS"
                {...styles.addBarInfants}
              />

              <GuestAddBar
                guest={filterGuests.pets}
                addHandler={addPetHandler}
                removeHandler={removePetHandler}
                translationKey="PETS"
                opacity={petOpacity}
                {...styles.addBarInfants}
              />
            </VStack>
          </View>
          <Button {...styles.CTAButton} onPress={onFilterHandler}>
            {t('UPDATE_GUESTS')}
          </Button>
        </Box>
      ) : null}
    </Box>
  );
};

export default GuestBoxDropdown;
