import { FC, useState } from 'react';
import { View, Text } from 'native-base';
import { GuestyListing } from 'src/generated/types';
import { ListingCard } from './ListingCard';
import { useTranslation } from 'react-i18next';

const styles = {
  myHomeContainer: {
    w: '100%',
    position: 'relative' as const,
    paddingVertical: '2%',
  },
  myHomeText: {
    fontFamily: 'body',
    fontSize: '2.5xl',
    lineHeight: '31.11px',
    color: 'textStay',
    h: '40px',
    textAlign: 'left' as const,
    position: 'absolute' as const,
    left: ['3%', '3%', '5%', '11%', '16%'],
  },
  mainView: {
    top: '24px',
    flexDirection: ['column' as const, 'column' as const, 'row' as const, 'row' as const, 'row' as const],
    flexWrap: ['nowrap' as const, 'nowrap' as const, 'wrap' as const, 'wrap' as const, 'wrap' as const],
    ml: ['0px', '0px', '5%', '10%', '15%'],
    mr: ['0px', '0px', '5%', '10%', '15%'],
  },
};
interface IUserHomeProps {
  ownerListings: GuestyListing[] | undefined;
}

export const UserHome: FC<IUserHomeProps> = ({ ownerListings }) => {
  const { t } = useTranslation();
  const [widthDims, setWidthDims] = useState(0);

  if (ownerListings && ownerListings.length > 0) {
    return (
      <View {...styles.myHomeContainer}>
        <Text {...styles.myHomeText}> {t('MY_HOME')} </Text>

        <View
          {...styles.mainView}
          onLayout={(event) => {
            const { width } = event.nativeEvent.layout;
            setWidthDims(width);
          }}>
          {ownerListings?.map((ownerListing: GuestyListing) => (
            <ListingCard key={ownerListing._id} listing={ownerListing} containerWidth={widthDims} />
          ))}
        </View>
      </View>
    );
  } else {
    return null;
  }
};
