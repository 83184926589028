import { createContext } from 'react';
import { GetUserQuery } from 'screens/Booking/queries/getUser.generated';

export type GetUserType = GetUserQuery['getUser']['data'];

export type ProfileInfoTypes = {
  name: boolean;
  email: boolean;
  phone: boolean;
  switch: boolean;
  gender: boolean;
  dateOfBirth: boolean;
  address: boolean;
};

export type UserContextProps = {
  user: GetUserType;
  isLoading: boolean;
  profileInfo: ProfileInfoTypes;
  setProfileInfo: (v) => void;
  refetch: Function;
};

export const UserContext = createContext<UserContextProps>({} as any);
