import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { Box, ScrollView } from 'native-base';
import { RootStackScreenProps } from '../../../types';
import { Loading } from 'components/Loading';
import { BookingDetailContext } from 'contexts/BookingDetailContext';
import BookingDates from 'components/Stays/BookingDates';
import BookingGuests from 'components/Stays/BookingGuests';
import BookingConfirmationCode from 'components/Stays/BookingConfirmationCode';
import HomeDetails from 'components/Stays/HomeDetails';
import SubscriptionOverview from 'components/SubscriptionDetails/SubscriptionOverview';
import CancelationPolicy from 'components/ConfirmationPage/CancelationPolicy';
import PaymentDetails from 'components/Stays/PaymentDetails';
import BackButtonHeader from 'components/Stays/BackButtonHeader';
import Layout from 'constants/Layout';

const windowWidth = Layout.window.width;

const styles = {
  mainBox: {
    bg: 'styleSheet.canvas',
    h: '100%',
    w: '100%',
  },
  innerBox: {
    pl: '16px',
    pr: '16px',
    pb: ['0px', '0px', '40px', '40px', '40px'],
  },
  button: {
    h: '54px',
    bg: 'styleSheet.shadow',
    _text: {
      color: 'styleSheet.canvas',
      fontSize: '2md',
    },
    _pressed: {
      opacity: '0.5',
      bg: 'styleSheet.shadow',
    },
  },
  mainView: {
    flexDirection: ['column' as const, 'column' as const, 'column' as const, 'row' as const, 'row' as const],
    w: ['100%', '100%', '100%', windowWidth - windowWidth * 0.35, windowWidth - windowWidth * 0.25],
    alignSelf: 'center' as const,
  },
  detailView: {
    w: ['100%', '100%', '60%', '60%', '60%'],
    mt: '24px',
    pr: ['0px', '0px', '0px', '80px', '100px'],
    alignSelf: 'center' as const,
  },
  paymentView: {
    w: ['100%', '100%', '60%', '40%', '40%'],
    alignSelf: [null, null, 'center' as const, null, null],
  },
};

const PastBookingDetail: FC<RootStackScreenProps<'PastBookingDetail'>> = ({ navigation, route }) => {
  const { setReservationId, reservation, isLoading } = useContext(BookingDetailContext);

  useEffect(() => {
    setReservationId(route.params?.reservationId);
  }, [route.params?.reservationId]);

  const [isTextDisplay, setIsTextDisplay] = useState<boolean>(true);

  const usedAmount = useMemo(() => reservation?.subscriptionUsedAmount, [reservation]);
  const currentBooking = useMemo(() => reservation?.currentBookingAmount, [reservation]);
  const remainingBalance = useMemo(() => reservation?.subscriptionRemainingBalance, [reservation]);
  const confirmationCode = useMemo(() => reservation?.confirmationCode!, [reservation]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Box safeAreaTop {...styles.mainBox}>
      <ScrollView>
        <Box {...styles.innerBox}>
          <BackButtonHeader onBackButton={() => navigation.goBack()} />

          <Box {...styles.mainView}>
            <Box {...styles.detailView}>
              <BookingDates />
              <BookingGuests onGuestHandler={() => {}} past={true} />
              <BookingConfirmationCode confirmationCode={confirmationCode} />
              <HomeDetails />
              <SubscriptionOverview
                usedAmount={usedAmount}
                currentBooking={currentBooking}
                remainingBalance={remainingBalance}
                text={isTextDisplay}
                setTextDisplay={setIsTextDisplay}
              />
              <CancelationPolicy />
            </Box>
            <Box {...styles.paymentView}>
              <PaymentDetails />
            </Box>
          </Box>
        </Box>
      </ScrollView>
    </Box>
  );
};

export default PastBookingDetail;
