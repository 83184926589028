import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEn from '../lang/en.json';
import 'intl-pluralrules';
i18next
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: translationEn,
      },
    },
    react: {
      useSuspense: true,
    },
    lng: 'en',
    fallbackLng: 'en',
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18next;
