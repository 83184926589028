import { FC } from 'react';
import { Text, View } from 'native-base';
import { useTranslation } from 'react-i18next';
import Layout from 'constants/Layout';

const windowWidth = Layout.window.width;
const windowHeight = Layout.window.height;

const styles = {
  title: {
    left: ['4%', '4%', '4%', '14%', '14%'],
    fontSize: '2.5xl',
    fontFamily: 'body600',
    position: 'absolute' as const,
  },
  message: {
    fontSize: 'lg',
    fontFamily: 'body',
    LineHeight: '22.95px',
    color: 'pdp.shadow',
    mt: '64px',
    mr: '40px',
    position: 'absolute' as const,
    left: ['4%', '4%', '4%', '10%', '10%'],
    right: ['0px', '0px', '0px', '10%', '10%'],
  },
  view: {
    h: windowHeight,
    w: windowWidth,
    top: ['54px', '54px', '54px', '3%', '3%'],
  },
};

export const NotFound: FC = () => {
  const { t } = useTranslation();

  return (
    <View {...styles.view}>
      <Text {...styles.title}>{t('NOT_FOUND.TITLE')}</Text>
      <Text {...styles.message}>{t('NOT_FOUND.MESSAGE')}</Text>
    </View>
  );
};
