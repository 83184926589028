import { FC, useContext, useEffect, useMemo } from 'react';
import { View } from 'native-base';
import { GuestyListing } from '../../generated/types';
import { LayoutWithNavigationIcon } from 'components/PropertyDetail/PageLayout';
import { Loading } from '../../components/Loading';
import { ListingsContext } from 'contexts/ListingsContext';
import ListingStickyBar from 'components/Listings/ListingStickyBar';
import { UserHome } from 'components/Listings/UserHome';
import { AvailableHomes } from 'components/Listings/AvailableHomes';
import { NotFound } from 'components/Listings/NotFound';
import { RootStackScreenProps } from 'types';
import { CitiesContext } from 'contexts/CitiesContext';
const styles = {
  box: {
    mt: ['65px', '65px', '33px', '65px', '65px'],
    mb: ['30px', '30px', '30px', '0px', '0px'],
    w: '100%',
    flexDirection: 'column' as const,
    justifyContent: 'flex-start' as const,
    alignItems: 'center' as const,
    h: '100%',
  },
};

export const ListingResults: FC<RootStackScreenProps<'ListingResults'>> = ({ route }) => {
  const { setCheckIn, setCheckOut, isLoading, listings, setAPISelectedCities } = useContext(ListingsContext);
  const { cities } = useContext(CitiesContext);

  useEffect(() => {
    if (!!route.params?.APISelectedCities) {
      setAPISelectedCities(route.params?.APISelectedCities);
    }
  }, [route.params?.APISelectedCities]);

  useEffect(() => {
    if (!!route.params?.checkInDate && !!route.params?.checkOutDate) {
      setCheckIn(route.params?.checkInDate);
      setCheckOut(route.params?.checkOutDate);
    }
  }, [route.params?.checkInDate, route.params?.checkOutDate]);

  useEffect(() => {
    if (!cities) return;

    if (!!route.params?.selectedCities) {
      setAPISelectedCities(cities.filter((c) => route.params?.selectedCities.indexOf(c.homeId || c.nickname) >= 0));
    } else {
      setAPISelectedCities(cities);
    }
  }, [route.params?.selectedCities, cities]);

  const availableHomes = useMemo(() => {
    return listings as GuestyListing[];
  }, [listings]);

  const ownerListings = useMemo(() => {
    const myHome =
      availableHomes && (availableHomes?.filter((listing: GuestyListing) => listing?.isOwner) as GuestyListing[]);
    if (myHome) return myHome;
    else return undefined;
  }, [availableHomes]);

  if (isLoading) return <Loading />;
  if (!listings || listings.length === 0)
    return (
      <LayoutWithNavigationIcon>
        <ListingStickyBar />
        <NotFound />
      </LayoutWithNavigationIcon>
    );
  else
    return (
      <LayoutWithNavigationIcon>
        <ListingStickyBar />
        <View {...styles.box}>
          <UserHome ownerListings={ownerListings} />
          <AvailableHomes listingResults={availableHomes} ownerListings={ownerListings} />
        </View>
      </LayoutWithNavigationIcon>
    );
};
