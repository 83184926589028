import { FC, useContext, useMemo } from 'react';
import { Button, Text, Image, HStack, Stack, Pressable } from 'native-base';
import AspectRatio from 'components/AspectRatio';
import { NavigationContext } from 'contexts/NavigationContext';
import { useTranslation } from 'react-i18next';
import Layout from 'constants/Layout';
import { usePersistentValue } from 'hooks/usePersistentValue';
import separator from 'utils/separator';

const windowWidth = Layout.window.width;

const styles = {
  imageHeight: {
    borderTopRadius: '12px',
  },
  imageRatio: {
    w: '100%',
    ratio: [16 / 10, 16 / 10, 16 / 10, 18 / 10, 18 / 10],
  },
  topContainer: (containerWidth: number) => ({
    overflow: 'hidden',
    backgroundColor: 'styleSheet.lightStain',
    borderColor: 'tint',
    borderWidth: '1px',
    borderTopRightRadius: '2xl',
    borderTopLeftRadius: '2xl',
    borderBottomLeftRadius: '2xl',
    borderBottomRightRadius: '2xl',
    mt: '16px',
    w: [windowWidth - 30, windowWidth - 30, containerWidth / 2.1, containerWidth / 2.2, containerWidth / 2.2],
    ml: ['0px', '0px', '8px', '16px', '16px'],
    alignSelf: 'center' as const,
    h: [null, null, '100%', null, null],
    _pressed: {
      opacity: '0.8',
    },
  }),
  textHomeName: {
    fontSize: 'lg',
    fontFamily: 'body600',
    color: 'styleSheet.shadow',
    lineHeight: 22,
  },
  textBalance: {
    fontSize: 'sm',
    pt: '8px',
    color: 'styleSheet.overCast',
  },
  hstack: {
    mt: '16px',
    pl: '16px',
    pb: '16px',
  },
  button: {
    position: 'absolute' as const,
    right: '16px',
    bg: 'styleSheet.lightShadow',
    borderRadius: '8px',
    _text: {
      fontFamily: 'body600',
      color: 'styleSheet.lightStain',
      fontSize: 'sm',
    },
    w: '110px',
    _pressed: {
      bg: 'styleSheet.lightShadow',
      opacity: '0.6',
      _text: { color: 'styleSheet.lightStain' },
    },
    _hover: {
      bg: 'styleSheet.darkStain',
      _text: { color: 'styleSheet.lightShadow' },
    },
    _focus: {
      bg: 'styleSheet.lightShadow',
    },
  },
  stack: {
    width: ['64%', '64%', '54%', '64%', '64%'],
  },
};

interface IHomePickerCard {
  subscriptionId: string;
  image: string;
  home: string;
  remainingBalance: string;
  containerWidth?: number;
  isFromMyHome?: boolean;
}

export const HomePickerCard: FC<IHomePickerCard> = ({
  subscriptionId,
  image,
  home,
  remainingBalance,
  containerWidth,
  isFromMyHome,
}) => {
  const { useNavigation } = useContext(NavigationContext);
  const { t } = useTranslation();
  const [, setStoredSubscriptionId] = usePersistentValue<string | null>('storedSubscriptionId', null);
  const [, setStoredSelectedHome] = usePersistentValue<string | null>('storedSelectedHome', null);

  const onSelectPressed = () => {
    setStoredSubscriptionId(subscriptionId);
    setStoredSelectedHome(home);

    isFromMyHome
      ? useNavigation('MyHome', { screen: 'MyHomeScreen' })
      : useNavigation('ProfileTabNavigator', { screen: 'Booking' });
  };

  const thumbnailUrl = useMemo(() => image.replace(/h_(\d+)/, 'h_700'), [image]);
  const currentRemainingBalance = useMemo(() => Math.round(Number(remainingBalance)).toString(), [remainingBalance]);

  return (
    <Pressable onPress={onSelectPressed} {...styles.topContainer(containerWidth!)}>
      <Stack>
        <AspectRatio {...styles.imageRatio}>
          <Image {...styles.imageHeight} source={{ uri: thumbnailUrl }} resizeMode="cover" alt={image} />
        </AspectRatio>
        <HStack {...styles.hstack}>
          <Stack {...styles.stack}>
            <Text {...styles.textHomeName}>{home}</Text>
            <Text {...styles.textBalance}>
              ${separator(currentRemainingBalance)}
              {t('MULTI_HOME.REMAINING')}
            </Text>
          </Stack>
          <Button {...styles.button} onPress={onSelectPressed} testID="homePickerTest">
            {t('MULTI_HOME.SELECT')}
          </Button>
        </HStack>
      </Stack>
    </Pressable>
  );
};
