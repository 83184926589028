import { FC, useContext } from 'react';
import { Feather } from '@expo/vector-icons';
import { Box, HStack, Pressable, Text } from 'native-base';
import { useTranslation } from 'react-i18next';
import { NavigationContext } from 'contexts/NavigationContext';

const styles = {
  homeDetails: {
    alignItems: 'center' as const,
    bg: 'pdp.buttonBg',
    borderRadius: '8px',
    minH: '34px',
    px: '12px',
    py: '6px',
    minW: '155px',
    maxW: '100%',
  },
  seeHomeDetails: {
    color: 'styleSheet.overCast',
    fontFamily: 'body600',
    fontSize: 'sm',
  },
  icon: {
    alignSelf: 'center' as const,
    paddingRight: 10,
  },
  wrapper: {
    alignItems: 'baseline' as const,
  },
};

interface IHomeDetailProps {
  homeId: string;
}
const SeeHomeDetail: FC<IHomeDetailProps> = ({ homeId }) => {
  const { t } = useTranslation();

  const { useNavigation } = useContext(NavigationContext);

  const handleSeeHomeDetails = () => {
    useNavigation('ListingNavigator', {
      screen: 'PropertyDetailsNavigator',
      params: {
        screen: 'PropertyDetail',
        params: {
          listingId: homeId,
        },
      },
    });
  };

  return (
    <Box {...styles.wrapper}>
      <Pressable onPress={handleSeeHomeDetails} hitSlop={8}>
        <HStack {...styles.homeDetails}>
          <Feather name="external-link" size={18} style={styles.icon} color="#5C5753" />
          <Text {...styles.seeHomeDetails}>{t('HOME.HOME_DETAILS')}</Text>
        </HStack>
      </Pressable>
    </Box>
  );
};

export default SeeHomeDetail;
