import { useContext, useState, FC } from 'react';
import {
  Box,
  CloseIcon,
  HStack,
  Pressable,
  Text,
  View,
  Button,
  VStack,
  Divider,
  useBreakpointValue,
} from 'native-base';
import { useTranslation } from 'react-i18next';
import { useUpdateReservationMutation } from '../../../screens/Stays/mutation/updateReservation.generated';
import { Loading } from 'components/Loading';
import { ICancelReservations } from './cancelBookingMainModal.d';
import { SubscriptionContext } from 'contexts/SubscriptionContext';
import { RootStackScreenProps } from 'types';
import { NavigationContext } from 'contexts/NavigationContext';

const styles = {
  container: {
    bgColor: 'styleSheet.canvas',
    w: ['100%', '100%', '60%', '50%', '35%'],
    h: ['100%', '100%', '35%', '60%', '70%'],
    borderRadius: [0, 0, 20, 20, 20],
    borderWidth: ['0px', '0px', '1px', '1px', '1px'],
    borderColor: 'styleSheet.canvas',
    alignSelf: 'center' as const,
  },
  top: {
    alignItems: 'center' as const,
  },
  icon: {
    color: 'styleSheet.shadow',
    alignSelf: 'center',
    size: '4',
  },
  pressable: {
    h: '50px',
    w: '50px',
    pr: '16px',
    position: 'absolute' as const,
    right: '0',
    zIndex: 1,
    justifyContent: 'center' as const,
  },
  text: {
    pl: '16px',
    pt: '28px',
    pb: '24px',
    fontSize: '2md',
    fontFamily: 'body600',
    color: 'styleSheet.shadow',
  },
  textArea: {
    pt: ['40px', '40px', '40px', null, null],
    pr: '16px',
    pl: '16px',
    justifyContent: 'center' as const,
    h: [null, null, null, '80%', '80%'],
  },
  alertMsg: {
    fontWeight: '400',
    fontSize: '1xl',
    textColor: 'styleSheet.shadow',
    fontFamily: 'body',
    lineHeight: '24.44px',
    alignSelf: [
      'flex-start' as const,
      'flex-start' as const,
      'flex-start' as const,
      'center' as const,
      'center' as const,
    ],
  },
  hr: {
    w: '100%',
    borderWidth: '1px',
    borderColor: 'styleSheet.overCast',
    opacity: '0.15',
  },
  keepButton: {
    h: '54px',
    mt: '40px',
    bg: 'styleSheet.shadow',
    _text: {
      color: 'styleSheet.canvas',
      fontSize: '2md',
      fontFamily: 'body600',
    },
    _pressed: {
      bg: 'styleSheet.shadow',
      opacity: '0.6',
      _text: { color: 'styleSheet.canvas' },
    },
    _hover: {
      bg: 'styleSheet.darkStain',
      _text: { color: 'styleSheet.shadow' },
    },
    _focus: {
      bg: 'styleSheet.shadow',
    },
    borderRadius: '8px',
    ml: ['0px', '0px', '0px', '0px', '80px'],
    mr: ['0px', '0px', '0px', '0px', '80px'],
  },
  cancelButton: {
    h: '54px',
    mt: '24px',
    bg: 'styleSheet.canvas',
    _text: {
      color: 'styleSheet.shadow',
      fontSize: '2md',
      fontFamily: 'body600',
    },
    _pressed: {
      bg: 'styleSheet.shadow',
      opacity: '0.6',
      _text: { color: 'styleSheet.canvas' },
    },
    _hover: {
      bg: 'styleSheet.overCast',
      _text: { color: 'styleSheet.lightStain' },
    },
    _focus: {
      bg: 'styleSheet.canvas',
    },
    borderRadius: '8px',
    borderColor: 'styleSheet.overCast',
    borderWidth: '1px',
    ml: ['0px', '0px', '0px', '0px', '80px'],
    mr: ['0px', '0px', '0px', '0px', '80px'],
  },
  sucessMsg: {
    fontSize: '1xl',
    textColor: 'styleSheet.shadow',
    fontFamily: 'body600',
    w: ['343px', '343px', '100%', '100%', '100%'],
    lineHeight: '24px',
    textAlign: ['left' as const, 'left' as const, 'left' as const, 'center' as const, 'center' as const],
  },
  note: {
    fontWeight: '400',
    fontSize: '2md',
    textColor: 'styleSheet.shadow',
    fontFamily: 'body',
    mt: '16px',
    pl: [null, null, null, '40px', '40px'],
    pr: [null, null, null, '40px', '40px'],
    textAlign: ['left' as const, 'left' as const, 'left' as const, 'center' as const, 'center' as const],
  },
  button: {
    h: '54px',
    mt: '40px',
    bg: 'styleSheet.shadow',
    _text: {
      color: 'styleSheet.canvas',
      fontSize: '2md',
      fontFamily: 'body600',
    },
    _pressed: {
      bg: 'styleSheet.shadow',
      opacity: '0.6',
      _text: { color: 'styleSheet.canvas' },
    },
    _hover: {
      bg: 'styleSheet.darkStain',
      _text: { color: 'styleSheet.shadow' },
    },
    _focus: {
      bg: 'styleSheet.shadow',
    },
    borderRadius: '8px',
    ml: ['0px', '0px', '0px', '0px', '80px'],
    mr: ['0px', '0px', '0px', '0px', '80px'],
  },
  view: {
    justifyContent: 'center',
    alignItems: 'center',
    mt: '15px',
  },
  dividerlink: {
    w: '50px',
    color: 'styleSheet.shadow',
    borderWidth: '0.5px',
  },
  linkButton: {
    color: 'styleSheet.shadow',
    fontWeight: '400',
    fontFamily: 'body',
    fontSize: '2md',
  },
  mainView: {
    w: ['100%', '100%', '100%', '70%', '35%'],
    h: ['100%', '100%', '100%', '70%', '50%'],
    borderRadius: [0, 0, 20, 20, 20],
    borderWidth: ['0px', '0px', '1px', '1px', '1px'],
    borderColor: 'styleSheet.canvas',
  },
};

interface ICancelBookingFirstProps {
  setIsShow: (e: boolean) => void;
  reservationId: string | undefined;
  setCancelBookingModal: (state: boolean) => void;
}

interface ICancelBookingSecondProps {
  setCancelBookingModal: (state: boolean) => void;
}
interface ICancelBookingModalProps extends RootStackScreenProps<'CancelBookingMainModal'> {
  reservationID: string;
  setCancelBookingModal: (state: boolean) => void;
}

const STATUS = 'canceled';
const CancelBookingFirstModal: FC<ICancelBookingFirstProps> = ({ setIsShow, reservationId, setCancelBookingModal }) => {
  const { t } = useTranslation();
  const { useNavigation } = useContext(NavigationContext);

  const { refetch: refreshBalance } = useContext(SubscriptionContext);
  const [updateResetvation, { loading, data, error }] = useUpdateReservationMutation();
  const handleComplete = () => {
    refreshBalance().then(() => setIsShow(false));
  };
  let breakpointTypeValue = useBreakpointValue({ base: 1, sm: 2, md: 3, lg: 4, xl: 5 });

  const handleError = (e: any) => {
    if (__DEV__) console.log('cancellation error', e);
  };

  const handleCancellation = (values: ICancelReservations) => {
    console.log('ICancelReservations', values);
    updateResetvation({
      variables: {
        data: {
          ...values,
        },
      },
      onCompleted: () => handleComplete(),
      onError: () => handleError(error),
    });
  };

  const handleKeepBooking = () => {
    if (breakpointTypeValue == 3 || breakpointTypeValue == 4 || breakpointTypeValue == 5) {
      setCancelBookingModal(false);
    } else {
      useNavigation();
    }
  };

  if (loading) {
    return (
      <>
        <Box {...styles.container}>
          <Loading />
        </Box>
      </>
    );
  }

  return (
    <Box {...styles.container} safeAreaTop>
      <HStack {...styles.top}>
        <Text {...styles.text}>{t('CANCEL_BOOKING_RES.CANCEL_BOOKING')}</Text>
        <Pressable onPress={handleKeepBooking} {...styles.pressable}>
          <CloseIcon {...styles.icon} />
        </Pressable>
      </HStack>
      <View {...styles.hr} />
      <View {...styles.textArea}>
        <Text {...styles.alertMsg}>{t('CANCEL_BOOKING_RES.ALERT_CANCEL_BOOKING')}</Text>
        <Button
          children={t('CANCEL_BOOKING_RES.KEEP_BOOKING_MSG')}
          onPress={handleKeepBooking}
          {...styles.keepButton}
        />
        <Button
          children={t('CANCEL_BOOKING_RES.CANCEL_BOOKING_MSG')}
          onPress={() => handleCancellation({ guestyReservationId: reservationId, status: STATUS })}
          {...styles.cancelButton}
        />
      </View>
    </Box>
  );
};

export const CancelBookingSecondModal: FC<ICancelBookingSecondProps> = ({ setCancelBookingModal }) => {
  const { t } = useTranslation();
  const { useNavigation } = useContext(NavigationContext);
  let breakpointTypeValue = useBreakpointValue({ base: 1, sm: 2, md: 3, lg: 4, xl: 5 });

  const handleCloseModal = () => {
    if (breakpointTypeValue == 3 || breakpointTypeValue == 4 || breakpointTypeValue == 5) {
      setCancelBookingModal(false);
    }
    useNavigation('Stays');
  };

  const handleBookDiffStay = () => {
    if (breakpointTypeValue == 3 || breakpointTypeValue == 4 || breakpointTypeValue == 5) {
      setCancelBookingModal(false);
    }
    useNavigation('Booking', {
      screen: 'ListingNavigator',
      params: { screen: 'ListingResults' },
    });
  };

  return (
    <Box {...styles.container} safeAreaTop>
      <HStack {...styles.top}>
        <Text {...styles.text}>{t('CANCEL_BOOKING_RES.CANCEL_BOOKING')}</Text>
        <Pressable onPress={handleCloseModal} {...styles.pressable}>
          <CloseIcon {...styles.icon} />
        </Pressable>
      </HStack>
      <View {...styles.hr} />

      <View {...styles.textArea}>
        <Text {...styles.sucessMsg}>{t('CANCEL_BOOKING_RES.CANCELLED_BOOKING_MSG')}</Text>
        <Text {...styles.note}>{t('CANCEL_BOOKING_RES.CANCEL_MSG')}</Text>
        <Button children={t('CANCEL_BOOKING_RES.BOOK_DIFF_STAY')} onPress={handleBookDiffStay} {...styles.button} />
        <VStack {...styles.view}>
          <Text children={t('CLOSE')} onPress={handleCloseModal} {...styles.linkButton} />
          <Divider {...styles.dividerlink} />
        </VStack>
      </View>
    </Box>
  );
};

const CancelBookingMainModal: FC<ICancelBookingModalProps> = ({ route, setCancelBookingModal, reservationID }) => {
  const reservationId = route ? route.params?.reservationId : reservationID;
  const [isShow, setIsShow] = useState(true);

  const content = isShow ? (
    <CancelBookingFirstModal
      setIsShow={setIsShow}
      reservationId={reservationId}
      setCancelBookingModal={setCancelBookingModal}
    />
  ) : (
    <CancelBookingSecondModal setCancelBookingModal={setCancelBookingModal} />
  );

  return content;
};

export default CancelBookingMainModal;
