import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { WebView, WebViewNavigation } from 'react-native-webview';
import { getLoginQuery } from 'utils/Auth0Utils';
import { AuthContext } from 'contexts/authContext';
import { Platform } from 'react-native';
import { ImageSourceOption, LoginBgImageContext } from 'contexts/LoginBgImageContext';
import { NavigationContext } from 'contexts/NavigationContext';

const LoginWebview: FC = () => {
  const { processAuth0Code } = useContext(AuthContext);
  const { useNavigation } = useContext(NavigationContext);
  const { bgImageSrc, setBgImageSrc } = useContext(LoginBgImageContext);
  const { authorizationEndpoint } = getLoginQuery();
  const [didSendCode, setDidSendCode] = useState(false);

  const authEndpointWithImage = useMemo(
    () => `${authorizationEndpoint}&bgImageSrc=${bgImageSrc}`,
    [authorizationEndpoint, bgImageSrc]
  );

  const onNavigationStateChange = (event: WebViewNavigation) => {
    const matches = /code=([^&]*)/.exec(event.url);

    if (matches && matches.length === 2 && !didSendCode) {
      setDidSendCode(true);
      processAuth0Code(matches[1]);
    }
  };

  useEffect(() => {
    if (authEndpointWithImage && Platform.OS === 'web') {
      location.href = authEndpointWithImage;
    }
  }, [authEndpointWithImage]);

  if (Platform.OS === 'web') {
    return null;
  }

  return (
    <WebView
      style={{ flexGrow: 1, height: '100%' }}
      onNavigationStateChange={onNavigationStateChange}
      source={{ uri: authEndpointWithImage }}
      onMessage={(event) => {
        const bgImageSrc = event.nativeEvent.data;
        if (bgImageSrc) {
          setBgImageSrc(bgImageSrc as ImageSourceOption);
          useNavigation('SignUp');
        }
      }}
    />
  );
};

export default LoginWebview;
