import { createContext } from 'react';
import { ReadSubscriptionBalanceBySubscriptionIdQuery } from 'screens/Booking/queries/readSubscriptionBalanceBySubscriptionId.generated';

export type ReadSubscriptionBalanceBySubscriptionIdType =
  ReadSubscriptionBalanceBySubscriptionIdQuery['readSubscriptionBalanceBySubscriptionId']['data'];

export type SubscriptionContextProps = {
  subscriptionBalance: ReadSubscriptionBalanceBySubscriptionIdType;
  isLoading: boolean;
  refetch: Function;
};

export const SubscriptionContext = createContext<SubscriptionContextProps>({} as any);
