import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomInputProps } from 'src/styles/input';
import { CustomTextInput } from './CustomTextInput';

const styles = {
  style: {
    width: '100%',
  },
};
export const DestinationInput: FC<CustomInputProps> = ({
  handleFieldChange,
  label,
  fieldName,
  errorMsg,
  validateField,
}) => {
  const { t } = useTranslation();
  return (
    <CustomTextInput
      label={label}
      handleFieldChange={handleFieldChange}
      fieldName={fieldName}
      style={styles.style}
      errorMsg={errorMsg}
      placeholder={t('DESTINATION_PLACEHOLDER')}
      validateField={validateField}
    />
  );
};
