import { FC, useContext, useEffect, useMemo } from 'react';
import { Text, Box, HStack, VStack } from 'native-base';
import { useTranslation } from 'react-i18next';
import { H2 } from '../libs/heading/Heading';
import DonutChart from './DonutChart';
import separator from 'utils/separator';
import { AppTheme } from 'constants/Theme';
import { HomeEquityContext } from 'contexts/HomeEquityContext';

const styles = {
  box: {
    mt: ['40px', '40px', '56px', '56px'],
    bg: 'styleSheet.canvas',
    mb: '-30px',
  },
  view: {
    ml: '16px',
    mr: '16px',
  },
  h2: {
    fontFamily: 'body',
    fontSize: '1xl',
    color: 'styleSheet.shadow',
  },
  hstack: {
    mt: '37px',
    space: '50px',
  },
  text: {
    fontSize: 'sm',
    opacity: '0.7',
    fontFamily: 'body',
  },
  number: {
    fontSize: '2md',
    color: 'styleSheet.shadow',
    fontWeight: '400',
    fontFamily: 'body',
    mb: '12px',
  },
  title: {
    fontSize: 'sm',
    color: 'styleSheet.overCast',
    fontWeight: '400',
    fontFamily: 'body',
    mt: '8px',
  },
};
interface IFinancialOverviewProps {
  onPress?: () => void;
  usedAmount: number | undefined | null;
  currentBooking: number | undefined | null;
  remainingBalance: number | undefined | null;
  text: boolean;
  setTextDisplay: (state: boolean) => void;
}

// TODO: Replace usage with FinancialOverview component.
const SubscriptionOverviews: FC<IFinancialOverviewProps> = ({
  onPress,
  usedAmount,
  currentBooking,
  remainingBalance,
  text,
  setTextDisplay,
}) => {
  const { t } = useTranslation();
  const { equityDetails } = useContext(HomeEquityContext);
  const usedAmountData = useMemo(() => Math.round(usedAmount as number).toString(), [usedAmount]);
  const remainingBalanceData = useMemo(() => Math.round(remainingBalance as number).toString(), [remainingBalance]);
  const currentBookingData = useMemo(() => Math.round(currentBooking as number).toString(), [currentBooking]);
  const userType = useMemo(() => equityDetails?.type, [equityDetails]);
  const data = [usedAmountData, remainingBalanceData, currentBookingData];

  const graphicColor = [
    AppTheme.colors.styleSheet.fall,
    AppTheme.colors.styleSheet.winter,
    AppTheme.colors.styleSheet.lightOverCast,
  ];
  useEffect(() => {
    setTextDisplay(text);
  }, [text]);

  return (
    <Box {...styles.box}>
      <H2 {...styles.h2}>
        {userType === 'immediate' ? t('FINANCIALS.SUBSCRIPTION_CREDIT_OVERVIEW') : t('SUBSCRIPTION_OVERVIEW')}
      </H2>

      {text && <Text {...styles.title}>{t('THIS_REFLECTS')}</Text>}
      <HStack {...styles.hstack}>
        <DonutChart
          amount={remainingBalance!}
          graphicData={data}
          graphicColors={graphicColor}
          label={t('MULTI_HOME.REMAINING')}
        />
        <VStack>
          <Text {...styles.text} color="styleSheet.darkFall">
            {t('USED_AMOUNT')}
          </Text>
          <Text {...styles.number}>${separator(usedAmountData)}</Text>
          <Text {...styles.text} color="styleSheet.lightOverCast">
            {t('CURRENT_BOOKING')}
          </Text>
          <Text {...styles.number}>${separator(currentBookingData)}</Text>
          <Text {...styles.text} color="styleSheet.darkWinter">
            {t('REMAINING_BALANCE')}
          </Text>
          <Text {...styles.number}>${separator(remainingBalanceData)}</Text>
        </VStack>
      </HStack>
    </Box>
  );
};

export default SubscriptionOverviews;
