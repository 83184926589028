import { FC } from 'react';
import LoginWebview from 'components/Login/LoginWebview';
import { SafeAreaView } from 'react-native';
import { PageView } from 'components/styled';
import { RootStackScreenProps } from 'types';
import { CloseIcon, Pressable } from 'native-base';

const styles = {
  icon: {
    color: 'styleSheet.black',
    alignSelf: 'center',
    size: '4',
  },

  pressable: {
    h: '40px',
    w: '50px',

    justifyContent: 'center',
  },
};

const Login: FC<RootStackScreenProps<'Login'>> = ({ navigation }) => {
  return (
    <PageView style={{ backgroundColor: '#F0EDE1' }}>
      <SafeAreaView style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
        <LoginWebview />
        <Pressable onPress={() => navigation.popToTop()} {...styles.pressable} position={'absolute'}>
          <CloseIcon {...styles.icon} />
        </Pressable>
      </SafeAreaView>
    </PageView>
  );
};

export default Login;
