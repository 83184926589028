import { Box, Button, Divider, Hidden, HStack, Text } from 'native-base';
import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { MultiSubscriptionsContext } from 'contexts/MultiSubscriptionsContext';
import { NavigationContext } from 'contexts/NavigationContext';
import separator from 'utils/separator';

const styles = {
  earnEquity: {
    fontSize: '2md',
    fontFamily: 'body',
    color: 'styleSheet.overCast',
    pt: ['32px', '32px', '32px', '24px', '24px'],
  },
  earnEquityAmount: {
    fontSize: '5.5xl',
    fontFamily: 'body',
    fontWeight: '300',
    color: 'styleSheet.shadow',
  },
  divider: {
    w: ['100%', '100%', '100%', '85%', '85%'],
    borderColor: 'styleSheet.darkStain',
    borderWidth: 1,
    mt: ['24px', '24px', '24px', '16px', '16px'],
  },
  hStack: {
    mt: '20px',
    justifyContent: 'space-between',
    space: [10, 10, 10, 5, 5],
  },
  headerText: {
    fontSize: 'sm',
    fontFamily: 'body',
    color: 'styleSheet.lightOverCast',
  },
  paymentText: {
    fontSize: '2md',
    fontFamily: 'body',
    color: 'styleSheet.lightShadow',
    mt: '2px',
    alignSelf: 'center' as const,
  },
  verticalDivider: {
    borderWidth: 1,
    borderColor: 'styleSheet.darkStain',
    height: '90%',
  },
  button: {
    variant: 'primaryMobile',
    _text: {
      fontFamily: 'body600',
      color: 'styleSheet.canvas',
      fontSize: '2md',
    },
    w: ['100%', '100%', '100%', '85%', '85%'],
    mt: ['36px', '36px', '36px', '24px', '24px'],
    mb: [null, null, null, '30px', '30px'],
  },
  box: {
    alignItems: 'center' as const,
    justifyContent: 'center' as const,
    w: ['100%', '100%', '100%', '40%', '40%'],
  },
  selectedHome: {
    fontFamily: 'body600',
    color: 'styleSheet.shadow',
    fontSize: '2md',
  },
  dividerForSelectedHome: {
    w: ['100%', '100%', '30%', null, null],
    color: 'styleSheet.darkStain',
    borderWidth: 1,
    opacity: '0.15',
    mt: '16px',
  },
  selectedHomeBox: {
    w: '100%',
    pt: ['16px', '16px', '16px', '32px', '32px'],
    alignItems: 'center' as const,
  },
};

interface IEarnedEquityDetails {
  totalEarnedEquity: string | null;
  initialPayment: string | null;
  subscriptionTotal: string | null;
  selectedHome: string | null;
}

const EarnedEquityDetails: FC<IEarnedEquityDetails> = ({
  totalEarnedEquity,
  initialPayment,
  subscriptionTotal,
  selectedHome,
}) => {
  const { t } = useTranslation();
  const { subscriptionsList } = useContext(MultiSubscriptionsContext);
  const { useNavigation } = useContext(NavigationContext);

  const handlePress = () => {
    useNavigation('HomeEquityScreen');
  };

  return (
    <Box {...styles.box}>
      {subscriptionsList?.length! > 1 ? (
        <Box {...styles.selectedHomeBox}>
          <Text {...styles.selectedHome}>{selectedHome}</Text>
          <Hidden from={'lg'}>
            <Divider {...styles.dividerForSelectedHome} />
          </Hidden>
        </Box>
      ) : null}

      <Box {...styles.selectedHomeBox}>
        <Text {...styles.earnEquity}>{t('HOME.EARNED_EQUITY')}</Text>
        <Text {...styles.earnEquityAmount}>{`$${separator(totalEarnedEquity!)}`}</Text>
        <Divider {...styles.divider} />
        <HStack {...styles.hStack}>
          <Box>
            <Text {...styles.headerText}>{t('HOME.INITIAL_PAYMENT')}</Text>
            <Text {...styles.paymentText}>{`$${separator(initialPayment!)}`}</Text>
          </Box>
          <Divider orientation="vertical" {...styles.verticalDivider} />
          <Box>
            <Text {...styles.headerText}>{t('HOME.SUBSCRIPTION_TOTAL')}</Text>
            <Text {...styles.paymentText}>{`$${separator(subscriptionTotal!)}`}</Text>
          </Box>
        </HStack>
      </Box>

      <Button {...styles.button} onPress={handlePress}>
        {t('HOME.EQUITY_DETAILS')}
      </Button>
    </Box>
  );
};

export default EarnedEquityDetails;
