import { FC, useCallback, useContext, useEffect } from 'react';
import { SignUpFormValues } from '../../../screens/Login/login.d';
import { SignUpForm } from '../../../screens/SignUp/SignUpForm';
import { useUserOnboardingMutation } from '../../../screens/SignUp/mutation/userOnboarding.generated';
import { NavigationContext } from 'contexts/NavigationContext';
import { RootStackScreenProps } from '../../../../types';
import { Box } from 'native-base';
import LandingBoard from 'components/Landing/LandingBoard';
import { ImageSourceOption, LoginBgImageContext } from 'contexts/LoginBgImageContext';

const styles = {
  box: {
    w: '100%',
    h: '100%',
  },
};

export const SignUpContainer: FC<RootStackScreenProps<'SignUp'>> = ({ route }) => {
  const [onBoarding, { loading }] = useUserOnboardingMutation();
  const { useNavigation } = useContext(NavigationContext);
  const { setBgImageSrc } = useContext(LoginBgImageContext);

  useEffect(() => {
    if (route.params?.bgImageSrc) {
      setBgImageSrc(route.params.bgImageSrc as ImageSourceOption);
    }
  }, [route.params]);

  const handleError = (e: any) => {
    if (__DEV__) console.log('on boarding error', e);
  };
  const handleComplete = useCallback(() => {
    useNavigation('Landing');
  }, []);
  const handleOnLogin = useCallback(() => {
    useNavigation('Login');
  }, []);
  const handleSignUp = useCallback(
    (values: SignUpFormValues): void => {
      onBoarding({
        variables: {
          ...values,
        },
        onCompleted: () => handleComplete(),
        onError: (error) => handleError(error),
      });
    },
    [onBoarding]
  );

  return (
    <Box {...styles.box}>
      <LandingBoard isSignUp>
        <SignUpForm handleFormSubmit={handleSignUp} loading={loading} handleOnLogin={handleOnLogin} />
      </LandingBoard>
    </Box>
  );
};
