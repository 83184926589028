import { createContext } from 'react';
import { ReadSubscriptionQuery } from 'screens/Booking/queries/readSubscription.generated';
import { noop } from 'lodash';

export type ReadSubscriptionType = ReadSubscriptionQuery['readSubscription']['data'];

export type HomeEquityContextProps = {
  equityDetails: ReadSubscriptionType;
  isLoading: boolean;
  refetch: Function;
};

export const HomeEquityContext = createContext<HomeEquityContextProps>({
  isLoading: false,
  equityDetails: {},
  refetch: noop,
});
