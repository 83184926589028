import { NavigationContext } from 'contexts/NavigationContext';
import BackArrow from 'components/ConfirmationPage/BackArrow';
import { Box, HStack, Pressable, View, Text, Hidden } from 'native-base';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import BackIcon from '../../assets/icons/Back-Arrow.svg';

const styles = {
  pressable: {
    position: 'absolute' as const,
    left: '0',
  },

  title: {
    color: 'pdp.shadow',
    fontSize: ['2md', '2md', '1xl', '1xl', '1xl'],
    fontFamily: 'body600',
  },
  hstack: {
    alignItems: 'center',
    justifyContent: 'center',
    w: '100%',
  },
  box: {
    pt: '26px',
    pb: '26px',
    pl: '10px',
  },
  hr: {
    w: '100%',
    borderWidth: '1px',
    borderColor: 'styleSheet.overCast',
    opacity: '0.15',
  },
  webBox: {
    mt: '24px',
  },
};

const ProfileHeader = () => {
  const { t } = useTranslation();
  const { useNavigation } = useContext(NavigationContext);

  return (
    <Box>
      <Hidden from={'md'}>
        <>
          <Box {...styles.box}>
            <HStack {...styles.hstack}>
              <Pressable
                {...styles.pressable}
                onPress={() => useNavigation('ProfileMgmtScreen')}
                testID="profile_navigation">
                <BackIcon />
              </Pressable>
              <Text {...styles.title}>{t('PROFILE.PERSONAL_INFO')}</Text>
            </HStack>
          </Box>
          <View {...styles.hr} />
        </>
      </Hidden>
      <Hidden till={'md'}>
        <Box {...styles.webBox}>
          <BackArrow />
        </Box>
      </Hidden>
    </Box>
  );
};

export default ProfileHeader;
