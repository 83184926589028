import { UserContext } from 'contexts/UserContext';
import { Box, Text, Divider, HStack, VStack, Pressable, View } from 'native-base';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ProfilePhoneNumberEdit from './ProfilePhoneNumberEdit';

const styles = {
  textKey: {
    textAlign: 'justify' as const,
    fontSize: ['sm', 'sm', '2md', '2md', '2md'],
    fontFamily: 'body600',
    color: 'styleSheet.shadow',
    opacity: '0.55',
  },
  textValue: {
    fontSize: '2md',
    fontFamily: 'body',
    color: 'styleSheet.shadow',
  },
  divider: {
    borderWidth: 0.5,
    borderColor: 'styleSheet.darkStain',
    mt: '16px',
  },
  hr: {
    borderWidth: '0.5px',
    borderColor: 'styleSheet.lightShadow',
    mt: '-2px',
  },
  linkButton: {
    fontFamily: 'body',
    color: 'styleSheet.shadow',
    fontSize: '2md',
  },
  hstack: {
    alignItems: 'center' as const,
    justifyContent: 'space-between' as const,
  },
  box: (v: boolean) => ({
    opacity: v ? 1 : 0.5,
    mt: '16px',
  }),
};

const ProfilePhoneNumberBox = () => {
  const { t } = useTranslation();
  const { user, profileInfo } = useContext(UserContext);
  const [editMode, setEditMode] = useState<boolean>(false);

  return (
    <Box {...styles.box(profileInfo.phone)}>
      {editMode ? (
        <ProfilePhoneNumberEdit setEditMode={setEditMode} profileInfo={profileInfo} />
      ) : (
        <HStack {...styles.hstack}>
          <VStack>
            <Text {...styles.textKey}>{t('PROFILE.PHONE_NUMBER')}</Text>
            <Text {...styles.textValue}>{user?.phoneNumber || 'N/A'}</Text>
          </VStack>
          <Pressable onPress={() => setEditMode(true)} isDisabled={!profileInfo.phone}>
            <Text {...styles.linkButton}>{t('PROFILE.EDIT')}</Text>
            <View {...styles.hr} />
          </Pressable>
        </HStack>
      )}
      <Divider {...styles.divider} />
    </Box>
  );
};

export default ProfilePhoneNumberBox;
