import * as Types from '../../../generated/types.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetUserQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetUserQuery = { __typename?: 'Query', getUser: { __typename?: 'UserOutputFromAuth0AndDatabaseResponse', status: { __typename?: 'CoreStatus', code: number, message: string }, data?: { __typename?: 'UserOutputFromAuth0AndDatabase', id: string, email?: string | null, family_name?: string | null, given_name?: string | null, gender?: string | null, auth0Id?: string | null, isFirstLogin: boolean, consentDate?: string | null, phoneNumber?: string | null, leadSource?: string | null, billingAddress?: string | null, primaryMemberBirthdate?: string | null, interestedAreas?: Array<string> | null, street?: string | null, city?: string | null, state?: string | null, zipcode?: string | null, country?: string | null, interestedHomeSize?: number | null, interestedHomeBedroom?: number | null, interestedHomeBathroom?: number | null, interestedHomeFeatures?: Array<string> | null, paymentInformation?: Array<{ __typename?: 'PaymentInformation', cardNumber?: string | null, cardHolder?: string | null, expirationDate?: string | null, cvv?: string | null }> | null } | null } };


export const GetUserDocument = gql`
    query getUser {
  getUser {
    status {
      code
      message
    }
    data {
      id
      email
      family_name
      given_name
      gender
      auth0Id
      isFirstLogin
      consentDate
      phoneNumber
      leadSource
      billingAddress
      primaryMemberBirthdate
      interestedAreas
      street
      city
      state
      zipcode
      country
      interestedHomeSize
      interestedHomeBedroom
      interestedHomeBathroom
      interestedHomeFeatures
      paymentInformation {
        cardNumber
        cardHolder
        expirationDate
        cvv
      }
    }
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    