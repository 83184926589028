import { Box, ScrollView, Text } from 'native-base';
import { StayBookingBox } from 'components/Stays/UpcomingStayBookingBox';
import { Loading } from 'components/Loading';
import { RefreshControl } from 'react-native';
import { useCallback, useContext, useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Layout from 'constants/Layout';
import CTAButton from 'components/libs/button/CTAButton';
import { NavigationContext } from 'contexts/NavigationContext';
import { StaysContext } from 'contexts/StaysContext';

const styles = {
  noResultBox: {
    alignItems: 'center' as const,
    justifyContent: ['center' as const, 'center' as const, 'center' as const, null, null],
    height: Layout.window.height * 0.65,
    w: '100%',
  },
  noStaysText: {
    color: 'pdp.shadow',
    fontSize: '1xl',
    fontFamily: 'body600',
  },
  bookStayButton: {
    variant: 'primaryMobile',
    borderRadius: '8px',
    w: ['92%', '92%', '22%', '22%', '22%'],
    mt: '32px',
    pt: '16px',
    pb: '16px',
    _text: {
      color: 'styleSheet.canvas',
      fontSize: '2md',
      fontFamily: 'body600',
    },
    _pressed: {
      bg: 'styleSheet.shadow',
      opacity: '0.6',
      _text: { color: 'styleSheet.canvas' },
    },
    _hover: {
      bg: 'styleSheet.darkStain',
      _text: { color: 'styleSheet.shadow' },
    },
    _focus: {
      bg: 'styleSheet.shadow',
    },
  },
  box: {
    mt: ['40px', '40px', '40px', '0px', '0px'],
    mb: '40px',
  },
};

const UpcomingStays = () => {
  const { refetchData, reservations: listing, isLoading } = useContext(StaysContext);
  const { t } = useTranslation();
  const { useNavigation } = useContext(NavigationContext);

  const upComingStays = listing?.data?.filter(
    (item) => item.status == 'confirmed' && moment(item.checkInDateLocalized).isAfter(moment())
  );

  useEffect(() => {
    refetchData();
  }, []);

  const onRefresh = useCallback(() => {
    refetchData();
  }, []);

  const handleBrowseAllHome = () => {
    useNavigation('Booking', {
      screen: 'ListingNavigator',
      params: { screen: 'ListingResults' },
    });
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <ScrollView refreshControl={<RefreshControl refreshing={isLoading} onRefresh={onRefresh} />}>
      {!upComingStays?.length && (
        <Box {...styles.noResultBox}>
          <Text {...styles.noStaysText}>{t('NO_UPCOMING_STAYS')}</Text>
          <CTAButton children={t('BOOK_STAYS_TODAY')} onPress={handleBrowseAllHome} {...styles.bookStayButton} />
        </Box>
      )}
      {
        <Box {...styles.box}>
          {upComingStays?.map((list: any) => {
            if (!list.listing.picture) return null;
            return (
              <Box key={list._id}>
                <StayBookingBox
                  _id={list._id}
                  image={list.listing.picture.thumbnail.replace(/h_(\d+)/, 'h_700')}
                  title={list.listing.title}
                  address={list.listing.address.full}
                  checkIn={list.checkInDateLocalized}
                  checkOut={list.checkOutDateLocalized}
                />
              </Box>
            );
          })}
        </Box>
      }
    </ScrollView>
  );
};
export default UpcomingStays;
