import { FC, useContext, useEffect, useState, useMemo, useRef } from 'react';
import {
  Box,
  Button,
  CloseIcon,
  Divider,
  Hidden,
  HStack,
  NativeBaseProvider,
  Pressable,
  ScrollView,
  Text,
  useBreakpointValue,
  VStack,
  WarningOutlineIcon,
} from 'native-base';
import { RootStackParamList, RootStackScreenProps } from '../../../types';
import { PropertyDetailContext } from 'contexts/PropertyDetailContext';
import PhotoSlider from 'components/PropertyDetail/ImageSlider/PhotoSlider';
import PropertyOverview from '../../components/PropertyDetail/PropertyOverview';
import PropertyAmenities from 'components/PropertyDetail/PropertyAmenities';
import ThingsToKnow from 'components/PropertyDetail/ThingsToKnow';
import Calendar from 'components/Calendars/Calendar';
import Location from 'components/PropertyDetail/Location';
import CTABooking from 'components/PropertyDetail/CTABooking';
import { Loading } from 'components/Loading';
import { ListingsContext } from 'contexts/ListingsContext';
import { CalendarProvider } from 'providers/CalendarProvider';
import { useTranslation } from 'react-i18next';
import { StyleProp, ViewStyle } from 'react-native';
import PdpGuestModal from '../../components/PropertyDetail/modals/PdpGuestModal';
import moment from 'moment';
import Modal from 'react-native-modal';
import UpdateGuestBox from 'components/Guests/UpdateGuestBox';
import { AppTheme } from 'constants/Theme';
import { ReservationContext } from 'contexts/reservationContext';
import { RouteProp } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import AboutHomeModal from '../../components/PropertyDetail/modals/AboutHomeModal';
import AmenitiesModal from '../../components/PropertyDetail/modals/AmenitiesModal';
import AboutAreaModal from '../../components/PropertyDetail/modals/AboutAreaModal';
import BookCalendar from 'components/Calendars/BookCalendar';
import Layout from 'constants/Layout';

const windowWidth = Layout.window.width;
const windowHeight = Layout.window.height;

const styles = {
  mainBox: {
    bg: 'bgPage',
    h: '100%',
  },
  msg: {
    w: '100%',
    h: '85px',
    position: 'absolute' as const,
    bottom: '50px',
    bg: 'styleSheet.canvas',
  },
  divider: {
    borderWidth: '0.5px',
    borderColor: 'styleSheet.overCast',
    opacity: '0.20',
    w: '100%',
  },
  textMsg: {
    pl: '16px',
    pr: '16px',
    fontFamily: 'body',
    fontSize: 'md',
    color: 'styleSheet.shadow',
  },
  iconMsg: {
    color: 'pdp.shadow',
    size: '4',
  },
  pressableClose: {
    position: 'absolute' as const,
    right: '15px',
    alignSelf: 'center' as const,
  },
  msgBox: {
    mt: '10px',
  },

  viewForDesktop: {
    flexDirection: ['column' as const, 'column' as const, 'column' as const, 'row' as const, 'row' as const],
    w: ['100%', '100%', '100%', windowWidth - windowWidth * 0.15, windowWidth - windowWidth * 0.25],
    alignSelf: 'center' as const,
  },
  detailView: {
    w: ['100%', '100%', '100%', '60%', '60%'],
    pr: ['0px', '0px', '0px', '80px', '80px'],
  },
  pdpGuestModal: {
    w: ['100%', '100%', '100%', '40%', '40%'],
  },
  overLay: {
    position: 'absolute' as const,
    w: windowWidth,
    h: windowHeight,
    top: '10%',
    borderRadius: 20,
    borderWidth: '1px',
    borderColor: 'styleSheet.canvas',
    zIndex: 100,
  },
  dividerDark: {
    borderWidth: '1',
    borderColor: 'pdp.shadow',
    opacity: '0.15',
  },
  innerBox: {
    ml: '16px',
    mr: '16px',
  },
  calendarBox: {
    w: [
      '100%',
      '100%',
      windowWidth - windowWidth * 0.15,
      windowWidth - windowWidth * 0.15,
      windowWidth - windowWidth * 0.25,
    ],
    alignSelf: 'center' as const,
  },
  calendarView: {
    bg: 'styleSheet.canvas',
    w: ['100%', '100%', '100%', '80%', '70%'],
    h: [null, null, '60%', windowHeight - 100, windowHeight - 100],
    borderRadius: [0, 0, 20, 20, 20],
    borderWidth: ['0px', '0px', '1px', '1px', '1px'],
    borderColor: 'styleSheet.canvas',
    alignSelf: 'center' as const,
  },
  title: {
    color: 'pdp.shadow',
    fontSize: '2md',
    fontFamily: 'body600',
    ml: '40px',
    mt: '24px',
    mb: '24px',
  },
  pressable: {
    h: '40px',
    w: '40px',
    position: 'absolute' as const,
    right: 2,
    alignSelf: 'center' as const,
    justifyContent: 'center' as const,
  },
  icon: {
    color: 'pdp.shadow',
    size: '4',
    alignSelf: 'center' as const,
  },

  hstackCal: {
    pl: '40px',
    alignItems: 'center' as const,
  },
  calendarBoxBottom: {
    position: 'absolute' as const,
    bottom: 0,
    w: '100%',
  },
  calBottom: {
    h: '100px',
  },
  calendarBoxTablet: {
    mr: 30,
    ml: 30,
    mt: 10,
  },
  buttonBox: {
    w: '30%',
    h: '70px',
    alignSelf: 'center' as const,
    borderRadius: '8px',
    bg: 'styleSheet.shadow',
    position: 'absolute' as const,
    right: '40px',
    _text: {
      color: 'styleSheet.canvas',
      fontSize: 'sm',
      fontFamily: 'body',
    },
    _pressed: {
      bg: 'styleSheet.shadow',
      opacity: '0.6',
      _text: { color: 'styleSheet.canvas' },
    },
    _hover: {
      bg: 'styleSheet.darkStain',
      _text: { color: 'styleSheet.shadow' },
    },
    _focus: {
      bg: 'styleSheet.shadow',
    },
  },
  buttonBoxDisabled: {
    position: 'absolute' as const,
    right: '40px',
    alignSelf: 'center' as const,
    opacity: '0.6',
    w: '30%',
    h: '70px',
    borderRadius: '8px',
    bg: 'styleSheet.shadow',
    _text: {
      color: 'styleSheet.canvas',
      fontSize: 'sm',
      fontFamily: 'body',
    },
    _pressed: {
      bg: 'styleSheet.shadow',
      opacity: '0.6',
      _text: { color: 'styleSheet.canvas' },
    },
    _hover: {
      bg: 'styleSheet.darkStain',
      _text: { color: 'styleSheet.shadow' },
    },
    _focus: {
      bg: 'styleSheet.shadow',
    },
  },
};
const customModalPropertyStyle = {
  margin: 0,
};

interface IUpdateBoxModal extends RootStackScreenProps<'BookingCalendar'> {}

const PropertyDetail: FC<RootStackScreenProps<'PropertyDetail'>> = (
  { navigation, route },
  Iupdate: IUpdateBoxModal
) => {
  const { checkIn, checkOut, setCheckIn, setCheckOut } = useContext(ListingsContext);
  const { listing, setMinNightsRequired, validateNightSelection, loading, setListingId } =
    useContext(PropertyDetailContext);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [displayMsgBox, setDisplayMsgBox] = useState<boolean>(false);
  const [msg, setMsg] = useState<string>('');
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showFullScreenModal, setShowFullScreenModal] = useState<boolean>(false);
  const [openPropComponent, setOpenPropComponent] = useState<string>('');
  const calendarRef = useRef<any>();
  const { setGuests } = useContext(ReservationContext);
  let breakpointTypeValue = useBreakpointValue({ base: 1, sm: 2, md: 3, lg: 4, xl: 5 });
  const [calendarModal, setCalendarModal] = useState<boolean>(false);
  const [widthDims, setWidthDims] = useState(0);

  const btnBoxStyle = disabled ? styles.buttonBoxDisabled : styles.buttonBox;
  const {
    checkIn: checkInParams,
    checkOut: checkOutParams,
    listingId: listingIdParams,
    adults: adultsParams,
    children: childrenParams,
    infants: infantsParams,
    pets: petsParams,
  } = route.params;

  useEffect(() => {
    if (!!checkInParams && !!checkOutParams) {
      setCheckIn(checkInParams);
      setCheckOut(checkOutParams);
    }
  }, [checkInParams, checkOutParams]);
  useEffect(() => {
    if (!!listingIdParams) {
      setListingId(listingIdParams);
    } else if (!!window) {
      window.location.href = '/';
    }
  }, [listingIdParams]);

  useEffect(() => {
    if (adultsParams || childrenParams || petsParams || infantsParams) {
      setGuests({
        adults: adultsParams,
        children: childrenParams,
        infants: infantsParams,
        pets: petsParams,
      });
    }
  }, [adultsParams, childrenParams, petsParams, infantsParams]);

  const nightSelection = useMemo(() => moment(checkOut).diff(moment(checkIn), 'days'), [checkOut, checkIn]);

  useEffect(() => {
    const { isValid, errorMessage } = validateNightSelection(nightSelection);
    if (isValid) {
      setMsg('');
      setDisabled(false);
      setDisplayMsgBox(false);
    } else {
      setMsg(errorMessage);
      setDisabled(true);
      setDisplayMsgBox(true);
    }
  }, [nightSelection, validateNightSelection]);

  const onSeeAvailableDates = () => {
    if (breakpointTypeValue == 3) {
      setCalendarModal(true);
    } else {
      setCalendarModal(false);
      navigation.navigate('CalendarModal', {
        listingId: listingIdParams,
      });
    }
  };

  const onBooking = () => {
    if (breakpointTypeValue == 3) {
      setShowFullScreenModal(true);
      setOpenPropComponent('PdpGuestModal');
    } else {
      navigation.navigate('BookingSubNavigator', {
        screen: 'PdpGuestModal',
        params: {
          listingId: listingIdParams,
          checkInDate: checkIn!,
          checkOutDate: checkOut!,
        },
      });
    }
  };

  const onShowCalendar = () => {
    calendarRef?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handlePropertyOverview = () => {
    if (breakpointTypeValue == 3 || breakpointTypeValue == 4 || breakpointTypeValue == 5) {
      setShowFullScreenModal(true);
      setOpenPropComponent('PropertyOverview');
    } else {
      navigation.navigate('AboutHomeModal');
    }
  };

  const handlePropertyAmenities = () => {
    if (breakpointTypeValue == 3 || breakpointTypeValue == 4 || breakpointTypeValue == 5) {
      setShowFullScreenModal(true);
      setOpenPropComponent('PropertyAmenities');
    } else {
      navigation.navigate('AmenitiesModal', { listing: listing! });
    }
  };

  const handleAboutArea = () => {
    if (breakpointTypeValue == 3 || breakpointTypeValue == 4 || breakpointTypeValue == 5) {
      setShowFullScreenModal(true);
      setOpenPropComponent('AboutAreaModal');
    } else {
      navigation.navigate('AboutAreaModal');
    }
  };

  const handleCloseModal = () => {
    setCalendarModal(false);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Box safeArea {...styles.mainBox}>
      <Hidden till={'lg'}>
        <Box display={showModal ? 'flex' : 'none'} {...styles.overLay}>
          <NativeBaseProvider theme={AppTheme}>
            <Modal isVisible={showModal}>
              <UpdateGuestBox navigation={Iupdate.navigation} route={Iupdate.route} setShowModal={setShowModal} />
            </Modal>
          </NativeBaseProvider>
        </Box>
      </Hidden>
      <Hidden till={'md'}>
        <Box display={showFullScreenModal ? 'flex' : 'none'}>
          <NativeBaseProvider theme={AppTheme}>
            <Modal
              presentationStyle="pageSheet"
              isVisible={showFullScreenModal}
              style={customModalPropertyStyle as StyleProp<ViewStyle>}>
              {openPropComponent === 'PropertyOverview' ? (
                <AboutHomeModal
                  setShowModal={setShowFullScreenModal}
                  navigation={navigation as unknown as NativeStackNavigationProp<RootStackParamList, 'AboutHomeModal'>}
                  route={route as unknown as RouteProp<RootStackParamList, 'AboutHomeModal'>}
                />
              ) : openPropComponent === 'PropertyAmenities' ? (
                <AmenitiesModal
                  setShowModal={setShowFullScreenModal}
                  navigation={navigation as unknown as NativeStackNavigationProp<RootStackParamList, 'AmenitiesModal'>}
                  route={route as unknown as RouteProp<RootStackParamList, 'AmenitiesModal'>}
                />
              ) : openPropComponent === 'AboutAreaModal' ? (
                <AboutAreaModal
                  setShowModal={setShowFullScreenModal}
                  navigation={navigation as unknown as NativeStackNavigationProp<RootStackParamList, 'AboutHomeModal'>}
                  route={route as unknown as RouteProp<RootStackParamList, 'AboutHomeModal'>}
                />
              ) : openPropComponent === 'PdpGuestModal' ? (
                <PdpGuestModal
                  navigation={
                    navigation as unknown as NativeStackNavigationProp<RootStackParamList, 'PdpGuestModal', undefined>
                  }
                  route={route as unknown as RouteProp<RootStackParamList, 'PdpGuestModal'>}
                  listingID={listingIdParams}
                  checkInDatePdp={checkIn}
                  checkOutDatePdp={checkOut}
                  setShowModal={setShowFullScreenModal}
                  onShowCalendar={onShowCalendar}></PdpGuestModal>
              ) : null}
            </Modal>
          </NativeBaseProvider>
        </Box>
      </Hidden>

      <Hidden only={['base', 'sm', 'lg', 'xl']}>
        <Box display={calendarModal ? 'flex' : 'none'} {...styles.overLay}>
          <NativeBaseProvider theme={AppTheme}>
            <Modal isVisible={calendarModal}>
              <VStack {...styles.calendarView}>
                <HStack>
                  <Text {...styles.title}>{t('CHANGE_DATES')}</Text>
                  <Pressable onPress={handleCloseModal} {...styles.pressable}>
                    <CloseIcon {...styles.icon} />
                  </Pressable>
                </HStack>

                <Divider {...styles.divider} />
                <Box
                  {...styles.calendarBoxTablet}
                  onLayout={(event) => {
                    const { width } = event.nativeEvent.layout;
                    setWidthDims(width);
                  }}>
                  <CalendarProvider listingId={listingIdParams}>
                    <BookCalendar
                      isLargeScreen={true}
                      isFromBooking={true}
                      weekVerticalMargin={6}
                      setCalendarWidth={widthDims / 2.12}
                      selectedDates={[checkIn, checkOut].filter((d) => !!d) as string[]}
                      pastScrollRange={0}
                      futureScrollRange={12}
                      setMinNight={setMinNightsRequired}
                      onSelectedDatesChanged={(checkInDate1, checkOutDate1) => {
                        if (checkInDate1 && checkOutDate1) {
                          setCheckIn(moment(checkInDate1).format('YYYY-MM-DD'));
                          setCheckOut(moment(checkOutDate1).format('YYYY-MM-DD'));
                        }
                      }}
                    />
                  </CalendarProvider>
                </Box>

                <Box {...styles.calendarBoxBottom}>
                  <Divider {...styles.divider} />
                  <HStack {...styles.calBottom}>
                    {disabled && displayMsgBox ? (
                      <HStack {...styles.hstackCal}>
                        <WarningOutlineIcon {...styles.icon} />
                        <Text {...styles.textMsg}>{msg}</Text>
                      </HStack>
                    ) : (
                      <HStack {...styles.hstackCal}>
                        {checkIn && checkOut ? (
                          <Text {...styles.textMsg}>
                            {moment(checkIn).format('dddd, MMMM DD')} - {moment(checkOut).format('dddd, MMMM DD')}
                          </Text>
                        ) : null}
                      </HStack>
                    )}

                    <Button
                      {...btnBoxStyle}
                      disabled={disabled}
                      children={t('CONFIRMATION.CHANGE_DATES')}
                      onPress={() => {
                        setCalendarModal(false);
                      }}
                    />
                  </HStack>
                </Box>
              </VStack>
            </Modal>
          </NativeBaseProvider>
        </Box>
      </Hidden>

      <ScrollView>
        <PhotoSlider
          onIconPress={() => navigation.goBack()}
          onPhotoPress={() => navigation.navigate('PhotoSliderModal')}
        />

        <Box {...styles.innerBox}>
          <Box>
            <Box {...styles.viewForDesktop}>
              <Box {...styles.detailView}>
                <PropertyOverview onPropertyOverview={handlePropertyOverview} />
                <PropertyAmenities onAmenitiesPress={handlePropertyAmenities} />
                <ThingsToKnow onCancellationPress={() => navigation.navigate('PdpCancellationModal')} />
              </Box>

              <Hidden till={'lg'}>
                <Box {...styles.pdpGuestModal}>
                  <PdpGuestModal
                    navigation={
                      navigation as unknown as NativeStackNavigationProp<RootStackParamList, 'PdpGuestModal', undefined>
                    }
                    route={route as unknown as RouteProp<RootStackParamList, 'PdpGuestModal'>}
                    listingID={listingIdParams}
                    checkInDatePdp={checkIn}
                    checkOutDatePdp={checkOut}
                    setShowModal={setShowModal}
                    onShowCalendar={onShowCalendar}></PdpGuestModal>
                </Box>
              </Hidden>
            </Box>

            <Box ref={calendarRef} {...styles.calendarBox}>
              <Divider {...styles.dividerDark} />
              <CalendarProvider listingId={listingIdParams}>
                <Calendar
                  checkIn={checkIn}
                  checkOut={checkOut}
                  setMinNight={setMinNightsRequired}
                  setSelectedDates={(checkInDate1, checkOutDate1) => {
                    setCheckIn(checkInDate1!);
                    setCheckOut(checkOutDate1!);
                  }}
                />
              </CalendarProvider>
            </Box>

            <Box {...styles.viewForDesktop}>
              <Box {...styles.detailView}>
                <Location onAboutPress={handleAboutArea} />
                {/* <MapView /> */}
              </Box>
            </Box>
          </Box>
        </Box>
      </ScrollView>

      {disabled && displayMsgBox ? (
        <Hidden from={'lg'}>
          <Box {...styles.msg}>
            <Divider {...styles.divider} />
            <HStack {...styles.msgBox}>
              <Text {...styles.textMsg}>{msg}</Text>
              <Pressable onPress={() => setDisplayMsgBox(false)} {...styles.pressableClose}>
                <CloseIcon {...styles.iconMsg} />
              </Pressable>
            </HStack>
          </Box>
        </Hidden>
      ) : null}

      <Hidden from={'lg'}>
        <CTABooking
          disabled={disabled}
          checkInDate={checkIn}
          checkOutDate={checkOut}
          onSeeAvailableDates={onSeeAvailableDates}
          onBooking={onBooking}
          listingId={listingIdParams}
        />
      </Hidden>
    </Box>
  );
};

export default PropertyDetail;
