import FinancialOverviews from 'components/SubscriptionDetails/FinancialOverview';
import { Text, VStack, useBreakpointValue } from 'native-base';
import { useTranslation } from 'react-i18next';
import { FC, useMemo } from 'react';
import Card from 'components/Card';
import separator from 'utils/separator';
import { AppTheme } from 'constants/Theme';

const styles = {
  headerText: {
    alignSelf: [
      'flex-start' as const,
      'flex-start' as const,
      'center' as const,
      'center' as const,
      'center' as const
    ],
    color: 'styleSheet.shadow',
    fontFamily: 'body600',
    fontSize: [
      '2md',
      '2md',
      '1xl',
      '1xl',
      '1xl',
    ],
  },
  detailBox: (useCondensedChart: boolean) => ({
    alignItems: 'center' as const,
    alignSelf: 'center' as const,
    maxW: '608px',
    px: useCondensedChart ? '24px' : '40px',
    py: useCondensedChart ? '32px' : '40px',
  }),
  box: {
    space: ['18px', '18px', '24px', '24px', '24px'],
  },
};

interface IAnnualSubscriptionProps {
  usedSubscriptionAmount: number;
  remainingSubscriptionAmount: number;
  totalSubscriptionAmount: number;
}

const AnnualSubscriptionDetails: FC<IAnnualSubscriptionProps> = ({
  usedSubscriptionAmount,
  remainingSubscriptionAmount,
  totalSubscriptionAmount,
}) => {
  const { t } = useTranslation();

  const useCondensedChart = useBreakpointValue({
    base: true,
    md: false,
  });

  const remainingData = useMemo(
    () => Math.round(remainingSubscriptionAmount as number).toString(),
    [remainingSubscriptionAmount]
  );
  const startingBalanceData = useMemo(
    () => Math.round(totalSubscriptionAmount as number).toString(),
    [totalSubscriptionAmount]
  );
  const usedData = useMemo(
    () => Math.round(usedSubscriptionAmount as number).toString(),
    [usedSubscriptionAmount]
  );

  const chartLabel = t('MULTI_HOME.REMAINING');
  const chartAmount = remainingSubscriptionAmount;
  const graphicColors = [AppTheme.colors.donutChart2, AppTheme.colors.donutChart3];
  const graphicData = [usedData, remainingData];
  const secondaryValues = [
    {
      label: t('FINANCIALS.STARTING_BALANCE'),
      value: `$${separator(startingBalanceData)}`
    },
    {
      label: t('FINANCIALS.USED_AMOUNT'),
      value: `$${separator(usedData)}`
    },
    {
      label: t('FINANCIALS.REMAINING_BALANCE'),
      value: `$${separator(remainingData)}`
    },
  ];

  return (
    <VStack {...styles.box}>
      <Text {...styles.headerText}>
        {t('HOME.ANNUAL_SUBSCRIPTION_USAGE')}
      </Text>
      <Card {...styles.detailBox(useCondensedChart)}>
        <FinancialOverviews
          condensed={useCondensedChart}
          graphicColors={graphicColors}
          graphicData={graphicData}
          mainAmount={chartAmount}
          mainLabel={chartLabel}
          secondaryValues={secondaryValues}
        />
      </Card>
    </VStack>
  );
};

export default AnnualSubscriptionDetails;
