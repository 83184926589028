import { createContext } from 'react';
import { noop } from 'lodash';

export type AuthInitialSate = {
  isReady: boolean;
  isUserAuthenticated: boolean;
  auth0Id: string | null;
  processAuth0Code: (code: string) => void;
  getAuth0Token: () => Promise<string | null>;
  clearAuth0Token: () => void;
  storedSubscriptionId: string | null;
};

//context
export const AuthContext = createContext<AuthInitialSate>({
  isReady: false,
  isUserAuthenticated: false,
  auth0Id: null,
  processAuth0Code: noop,
  clearAuth0Token: noop,
  getAuth0Token: noop as any,
  storedSubscriptionId: '',
});
