import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { useTranslation } from 'react-i18next';
import { getOptions } from '.';
import Login from 'screens/Login/Login';
import Auth from 'screens/Login/Auth';
import { RootStackParamList } from 'types';
import LogoutWebview from 'components/Login/LogoutWebview';
import { SignUpContainer } from 'components/SignUp/container/SignUpContainer';
import { LoginBgImageProvider } from 'providers/LoginBgImageProvider';
import { LandingScreen } from '../screens/Landing/LandingScreen';

const Stack = createNativeStackNavigator<RootStackParamList>();

export function LoginNavigator() {
  const { t } = useTranslation();
  return (
    <LoginBgImageProvider>
      <Stack.Navigator initialRouteName={'Landing'}>
        <Stack.Screen name="Auth" component={Auth} options={getOptions(t('GO_SUMMER') + t('AUTH'))} />
        <Stack.Screen name="Login" component={Login} options={getOptions(t('GO_SUMMER') + t('LOGIN'))} />
        <Stack.Screen
          name="Logout"
          component={LogoutWebview}
          options={getOptions(t('GO_SUMMER') + t('PROFILE.LOGOUT'))}
        />
        <Stack.Screen name="SignUp" component={SignUpContainer} options={getOptions(t('GO_SUMMER') + t('SIGN_UP'))} />
        <Stack.Screen name="Landing" component={LandingScreen} options={getOptions(t('GO_SUMMER') + t('LANDING'))} />
      </Stack.Navigator>
    </LoginBgImageProvider>
  );
}
