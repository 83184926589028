import { RootTabScreenProps } from '../../../types';
import { Box, Image, Hidden, ScrollView } from 'native-base';
import { FC } from 'react';
import InitialAvtar from 'components/Profile/InitialAvtar';
import UserDetail from 'components/Profile/UserDetail';
import ProfileInfo from 'components/Profile/ProfileInfo';
import BtnLogout from 'components/Profile/BtnLogout';
import VersionDetails from 'components/Profile/VersionDetails';
const styles = {
  mainView: {
    bg: 'styleSheet.canvas',
    h: '100%',
    w: '100%',
  },
  detailView: {
    pl: ['16px', '16px', '30%', '38%', '38%'],
    pr: ['16px', '16px', '30%', '38%', '38%'],
    w: '100%',
    borderTopRadius: '24px',
    borderWidth: 1,
    borderColor: 'styleSheet.canvas',
    position: 'relative' as const,
    bg: 'styleSheet.canvas',
    mt: [null, null, '10%', '10%', '10%'],
    pb: '40px',
    bottom: 10,
  },
  image: {
    h: ['200px', '200px', '352px', null, null],
  },
};

const ProfileMgmtScreen: FC<RootTabScreenProps<'Profile'>> = () => {
  return (
    <Box safeAreaTop {...styles.mainView}>
      <ScrollView>
        <Hidden from={'lg'}>
          <Image
            source={require('../../assets/images/Summer-Hero-Finals.jpg')}
            alt="summer-hero"
            {...styles.image}
            testID="summer-hero"
          />
        </Hidden>
        <Box {...styles.detailView}>
          <InitialAvtar />
          <UserDetail />
          <ProfileInfo />
          <BtnLogout />
          <VersionDetails />
        </Box>
      </ScrollView>
    </Box>
  );
};
export default ProfileMgmtScreen;
