import { FC, useContext, useMemo } from 'react';
import { View, Text, Image, VStack, Pressable, HStack, Stack } from 'native-base';
import { GuestyListing } from 'src/generated/types';
import { useTranslation } from 'react-i18next';
import { NavigationContext } from 'contexts/NavigationContext';
import Layout from 'constants/Layout';
import AspectRatio from 'components/AspectRatio';
import { ListingsContext } from 'contexts/ListingsContext';

const windowWidth = Layout.window.width;

const styles = {
  topContainer: (containerWidth: number) => ({
    overflow: 'hidden',
    backgroundColor: 'styleSheet.lightStain',
    borderColor: 'tint',
    borderWidth: '1px',
    borderTopRightRadius: '2xl',
    borderTopLeftRadius: '2xl',
    borderBottomLeftRadius: '2xl',
    borderBottomRightRadius: '2xl',
    w: [windowWidth - 30, windowWidth - 30, containerWidth / 2.1, containerWidth / 2.2, containerWidth / 2.2],
    ml: ['0px', '0px', '8px', '16px', '16px'],
    mt: '20px',
    alignSelf: 'center' as const,
    padding: 0,
  }),
  mainView: {
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  nickname: {
    fontFamily: 'body600',
    fontSize: '1xl',
    color: 'styleSheet.shadow',
    mt: '16px',
    lineHeight: '30px',
  },
  detailsText: {
    fontFamily: 'body600',
    color: 'styleSheet.lightOverCast',
    fontSize: 'sm',
  },
  detailsTextOther: {
    fontFamily: 'body600',
    color: 'styleSheet.lightOverCast',
    fontSize: 'sm',
    ml: '5px',
  },
  rateAndLocation: (containerWidth: number) => ({
    bottom: [null, null, '30px', '30px', '30px'],
    w: [null, null, containerWidth / 2.1 - 40, containerWidth / 2.2 - 40, containerWidth / 2.2 - 40],
    flexDirection: 'row' as const,
    mb: ['40px', '40px', null, null, null],
    mr: ['16px', '16px', null, null, null],
    pt: [null, null, null, '16px', '16px'],
  }),
  averageContainer: {
    position: 'absolute' as const,
    right: 0,
    top: '16px',
  },
  noAverageContainer: {
    w: '180px',
    h: '18px',
  },
  averageTitle: {
    fontFamily: 'body600',
    fontSize: 'sm',
    color: 'styleSheet.lightOverCast',
    textAlign: 'right' as const,
  },
  averageAmount: {
    fontFamily: 'body',
    fontSize: '2md',
    color: 'styleSheet.shadow',
  },
  locationContainer: {
    pl: '12px',
    pr: '12px',
    pt: '8px',
    pb: '8px',
    top: '8px',
    borderRadius: '8px',
    backgroundColor: 'styleSheet.canvas',
    mt: '16px',
  },
  location: {
    fontFamily: 'body600',
    fontSize: 'xs',
    textAlign: 'center' as const,
    color: 'styleSheet.overCast',
  },
  imageRatio: {
    w: '100%',
    ratio: [16 / 10, 16 / 10, 16 / 10, 18 / 10, 18 / 10],
  },
  bullet: {
    fontSize: '3px',
    color: 'styleSheet.lightOverCast',
    ml: '5px',
  },
  view: {
    mt: '2px',
    alignItems: 'center' as const,
  },
};

interface CardProps {
  listing: GuestyListing;
  containerWidth?: number;
}

export const ListingCard: FC<CardProps> = ({ listing, containerWidth }) => {
  const { t } = useTranslation();
  const { useNavigation } = useContext(NavigationContext);

  const { filterGuests, checkIn, checkOut } = useContext(ListingsContext);
  const accomodates = useMemo(() => listing.accommodates, [listing]);
  const beds = useMemo(() => listing.beds, [listing]);
  const bathrooms = useMemo(() => listing.bathrooms, [listing]);
  const average = useMemo(() => listing.averagePricePerNight, [listing]);
  const totalPrice = useMemo(() => listing.totalPrice, [listing]);
  const averageContainerStyles = average ? styles.averageContainer : styles.noAverageContainer;
  const summerHomeName = useMemo(
    () => listing.customFields?.filter((l) => l.key === 'DND_Summer App Nickname'),
    [listing]
  );

  const handleSelection = () => {
    useNavigation('PropertyDetailsNavigator', {
      screen: 'PropertyDetail',
      params: {
        listingId: listing._id,
        adults: filterGuests.adults,
        children: filterGuests.children,
        pets: filterGuests.pets,
        infants: filterGuests.infants,
        checkIn: checkIn,
        checkOut: checkOut,
      },
    });
  };

  const thumbnailUrl = useMemo(() => listing?.picture?.thumbnail?.replace(/h_(\d+)/, 'h_700'), [listing]);
  return (
    <Pressable {...styles.topContainer(containerWidth!)} onPress={() => handleSelection()} testID="cardPressed">
      <Stack>
        <AspectRatio {...styles.imageRatio}>
          {listing?.picture?.thumbnail && (
            <Image source={{ uri: thumbnailUrl }} resizeMode="cover" alt={listing?.picture.thumbnail} />
          )}
        </AspectRatio>
        <View {...styles.mainView}>
          {summerHomeName?.map((listing) => (
            <View key={listing._id}>
              <Text {...styles.nickname}>{listing?.value}</Text>
            </View>
          ))}
          <HStack {...styles.view}>
            <Text {...styles.detailsText}>{t('PROPERTY_DETAILS_GUEST', { accomodates })}</Text>

            <Text {...styles.bullet}>{'\u2B24'}</Text>
            <Text {...styles.detailsTextOther}>{t('PROPERTY_DETAILS_BEDS', { beds })}</Text>
            <Text {...styles.bullet}>{'\u2B24'}</Text>
            <Text {...styles.detailsTextOther}>{t('PROPERTY_DETAILS_BATHS', { bathrooms })}</Text>
          </HStack>

          <VStack {...styles.rateAndLocation(containerWidth!)}>
            <View {...styles.locationContainer}>
              <Text {...styles.location}>
                {listing?.address?.city}, {listing?.address?.state}
              </Text>
            </View>

            <View {...averageContainerStyles}>
              {average ? (
                <View>
                  <Text {...styles.averageTitle}>{t('TOTAL_PRICE', { totalPrice })}</Text>
                  <Text {...styles.averageAmount}>{t('PRICE_PER_NIGHT', { average })}</Text>
                </View>
              ) : null}
            </View>
          </VStack>
        </View>
      </Stack>
    </Pressable>
  );
};
