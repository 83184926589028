import { useState } from 'react';
import { ReservationContext } from 'contexts/reservationContext';

export interface IGuests {
  adults: number;
  children: number;
  infants: number;
  pets: number;
}

interface IItems {
  name: string;
  id: string;
  city: string;
}

export const ReservationProvider = ({ children }: any) => {
  const [guests, setGuests] = useState<IGuests>({ adults: 1, children: 0, infants: 0, pets: 0 });
  const [selectedItem, setSelectedItem] = useState<IItems[]>([]);
  const [totalGuest, setTotalGuest] = useState<number>(0);
  const [checkInDate, setCheckInDate] = useState('');
  const [checkOutDate, setCheckOutDate] = useState('');

  const handleTotalGuest = (totalGuest: number) => {
    setTotalGuest(totalGuest);
  };

  const addAdultHandler = () => {
    if (guests.adults + guests.children < totalGuest) {
      setGuests({ ...guests, adults: guests.adults + 1 });
    }
  };

  const removeAdultHandler = () => {
    if (guests.adults > 1) {
      setGuests({ ...guests, adults: guests.adults - 1 });
    }
  };

  const addChildrenHandler = () => {
    if (guests.adults + guests.children < totalGuest) {
      setGuests({ ...guests, children: guests.children + 1 });
    }
  };

  const removeChildrenHandler = () => {
    if (guests.children > 0) {
      setGuests({ ...guests, children: guests.children - 1 });
    }
  };

  const addInfantHandler = () => {
    setGuests({ ...guests, infants: guests.infants + 1 });
  };

  const removeInfantHandler = () => {
    if (guests.infants > 0) {
      setGuests({ ...guests, infants: guests.infants - 1 });
    }
  };

  const addPetHandler = () => {
    if (guests.pets < 2) {
      setGuests({ ...guests, pets: guests.pets + 1 });
    }
  };

  const removePetHandler = () => {
    if (guests.pets > 0) {
      setGuests({ ...guests, pets: guests.pets - 1 });
    }
  };
  return (
    <ReservationContext.Provider
      value={{
        totalGuest,
        setTotalGuest: handleTotalGuest,
        addAdultHandler,
        addInfantHandler,
        addPetHandler,
        removeChildrenHandler,
        removeInfantHandler,
        removePetHandler,
        removeAdultHandler,
        addChildrenHandler,
        guests,
        setGuests,
        selectedItem,
        setSelectedItem,
        checkInDate,
        checkOutDate,
        setCheckInDate,
        setCheckOutDate,
      }}>
      {children}
    </ReservationContext.Provider>
  );
};
