import { NavigationContext } from 'contexts/NavigationContext';
import { Button } from 'native-base';
import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';

const styles = {
  button: {
    variant: 'primaryMobile',
    _text: {
      fontFamily: 'body600',
      color: 'styleSheet.canvas',
      fontSize: '2md',
    },
    w: '100%',
    mt: '16px',
  },
};

const BtnLogout: FC = () => {
  const { t } = useTranslation();
  const { useNavigation } = useContext(NavigationContext);

  const handleLogout = () => {
    useNavigation('Booking', {
      screen: 'Logout',
    });
  };
  return (
    <Button {...styles.button} onPress={handleLogout}>
      {t('PROFILE.LOGOUT')}
    </Button>
  );
};
export default BtnLogout;
