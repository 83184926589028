import { FC, useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';
import { NavigationContext, NavigationParam } from 'contexts/NavigationContext';

interface NavigationProviderProps {
  children: React.ReactNode;
}

export const NavigationProvider: FC<NavigationProviderProps> = ({ children }) => {
  const navigation = useNavigation();
  const handleNavigation = useCallback((navigateTo?: NavigationParam, params?: any) => {
    if (navigateTo || params) {
      //@ts-ignore
      navigation.navigate(navigateTo, params);
    } else {
      navigation.goBack();
    }
  }, []);

  return (
    <NavigationContext.Provider
      value={{
        useNavigation: handleNavigation,
      }}>
      {children}
    </NavigationContext.Provider>
  );
};
