import { PropertyDetailContext } from 'contexts/PropertyDetailContext';
import { Box, Text, HStack, Button, Divider, Pressable } from 'native-base';
import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import iconAmenities from '../../utils/iconAmenities';

const styles = {
  box: {
    bg: 'bgPage',
    ml: ['0px', '0px', '80px', '0px'],
    mr: ['0px', '0px', '80px', '0px'],
  },
  title: {
    mt: '48px',
    color: 'pdp.shadow',
    fontSize: '1xl',
    fontFamily: 'body600',
    mb: '24px',
  },
  text: {
    color: 'pdp.shadow',
    fontSize: '2md',
  },
  hstack: {
    mb: '21px',
    alignItems: 'center',
    space: 2,
    w: ['100%', '100%', '50%', '50%'],
  },
  button: {
    borderWidth: '1',
    bg: 'styleSheet.canvas',
    opacity: '0.7',
    h: '46px',
    borderRadius: '8px',
    borderColor: 'pdp.border',
    _pressed: {
      bg: 'styleSheet.shadow',
      opacity: '0.6',
      _text: { color: 'styleSheet.canvas' },
    },
    _hover: {
      bg: 'styleSheet.overCast',
      _text: { color: 'styleSheet.lightStain' },
    },
    _focus: {
      bg: 'styleSheet.canvas',
    },
    _text: {
      color: 'pdp.shadow',
      fontSize: 'sm',
    },
    w: ['100%', '100%', '142px', '142px', '142px'],
  },
  buttonClickZone: {
    w: ['100%', '100%', '160px', '160px', '160px'],
    mt: '7px',
    mb: '48px',
    p: '8px',
  },
  divider: {
    borderWidth: '1',
    borderColor: 'pdp.shadow',
    opacity: '0.15',
  },
  mainView: {
    flexDirection: ['column' as const, 'column' as const, 'row' as const, 'row' as const],
    w: '100%',
    flexWrap: ['nowrap' as const, 'nowrap' as const, 'wrap' as const, 'wrap' as const],
  },
};

interface IPropertyAmenitiesProps {
  onAmenitiesPress: () => void;
}

const PropertyAmenities: FC<IPropertyAmenitiesProps> = ({ onAmenitiesPress }) => {
  const { t } = useTranslation();
  const { listing } = useContext(PropertyDetailContext);

  let mergedAmenities: string[] = [];
  if (listing?.mappedAmenities) {
    let amenitiesArray: any[] = [];
    Object.entries(listing?.mappedAmenities as any[]).forEach(([key, value]) => {
      if (value !== null) {
        amenitiesArray.push(value);
      }
    });
    mergedAmenities = [].concat.apply([], amenitiesArray).slice(1, 9);
  }

  return (
    <Box {...styles.box}>
      <Box>
        <Text {...styles.title}>{t('PROPERTY_DETAIL.PROPERTY_AMENITIES')}</Text>
        <Box {...styles.mainView}>
          {iconAmenities(mergedAmenities).map((el) => {
            if (el.name !== null) {
              return (
                <HStack {...styles.hstack} key={el.name}>
                  <Box>{el.icon}</Box>
                  <Text {...styles.text}>{el.name}</Text>
                </HStack>
              );
            }
          })}
        </Box>
        <Pressable onPress={onAmenitiesPress} {...styles.buttonClickZone}>
          <Button {...styles.button} onPress={onAmenitiesPress}>
            {t('PROPERTY_DETAIL.SEE_ALL_AMENITIES')}
          </Button>
        </Pressable>
        <Divider {...styles.divider} />
      </Box>
    </Box>
  );
};

export default PropertyAmenities;
