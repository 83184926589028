import { ReservationContext } from 'contexts/reservationContext';
import { FC, useState, useContext, useMemo, useEffect } from 'react';
import { IGuests, ListingsContext } from '../contexts/ListingsContext';
import { CitiesContext, City } from 'contexts/CitiesContext';
import { useGetAllListingsLazyQuery } from 'screens/Booking/queries/getAllListings.generated';
import { usePersistentValue } from 'hooks/usePersistentValue';

interface ListingProviderProps {
  children: React.ReactNode;
}

export const ListingProvider: FC<ListingProviderProps> = ({ children }) => {
  const [storedSubscriptionId, , isStoredSubscriptionIdLoaded] = usePersistentValue<string | null>(
    'storedSubscriptionId',
    null
  );
  const { cities } = useContext(CitiesContext);
  const [checkIn, setCheckIn] = useState<string>('');
  const [checkOut, setCheckOut] = useState<string>('');
  const [APISelectedCities, setAPISelectedCities] = useState<City[]>([]);
  const [filterGuests, setFilterGuests] = useState<IGuests>({ adults: 1, children: 0, infants: 0, pets: 0 });
  const [guestsComposition, setGuestsComposition] = useState<IGuests>({ adults: 1, children: 0, infants: 0, pets: 0 });

  const cityList = useMemo(() => {
    if (APISelectedCities.length === cities?.length) return '';
    return (APISelectedCities || []).map((city) => city.city).join(',');
  }, [APISelectedCities]);

  const addAdultHandler = () => {
    setFilterGuests({ ...filterGuests, adults: filterGuests.adults + 1 });
  };

  const removeAdultHandler = () => {
    if (filterGuests.adults > 1) {
      setFilterGuests({ ...filterGuests, adults: filterGuests.adults - 1 });
    }
  };

  const addChildrenHandler = () => {
    setFilterGuests({ ...filterGuests, children: filterGuests.children + 1 });
  };

  const removeChildrenHandler = () => {
    if (filterGuests.children > 0) {
      setFilterGuests({ ...filterGuests, children: filterGuests.children - 1 });
    }
  };

  const addInfantHandler = () => {
    setFilterGuests({ ...filterGuests, infants: filterGuests.infants + 1 });
  };

  const removeInfantHandler = () => {
    if (filterGuests.infants > 0) {
      setFilterGuests({ ...filterGuests, infants: filterGuests.infants - 1 });
    }
  };

  const addPetHandler = () => {
    if (filterGuests.pets < 2) {
      setFilterGuests({ ...filterGuests, pets: filterGuests.pets + 1 });
    }
  };

  const removePetHandler = () => {
    if (filterGuests.pets > 0) {
      setFilterGuests({ ...filterGuests, pets: filterGuests.pets - 1 });
    }
  };

  const [getAllListings, { data, loading, called }] = useGetAllListingsLazyQuery({
    variables: {
      data: {
        checkIn: checkIn || '',
        checkOut: checkOut || '',
        city: cityList,
        guestInput: guestsComposition,
        subscriptionId: storedSubscriptionId || '',
      },
    },
  });

  useEffect(() => {
    if (isStoredSubscriptionIdLoaded && storedSubscriptionId) {
      getAllListings();
    }
  }, [isStoredSubscriptionIdLoaded, storedSubscriptionId]);

  const { guests } = useContext(ReservationContext);

  return (
    <ListingsContext.Provider
      value={{
        checkIn: checkIn,
        checkOut: checkOut,
        guestInput: guests,
        setCheckIn,
        guestsComposition,
        setGuestsComposition,
        setCheckOut,
        filterGuests,
        setFilterGuests,
        APISelectedCities,
        setAPISelectedCities,
        isLoading: !called || loading,
        listings: data?.getAllListings?.data,
        addAdultHandler,
        addChildrenHandler,
        addInfantHandler,
        addPetHandler,
        removeAdultHandler,
        removeChildrenHandler,
        removeInfantHandler,
        removePetHandler,
      }}>
      {children}
    </ListingsContext.Provider>
  );
};
