import { Loading } from 'components/Loading';
import ListOfMultiHomes from 'components/MultiAccountHome/ListOfMultiHomes';
import { MultiSubscriptionsContext } from 'contexts/MultiSubscriptionsContext';
import { UserContext } from 'contexts/UserContext';
import { Box, Text, ScrollView } from 'native-base';
import { FC, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const styles = {
  box: {
    h: '100%',
    bg: 'styleSheet.canvas',
  },
  h2: {
    fontSize: ['2.5xl', '2.5xl', '2.5xl', '5.5xl', '5.5xl'],
    color: 'styleSheet.shadow',
    textAlign: 'center' as const,
    alignSelf: 'center' as const,
    lineHeight: ['31px', '31px', '31px', '51px', '51px'],
    fontFamily: 'body',
    fontWeight: '300',
    pr: ['33px', '33px', '119px', '33px', '33px'],
    pl: ['33px', '33px', '119px', '33px', '33px'],
    pt: ['56px', '56px', '150px', '150px', '150px'],
    w: ['100%', '100%', '100%', '50%', '50%'],
  },

  msg: {
    overCast: 'styleSheet.shadow',
    fontFamily: 'body',
    fontSize: 'sm',
    pr: '58px',
    pl: '58px',
    textAlign: 'center' as const,
    pt: '16px',
  },
};

const MultAccHomePicker: FC = () => {
  const { t } = useTranslation();
  const { user, isLoading: isUserLoading } = useContext(UserContext);
  const firstName = useMemo(() => user?.given_name, [user]);

  const capFirstName = firstName?.charAt(0).toUpperCase()! + firstName?.slice(1);
  const { isLoading: isSubscriptionLoading } = useContext(MultiSubscriptionsContext);
  if (isUserLoading || isSubscriptionLoading) return <Loading />;

  return (
    <Box safeAreaTop {...styles.box}>
      <ScrollView>
        <Text {...styles.h2}>{t('MULTI_HOME.SELECT_YOUR_HOME', { firstName: capFirstName })}</Text>
        <ListOfMultiHomes />
      </ScrollView>
    </Box>
  );
};

export default MultAccHomePicker;
