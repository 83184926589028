import * as SentryBrowser from '@sentry/browser';
import * as SentryNative from '@sentry/react-native';
import { Breadcrumb, User } from '@sentry/react-native';
import { CaptureContext } from '@sentry/types';
import { Platform } from 'react-native';

export class SentryService {
  static addBreadcrumb(breadcrumb: Breadcrumb) {
    if (Platform.OS === 'web') {
      SentryBrowser.addBreadcrumb(breadcrumb);
    } else {
      SentryNative.addBreadcrumb(breadcrumb);
    }
  }
  static captureException(err: any, captureContext?: CaptureContext) {
    if (Platform.OS === 'web') {
      SentryBrowser.captureException(err, captureContext);
    } else {
      SentryNative.captureException(err, captureContext);
    }
  }

  static captureMessage(message: string, captureContext?: CaptureContext) {
    if (Platform.OS === 'web') {
      SentryBrowser.captureMessage(message, captureContext);
    } else {
      SentryNative.captureMessage(message, captureContext);
    }
  }

  static setUser(user: User) {
    if (Platform.OS === 'web') {
      SentryBrowser.setUser(user);
    } else {
      SentryNative.setUser(user);
    }
  }

  static NativeCrash() {
    SentryNative.nativeCrash();
  }
}
