import * as Types from '../../../generated/types.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetPriceCalculationQueryVariables = Types.Exact<{
  from: Types.Scalars['String'];
  guestyListingId: Types.Scalars['String'];
  to: Types.Scalars['String'];
}>;


export type GetPriceCalculationQuery = { __typename?: 'Query', getPriceCalculation: { __typename?: 'PriceCalculationResponse', status: { __typename?: 'CoreStatus', code: number, message: string }, data?: { __typename?: 'PriceCalculation', basePrice?: number | null, baseDiscountedPrice?: number | null, cleaningFees?: number | null, discount?: number | null, totalTax?: number | null, totalPriceWithTaxAndDiscount?: number | null, totalPriceWithoutTaxAndDiscount?: number | null, noOfDays?: number | null, currency?: string | null, taxesData?: Array<{ __typename?: 'TaxesData', type?: string | null, amount?: number | null }> | null } | null } };


export const GetPriceCalculationDocument = gql`
    query getPriceCalculation($from: String!, $guestyListingId: String!, $to: String!) {
  getPriceCalculation(from: $from, guestyListingId: $guestyListingId, to: $to) {
    status {
      code
      message
    }
    data {
      basePrice
      baseDiscountedPrice
      cleaningFees
      discount
      totalTax
      taxesData {
        type
        amount
      }
      totalPriceWithTaxAndDiscount
      totalPriceWithoutTaxAndDiscount
      noOfDays
      currency
    }
  }
}
    `;

/**
 * __useGetPriceCalculationQuery__
 *
 * To run a query within a React component, call `useGetPriceCalculationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPriceCalculationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPriceCalculationQuery({
 *   variables: {
 *      from: // value for 'from'
 *      guestyListingId: // value for 'guestyListingId'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useGetPriceCalculationQuery(baseOptions: Apollo.QueryHookOptions<GetPriceCalculationQuery, GetPriceCalculationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPriceCalculationQuery, GetPriceCalculationQueryVariables>(GetPriceCalculationDocument, options);
      }
export function useGetPriceCalculationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPriceCalculationQuery, GetPriceCalculationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPriceCalculationQuery, GetPriceCalculationQueryVariables>(GetPriceCalculationDocument, options);
        }
export type GetPriceCalculationQueryHookResult = ReturnType<typeof useGetPriceCalculationQuery>;
export type GetPriceCalculationLazyQueryHookResult = ReturnType<typeof useGetPriceCalculationLazyQuery>;
export type GetPriceCalculationQueryResult = Apollo.QueryResult<GetPriceCalculationQuery, GetPriceCalculationQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    