import { HStack, Text, Divider } from 'native-base';
import { useTranslation } from 'react-i18next';
import separator from '../../utils/separator';
import { H3 } from 'components/libs/heading/Heading';

const styles = {
  rates: {
    fontSize: '2md',
    fontWeight: '400',
    fontFamily: 'body',
  },
  amount: {
    justifyContent: 'space-between',
  },
  secondHstack: {
    justifyContent: 'space-between',
    mt: '4px',
  },
  hstack: {
    justifyContent: 'space-between',
  },
  textAmount: {
    fontSize: 'sm',
    textDecorationLine: 'line-through',
    mr: '8px',
  },
  ratesTotal: {
    fontSize: '2md',
    fontFamily: 'body800',
  },
  divider: {
    variant: 'primary',
    mt: '16px',
    mb: '16px',
  },
};

const ReservationAmount = ({ price }: any) => {
  const { t } = useTranslation();

  let nightsAmount;
  let discountAmount;
  if (price) {
    nightsAmount = separator(price?.getPriceCalculation.data?.basePrice.toFixed(2).toString());
    discountAmount = separator(price?.getPriceCalculation.data?.discount.toFixed(2).toString());
  }

  return (
    <>
      <HStack {...styles.hstack}>
        <Text {...styles.rates}>{`${price?.getPriceCalculation.data?.noOfDays} nights`}</Text>
        <Text {...styles.rates}>${nightsAmount}</Text>
      </HStack>
      {Number(discountAmount) !== 0 && (
        <HStack {...styles.secondHstack}>
          <Text {...styles.rates}>{t('MEMBER_DISCOUNT')}</Text>
          <Text {...styles.rates}>-${discountAmount}</Text>
        </HStack>
      )}
      <HStack {...styles.secondHstack}>
        <Text {...styles.rates}>{t('CLEANING_FEE')}</Text>
        <Text {...styles.rates}>${price?.getPriceCalculation.data?.cleaningFees.toFixed(2)}</Text>
      </HStack>
      <HStack {...styles.secondHstack}>
        <Text {...styles.rates}>{t('TAXES')}</Text>
        <Text {...styles.rates}>${price?.getPriceCalculation.data?.totalTax?.toFixed(2)}</Text>
      </HStack>
      <Divider {...styles.divider} />
      <HStack {...styles.amount}>
        <H3 {...styles.ratesTotal}>{t('TOTAL_AMOUNT')}</H3>
        <HStack alignItems="flex-end">
          <H3 {...styles.ratesTotal}>${price?.getPriceCalculation.data?.totalPriceWithTaxAndDiscount.toFixed(2)}</H3>
        </HStack>
      </HStack>
    </>
  );
};

export default ReservationAmount;
