import { LandingScreenContent } from 'components/Landing/LandingScreenContent';
import LandingBoard from 'components/Landing/LandingBoard';
import { Box } from 'native-base';
import { FC } from 'react';
import { RootStackScreenProps } from 'types';

const styles = {
  box: {
    w: '100%',
    h: '100%',
  },
};
export const LandingScreen: FC<RootStackScreenProps<'Login'>> = () => {
  return (
    <Box {...styles.box}>
      <LandingBoard landing={true}>
        <LandingScreenContent />
      </LandingBoard>
    </Box>
  );
};
