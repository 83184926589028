import { FC, useEffect, useState } from 'react';
import { StaysContext, ReservationsType } from 'contexts/StaysContext';
import { useGetAllReservationsQuery } from 'screens/Booking/queries/getAllReservations.generated';

interface StaysProviderProps {
  children: React.ReactNode;
}

export const StaysProvider: FC<StaysProviderProps> = ({ children }) => {
  const [reservation, setReservations] = useState<ReservationsType | null | undefined>(null);
  const { data, loading, refetch } = useGetAllReservationsQuery();

  useEffect(() => {
    if (!!data) {
      setReservations(data.getAllReservations);
    }
  }, [data]);

  return (
    <StaysContext.Provider
      value={{
        reservations: reservation,
        isLoading: loading,
        refetchData: refetch,
      }}>
      {children}
    </StaysContext.Provider>
  );
};
