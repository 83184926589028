import { useTranslation } from 'react-i18next';
import { Platform, useWindowDimensions } from 'react-native';
import { getTabOptions } from '.';
import { Divider, Hidden, useBreakpointValue } from 'native-base';
import { useContext, useEffect } from 'react';
import { UserProvider } from 'providers/UserProvider';
import { SubscriptionProvider } from 'providers/SubscriptionProvider';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { RootTabParamList } from 'types';
import { AppTheme } from 'constants/Theme';
import { MyHomeStackNavigator } from './MyHomeStackNavigator';
import ContactScreen from 'screens/Contact/ContactScreen';
import { ProfileStackNavigator } from './ProfileStackNavigator';
import { StayNavigator } from './StayNavigator';
import { BookingNavigator } from './BookingNavigator';
import BookIcon from '../assets/icons/Book.svg';
import StaysIcon from '../assets/icons/Stays.svg';
import MyHomeIcon from '../assets/icons/My-Home.svg';
import ProfileIcon from '../assets/icons/Profile.svg';
import HelpIcon from '../assets/icons/Help.svg';
import { UserContext } from 'contexts/UserContext';
import { HomeEquityProvider } from 'providers/HomeEquityProvider';

const ProfileTab = createBottomTabNavigator<RootTabParamList>();

const style = {
  divider: {
    borderWidth: 0.7,
    borderColor: 'styleSheet.darkStain',
    height: '35%',
  },
};

export function ProfileTabNavigator() {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  let windowWidth = useWindowDimensions()?.width;
  let breakpointTypeValue = useBreakpointValue({ base: 1, sm: 2, md: 3, lg: 4, xl: 5 });
  useEffect(() => {
    if (__DEV__) {
      console.log(
        `Window width: ${windowWidth} and Breakpoint type: ${
          breakpointTypeValue == 1
            ? 'BASE'
            : breakpointTypeValue == 2
            ? 'SM'
            : breakpointTypeValue == 3
            ? 'MD'
            : breakpointTypeValue == 4
            ? 'LG'
            : breakpointTypeValue == 5
            ? 'XL'
            : 'UNDEFINED'
        }`
      );
    }
  }, [windowWidth, breakpointTypeValue]);

  return (
    <UserProvider>
      <HomeEquityProvider>
        <SubscriptionProvider>
          <ProfileTab.Navigator
            initialRouteName="Booking"
            id="TabNavigator"
            screenOptions={{
              unmountOnBlur: true,
              tabBarLabelStyle: {
                marginBottom: 5,
                fontSize: useBreakpointValue({ base: 14, sm: 14, md: 17, lg: 17, xl: 17 }),
              },
              tabBarIconStyle: {
                marginTop: 2,
              },
              tabBarStyle: {
                backgroundColor: AppTheme.colors.styleSheet.canvas,
                borderTopWidth: useBreakpointValue({
                  base: 1,
                  sm: 1,
                  md: 0,
                  lg: 0,
                  xl: 0,
                }),
                borderBottomWidth: 2,
                borderBottomColor: 'rgba(50, 50, 50, 0.12)',
                height: useBreakpointValue({ base: '10%', md: 80, lg: 80, xl: 80 }),
                overflow: 'hidden',
                position: useBreakpointValue({
                  base: 'relative' as const,
                  sm: 'relative' as const,
                  md: 'absolute' as const,
                  lg: 'absolute' as const,
                  xl: 'absolute' as const,
                }),
                top: useBreakpointValue({
                  base: 0,
                  sm: 0,
                  md: 0,
                  lg: 0,
                  xl: 0,
                }),
                alignItems: useBreakpointValue({
                  base: 'stretch' as const,
                  sm: 'stretch' as const,
                  md: 'flex-end' as const,
                  lg: 'flex-end' as const,
                  xl: 'flex-end' as const,
                }),
                justifyContent: useBreakpointValue({
                  base: 'flex-start' as const,
                  sm: 'flex-start' as const,
                  md: 'flex-start' as const,
                  lg: 'space-evenly' as const,
                  xl: 'space-evenly' as const,
                }),
              },
              tabBarItemStyle: {
                position: 'relative' as const,
                paddingHorizontal: useBreakpointValue({ base: 0, sm: 0, md: 10, lg: 10, xl: 10 }),
                display: 'flex',
                flexDirection: useBreakpointValue({ base: 'column', sm: 'column', md: 'row', lg: 'row', xl: 'row' }),
                alignItems: 'center' as const,
                justifyContent: 'center' as const,
                right:
                  Platform.OS == 'web'
                    ? useBreakpointValue({ base: '0%', sm: '10%', md: '40px', lg: '64px', xl: '64px' })
                    : useBreakpointValue({ base: '0%', sm: '0%', md: 20, lg: '60%', xl: '80%' }),
                maxWidth: useBreakpointValue({ base: null, sm: null, md: 150, lg: null, xl: null }),
                top: useBreakpointValue({ base: 0, sm: 0, md: 8, lg: 0, xl: 0 }),
              },
            }}>
            <ProfileTab.Screen
              name="Booking"
              component={BookingNavigator}
              options={getTabOptions('Book', {
                tabBarIcon: ({ color }: { color: string }) => (
                  <Hidden from={'md'}>
                    <BookIcon fill={color} />
                  </Hidden>
                ),
                tabBarActiveTintColor: 'black',
              })}
            />
            <ProfileTab.Screen
              name="StaysTab"
              component={StayNavigator}
              options={getTabOptions(
                useBreakpointValue({ base: 'Stays', sm: 'Stays', md: 'My Stays', lg: 'My Stays', xl: 'My Stays' }),
                {
                  tabBarIcon: ({ color }: { color: string }) => (
                    <Hidden from={'md'}>
                      <StaysIcon fill={color} />
                    </Hidden>
                  ),
                  tabBarActiveTintColor: 'black',
                }
              )}
            />
            <ProfileTab.Screen
              name="MyHome"
              component={MyHomeStackNavigator}
              options={getTabOptions(
                useBreakpointValue({ base: 'My Home', sm: 'My Home', md: 'My Home', lg: 'My Home', xl: 'My Home' }),
                {
                  tabBarIcon: ({ color }: { color: string }) => (
                    <Hidden from={'md'}>
                      <MyHomeIcon fill={color} />
                    </Hidden>
                  ),
                  tabBarActiveTintColor: 'black',
                }
              )}
            />
            <ProfileTab.Screen
              name="Contact"
              component={ContactScreen}
              options={getTabOptions(
                useBreakpointValue({
                  base: 'Contact',
                  sm: 'Contact',
                  md: 'Contact',
                  lg: t('GO_SUMMER') + t('CONTACT.TITLE'),
                  xl: t('GO_SUMMER') + t('CONTACT.TITLE'),
                }),
                {
                  tabBarIcon: ({ color }: { color: string }) => (
                    <>
                      <Hidden from={'md'}>
                        <HelpIcon fill={color} />
                      </Hidden>
                    </>
                  ),
                  tabBarActiveTintColor: 'black',
                  tabBarLabel: 'Contact',
                }
              )}
            />
            <ProfileTab.Screen
              name="Profile"
              component={ProfileStackNavigator}
              options={getTabOptions(
                useBreakpointValue({
                  base: 'Profile',
                  sm: 'Profile',
                  md: t('NAVBAR.GREETINGS', { name: user?.given_name }),
                  lg: t('NAVBAR.GREETINGS', { name: user?.given_name }),
                  xl: t('NAVBAR.GREETINGS', { name: user?.given_name }),
                }),
                {
                  tabBarIcon: ({ color }: { color: string }) => (
                    <>
                      <Hidden from={'md'}>
                        <ProfileIcon fill={color} />
                      </Hidden>
                      <Hidden till={'md'}>
                        <Divider orientation="vertical" {...style.divider} />
                      </Hidden>
                    </>
                  ),
                  tabBarActiveTintColor: 'black',
                }
              )}
            />
          </ProfileTab.Navigator>
        </SubscriptionProvider>
      </HomeEquityProvider>
    </UserProvider>
  );
}
