const fontConfig = {
  TWKL: {
    300: {
      normal: 'TWKL-300',
    },
    400: {
      normal: 'TWKL-400',
    },
    600: {
      normal: 'TWKL-600',
    },
    800: {
      normal: 'TWKL-800',
    },
  },
};

export default fontConfig;
