import { FC } from 'react';
import { View, Text, Spinner, HStack } from 'native-base';
import { direction, contentAlignment } from '../styles/flexbox.d';

const styles = {
  container: {
    flexDirection: direction.column,
    justifyContent: contentAlignment.center,
    alignItems: contentAlignment.center,
    height: '100%',
    bg: 'styleSheet.canvas',
  },
  text: {
    textAlign: 'center',
  },
  spinner: {},
};

export const Loading: FC = () => {
  return (
    <View {...styles.container}>
      <HStack>
        <Spinner color="styleSheet.shadow" size="lg" />
        <Text> Loading... </Text>
      </HStack>
    </View>
  );
};
