import { FC, ReactNode } from 'react';
import { Box, HStack, Stack, StyledProps, Text, VStack } from 'native-base';
import DonutChart from './DonutChart';

const styles = {
  hstack: {
    alignItems: 'center' as const,
    justifyContent: 'center' as const,
    space: '32px',
  },
  text: {
    fontSize: 'sm',
    color: 'styleSheet.shadow',
    opacity: '0.7',
    fontFamily: 'body600',
  },
  number: {
    fontSize: '2md',
    color: 'styleSheet.shadow',
    fontWeight: '400',
    fontFamily: 'body',
  },
  condensed: {
    labelContainer: {
      alignItems: 'flex-start',
      direction: 'column' as const,
      justifyContent: 'center' as const,
      space: '16px',
    },
    wrapper: {
      alignItems: 'center' as const,
      justifyContent: 'center' as const,
      space: '32px',
      w: '100%',
    },
  },
  fullSize: {
    labelContainer: {
      alignItems: 'center' as const,
      direction: 'row' as const,
      justifyContent: 'flex-start' as const,
      space: '20px',
    },
    labelAndButtonWrapper: {
      space: '24px',
    },
  },
};

interface ISecondaryValue {
  label: string;
  value: string;
}

interface IFinancialOverviewProps {
  children?: ReactNode,
  condensed?: boolean;
  graphicColors: string[];
  graphicData: string[];
  mainLabel: string;
  mainAmount: number;
  secondaryValues: ISecondaryValue[],
}

type ContentProps = Omit<IFinancialOverviewProps, 'condensed'>

type ValueLabelProps = {
  wrapperStyle: StyledProps;
} & Pick<ContentProps, 'secondaryValues'>; 

const ValueLabels: FC<ValueLabelProps> = ({
  secondaryValues,
  wrapperStyle,
}) => {
  return (
    <Stack {...wrapperStyle}>
      {secondaryValues.map((value) => (
        <Box key={`${value.label}-${value.value}`}>
          <Text {...styles.text}>{value.label}</Text>
          <Text {...styles.number}>{value.value}</Text>
        </Box>
      ))}
    </Stack>
  )
};

const CondensedContents: FC<ContentProps> = ({
  children,
  graphicColors,
  graphicData,
  mainAmount,
  mainLabel,
  secondaryValues,
}) => {
  return (
      <VStack {...styles.condensed.wrapper}>
        <HStack {...styles.hstack}>
          <DonutChart
            amount={mainAmount}
            graphicData={graphicData}
            graphicColors={graphicColors}
            label={mainLabel}
          />
          <ValueLabels
            secondaryValues={secondaryValues}
            wrapperStyle={styles.condensed.labelContainer}
          />
        </HStack>
        {children}
      </VStack>
  );
};

const FullSizeContents: FC<ContentProps> = ({
  children,
  graphicColors,
  graphicData,
  mainAmount,
  mainLabel,
  secondaryValues,
}) => {
  return (
      <HStack {...styles.hstack}>
        <DonutChart
          amount={mainAmount}
          graphicData={graphicData}
          graphicColors={graphicColors}
          label={mainLabel}
        />
        <VStack {...styles.fullSize.labelAndButtonWrapper}>
          <ValueLabels
            secondaryValues={secondaryValues}
            wrapperStyle={styles.fullSize.labelContainer}
          />
          {children}
        </VStack>
      </HStack>
  );
};

const FinancialOverview: FC<IFinancialOverviewProps> = ({
  children,
  condensed,
  graphicColors,
  graphicData,
  mainAmount,
  mainLabel,
  secondaryValues
}) => {
  if (condensed) {
    return (
      <CondensedContents
        graphicColors={graphicColors}
        graphicData={graphicData}
        mainAmount={mainAmount}
        mainLabel={mainLabel}
        secondaryValues={secondaryValues}
      >
        {children}
      </CondensedContents>
    );
  }

  return (
    <FullSizeContents
      graphicColors={graphicColors}
      graphicData={graphicData}
      mainAmount={mainAmount}
      mainLabel={mainLabel}
      secondaryValues={secondaryValues}
    >
      {children}
    </FullSizeContents>
  );
};

export default FinancialOverview;
