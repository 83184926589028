import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { useTranslation } from 'react-i18next';
import ProfileMgmtScreen from 'screens/Profile/ProfileMgmtScreen';
import ProfileScreen from 'screens/Profile/ProfileScreen';
import { RootStackParamList } from 'types';
import { getOptions } from '.';

const Profile = createNativeStackNavigator<RootStackParamList>();

export function ProfileStackNavigator() {
  const { t } = useTranslation();
  return (
    <Profile.Navigator initialRouteName="ProfileMgmtScreen">
      <Profile.Screen
        name="ProfileMgmtScreen"
        component={ProfileMgmtScreen}
        options={getOptions(t('GO_SUMMER') + t('PROFILE.PROFILE_MGMT'))}
      />
      <Profile.Screen
        name="ProfileScreen"
        component={ProfileScreen}
        options={getOptions(t('GO_SUMMER') + t('PROFILE.TITLE'))}
      />
    </Profile.Navigator>
  );
}
