import { createContext } from 'react';
import { noop } from 'lodash';
import { RootStackParamList, RootTabParamList } from 'types';

export type NavigationParam = keyof RootTabParamList | keyof RootStackParamList | string;
export type NavigationInitialState = {
  useNavigation: (screen?: NavigationParam, params?: any) => void;
};

export const NavigationContext = createContext<NavigationInitialState>({
  useNavigation: noop,
});
