import { FC } from 'react';
import { Box, CloseIcon, HStack, Pressable, Text, View } from 'native-base';
import { useTranslation } from 'react-i18next';
import { RootStackScreenProps } from 'types';
const styles = {
  container: {
    flex: 1,
    bgColor: 'styleSheet.canvas',
  },
  top: {
    alignItems: 'center',
    mt: ['0px', '0px', '0px', '40px', '80px'],
  },
  icon: {
    color: 'styleSheet.shadow',
    alignSelf: 'center',
    size: '4',
  },
  hr: {
    w: '100%',
    borderWidth: '1px',
    borderColor: 'styleSheet.overCast',
    opacity: '0.15',
  },
  page: {
    h: '100%',
    pl: ['16px', '16px', '80px', '80px', '465px'],
    pr: ['16px', '16px', '80px', '80px', '465px'],
    pt: ['40px', '40px', '40px', '40px', '80px'],
    pb: '16px',
  },
  title: {
    fontFamily: 'body600',
    color: 'styleSheet.shadow',
    pl: ['16px', '16px', '40px', '40px', '64px'],
    pt: ['28px', '28px', '40px', '40px', '40px'],
    pb: ['24px', '28px', '40px', '40px', '40px'],
    fontSize: ['2md', '2md', '1xl', '1xl', '1xl'],
  },
  pressable: {
    h: '50px',
    w: '50px',
    position: 'absolute' as const,
    right: '0',
    zIndex: 1,
    justifyContent: 'center' as const,
    pr: ['16px', '16px', '16px', '64px'],
  },
  cancellation: {
    fontSize: '2md',
    fontFamily: 'body',
    fontWeight: '400',
    color: 'pdp.shadow',
  },
};

const PdpCancellationModal: FC<RootStackScreenProps<'PdpCancellationModal'>> = ({ navigation }) => {
  const { t } = useTranslation();
  //TODO Get cancellation from BE

  return (
    <Box {...styles.container} safeAreaTop safeAreaBottom>
      <HStack {...styles.top}>
        <Pressable onPress={() => navigation.goBack()} {...styles.pressable}>
          <CloseIcon {...styles.icon} />
        </Pressable>
        <Text {...styles.title}>{t('PROPERTY_DETAIL.CANCELLATION_POLICY')}</Text>
      </HStack>
      <View {...styles.hr} />
      <View {...styles.page}>
        <Text {...styles.cancellation}>{t('PROPERTY_DETAIL.CANCELLATION_POLICY_DETAIL')}</Text>
      </View>
    </Box>
  );
};

export default PdpCancellationModal;
