import { MultiSubscriptionsContext } from 'contexts/MultiSubscriptionsContext';
import { Box } from 'native-base';
import { FC, useContext, useState } from 'react';
import { HomePickerCard } from './HomePickerCard';

const styles = {
  pickerBox: {
    mt: ['20px', '20px', '43px', '43px', '43px'],
    flexDirection: ['column' as const, 'column' as const, 'row' as const, 'row' as const, 'row' as const],
    flexWrap: ['nowrap' as const, 'nowrap' as const, 'nowrap' as const, 'wrap' as const, 'wrap' as const],
    ml: ['0px', '0px', '5%', '10%', '15%'],
    mr: ['0px', '0px', '5%', '10%', '10%'],
    pb: '20px',
  },
};

interface IListOfMultiHomes {
  isFromMyHome?: boolean;
}

const ListOfMultiHomes: FC<IListOfMultiHomes> = ({ isFromMyHome }) => {
  const [widthDims, setWidthDims] = useState(0);
  const { subscriptionsList } = useContext(MultiSubscriptionsContext);

  return (
    <Box
      {...styles.pickerBox}
      onLayout={(event) => {
        const { width } = event.nativeEvent.layout;
        setWidthDims(width);
      }}>
      {subscriptionsList?.map((list: any) => {
        return (
          <Box key={list.subscriptionId}>
            <HomePickerCard
              subscriptionId={list.subscriptionId}
              image={list.picture}
              home={list.nickName}
              remainingBalance={list.remainingBalance}
              containerWidth={widthDims}
              isFromMyHome={isFromMyHome}
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default ListOfMultiHomes;
