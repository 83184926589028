import { FC, useContext, useEffect, useRef, useState } from 'react';
import { ListingsContext } from 'contexts/ListingsContext';
import { HStack, View, Text, Pressable, Hidden, Divider, useBreakpointValue } from 'native-base';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { NavigationContext } from 'contexts/NavigationContext';
import FilterIcon from '../../assets/icons/Filter.svg';
import DropDownPopupLarger from '../LocationDropdown/DropDownPopupLarger';
import DropDownPopup from 'components/LocationDropdown/DropDownPopup';
import { CalendarProvider } from 'providers/CalendarProvider';
import CalendarPopupLarger from 'components/Calendars/CalendarPopupLarger';
import GuestBoxDropdown from 'components/Guests/GuestBoxDropdown';

const styles = {
  container: {
    bottom: '1',
    zIndex: 1,
    w: '100%',
    backgroundColor: 'transparent',
    position: 'relative' as const,
    top: '10px',
    justifyContent: 'center',
  },
  locationSectionContainer: {
    borderTopRight: 'none',
    w: ['100%', '100%', '30%', '30%', '30%'],
    h: '80px',
    flexDirection: 'column' as const,
    justifyContent: 'space-between',
    position: 'relative' as const,
    pt: '3px',
    pb: '3px',
  },
  datesAndFilterContainer: {
    borderWidth: '1.5px',
    borderColor: 'styleSheet.darkStain',
    justifyContent: 'space-between',
    alignItems: 'center',
    h: '80px',
    paddingHorizontal: '3%',
    w: '100%',
    bg: 'black',
  },
  locationHeaderTitle: {
    color: 'styleSheet.overCast',
    position: 'absolute' as const,
    top: ['5px', '5px', '4px', '4px', '4px'],
    ml: '14px',
    fontSize: 'sm',
    fontFamily: 'body',
  },
  datesHeaderTitle: {
    color: 'styleSheet.overCast',
    fontSize: 'sm',
    fontFamily: 'body',
    ml: ['0px', '10px', '0px', '0px', '0px'],
    pl: '16px',
  },
  datesText: {
    fontSize: '17px',
    fontFamily: 'body',
    color: 'styleSheet.shadow',
    fontWeight: '400',
    pl: '16px',
  },
  filterIconContainer: {
    h: '80px',
    w: '0',
    borderWidth: '1px',
    borderColor: 'styleSheet.darkStain',
    position: 'absolute' as const,
    left: 60,
  },
  mainView: {
    top: ['0px', '0px', '70px', '70px', '75px'],
    flexDirection: ['column' as const, 'column' as const, 'row' as const, 'row' as const, 'row' as const],
    flexWrap: ['nowrap' as const, 'nowrap' as const, 'wrap' as const, 'wrap' as const, 'wrap' as const],
    alignItems: 'center',
  },
  divider: {
    w: '100%',
    color: 'pdp.shadow',
    borderWidth: '1px',
    opacity: '0.15',
  },
  guestView: {
    borderTopRight: 'none',
    w: ['100%', '100%', '30%', '30%', '30%'],
    h: '80px',
    flexDirection: 'column' as const,
    justifyContent: 'space-between',
    position: 'relative' as const,
    pt: '3px',
    pb: '3px',
  },
  boxHstack: {
    alignItems: 'center',
    justifyContent: 'space-between',
    w: '100%',
  },
  upperText: {
    ml: ['0px', '0px', '20px', '20px', '20px'],
    w: '80%',
    color: 'styleSheet.overCast',
    fontSize: 'sm',
    fontFamily: 'body',
    opacity: '0.55',
  },
  pressable: {
    h: '40px',
    w: '50px',
    justifyContent: 'center',
    alignSelf: 'center',
    position: 'absolute' as const,
    right: '5%',
  },
  lowerText: {
    fontWeight: '400',
    fontFamily: 'body',
    fontSize: '2md',
    ml: ['0px', '0px', '20px', '20px', '20px'],
  },
  verticalLineGuest: {
    h: '50px',
    w: '0',
    borderWidth: '1px',
    borderColor: 'styleSheet.darkStain',
    alignSelf: 'center' as const,
  },
  verticalLine: {
    h: '100%',
    borderWidth: '1px',
    borderColor: 'styleSheet.darkStain',
    alignSelf: 'center' as const,
  },
  locationContainer: {
    width: '100%',
  },
  datesContainer: {
    width: '100%',
    flexDirection: 'row' as const,
    mt: '16px',
    mb: '16px',
  },
  dropdownHstack: {
    alignItems: 'center' as const,
  },
  icon: {
    width: '15%',
    alignItems: 'center' as const,
    justifyContent: 'center' as const,
  },
  view: {
    width: '30%',
    alignSelf: 'center' as const,
  },
  viewForSticky: {
    width: '100%',
    marginTop: '70px',
    borderBottomWidth: 2,
    borderColor: 'styleSheet.darkStain',
  },
  dropDownView: {
    width: [null, null, '90%', '73%', '73%'],
    alignSelf: 'flex-end' as const,
  },

  listingBarHstack: {
    pr: [null, null, null, '8%', '8%'],
  },
};

interface IListingStickyBarProps {}

const ListingStickyBar: FC<IListingStickyBarProps> = () => {
  const { t } = useTranslation();
  const { checkIn, checkOut, setAPISelectedCities, APISelectedCities, setCheckIn, setCheckOut } =
    useContext(ListingsContext);
  const { useNavigation } = useContext(NavigationContext);
  const [isOpen, setIsOpen] = useState(false);
  const [isGuestBoxOpen, setIsGuestBoxOpen] = useState(false);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  let breakpointTypeValue = useBreakpointValue({ base: 1, sm: 2, md: 3, lg: 4, xl: 5 });
  const [widthDims, setWidthDims] = useState(0);

  useEffect(() => {
    if (breakpointTypeValue == 4 || breakpointTypeValue == 5) {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsCalendarOpen(false);
          setIsOpen(false);
          setIsGuestBoxOpen(false);
        }
      };
      document.addEventListener('click', handleClickOutside, true);
      return () => {
        document.removeEventListener('click', handleClickOutside, true);
      };
    }
  }, []);

  const setCalendarBoxWidth = (width: number) => {
    if (breakpointTypeValue == 4 || breakpointTypeValue == 5) setWidthDims(width + width / 1.5);
    if (breakpointTypeValue == 3) setWidthDims(width + width / 0.6);
  };

  const handleOnPressed = () => {
    useNavigation('BookingCalendar', {
      screenName: 'ListingResults',
      params: { checkInDate: checkIn, checkOutDate: checkOut },
    });
  };

  return (
    <View {...styles.container} ref={ref}>
      <Hidden from={'md'}>
        <View>
          <HStack {...styles.dropdownHstack}>
            <View {...styles.locationContainer}>
              <DropDownPopup
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                selectedCities={APISelectedCities || []}
                onSelectionChanged={setAPISelectedCities}
              />
            </View>
          </HStack>
          <Divider {...styles.divider} />
          <Pressable onPress={handleOnPressed}>
            <View {...styles.datesContainer}>
              <View style={{ width: '85%' }}>
                <Text {...styles.datesHeaderTitle}>{t('BOOK_A_STAY.DATES')}</Text>
                <Text {...styles.datesText}>
                  {checkIn !== '' && checkOut !== ''
                    ? `${moment(checkIn).format('ddd, MMM DD')} - ${moment(checkOut).format('ddd, MMM DD')}`
                    : t('LISTINGS_RESULTS.SELECT_A_DATE')}
                </Text>
              </View>

              <View {...styles.verticalLineGuest} />

              <View {...styles.icon}>
                <FilterIcon />
              </View>
            </View>
          </Pressable>
          <Divider {...styles.divider} />
        </View>
      </Hidden>

      <Hidden till={'md'}>
        <View {...styles.viewForSticky}>
          <HStack {...styles.listingBarHstack}>
            <View style={{ width: '40%' }}>
              <View {...styles.dropDownView}>
                <DropDownPopupLarger
                  isFromStickyBar={true}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  isOpenCal={isCalendarOpen}
                  setIsOpenCal={setIsCalendarOpen}
                  selectedCitiesList={APISelectedCities}
                  onSelectionChanged={setAPISelectedCities}
                  setIsDropDownOpen={setIsGuestBoxOpen}
                />
              </View>
            </View>
            {isOpen || isCalendarOpen ? null : <View {...styles.verticalLineGuest} />}
            <View
              {...styles.view}
              onLayout={(event) => {
                const { width } = event.nativeEvent.layout;
                setCalendarBoxWidth(width);
              }}>
              <CalendarProvider>
                <CalendarPopupLarger
                  isFromStickyBar={true}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  isOpenCal={isCalendarOpen}
                  setIsOpenCal={setIsCalendarOpen}
                  checkIn={checkIn}
                  checkOut={checkOut}
                  popoverWidth={widthDims}
                  setCheckIn={setCheckIn}
                  setCheckOut={setCheckOut}
                />
              </CalendarProvider>
            </View>
            {isCalendarOpen ? null : <View {...styles.verticalLineGuest} />}
            {!isCalendarOpen ? (
              <View {...styles.view}>
                <GuestBoxDropdown
                  isFromStickyBar={true}
                  isOpen={isGuestBoxOpen}
                  setIsOpen={setIsGuestBoxOpen}
                  setIsOpenCal={setIsCalendarOpen}
                  setIsDropDownOpen={setIsOpen}
                />
              </View>
            ) : null}
          </HStack>
        </View>
      </Hidden>
    </View>
  );
};

export default ListingStickyBar;
