import { Platform } from 'react-native';
import Layout from 'constants/Layout';

const windowWidth = Layout.window.width;
const windowHeight = Layout.window.height;
const dimen = Layout.dimensions;

export const isIphoneX = () => {
  return (
    Platform.OS === 'ios' &&
    !Platform.isPad &&
    !Platform.isTVOS &&
    (windowHeight === 812 || windowWidth === 812 || windowHeight === 896 || windowWidth === 896) // 896 = XR and XS Max, 812 X and XS
  );
};

export const isAndroid = () => {
  return Platform.OS === 'android' && dimen;
};
export const getErrorStyle = (error: string | undefined) => {
  if (!error || undefined) {
    return noErrorStyle;
  } else if (error === 'Required') return requiredEnabledStyle;
  else return fieldValidationErrorStyle;
};

export const noErrorStyle = {
  display: 'none',
};

export const requiredEnabledStyle = {
  top: isIphoneX() ? '5%' : !isAndroid() ? '6%' : '0%',
};

export const fieldValidationErrorStyle = {
  top: isIphoneX() ? '5%' : !isAndroid() ? '4.5%' : '-4.1%',
};
