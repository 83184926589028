import { useState, useEffect, FC, useContext } from 'react';
import { PropertyDetailContext } from 'contexts/PropertyDetailContext';
import { Box, Text, HStack, Hidden } from 'native-base';
import { useTranslation } from 'react-i18next';
import iconAmenities from 'utils/iconAmenities';

const styles = {
  box: {
    bg: 'bgPage',
    ml: ['0px', '0px', '80px', '0px', '0px'],
    mr: ['0px', '0px', '80px', '0px', '0px'],
  },
  title: {
    mt: '48px',
    color: 'pdp.shadow',
    fontSize: '1xl',
    fontFamily: 'body600',
    mb: '14px',
  },
  text: {
    color: 'pdp.shadow',
    fontSize: '2md',
    lineHeight: '25px',
    mr: '10px',
  },
  cancellation: {
    color: 'pdp.shadow',
    fontSize: '2md',
    textDecorationLine: 'underline',
    ml: '29px',
    mt: '10px',
    mb: '52px',
  },
  hstack: {
    mt: '10px',
    alignItems: 'center',
    space: 2,
  },
  cancellationDetail: {
    color: 'pdp.shadow',
    fontSize: '2md',
    ml: '29px',
    mt: '10px',
    mb: '52px',
  },
};

interface IThingsToKnowProps {
  onCancellationPress: () => void;
}

const ThingsToKnow: FC<IThingsToKnowProps> = ({ onCancellationPress }) => {
  const { t } = useTranslation();
  const { listing } = useContext(PropertyDetailContext);
  const [propertyRules, setPropertyRules] = useState<string[]>([]);
  useEffect(() => {
    let houseRules: { [key: string]: any }[] = [];
    let rules: string[] = [];
    if (listing?.customFields) {
      houseRules = listing?.customFields?.filter((res) => res.key === 'House Rules');
      if (!!houseRules && houseRules.length > 0) {
        rules = houseRules[0].value.replace(/(\r\n|\n|\r)/gm, '').split('• ');
      }
      rules.push(`${t('PROPERTY_DETAIL.CANCELLATION')}`);
      setPropertyRules(rules);
    }
  }, [listing]);

  return (
    <Box {...styles.box}>
      <Box>
        <Text {...styles.title}>{t('PROPERTY_DETAIL.THINGS_TO_KNOW')}</Text>
        {iconAmenities(propertyRules).map((el) => {
          if (el.name !== '' && el.name) {
            return (
              <HStack {...styles.hstack} key={el.name}>
                <Box>{el.icon}</Box>
                <Text {...styles.text}>{el.name}</Text>
              </HStack>
            );
          }
        })}

        <Hidden from={'md'}>
          <Text onPress={onCancellationPress} {...styles.cancellation}>
            {t('PROPERTY_DETAIL.READ_CANCELLATION')}
          </Text>
        </Hidden>
        <Hidden till={'md'}>
          <Text {...styles.cancellationDetail}>{t('PROPERTY_DETAIL.CANCELLATION_POLICY_DETAIL')}</Text>
        </Hidden>
      </Box>
    </Box>
  );
};

export default ThingsToKnow;
