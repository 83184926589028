import { Box, ScrollView } from 'native-base';
import HomeEquityHeader from 'components/Home/HomeEquityHeader';
import InitialPayment from 'components/Home/HomeEquityDetail/InitialPayment';
import SubscriptionPayment from 'components/Home/HomeEquityDetail/SubscriptionPayment';
import TotalEquity from 'components/Home/HomeEquityDetail/TotalEquity';
import { useContext, useMemo } from 'react';
import { HomeEquityContext } from 'contexts/HomeEquityContext';
import { Loading } from 'components/Loading';

const styles = {
  mainView: {
    h: '100%',
    w: '100%',
    bg: 'styleSheet.canvas',
  },
  homeEuityDetail: {
    mx: ['16px', '16px', '20%', '30%', '30%'],
    pt: '40px',
    px: '24px',
  },
  totalEquityBox: {
    bg: 'styleSheet.lightStain',
    my: '24px',
    borderRadius: '12px',
    mx: ['16px', '16px', '20%', '30%', '30%'],
    px: '24px',
    pb: '24px',
  },
};

const HomeEquityScreen = () => {
  const { equityDetails, isLoading } = useContext(HomeEquityContext);
  const initialPayment = useMemo(() => equityDetails?.initialPayment, [equityDetails]);
  const fiftyPercentPayment = useMemo(() => equityDetails?.subscriptionTotal! / 2, [equityDetails]);
  const totalPayment = useMemo(() => initialPayment! + fiftyPercentPayment, [equityDetails]);
  const totalForYear1 = useMemo(() => equityDetails?.subscriptionPaidYear1, [equityDetails]);
  const totalForYear2 = useMemo(() => equityDetails?.subscriptionPaidYear2, [equityDetails]);
  const totalForYear3 = useMemo(() => equityDetails?.subscriptionPaidYear3, [equityDetails]);
  const totalSubscription = useMemo(() => equityDetails?.subscriptionTotal, [equityDetails]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Box safeAreaTop {...styles.mainView}>
      <HomeEquityHeader />
      <ScrollView>
        <Box {...styles.homeEuityDetail}>
          <InitialPayment initialPayment={initialPayment!.toString()} />
          <SubscriptionPayment
            totalForYear1={totalForYear1!.toString()}
            totalForYear2={totalForYear2!.toString()}
            totalForYear3={totalForYear3!.toString()}
            totalSubscription={totalSubscription!.toString()}
          />
        </Box>
        <Box {...styles.totalEquityBox}>
          <TotalEquity
            initialPayment={initialPayment!.toString()}
            fiftyPercentPayment={fiftyPercentPayment.toString()}
            totalEquityAmt={totalPayment.toString()}
          />
        </Box>
      </ScrollView>
    </Box>
  );
};

export default HomeEquityScreen;
