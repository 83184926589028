import { Box, Divider, Hidden, Text } from 'native-base';
import { H3 } from 'components/libs/heading/Heading';
import { useTranslation } from 'react-i18next';

const styles = {
  title: {
    fontSize: '1xl',
    color: 'styleSheet.shadow',
    fontFamily: 'body',
  },
  cancellation: {
    mt: '24px',
    color: 'styleSheet.shadow',
    fontFamily: 'body',
    fontSize: '2md',
    pb: ['0px', '0px', '0px', '30px'],
  },
  divider: {
    variant: 'primary',
    mt: '40px',
    mb: '40px',
  },
};

const CancelationPolicy = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <Divider {...styles.divider} />
      <H3 {...styles.title}>{t('CANCELLATION_POLICY')}</H3>
      <Text {...styles.cancellation}>{t('PROPERTY_DETAIL.CANCELLATION_POLICY_DETAIL')}</Text>
      <Hidden from={'lg'}>
        <Divider {...styles.divider} />
      </Hidden>
    </Box>
  );
};

export default CancelationPolicy;
