import { FC, useContext, useEffect } from 'react';
import {
  Box,
  CloseIcon,
  Divider,
  Hidden,
  HStack,
  Pressable,
  Text,
  useBreakpointValue,
  View,
  VStack,
} from 'native-base';
import { useTranslation } from 'react-i18next';
import GuestAddBar from 'components/Guests/GuestAddBar';
import CTAButton from 'components/libs/button/CTAButton';
import { ReservationContext } from 'contexts/reservationContext';
import Layout from 'constants/Layout';
import { useUpdateReservationMutation } from 'screens/Stays/mutation/updateReservation.generated';
import { NavigationContext } from 'contexts/NavigationContext';
import { BookingDetailContext } from 'contexts/BookingDetailContext';

const styles = {
  icon: {
    color: 'pdp.shadow',
    alignSelf: 'center' as const,
    size: '4',
  },
  page: {
    h: ['100%', '100%', '90%', '100%', '100%'],
    ml: ['0px', '0px', '40px', '32px', '32px'],
    mr: ['0px', '0px', '40px', '32px', '32px'],
  },
  divider: {
    w: ['110%', '110%', '108%', '108%', '106%'],
    ml: ['-17px', '-17px', '-20px', '-20px', '-17px'],
    mt: ['24px', '24px', '24px', '24px', '10px'],
    color: 'pdp.shadow',
    borderWidth: '1px',
    opacity: '0.15',
  },
  vstack: {
    mt: ['38px', '38px', '38px', '18px', '18px'],
  },
  addBar: {
    mt: ['23px', '23px', '20px', '16px', '16px'],
  },
  text: {
    fontSize: 'sm',
    fontFamily: 'body600',
    mb: ['16px', '16px', '6px', '6px', '6px'],
    color: 'styleSheet.shadow',
    opacity: '0.55',
  },
  stickyBar: {
    position: 'absolute' as const,
    bg: 'pdp.shadow',
    w: '100%',
    h: '70px',
    zIndex: 1,
    _text: {
      left: '-17px',
      fontSize: '2md',
      fontFamily: 'body600',
      color: 'styleSheet.canvas',
    },
    _pressed: {
      bg: 'styleSheet.overCast',
      _text: { color: 'styleSheet.canvas' },
    },
    _hover: {
      bg: 'styleSheet.darkStain',
      _text: { color: 'styleSheet.shadow' },
    },
  },
  buttonBox: {
    position: 'absolute' as const,
    bottom: 0,
    bg: 'black',
    left: '-17px',
    w: '118%',
    h: '125px',
    mb: '30px',
  },
  hstack: {
    justifyContent: 'space-between' as const,
    alignItems: 'center' as const,
    mt: '18px',
    mb: '18px',
  },
  bottomText: {
    fontSize: 'md',
  },
  error: {
    alignItems: 'center' as const,
    justifyContent: 'center' as const,
    bg: 'pdp.buttonBg',
    borderRadius: '8px',
    mt: '35px',
    w: '100%',
    h: '38px',
  },
  textError: {
    color: 'pdp.shadow',
    fontSize: 'sm',
    fontFamily: 'body600',
    opacity: '0.7',
  },
  title: {
    color: 'pdp.shadow',
    fontSize: '2md',
    alignSelf: 'center' as const,
    fontFamily: 'body600',
  },
  pressable: {
    h: '40px',
    w: '50px',
    justifyContent: 'center' as const,
    alignSelf: 'center' as const,
  },
  innerBox: {
    ml: '16px',
    mr: '16px',
    h: '100%',
    bg: 'styleSheet.canvas',
  },
  hstackHeader: {
    w: '100%',
    mt: ['31px', '31px', '31px', '15px', '15px'],
    justifyContent: 'space-between' as const,
    pl: ['0px', '0px', '0px', '30px', '30px'],
    pr: ['0px', '0px', '0px', '10px', '10px'],
  },
  bottonText: {
    color: 'pdp.shadow',
    fontSize: 'sm',
    fontFamily: 'body',
    alignSelf: 'center' as const,
  },
  textBox: {
    position: 'absolute' as const,
    top: [Layout.window.height * 0.58, Layout.window.height * 0.58, '60%', '60%', '63%'],
    left: 0,
    right: 0,
    pt: ['0px', '0px', '0px', '10px', '10px'],
  },
  modelButton: {
    bg: 'styleSheet.shadow',
    w: '100%',
    h: '70px',
    _text: {
      left: '-15px',
      fontSize: '2md',
      fontFamily: 'body600',
      color: 'styleSheet.canvas',
    },
    _pressed: {
      bg: 'styleSheet.shadow',
      opacity: '0.6',
      _text: { color: 'styleSheet.canvas' },
    },
    _hover: {
      bg: 'styleSheet.darkStain',
      _text: { color: 'styleSheet.shadow' },
    },
    _focus: {
      bg: 'styleSheet.shadow',
    },
    alignSelf: 'center' as const,
    position: 'absolute' as const,
    top: [null, null, '70%', '70%', '74%'],
  },
  mainView: {
    bg: 'styleSheet.canvas',
    w: ['100%', '100%', '80%', '50%', '40%'],
    h: '100%',
    borderRadius: [0, 0, 20, 20, 20],
    borderWidth: ['0px', '0px', '1px', '1px', '1px'],
    borderColor: 'styleSheet.canvas',
    alignSelf: 'center' as const,
  },
  dividerForBigScreen: {
    w: '100%',
    mt: '16px',
    color: 'styleSheet.darkStain',
    borderWidth: '0.5',
    opacity: '0.15',
  },
  dividerForInfants: {
    w: '100%',
    mt: ['0px', '0px', '20px', '16px', '16px'],
    color: 'styleSheet.darkStain',
    borderWidth: '0.5',
    opacity: '0.15',
  },
  addBarInfants: {
    mt: ['0px', '0px', '16px', '16px', '16px'],
  },
};

interface IUpdateReservation {
  guestyReservationId: string | undefined;
  guestGroupComposition: {
    adults: number;
    children: number;
    pets: number;
    infants: number;
  };
}

interface IEditGuestModalProps {
  setShowModal: (state: boolean) => void;
}

const EditGuestModal: FC<IEditGuestModalProps> = ({ setShowModal }) => {
  let breakpointTypeValue = useBreakpointValue({ base: 1, sm: 2, md: 3, lg: 4, xl: 5 });
  const { useNavigation } = useContext(NavigationContext);
  const { guests: inputGuests, listing, reservationId } = useContext(BookingDetailContext);
  const { t } = useTranslation();
  const [updateResetvation, { error }] = useUpdateReservationMutation();

  const {
    totalGuest,
    setTotalGuest,
    setGuests,
    guests,
    addAdultHandler,
    addChildrenHandler,
    addInfantHandler,
    addPetHandler,
    removeChildrenHandler,
    removeInfantHandler,
    removePetHandler,
    removeAdultHandler,
  } = useContext(ReservationContext);

  useEffect(() => {
    setGuests(inputGuests!);
    if (!!listing) {
      setTotalGuest(listing.accommodates!);
    }
  }, [inputGuests, listing]);

  const handleError = (e: any) => {
    if (__DEV__) console.log('update guest error', e);
  };

  const handleUpdateReservation = (values: IUpdateReservation) => {
    updateResetvation({
      variables: {
        data: {
          ...values,
        },
      },
      onCompleted: () => handleCloseModal(),
      onError: () => handleError(error),
    });
  };

  const handleCloseModal = () => {
    if (breakpointTypeValue == 3 || breakpointTypeValue == 4 || breakpointTypeValue == 5) {
      setShowModal(false);
    } else {
      useNavigation();
    }
  };

  const handleUpdateGuest = () => {
    handleUpdateReservation({
      guestyReservationId: reservationId!,
      guestGroupComposition: {
        adults: guests.adults,
        children: guests.children,
        pets: guests.pets,
        infants: guests.infants,
      },
    });
  };

  const guestCount = guests.adults + guests.children;
  const opacity = guestCount === listing?.accommodates ? 0.4 : 1;
  const minusOpacity = guests.adults === 1 ? 0.4 : 1;
  const petOpacity = guests.pets === 2 ? 0.4 : 1;

  let maxGuestError;
  if (guestCount === listing?.accommodates) {
    maxGuestError = (
      <Box {...styles.error}>
        <Text {...styles.textError}>{t('THIS_HOME_HAS', { totalGuest })}</Text>
      </Box>
    );
  }

  return (
    <Box {...styles.mainView} safeAreaTop safeAreaBottom>
      <Box {...styles.innerBox}>
        <HStack {...styles.hstackHeader}>
          <Text {...styles.title}>{t('CHANGE_GUESTS')}</Text>
          <Pressable onPress={handleCloseModal} {...styles.pressable}>
            <CloseIcon {...styles.icon} />
          </Pressable>
        </HStack>
        <Divider {...styles.divider} />
        <View {...styles.page}>
          <VStack {...styles.vstack}>
            <View>
              <GuestAddBar
                guest={guests.adults}
                translationKey="ADULTS"
                addHandler={addAdultHandler}
                removeHandler={removeAdultHandler}
                opacity={opacity}
                minusOpacity={minusOpacity}
              />
              <Hidden till={'md'}>
                <Divider {...styles.dividerForBigScreen} />
              </Hidden>
              <GuestAddBar
                guest={guests.children}
                addHandler={addChildrenHandler}
                removeHandler={removeChildrenHandler}
                translationKey="CHILDREN"
                opacity={opacity}
                {...styles.addBar}
              />
              <Hidden from={'md'}>
                <Text {...styles.text}>{t('AGES')}</Text>
              </Hidden>
              <Hidden till={'md'}>
                <Divider {...styles.dividerForInfants} />
              </Hidden>
              <GuestAddBar
                guest={guests.infants}
                addHandler={addInfantHandler}
                removeHandler={removeInfantHandler}
                translationKey="INFANTS"
                {...styles.addBarInfants}
              />
              <Hidden from={'md'}>
                <Text {...styles.text}>{t('UNDER2')}</Text>
              </Hidden>
              <Hidden till={'md'}>
                <Divider {...styles.dividerForInfants} />
              </Hidden>
              <GuestAddBar
                guest={guests.pets}
                addHandler={addPetHandler}
                removeHandler={removePetHandler}
                translationKey="PETS"
                opacity={petOpacity}
                {...styles.addBarInfants}
              />
            </View>
            {maxGuestError}
          </VStack>
          <Box {...styles.textBox}>
            <Text {...styles.bottonText}>{t('YOU_CAN_ALWAYS')}</Text>
            <Text {...styles.bottonText}>{t('YOU_BOOK_THIS_TRIP')}</Text>
          </Box>

          <Hidden till={'md'}>
            <CTAButton children={t('UPDATE_GUESTS')} onPress={handleUpdateGuest} {...styles.modelButton} />
          </Hidden>

          <Hidden from={'md'}>
            <Box {...styles.buttonBox}>
              <CTAButton children={t('UPDATE_GUESTS')} onPress={handleUpdateGuest} {...styles.stickyBar} />
            </Box>
          </Hidden>
        </View>
      </Box>
    </Box>
  );
};

export default EditGuestModal;
