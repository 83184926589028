import { useContext } from 'react';
import { Pressable, Avatar } from 'native-base';
import { NavigationContext } from 'contexts/NavigationContext';
import BackIcon from '../../assets/icons/Back-Arrow-White.svg';

const styles = {
  pressable: {
    mt: ['16px', '16px', '80px', '84px', '84px'],
    ml: ['16px', '16px', '80px', '80px', '80px'],
    w: '50px',
    h: '50px',
  },

  avatar: {
    bg: 'styleSheet.shadow',
    size: 'md',
  },
};

const BackArrow = () => {
  const { useNavigation } = useContext(NavigationContext);

  return (
    <Pressable onPress={() => useNavigation()} testID="button" {...styles.pressable}>
      <Avatar {...styles.avatar}>
        <BackIcon />
      </Avatar>
    </Pressable>
  );
};

export default BackArrow;
