import { useState, useContext } from 'react';
import { Box, CloseIcon, HStack, Pressable, Text } from 'native-base';
import { RootStackScreenProps } from 'types';
import { PropertyDetailContext } from 'contexts/PropertyDetailContext';
import ImageSlider from '../ImageSlider/ImageSlider';

const styles = {
  box: {
    backgroundColor: 'customBlack',
    height: '48px',
    justifyContent: 'center',
  },
  icon: {
    color: 'pdp.shadow',
    alignSelf: 'center',
    size: '4',
  },
  innerBox: {
    ml: ['16px', '16px', '16px', '0px', '0px'],
    mr: ['16px', '16px', '16px', '0px', '0px'],
    h: '100%',
    bg: 'bgPage',
    mt: ['0px', '0px', '80px', '40px', '40px'],
    pl: ['0px', '0px', '40px', '40px', '40px'],
    pr: ['0px', '0px', '40px', '40px', '40px'],
  },
  pressable: {
    justifyContent: 'center',
    alignSelf: 'center',
    p: '20px',
    _hover: {
      bg: 'styleSheet.canvas',
      borderColor: 'styleSheet.darkStain',
      borderWidth: '1px',
      borderRadius: '12px',
    },
  },
  title: {
    color: 'pdp.shadow',
    fontSize: ['2md', '2md', 'xl', 'xl', 'xl'],
    fontFamily: 'body600',
    alignSelf: 'center',
  },
  hstack: {
    w: '100%',
    mt: ['31px', '31px', '70px', '70px', '70px'],
    justifyContent: 'space-between',
    mb: ['0px', '0px', '40px', '40px', '40px'],
  },

  container: {
    flex: 0.96,
    bgColor: 'styleSheet.canvas',
  },

  pagination: {
    alignSelf: 'center',
    position: 'absolute' as const,
    top: [null, null, '70px', '70px', '70px'],
    bottom: ['20px', '20px', null, null, null],
    fontFamily: 'body',
    fontSize: 'sm',
  },
  captionBox: {
    alignSelf: 'center',
    position: 'absolute' as const,
    bottom: '40px',
    fontFamily: 'body',
    fontSize: 'sm',
  },
};

interface IPictures {
  _typename?: string;
  _id: string;
  original: string;
  size: number;
  thumbnail: string;
  width: number;
  caption?: string | null | undefined;
}

const PhotoSliderModal: React.FC<RootStackScreenProps<'PhotoSliderModal'>> = ({ navigation }) => {
  const { listing } = useContext(PropertyDetailContext);

  const [index, setIndex] = useState<number>(1);

  let listingPictures: IPictures[] = [];
  if (listing?.pictures) {
    listingPictures = listing?.pictures;
  }

  const imagesArray = listingPictures.map((el) => {
    return el.original;
  });

  return (
    <Box {...styles.container} safeAreaTop safeAreaBottom>
      <Box {...styles.innerBox}>
        <HStack {...styles.hstack}>
          <Text {...styles.title}>{listing?.address?.city}</Text>
          <Pressable onPress={() => navigation.goBack()} {...styles.pressable}>
            <CloseIcon {...styles.icon} />
          </Pressable>
        </HStack>
        <ImageSlider imagesArray={imagesArray} setIndex={setIndex} />
        <Text {...styles.pagination}>
          {index} / {imagesArray.length}
        </Text>
        {listingPictures[index] && <Text {...styles.captionBox}>{listingPictures[index].caption}</Text>}
      </Box>
    </Box>
  );
};

export default PhotoSliderModal;
