import BackArrow from 'components/ConfirmationPage/BackArrow';
import { NavigationContext } from 'contexts/NavigationContext';
import { Box, Hidden, HStack, Pressable, Text, View } from 'native-base';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import BackIcon from '../../assets/icons/Back-Arrow.svg';

const styles = {
  box: {
    pt: '26px',
    pb: '26px',
    pl: '10px',
  },
  hstack: {
    alignItems: 'center',
    justifyContent: 'center',
    w: '100%',
  },
  pressable: {
    position: 'absolute' as const,
    left: 0,
  },
  title: {
    color: 'pdp.shadow',
    fontSize: ['2md', '2md', '1xl', '1xl', '1xl'],
    fontFamily: 'body600',
    alignSelf: 'center' as const,
  },
  arrowBox: {
    mt: '24px',
  },
  hr: {
    w: '100%',
    borderWidth: 1,
    borderColor: 'styleSheet.overCast',
    opacity: '0.15',
  },
  backButton: {
    mt: '24px',
  },
};

const HomePickerHeader = () => {
  const { useNavigation } = useContext(NavigationContext);
  const { t } = useTranslation();

  return (
    <Box>
      <Hidden from={'md'}>
        <Box>
          <Box {...styles.box}>
            <HStack {...styles.hstack}>
              <Pressable
                {...styles.pressable}
                onPress={() => useNavigation('MyHome', { screen: 'MyHomeScreen' })}
                testID="homePickerHeaderTestId">
                <BackIcon />
              </Pressable>
              <Text {...styles.title}>{t('MULTI_HOME.SELECT_HOME_ACCOUNT')}</Text>
            </HStack>
          </Box>
          <View {...styles.hr} />
        </Box>
      </Hidden>

      <Hidden till={'md'}>
        <Box {...styles.backButton}>
          <BackArrow />
          <Text {...styles.title}>{t('MULTI_HOME.SELECT_HOME_ACCOUNT')}</Text>
        </Box>
      </Hidden>
    </Box>
  );
};

export default HomePickerHeader;
