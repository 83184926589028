import { Hidden, View } from 'native-base';
import { Platform, TouchableOpacity } from 'react-native';
import Image from 'assets/images/Logo-Smaller-Shadow.svg';
import { InterfaceViewProps } from 'native-base/src/components/basic/View/types';
import { FC } from 'react';

const viewContainer: InterfaceViewProps & React.RefAttributes<unknown> = {
  position: 'absolute',
  left: [null, null, '40px', '64px', '64px'],
  top: -11,
};

const style = {
  tabletLogo: {
    position: 'absolute',
    left: '3%',
    top: -8,
  },
};

export const SummerLogo: FC = () => {
  //TODO - try to remove the condition rendring in future
  const logoDisplayCondition = !!window?.location
    ? window.location.pathname !== '/Landing' &&
      window.location.pathname !== '/' &&
      window.location.pathname !== '/SignUp'
    : false;

  const onPress = () => {
    // @ts-ignore
    if (!!window?.location) {
      // @ts-ignore
      window.location.href = '/Booking';
    }
  };
  return (
    <Hidden till={'md'}>
      {Platform.OS == 'web' ? (
        <View {...viewContainer}>
          {logoDisplayCondition ? (
            <TouchableOpacity onPress={onPress}>
              <Image height={100} width={100} />
            </TouchableOpacity>
          ) : null}
        </View>
      ) : (
        <Image height={100} width={100} {...style.tabletLogo} />
      )}
    </Hidden>
  );
};
