import { Feather } from '@expo/vector-icons';
import DishWasherIcon from '../assets/icons/Dishwasher.svg';
import TvIcon from '../assets/icons/TV.svg';
import WifiIcon from '../assets/icons/Wifi.svg';
import CoffeeMakerIcon from '../assets/icons/Coffee-Maker.svg';
import CookwareIcon from '../assets/icons/Cookware.svg';
import BedLineneIcon from '../assets/icons/Bed-Linene.svg';
import HangersIcon from '../assets/icons/Hangers.svg';
import LuggageDropoffIcon from '../assets/icons/Luggage-Dropoff.svg';
import TowelsIcon from '../assets/icons/Towels.svg';
import ShowerEssentialsIcon from '../assets/icons/Shower-Essentials.svg';
import FreeParkingIcon from '../assets/icons/Free-Parking.svg';
import KidFriendlyIcon from '../assets/icons/Kid-Friendly.svg';
import WorkspaceIcon from '../assets/icons/Workspace.svg';
import DishwareIcon from '../assets/icons/Dishware.svg';
import CheckinCancellationIcon from '../assets/icons/Checkin-Cancellation.svg';
import NoPartiesIcon from '../assets/icons/No-Parties.svg';
import NoSmokingIcon from '../assets/icons/No-Smoking.svg';
import PetsAllowedIcon from '../assets/icons/Pets-Allowed.svg';
import DefaultIcon from '../assets/icons/Default.svg';

const iconAmenities = (mergedAmenities: string[]) => {
  let iconedAmenities: { name: string; icon: string | JSX.Element }[] = [];
  mergedAmenities.forEach((v: string) => {
    switch (v) {
      case 'Dishwasher':
        return iconedAmenities.push({
          name: v,
          icon: <DishWasherIcon />,
        });
      case 'TV or CableTV':
        return iconedAmenities.push({
          name: v,
          icon: <TvIcon />,
        });
      case 'Pocket Wifi':
        return iconedAmenities.push({
          name: v,
          icon: <WifiIcon />,
        });
      case 'Coffee maker':
        return iconedAmenities.push({
          name: v,
          icon: <CoffeeMakerIcon />,
        });
      case 'Dishes and silverware':
        return iconedAmenities.push({
          name: v,
          icon: <DishwareIcon />,
        });
      case 'Cookware':
        return iconedAmenities.push({
          name: v,
          icon: <CookwareIcon />,
        });
      case 'Bed linens':
        return iconedAmenities.push({
          name: v,
          icon: <BedLineneIcon />,
        });
      case 'Hangers':
        return iconedAmenities.push({
          name: v,
          icon: <HangersIcon />,
        });
      case 'Luggage dropoff allowed':
        return iconedAmenities.push({
          name: v,
          icon: <LuggageDropoffIcon />,
        });
      case 'Laptop friendly workspace':
        return iconedAmenities.push({
          name: v,
          icon: <WorkspaceIcon />,
        });
      case 'Towels provided':
        return iconedAmenities.push({
          name: v,
          icon: <TowelsIcon />,
        });
      case 'Essentials':
        return iconedAmenities.push({
          name: v,
          icon: <ShowerEssentialsIcon />,
        });
      case 'Free parking on premises':
        return iconedAmenities.push({
          name: v,
          icon: <FreeParkingIcon />,
        });
      case 'Family/kid friendly':
        return iconedAmenities.push({
          name: v,
          icon: <KidFriendlyIcon />,
        });
      case 'Check-in: After 4:00 PM':
        return iconedAmenities.push({
          name: v,
          icon: <CheckinCancellationIcon />,
        });
      case 'Checkout: 11:00 AM':
        return iconedAmenities.push({
          name: v,
          icon: <CheckinCancellationIcon />,
        });
      case 'Full refund if cancelled 2 days before check-in':
        return iconedAmenities.push({
          name: v,
          icon: <CheckinCancellationIcon />,
        });
      case 'Pets are allowed':
        return iconedAmenities.push({
          name: v,
          icon: <PetsAllowedIcon />,
        });
      case 'No parties or events':
        return iconedAmenities.push({
          name: v,
          icon: <NoPartiesIcon />,
        });
      case 'No smoking':
        return iconedAmenities.push({
          name: v,
          icon: <NoSmokingIcon />,
        });
      default:
        return iconedAmenities.push({
          name: v,
          icon: <DefaultIcon />,
        });
    }
  });

  return iconedAmenities;
};

export default iconAmenities;
