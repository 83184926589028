import { FC, useContext } from 'react';
import { Box, Button, Divider, Hidden, HStack, Pressable, Text } from 'native-base';
import { PropertyDetailContext } from 'contexts/PropertyDetailContext';
import { useTranslation } from 'react-i18next';
import { CustomFields } from 'src/generated/types';

const styles = {
  box: {
    bg: 'bgPage',
    ml: ['0px', '0px', '80px', '0px', '0px'],
    mr: ['0px', '0px', '80px', '0px', '0px'],
  },
  upperText: {
    textAlign: 'center' as const,
    fontSize: 'sm',
    pt: '8px',
    pb: '8px',
    pl: '16px',
    pr: '16px',
  },

  textBox: {
    borderRadius: '8px',
    bg: 'pdp.buttonBg',
  },

  upperHstack: {
    mt: '40px',
    mb: '24px',
  },

  address: {
    fontSize: '2.5xl',
    color: 'pdp.shadow',
    lineHeight: '31px',
    mb: '16px',
  },
  dot: {
    color: 'styleSheet.darkStain',
  },
  accommodateText: {
    fontSize: 'sm',
    color: 'pdp.shadow',
    fontFamily: 'body600',
  },
  accommodateHstack: {
    alignItems: 'center',
    mb: '40px',
  },
  description: {
    color: 'pdp.shadow',
    fontSize: '2md',
    lineHeight: '25.5px',
    numberOfLines: 5,
    mb: '24px',
  },
  button: {
    bg: 'styleSheet.canvas',
    borderWidth: '1',
    h: '46px',
    borderRadius: '8px',
    borderColor: 'styleSheet.lightOverCast',
    _text: {
      color: 'styleSheet.lightOverCast',
      fontSize: 'sm',
      fontFamily: 'body600',
    },
    _pressed: {
      bg: 'styleSheet.shadow',
      opacity: '0.6',
      _text: { color: 'styleSheet.canvas' },
    },
    _hover: {
      bg: 'styleSheet.overCast',
      _text: { color: 'styleSheet.lightStain' },
    },
    _focus: {
      bg: 'styleSheet.canvas',
    },
    w: ['100%', '100%', '135px', '103px', '103px'],
  },
  divider: {
    borderWidth: '1',
    borderColor: 'pdp.shadow',
    opacity: '0.15',
  },
  buttonClickZone: {
    w: ['100%', '100%', '151px', '119px', '119px'],
    mb: ['0px', '0px', '48px', '48px', '48px'],
    p: '8px',
  },
};
interface IPropertyOverviewProps {
  onPropertyOverview: () => void;
}

const PropertyOverview: FC<IPropertyOverviewProps> = ({ onPropertyOverview }) => {
  const { listing } = useContext(PropertyDetailContext);
  const { t } = useTranslation();

  let nickname: string | null | undefined = '';
  let propNickname: CustomFields[] = [];
  if (listing?.customFields) {
    propNickname = listing?.customFields?.filter((res) => res.key === 'DND_Summer App Nickname') || [];
    if (propNickname.length > 0) {
      nickname = propNickname[0].value;
    }
  }

  return (
    <Box {...styles.box}>
      <Box>
        <HStack {...styles.upperHstack}>
          <Box {...styles.textBox}>
            <Text {...styles.upperText}>
              {listing?.address?.city}, {listing?.address?.state}
            </Text>
          </Box>
        </HStack>
        <Text {...styles.address}>{nickname}</Text>
        <HStack {...styles.accommodateHstack}>
          <Text {...styles.accommodateText}>{`${listing?.accommodates} ${t('PROPERTY_DETAIL.GUESTS')} `}</Text>
          <Text {...styles.dot}>•</Text>
          <Text {...styles.accommodateText}>{` ${listing?.bedrooms} ${t('PROPERTY_DETAIL.BEDROOMS')} `}</Text>
          <Text {...styles.dot}>•</Text>
          <Text {...styles.accommodateText}>{` ${listing?.beds} ${t('PROPERTY_DETAIL.BED')} `}</Text>
          <Text {...styles.dot}>•</Text>
          <Text {...styles.accommodateText}>{` ${listing?.bathrooms} ${t('PROPERTY_DETAIL.BATH')} `}</Text>
        </HStack>
        {listing?.publicDescription?.summary && (
          <Box>
            <Text {...styles.description}>{listing?.publicDescription?.summary}</Text>
            <Pressable {...styles.buttonClickZone} onPress={onPropertyOverview}>
              <Button {...styles.button} onPress={onPropertyOverview}>
                {t('PROPERTY_DETAIL.READ_MORE')}
              </Button>
            </Pressable>
          </Box>
        )}

        <Hidden till={'md'}>
          <Divider {...styles.divider} />
        </Hidden>
      </Box>
    </Box>
  );
};

export default PropertyOverview;
