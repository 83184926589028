import { FC } from 'react';
import { ScrollView, View, Box } from 'native-base';

const styles = {
  container: {
    h: '100%',
  },
  page: {
    mb: ['98px', '98px', '98px', '298px', '298px'],
    h: ['150%', '150%', '150%', null, null],
  },
  icon: {
    overflow: 'hidden',
    color: 'styleSheet.canvas',
    size: '6',
    alignSelf: 'center',
  },
  iconCircle: {
    borderRadius: 20,
    bg: 'styleSheet.shadow',
    h: '30px',
    w: '30px',
    justifyContent: 'center',
    position: 'absolute' as const,
    left: '0',
    mt: '30px',
  },
  stickyBar: {
    position: 'absolute' as const,
    bottom: '0',
    w: '100%',
    h: '93px',
    bg: 'white',
    zIndex: 1,
    borderTopWidth: '2',
    borderColor: 'borderColor',
  },
  pressable: {
    justifyContent: 'center',
  },
};

interface Props {
  children: React.ReactNode;
}

export const LayoutWithNavigationIcon: FC<Props> = ({ children }) => {
  return (
    <Box safeAreaTop backgroundColor="bgPage" {...styles.container}>
      <ScrollView>
        <View {...styles.page}>{children}</View>
      </ScrollView>
    </Box>
  );
};
