import { VictoryPie } from 'victory';

interface IPieChartProps {
  graphicColors: string[];
  graphicData: string[];
}

const PieChart = ({
  graphicColors,
  graphicData,
}: IPieChartProps) => {
  return (
    <VictoryPie
      colorScale={graphicColors}
      cornerRadius={15}
      data={graphicData}
      innerRadius={170}
      padAngle={2}
      padding={0}
      style={{
        labels: {
          display: 'none',
        },
      }}
    />
  );
};

export default PieChart;
