import { Pressable, Text } from 'native-base';

const styles = {
  pressable: {
    mt: '5',
    ml: '2',
  },
  text: {
    fontSize: '40',
    fontWeight: '700',
    color: 'white',
  },
};

interface INavPressableProps {
  children: string;
  onPress: () => void;
  textStyle?: object;
}

const NavPressable = ({ children, onPress, textStyle }: INavPressableProps) => {
  const textStyles = textStyle ? textStyle : styles.text;
  return (
    <Pressable onPress={onPress} {...styles.pressable}>
      <Text {...textStyles}>{children}</Text>
    </Pressable>
  );
};

export default NavPressable;
