import { FC, useContext } from 'react';
import { ApolloClient, ApolloProvider, from, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { AuthContext } from 'contexts/authContext';
import { onError } from '@apollo/client/link/error';
import Constants from 'expo-constants';
import { SentryService } from '../services/SentryService';

interface CoreApiClientProviderProps {
  children: React.ReactNode;
}

export const CoreApiClientProvider: FC<CoreApiClientProviderProps> = ({ children }) => {
  const { getAuth0Token } = useContext(AuthContext);

  const authMiddleware = setContext(async (operation) => {
    try {
      const token = await getAuth0Token();
      if (token) {
        return {
          headers: {
            authorization: `Bearer ${token}`,
          },
        };
      }
    } catch (e) {
      throw e;
    }
    return {};
  });

  const loggerLink = setContext(async (operation) => {
    const operationType = operation.query.definitions[0]?.operation;
    SentryService.addBreadcrumb({
      message: operation.operationName,
      category: 'network',
      type: 'network',
      data: operation.variables,
    });
    if (__DEV__)
      console.log(`[apollo] ${operationType} '${operation.operationName}' var: ${JSON.stringify(operation.variables)}`);
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    const errorMessage = graphQLErrors?.map((e) => e.message).join(', ') || networkError?.message || 'unknown error';
    SentryService.captureException(errorMessage, {
      extra: {
        graphQLErrors,
        networkError,
      },
    });
  });

  const getCoreApiClient = () => {
    return new ApolloClient({
      link: from([
        loggerLink,
        errorLink,
        authMiddleware,
        new HttpLink({ uri: Constants?.expoConfig?.extra?.coreApiEndpoint }),
      ]),
      cache: new InMemoryCache(),
    });
  };

  return <ApolloProvider client={getCoreApiClient()}>{children}</ApolloProvider>;
};
