import { Box, Image, ScrollView, Divider, Hidden, HStack, VStack } from 'native-base';
import AnnualSubscriptionDetails from 'components/Home/AnnualSubscriptionDetails';
import EarnedEquityDetails from 'components/Home/EarnedEquityDetails';
import AspectRatio from 'components/AspectRatio';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import SeeHomeDetail from 'components/Home/SeeHomeDetail';
import { HomeEquityContext } from 'contexts/HomeEquityContext';
import { Loading } from 'components/Loading';
import SwitchHome from 'components/Home/SwitchHome';
import { MultiSubscriptionsContext } from 'contexts/MultiSubscriptionsContext';
import { usePersistentValue } from 'hooks/usePersistentValue';
import { RootStackScreenProps } from 'types';
import HomeRevenueDetails from 'components/Home/HomeRevenueDetails';
import { SubscriptionContext } from 'contexts/SubscriptionContext';

const styles = {
  mainView: {
    bg: 'styleSheet.canvas',
    h: '100%',
    mb: ['10%', '10%', 0, 0, 0],
    w: '100%',
  },
  contentContainer: {
    bg: 'styleSheet.canvas',
    borderTopRadius: '24px',
    marginTop: ['-10%', '-10%', '-5%', 0, 0],
    pb: ['32px', '32px', '32px', '80px', '80px'],
    px: ['16px', '16px', '80px', '84px', '84px'],
    w: '100%',
    // Max width of card (932px) + padding
    maxW: '1100px',
  },
  divider: {
    w: '100%',
    color: 'styleSheet.darkStain',
    borderWidth: 0.5,
    opacity: '0.15',
  },
  boxEarnedEquity: {
    alignSelf: 'center' as const,
    pb: ['56px', '56px', '48px', 0, 0],
    w: ['100%', '100%', '45%', 0, 0],
  },
  boxForLargeScreen: {
    flexDirection: 'row' as const,
    bg: 'styleSheet.lightStain',
    overflow: 'hidden',
    alignSelf: 'center' as const,
    borderRadius: '24px',
    w: '100%',
  },
  imageRatio: (imageRatioForDesktop: boolean) => ({
    ratio: [
      16 / 10,
      16 / 10,
      16 / 10,
      imageRatioForDesktop ? 10 / 10 : 14 / 10,
      imageRatioForDesktop ? 12 / 10 : 14 / 10,
    ],
  }),
  topButtons: {
    justifyContent: 'space-between' as const,
    p: ['16px', '16px', '24px', '24px', '24px'],
    position: 'absolute' as const,
    w: '100%',
    zIndex: 1,
  },
  topButtonLarge: {
    justifyContent: 'flex-start' as const,
    p: ['16px', '16px', '24px', '24px', '24px'],
    w: '100%',
    zIndex: 1,
  },
  boxForLargeSubscriptionDetails: {
    pt: '80px',
  },
  boxForImage: {
    w: '60%',
  },
  largeHomeDetailWrapper: {
    bottom: 0,
    p: '24px',
    position: 'absolute' as const,
  },
  largeHomeSwitchWrapper: {
    py: '24px',
  },
  subscriptionDetailsContainer: {
    pt: [0, 0, '40px', 0, 0],
  },
  scrollView: {
    pt: [null, null, '80px', '80px', '80px'],
  },
};

const MyHomeScreen: FC<RootStackScreenProps<'MyHomeScreen'>> = () => {
  const { equityDetails, isLoading: areEquityDetailsLoading } = useContext(HomeEquityContext);
  const { subscriptionsList, isLoading: areMultiSubscriptionsLoading } = useContext(MultiSubscriptionsContext);
  const { subscriptionBalance, isLoading: isSubscriptionLoading } = useContext(SubscriptionContext);

  const [imageRatioForDesktop, setImageRatioForDesktop] = useState(false);

  const [storedSelectedHome] = usePersistentValue<string | null>('storedSelectedHome', null);

  const initialPayment = useMemo(() => equityDetails?.initialPayment, [equityDetails]);
  const subscriptionTotal = useMemo(() => equityDetails?.subscriptionTotal! / 2, [equityDetails]);
  const earnEquityAmount = useMemo(() => initialPayment! + subscriptionTotal!, [equityDetails]);
  const usedSubscriptionAmount = useMemo(() => subscriptionBalance?.usedAmount, [subscriptionBalance]);
  const remainingSubscriptionAmount = useMemo(() => subscriptionBalance?.remainingAmount, [subscriptionBalance]);
  const totalSubscriptionAmount = useMemo(() => subscriptionBalance?.totalAmount, [subscriptionBalance]);
  const thumbnailUrl = useMemo(() => equityDetails?.thumbnail?.replace(/h_(\d+)/, 'h_700'), [equityDetails]);
  const userType = useMemo(() => equityDetails?.type, [equityDetails]);

  useEffect(() => {
    if (subscriptionsList?.length! > 1) {
      setImageRatioForDesktop(true);
    } else {
      setImageRatioForDesktop(false);
    }
  }, [subscriptionsList]);

  if (areEquityDetailsLoading || areMultiSubscriptionsLoading || isSubscriptionLoading) {
    return <Loading />;
  }

  const homeFinancialDetailsComponent =
    userType === 'immediate' ? (
      <HomeRevenueDetails homeId={equityDetails?.homeId!} selectedHome={storedSelectedHome} />
    ) : (
      <EarnedEquityDetails
        totalEarnedEquity={earnEquityAmount.toString()}
        initialPayment={initialPayment!.toString()}
        subscriptionTotal={subscriptionTotal.toString()}
        selectedHome={storedSelectedHome}
      />
    );

  return (
    <Box safeAreaTop {...styles.mainView}>
      <ScrollView {...styles.scrollView} contentContainerStyle={{ alignItems: 'center' }}>
        <Hidden from={'lg'}>
          <>
            <HStack {...styles.topButtons}>
              {subscriptionsList?.length! > 1 ? <SwitchHome /> : null}
              {userType === 'immediate' ? null : <SeeHomeDetail homeId={equityDetails?.homeId!} />}
            </HStack>

            <AspectRatio minWidth={'100%'} maxWidth={'100%'} {...styles.imageRatio}>
              {equityDetails?.thumbnail && (
                <Image source={{ uri: thumbnailUrl }} resizeMode="cover" alt={equityDetails?.thumbnail} />
              )}
            </AspectRatio>

            <VStack {...styles.contentContainer}>
              <Box {...styles.boxEarnedEquity}>{homeFinancialDetailsComponent}</Box>
              <Hidden only={['base', 'sm']}>
                <Divider {...styles.divider} />
              </Hidden>
              <Box {...styles.subscriptionDetailsContainer}>
                <AnnualSubscriptionDetails
                  usedSubscriptionAmount={usedSubscriptionAmount!}
                  remainingSubscriptionAmount={remainingSubscriptionAmount!}
                  totalSubscriptionAmount={totalSubscriptionAmount!}
                />
              </Box>
            </VStack>
          </>
        </Hidden>
        <Hidden only={['base', 'sm', 'md']}>
          <VStack {...styles.contentContainer}>
            <Box {...styles.largeHomeSwitchWrapper}>{subscriptionsList?.length! > 1 ? <SwitchHome /> : null}</Box>

            <Box {...styles.boxForLargeScreen}>
              <Box {...styles.boxForImage}>
                <AspectRatio minWidth={'100%'} maxWidth={'100%'} {...styles.imageRatio(imageRatioForDesktop)}>
                  {equityDetails?.thumbnail && (
                    <Image source={{ uri: thumbnailUrl }} resizeMode="cover" alt={equityDetails?.thumbnail} />
                  )}
                </AspectRatio>
              </Box>

              {homeFinancialDetailsComponent}

              <Box {...styles.largeHomeDetailWrapper}>
                {userType === 'immediate' ? null : <SeeHomeDetail homeId={equityDetails?.homeId!} />}
              </Box>
            </Box>

            <Box {...styles.boxForLargeSubscriptionDetails}>
              <AnnualSubscriptionDetails
                usedSubscriptionAmount={usedSubscriptionAmount!}
                remainingSubscriptionAmount={remainingSubscriptionAmount!}
                totalSubscriptionAmount={totalSubscriptionAmount!}
              />
            </Box>
          </VStack>
        </Hidden>
      </ScrollView>
    </Box>
  );
};

export default MyHomeScreen;
