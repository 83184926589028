import { FC } from 'react';
import { WebView } from 'react-native-webview';
import { Modal, CloseIcon, HStack, Pressable, Text, View } from 'native-base';

interface ITermsConditionModalProps {
  uri: string;
  title: string;
  open: boolean;
  close: () => void;
}

const styles = {
  container: {
    bgColor: 'styleSheet.canvas',
  },
  body: {
    width: '100%',
    height: '100%',
  },
  main: {
    alignItems: 'center',
  },
  text: {
    pl: '36px',
    pt: '28px',
    pb: '24px',
    fontSize: '2md',
    fontFamily: 'body600',
    color: 'textStay',
  },
  icon: {
    color: 'styleSheet.shadow',
    alignSelf: 'center',
    size: '4',
  },
  pressable: {
    h: '50px',
    w: '50px',
    pr: '16px',
    position: 'absolute' as const,
    right: '0',
    zIndex: 1,
    justifyContent: 'center',
  },
};

const TermsConditionWebview: FC<ITermsConditionModalProps> = ({ open, close, uri, title }) => {
  return (
    <Modal isOpen={open} {...styles.container} safeAreaTop>
      <View {...styles.body}>
        <HStack {...styles.main}>
          <Text {...styles.text}>{title}</Text>
          <Pressable onPress={close} {...styles.pressable}>
            <CloseIcon {...styles.icon} />
          </Pressable>
        </HStack>
        <WebView source={{ uri: uri }} />
      </View>
    </Modal>
  );
};

export default TermsConditionWebview;
