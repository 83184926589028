import { createNativeStackNavigator } from '@react-navigation/native-stack';
import UpdateGuestBox from 'components/Guests/UpdateGuestBox';
import PdpCancellationModal from 'components/PropertyDetail/modals/PdpCancellationModal';
import PdpGuestModal from 'components/PropertyDetail/modals/PdpGuestModal';
import TermsConditionWebview from 'components/SignUp/TermsConditionWebview';
import { useTranslation } from 'react-i18next';
import AddGuest from 'screens/Booking/AddGuest';
import BookingCalendarScreen from 'screens/Booking/BookingCalendarScreen';
import BookingCalendarModalScreen from 'screens/Booking/CalendarModalScreen';
import Confirmation from 'screens/Booking/Confirmation';
import ThankYouScreen from 'screens/Booking/ThankYouScreen';
import { RootStackParamList } from 'types';
import { getOptions } from '.';

const BookingSub = createNativeStackNavigator<RootStackParamList>();

export function BookingSubNavigator() {
  const { t } = useTranslation();
  return (
    <BookingSub.Navigator initialRouteName="PdpGuestModal">
      <BookingSub.Screen name="AddGuest" component={AddGuest} options={getOptions(t('GO_SUMMER') + t('ADD_GUESTS'))} />
      <BookingSub.Screen
        name="TermsConditionWebview"
        component={TermsConditionWebview}
        options={getOptions(t('GO_SUMMER') + t('TERM_AND_CONDITION.TERM_CONDITION'))}
      />
      <BookingSub.Screen
        name="UpdateGuests"
        component={UpdateGuestBox}
        options={getOptions(t('GO_SUMMER') + t('UPDATE_GUESTS'))}
      />
      <BookingSub.Screen
        name="PdpCancellationModal"
        component={PdpCancellationModal}
        options={getOptions(t('GO_SUMMER') + t('CANCELLATION_POLICY'))}
      />
      <BookingSub.Screen
        name="PdpGuestModal"
        component={PdpGuestModal}
        options={getOptions(t('GO_SUMMER') + t('GUESTS'))}
      />
      <BookingSub.Screen
        name="BookingCalendar"
        component={BookingCalendarScreen}
        options={getOptions(t('GO_SUMMER') + t('CONFIRMATION.BOOKING_CAL'))}
      />
      <BookingSub.Screen
        name="BookingCalendarModalScreen"
        component={BookingCalendarModalScreen}
        options={getOptions(t('GO_SUMMER') + t('CONFIRMATION.BOOKING_CAL'))}
      />
      <BookingSub.Screen
        name="ThankYou"
        component={ThankYouScreen}
        options={getOptions(t('GO_SUMMER') + t('CONFIRMATION.THANK_YOU'))}
      />
      <BookingSub.Screen
        name="Confirmation"
        component={Confirmation}
        options={getOptions(t('GO_SUMMER') + t('CONFIRM_BOOKING'))}
      />
    </BookingSub.Navigator>
  );
}
