import { createNativeStackNavigator } from '@react-navigation/native-stack';
import AboutAreaModal from 'components/PropertyDetail/modals/AboutAreaModal';
import AboutHomeModal from 'components/PropertyDetail/modals/AboutHomeModal';
import AmenitiesModal from 'components/PropertyDetail/modals/AmenitiesModal';
import CalendarModal from 'components/PropertyDetail/modals/CalendarModal';
import PdpCancellationModal from 'components/PropertyDetail/modals/PdpCancellationModal';
import PhotoSliderModal from 'components/PropertyDetail/modals/PhotoSliderModal';
import { PropertyDetailProvider } from 'providers/PropertyDetailProvider';
import { ReservationProvider } from 'providers/ReservationProvider';
import { useTranslation } from 'react-i18next';
import BookingCalendarScreen from 'screens/Booking/BookingCalendarScreen';
import BookingCalendarModalScreen from 'screens/Booking/CalendarModalScreen';
import PropertyDetail from 'screens/PropertyDetails/PropertyDetail';
import { ListingStackParamList, RootStackParamList } from 'types';
import { getOptions } from '.';
import { BookingSubNavigator } from './BookingSubNavigator';

const PropertyDetailNav = createNativeStackNavigator<ListingStackParamList>();
const BookingSub = createNativeStackNavigator<RootStackParamList>();

export function PropertyDetailsNavigator() {
  const { t } = useTranslation();
  return (
    <PropertyDetailProvider>
      <ReservationProvider>
        <PropertyDetailNav.Navigator initialRouteName="PropertyDetail">
          <PropertyDetailNav.Screen
            name="PropertyDetail"
            component={PropertyDetail}
            options={getOptions(t('GO_SUMMER') + t('PROPERTY_DETAIL.TITLE'))}
          />
          <BookingSub.Group screenOptions={{ presentation: 'fullScreenModal', animation: 'slide_from_bottom' }}>
            <PropertyDetailNav.Screen
              name="BookingCalendar"
              component={BookingCalendarScreen}
              options={getOptions(t('GO_SUMMER') + t('CONFIRMATION.BOOKING_CAL'))}
            />
            <PropertyDetailNav.Screen
              name="CalendarModal"
              component={CalendarModal}
              options={getOptions(t('GO_SUMMER') + t('CONFIRMATION.BOOKING_CAL'))}
            />
            <PropertyDetailNav.Screen
              name="AmenitiesModal"
              component={AmenitiesModal}
              options={getOptions(t('GO_SUMMER') + t('PROPERTY_DETAIL.PROPERTY_AMENITIES'))}
            />
            <PropertyDetailNav.Screen
              name="PhotoSliderModal"
              component={PhotoSliderModal}
              options={getOptions(t('GO_SUMMER') + t('PROPERTY_DETAIL.PHOTO_SLIDER'))}
            />
            <PropertyDetailNav.Screen
              name="AboutAreaModal"
              component={AboutAreaModal}
              options={getOptions(t('GO_SUMMER') + t('ABOUT_THE_AREA'))}
            />
            <PropertyDetailNav.Screen
              name="AboutHomeModal"
              component={AboutHomeModal}
              options={getOptions(t('GO_SUMMER') + t('PROPERTY_DETAIL.ABOUT_THE_HOME'))}
            />
            <PropertyDetailNav.Screen
              name="PdpCancellationModal"
              component={PdpCancellationModal}
              options={getOptions(t('GO_SUMMER') + t('PROPERTY_DETAIL.CANCELLATION_POLICY'))}
            />
            <PropertyDetailNav.Screen
              name="BookingCalendarModalScreen"
              component={BookingCalendarModalScreen}
              options={getOptions(t('GO_SUMMER') + t('CONFIRMATION.BOOKING_CAL'))}
            />
            <PropertyDetailNav.Screen
              name="BookingSubNavigator"
              component={BookingSubNavigator}
              options={getOptions('')}
            />
          </BookingSub.Group>
        </PropertyDetailNav.Navigator>
      </ReservationProvider>
    </PropertyDetailProvider>
  );
}
