import { NightSelectionValidationResult } from 'providers/PropertyDetailProvider';
import { createContext } from 'react';
import { GetListingDetailQuery } from 'screens/PropertyDetails/queries/getListingDetail.generated';

export type ListingType = GetListingDetailQuery['getListing']['data'];

export type PropertyDetailContextProps = {
  listing?: Partial<ListingType>;
  listingId: string | null;
  setMinNightsRequired: (minNightsRequired: number) => void;
  setListingId: (listingId: string) => void;
  validateNightSelection: (selectedNights: number) => NightSelectionValidationResult;
  loading: boolean;
};

//context
export const PropertyDetailContext = createContext<PropertyDetailContextProps>({} as any);
