import { Box, Button, Pressable, Text, HStack, Checkbox, Divider, View, VStack } from 'native-base';
import { useTranslation } from 'react-i18next';
import React, { useContext, useState, useEffect } from 'react';
import { noop } from 'lodash';
import { CitiesContext, City } from 'contexts/CitiesContext';
import { ListingsContext } from 'contexts/ListingsContext';
import DownArrowIcon from '../../assets/icons/Down-arrow.svg';
import UpArrowIcon from '../../assets/icons/Up-Arrow.svg';

const styles = {
  pressable: {
    h: ['150px', '150px', '80px', '75px', '75px'],
    w: '100%',
    pl: [null, null, null, '1px'],
  },
  headerText: {
    position: 'absolute' as const,
    fontSize: 'sm',
    top: ['10px', '10px', '14px', '13px', '13px'],
    left: '16px',
    color: 'styleSheet.lightShadow',
    fontFamily: 'body',
    fontWeight: '400',
    opacity: '0.55',
  },
  icon: {
    position: 'absolute' as const,
    right: 5,
    top: '27px',
  },
  text: {
    pt: '15px',
    fontSize: '2md',
    fontFamily: 'body',
    fontWeight: '400',
    color: 'styleSheet.shadow',
  },
  hstack: {
    mt: '20px',
    ml: '15px',
    flex: 1,
    justifyContent: 'flex-start',
    overflow: 'hidden',
    space: 2,
  },
  buttonStack: (isFromStickyBar: boolean) => ({
    mb: '16px',
    mt: '12px',
    space: '1',
    ml: isFromStickyBar ? '3px' : '-5px',
  }),
  button: {
    bg: 'transparent',
    _text: {
      color: 'styleSheet.overCast',
      fontSize: 'md',
      fontFamily: 'body',
      fontWeight: '400',
      textDecorationLine: 'underline',
    },
    _pressed: {
      bg: 'transparent',
      opacity: '0.3',
    },
    _hover: {
      bg: 'transparent',
    },
    _focus: {
      bg: 'transparent',
    },
  },
  checkbox: (isFromStickyBar: boolean) => ({
    mb: '10px',
    ml: isFromStickyBar ? '15px' : '5px',
    bg: 'styleSheet.canvas',
    _checked: {
      opacity: '0.7',
      bg: 'styleSheet.shadow',
      borderColor: 'styleSheet.shadow',
    },
    _text: {
      color: 'styleSheet.overCast',
      fontSize: 'md',
      fontFamily: 'body',
      fontWeight: '400',
    },
  }),
  footerButton: {
    alignSelf: 'center',
    w: '95.5%',
    h: '56px',
    bg: 'styleSheet.lightShadow',
    _text: {
      fontSize: 'sm',
      fontFamily: 'body',
      fontWeight: '400',
      color: 'styleSheet.canvas',
    },
    _pressed: {
      bg: 'styleSheet.shadow',
      opacity: '0.6',
      _text: { color: 'styleSheet.canvas' },
    },
    _hover: {
      bg: 'styleSheet.darkStain',
      _text: { color: 'styleSheet.shadow' },
    },
    _focus: {
      bg: 'styleSheet.darkStain',
      _text: { color: 'styleSheet.shadow' },
    },
    mt: '16px',
    mb: '24px',
  },
  dropdown: (isFromStickyBar: boolean, widthDims: number) => ({
    position: 'absolute' as const,
    top: isFromStickyBar ? -1 : 77.2,
    right: '1.2px',
    width: widthDims,
    bg: 'styleSheet.canvas',
    borderWidth: 2,
    borderColor: 'styleSheet.darkStain',
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    borderTopWidth: isFromStickyBar ? 1.5 : 0,
    borderTopRightRadius: isFromStickyBar ? 10 : 0,
    borderTopLeftRadius: isFromStickyBar ? 10 : 0,
    paddingRight: isFromStickyBar ? 1 : 2,
    paddingLeft: isFromStickyBar ? 1 : 2,
    marginBottom: 50,
  }),
  dividerTop: {
    w: '95%',
    borderColor: 'styleSheet.darkStain',
    borderWidth: '1px',
    alignSelf: 'center' as const,
    mt: '12px',
  },
  innerBox: {
    pl: [null, null, null, '10px', '8px'],
  },
  cityContainer: {
    maxHeight: '316px',
    overflow: 'auto',
  },
};

interface DropDownWebProps {
  isFromStickyBar?: boolean;
  isOpen: boolean;
  setIsOpen: (e: boolean) => void;
  isOpenCal?: boolean;
  setIsOpenCal: (e: boolean) => void;
  selectedCitiesList?: City[];
  onSelectionChanged(selectedCities: any[]);
  setIsDropDownOpen?: (e: boolean) => void;
}

const DropDownPopupLarger = ({
  isOpen,
  setIsOpen,
  onSelectionChanged,
  isFromStickyBar,
  setIsOpenCal,
  selectedCitiesList,
  setIsDropDownOpen = noop,
}: DropDownWebProps) => {
  const { cities } = useContext(CitiesContext);
  const { setAPISelectedCities } = useContext(ListingsContext);
  const { t } = useTranslation();
  const [selectedCities, setSelectedCities] = React.useState<City[]>(selectedCitiesList!);
  const [widthDims, setWidthDims] = useState(0);

  useEffect(() => {
    if ((!selectedCities || !selectedCities.length) && !!cities) {
      setSelectedCities([cities[0]]);
    }
  }, [selectedCities, cities]);

  const getCityDisplayName = (city: City) =>
    city.full! === `My Home` ? `${t('YOUR_HOME')} (${city.nickname})` : city.nickname!;

  const toogleSelected = (city: City, isSelected: boolean) => {
    if (isSelected) {
      setSelectedCities((s) => [...s, city]);
    } else {
      setSelectedCities((s) => s.filter((v) => v !== city));
    }
  };

  const renderText = () => {
    if (!selectedCities.length) return;

    const locationText = selectedCities.length > 1 ? t('LOCATIONS') : t('LOCATION');

    const getDisplayName = (city: City[]) => {
      if (city.length === 1) {
        return city[0].full! === 'My Home' ? `${t('YOUR_HOME')} (${city[0].nickname})` : city[0].nickname!;
      } else {
        return city[0].full! === 'My Home' ? `${t('YOUR_HOME')}` : city[0].nickname!;
      }
    };

    if (selectedCities && selectedCities.length === 1) {
      return (
        <HStack {...styles.hstack}>
          <Text {...styles.text}>{getDisplayName(selectedCities)}</Text>
        </HStack>
      );
    } else if (selectedCities && selectedCities.length > 1) {
      return (
        <HStack {...styles.hstack}>
          <Text {...styles.text}>{`${getDisplayName(selectedCities)} & ${
            selectedCities.length - 1
          } ${locationText}`}</Text>
        </HStack>
      );
    } else {
      return <HStack {...styles.hstack}></HStack>;
    }
  };

  const handlePress = () => {
    setIsOpen(!isOpen);
    setIsOpenCal(false);
    setIsDropDownOpen(false);
  };

  return (
    <Box>
      <Pressable
        {...styles.pressable}
        onPress={handlePress}
        onLayout={(event) => {
          const { width } = event.nativeEvent.layout;
          setWidthDims(width + 1);
        }}>
        <Box {...styles.icon}>
          <DownArrowIcon height={30} width={30} />
        </Box>
        <Text {...styles.headerText}>{t('BOOK_A_STAY.LOCATION')}</Text>
        {renderText()}
      </Pressable>

      {isOpen ? (
        <Box {...styles.dropdown(isFromStickyBar!, widthDims)}>
          {!isFromStickyBar ? (
            <HStack {...styles.buttonStack(isFromStickyBar!)}>
              <Button children="Select all" {...styles.button} onPress={() => setSelectedCities(cities || [])} />
              <Button children="Clear all" {...styles.button} onPress={() => setSelectedCities([cities![0]])} />
            </HStack>
          ) : (
            <Pressable onPress={handlePress} style={{ marginBottom: '30px' }}>
              <Box>
                <Box {...styles.icon}>
                  <UpArrowIcon />
                </Box>

                <Text {...styles.headerText}>{t('BOOK_A_STAY.LOCATION')}</Text>
                {renderText()}
              </Box>
              <Divider {...styles.dividerTop} />
            </Pressable>
          )}

          <VStack {...styles.cityContainer}>
            {cities?.map((city) => {
              return (
                <Checkbox
                  value={''}
                  isChecked={selectedCities.indexOf(city) > -1}
                  {...styles.checkbox(isFromStickyBar!)}
                  onChange={(v) => toogleSelected(city, v)}>
                  {getCityDisplayName(city)}
                </Checkbox>
              );
            })}
          </VStack>

          {!isFromStickyBar ? (
            <Button
              {...styles.footerButton}
              onPress={() => {
                onSelectionChanged(selectedCities);
                setIsOpen(false);
              }}>{`Add ${t('LOCATION')}`}</Button>
          ) : null}

          {isFromStickyBar ? (
            <View>
              <HStack {...styles.buttonStack(isFromStickyBar!)}>
                <Button children="Select all" {...styles.button} onPress={() => setSelectedCities(cities || [])} />
                <Button children="Clear all" {...styles.button} onPress={() => setSelectedCities([cities![0]])} />
              </HStack>
              <Button
                {...styles.footerButton}
                w="93%"
                onPress={() => {
                  setAPISelectedCities(selectedCities);
                  setIsOpen(false);
                }}>{`Add ${t('LOCATION')}`}</Button>
            </View>
          ) : null}
        </Box>
      ) : null}
    </Box>
  );
};

export default DropDownPopupLarger;
