import { FC, useContext } from 'react';
import { Box, Image, Stack, Text, Pressable } from 'native-base';
import { NavigationContext } from 'contexts/NavigationContext';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import AspectRatio from 'components/AspectRatio';

const styles = {
  image: {
    borderTopRadius: ['12px', '12px', null, null, null],
  },
  imageRatio: {
    minWidth: ['100%', '100%', '35%', '35%', '35%'],
    maxWidth: ['100%', '100%', '35%', '35%', '35%'],
    ratio: 16 / 10,
  },
  pressable: {
    w: ['92%', '92%', '90%', '660px', '660px'],
    h: [null, null, null, '100%', '100%'],
    bg: 'bgStayBox',
    borderRadius: '12px',
    alignSelf: 'center' as const,
    overflow: 'hidden' as const,
    _pressed: {
      opacity: '0.8',
    },
  },
  textAddress: {
    pt: ['18px', '18px', '18px', '24px', '24px'],
    pl: ['14px', '14px', '14px', '24px', '24px'],
    fontSize: 'sm',
    fontFamily: 'body600',
    color: 'styleSheet.overCast',
  },
  textTitle: {
    fontSize: 'xl',
    fontFamily: 'body600',
    pl: ['14px', '14px', '14px', '24px', '24px'],
    pr: '14px',
    color: 'textStay',
  },
  textDate: {
    pl: ['14px', '14px', '14px', '24px', '24px'],
    pt: '14px',
    pb: '20px',
    mt: ['0px', '0px', '10px', '10px', '10px'],
    fontSize: 'sm',
    fontFamily: 'body',
    color: 'textStay',
  },
  box: {
    mt: '16px',
    overflow: 'hidden' as const,
  },
  stack: {
    flexDirection: ['column' as const, 'column' as const, 'row' as const, 'row' as const, 'row' as const],
  },
};

interface IStayBookingBox {
  _id: string;
  image: string;
  title: string;
  address: string;
  checkIn: string;
  checkOut: string;
}

export const StayBookingBox: FC<IStayBookingBox> = ({ _id, image, title, address, checkIn, checkOut }) => {
  const { useNavigation } = useContext(NavigationContext);
  const { t } = useTranslation();
  const displayTitle = title ? title.split('|').slice(-1)[0].trim() : '';

  const handleOnPress = () => {
    useNavigation('StayDetailsNavigator', {
      screen: 'BookingDetail',
      params: { reservationId: _id },
    });
  };

  return (
    <Box {...styles.box}>
      <Pressable onPress={handleOnPress} {...styles.pressable} testID="upcomingStayBookingBoxId">
        <Stack {...styles.stack}>
          <AspectRatio {...styles.imageRatio}>
            <Image {...styles.image} source={{ uri: image }} alt={_id} />
          </AspectRatio>
          <Stack>
            <Text {...styles.textAddress}>{address}</Text>
            <Text {...styles.textTitle}>{displayTitle}</Text>
            <Text {...styles.textDate}>
              {moment(checkIn).format(t('DDD_MMM_D'))} – {moment(checkOut).format(t('DDD_MMM_D'))}
            </Text>
          </Stack>
        </Stack>
      </Pressable>
    </Box>
  );
};
