import { createContext } from 'react';
import { GetReservationWithListingQuery } from 'screens/Stays/queries/getReservationWithListing.generated';
import { ListingType } from 'contexts/PropertyDetailContext';
import { IGuests } from 'providers/ReservationProvider';

// @ts-ignore
export type ReservationType = GetReservationWithListingQuery['getReservation']['data'];

export type BookingDetailContextProps = {
  reservation: ReservationType | null;
  listing: ListingType | null;
  reservationId: string | null;
  setReservationId(id: string);
  isLoading: boolean;
  guests: IGuests | null;
};

//context
export const BookingDetailContext = createContext<BookingDetailContextProps>({} as any);
