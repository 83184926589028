import { HStack, Text, Spinner } from 'native-base';
import { useTranslation } from 'react-i18next';

const styles = {
  toastHstack: {
    borderRadius: '12px',
    bg: 'styleSheet.shadow',
    h: '70px',
    alignItems: 'center',
    justifyContent: 'center',
    w: ['100%', '100%', '100%', '25%', '25%'],
    mt: '16px',
  },
  text: {
    color: 'styleSheet.canvas',
    fontFamily: 'body600',
    fontSize: '2md',
    ml: '8px',
  },
};

const LoadingToast = () => {
  const { t } = useTranslation();
  return (
    <HStack {...styles.toastHstack}>
      <Spinner color="styleSheet.canvas" size="lg" />
      <Text {...styles.text}>{t('PROFILE.LOADING')}</Text>
    </HStack>
  );
};

export default LoadingToast;
