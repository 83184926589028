import { FontAwesome5 } from '@expo/vector-icons';
import { Box, Button, HStack, Pressable, Text, Hidden, Avatar } from 'native-base';
import { PropertyDetailContext } from 'contexts/PropertyDetailContext';
import { useTranslation } from 'react-i18next';
import ImageSlider from './ImageSlider';
import { FC, useContext } from 'react';
import BackArrowIcon from '../../../assets/icons/Back-Arrow.svg';

const styles = {
  box: {
    bg: 'pdp.shadow',
    w: '100%',
    mt: ['0px', '0px', '80px', '80px', '80px'],
    h: ['596px', '596px', '596px', '596px', null],
    pl: ['16px', '64px', '64px', '64px', '100px'],
    pr: ['16px', '64px', '64px', '64px', '100px'],
  },
  innerBox: {
    zIndex: 1,
  },
  iconHstack: {
    alignItems: 'center' as const,
    justifyContent: 'space-between' as const,
  },

  innerHstack: {
    alignItems: 'center' as const,
  },
  pressable: {
    h: '50px',
    justifyContent: 'center' as const,
    mt: '24px',
  },
  iconText: {
    color: 'styleSheet.canvas',
    fontSize: '2md',
    fontFamily: 'body',
    ml: '10px',
  },
  image: {
    w: ['100%', '100%', '100%', '100%', '75%'],
    h: ['380px', '380px', '380px', '380px', null],
    mt: '25px',
    overflow: 'hidden',
    borderRadius: '15px',
  },
  header: {
    color: 'styleSheet.canvas',
    fontSize: ['7.5xl', '7.5xl', '7.5xl', '7.5xl', '9xl'],
    lineHeight: ['66px', '66px', '66px', '66px', '86px'],
    zIndex: 1,
    mt: ['32px', '32px', '32px', '32px', '-250px'],
    alignSelf: 'center',
    fontFamily: 'body',
    fontWeight: '400',
  },
  button: {
    bg: 'pdp.buttonBg',
    borderRadius: '8px',
    _text: {
      color: 'pdp.shadow',
      fontSize: 'sm',
      fontFamily: 'body600',
    },
    _pressed: {
      bg: 'styleSheet.shadow',
      _text: { color: 'styleSheet.canvas' },
    },
    _hover: {
      bg: 'styleSheet.shadow',
      _text: { color: 'styleSheet.canvas' },
    },
    w: '131px',
    h: '42px',
  },
  buttonClickZone: {
    p: '8px',
    w: '147px',
    h: '58px',
    position: 'absolute' as const,
    bottom: ['48px', '75px', '75px', '105px'],
    right: ['28px', '75px', '75px', '12%'],
    zIndex: 1,
  },
  imageBox: {
    flexDirection: ['column' as const, 'column' as const, 'column' as const, 'column' as const, 'row' as const],
    h: [null, null, null, null, '450px'],
    w: '100%',
    mb: ['0px', '0px', '0px', '0px', '86px'],
    overflow: 'hidden',
    borderRadius: '15px',
  },
  address: {
    position: 'absolute' as const,
    alignItems: 'center' as const,
    space: 2,
    bottom: '5px',
  },
  addressText: {
    color: 'styleSheet.canvas',
    fontSize: 'md',
  },
  avatar: {
    bg: 'bgPage',
  },
};

interface IPictures {
  _typename?: string;
  _id: string;
  original: string;
  size: number;
  thumbnail: string;
  width: number;
}

interface IPhotoSliderProps {
  onIconPress: () => void;
  onPhotoPress: () => void;
}

const PhotoSlider: FC<IPhotoSliderProps> = ({ onIconPress, onPhotoPress }) => {
  const { listing } = useContext(PropertyDetailContext);
  const { t } = useTranslation();

  let listingPictures: IPictures[] = [];
  if (listing?.pictures) {
    listingPictures = listing.pictures;
  }

  const imagesArray = listingPictures.map((el) => {
    return el.original;
  });

  return (
    <Box {...styles.box}>
      <Box {...styles.innerBox}>
        <HStack {...styles.iconHstack}>
          <Pressable onPress={onIconPress} {...styles.pressable}>
            <HStack {...styles.innerHstack}>
              <Avatar {...styles.avatar} size="md">
                <BackArrowIcon />
              </Avatar>

              <Text {...styles.iconText}>{t('BACK_TO_RESULTS')}</Text>
            </HStack>
          </Pressable>
        </HStack>
        <Box {...styles.imageBox}>
          <Text {...styles.header}>{listing?.address?.city}</Text>
          <Box {...styles.image}>
            <ImageSlider imagesArray={imagesArray} styleOverRide={false} staticImage={true} />
          </Box>
          <Hidden till={'lg'}>
            <HStack {...styles.address}>
              <FontAwesome5 name="map-marker-alt" size={16} color="#FFFEF3" />
              <Text {...styles.addressText}>{listing?.address?.full}</Text>
            </HStack>
          </Hidden>
        </Box>
      </Box>
      <Pressable onPress={onPhotoPress} {...styles.buttonClickZone}>
        <Button onPress={onPhotoPress} {...styles.button}>
          {t('VIEW_PHOTOS')}
        </Button>
      </Pressable>
    </Box>
  );
};

export default PhotoSlider;
