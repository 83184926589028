import { ComponentType } from 'react';
import { IInputProps, Input } from 'native-base';
import { CustomInputStyles } from 'src/styles/input';

const defautlStyle = {
  borderRadius: '10px',
  backgroundColor: 'customWhite',
  mt: '0px',
  h: '74px',
  pl: '16px',
  fontSize: '18px',
};
const styles = {
  focusedWithoutError: {
    ...defautlStyle,
    pt: '4%',
    _focus: {
      borderColor: 'bgStayBox',
    },
    pl: ['4%', '5%', '5%', '5%'],
  },
  focusedWithError: {
    ...defautlStyle,
    borderWidth: '1px',
    _focus: {
      borderColor: 'notification',
    },
    pt: '1%',
    pl: ['4%', '3%', '3%', '3%'],
  },
  notFocusedAndWithtError: {
    ...defautlStyle,
    borderColor: 'notification',
    pt: '1%',
  },
};
export type BaseStyleInputProps = {
  style?: CustomInputStyles;
  error?: boolean;
} & IInputProps;

export const BaseInput: ComponentType<BaseStyleInputProps> = ({
  onFocus,
  onBlur,
  placeholder,
  placeholderTextColor,
  error,
  autoCapitalize,
  style,
  keyboardType,
  onChangeText,
  isFocused,
  value,
  backgroundColor,
}) => {
  const inputStyles =
    isFocused && !error
      ? styles.focusedWithoutError
      : isFocused && error
      ? styles.focusedWithError
      : !isFocused && error
      ? styles.notFocusedAndWithtError
      : defautlStyle;

  return (
    <Input
      onFocus={onFocus}
      onBlur={onBlur}
      placeholder={placeholder}
      placeholderTextColor={placeholderTextColor}
      error={error}
      autoCapitalize={autoCapitalize}
      style={style}
      keyboardType={keyboardType}
      onChangeText={onChangeText}
      isFocused={isFocused}
      value={value}
      {...inputStyles}
      backgroundColor={backgroundColor ?? 'customWhite'}
    />
  );
};
