import { View, HStack, Text, Divider, Hidden, Button } from 'native-base';
import { useTranslation } from 'react-i18next';
import { H3 } from 'components/libs/heading/Heading';

const styles = {
  title: {
    fontSize: '1xl',
    color: 'styleSheet.shadow',
    fontFamily: 'body',
    fontWeight: '600',
  },

  hstack: {
    justifyContent: 'space-between',
    mt: '24px',
  },
  rates: {
    color: 'styleSheet.shadow',
    fontFamily: 'body',
    fontWeight: '400',
    fontSize: '2md',
  },
  paymentDetailView: {
    mt: ['0px', '0px', '0px', '25px', '25px'],
  },
  secondHstack: {
    justifyContent: 'space-between',
    mt: '4px',
  },
  amount: {
    justifyContent: 'space-between',
  },
  amountDivider: {
    variant: 'primary',
    mt: '24px',
    mb: '16px',
  },
  rateAmount: {
    color: 'styleSheet.shadow',
    fontFamily: 'body800',
    fontSize: '2md',
  },
  stickyBarForDesktop: {
    position: 'absolute' as const,
    bottom: '0',
    bg: 'styleSheet.shadow',
    w: '100%',
    right: 0,
    h: '70px',
    borderRadius: '8',
    _text: {
      color: 'styleSheet.canvas',
      fontFamily: 'body',
      fontWeight: '400',
      fontSize: '2md',
    },
    _pressed: {
      bg: 'styleSheet.shadow',
      opacity: '0.5',
    },
    colorScheme: 'rgba(49, 44, 44, 0.7)',
  },
  bottomText: {
    fontSize: 'sm',
    color: 'styleSheet.shadow',
    fontFamily: 'body',
    fontWeight: '400',
    mt: '24px',
    mb: ['70px', '70px', '127px', '70px', '70px'],
    textAlign: 'center' as const,
  },
  buttonView: {
    mt: '110px',
  },
};

interface PaymentBlockProps {
  disabled: boolean;
  days: number | null | undefined;
  basePrice: number | null | undefined;
  totalPrice: number | null | undefined;
  discount: number | null | undefined;
  cleaningFee: number | null | undefined;
  totalTax: number | null | undefined;
  setDisabled: (e: boolean) => void;
  setLoader: (e: boolean) => void;
  handleReservation: () => void;
}

const PaymentBlock = ({
  handleReservation,
  setDisabled,
  setLoader,
  disabled,
  days,
  basePrice,
  discount,
  cleaningFee,
  totalTax,
  totalPrice,
}: PaymentBlockProps) => {
  const { t } = useTranslation();

  return (
    <View {...styles.paymentDetailView}>
      <H3 {...styles.title}>{t('PAYMENT_DETAILS')}</H3>
      <HStack {...styles.hstack}>
        <Text {...styles.rates}>{`${days} nights`}</Text>
        <Text {...styles.rates}>${basePrice?.toFixed(2)}</Text>
      </HStack>
      {discount !== 0 && (
        <HStack {...styles.secondHstack}>
          <Text {...styles.rates}>{t('MEMBER_DISCOUNT')}</Text>
          <Text {...styles.rates}>-${discount?.toFixed(2)}</Text>
        </HStack>
      )}
      <HStack {...styles.secondHstack}>
        <Text {...styles.rates}>{t('CLEANING_FEE')}</Text>
        <Text {...styles.rates}>${cleaningFee?.toFixed(2)}</Text>
      </HStack>
      <HStack {...styles.secondHstack}>
        <Text {...styles.rates}>{t('TAXES')}</Text>
        <Text {...styles.rates}>${totalTax?.toFixed(2)}</Text>
      </HStack>
      <Divider {...styles.amountDivider} />
      <HStack {...styles.amount}>
        <H3 {...styles.rateAmount}>{t('TOTAL_AMOUNT')}</H3>
        <HStack alignItems="flex-end">
          <H3 {...styles.rateAmount}>${totalPrice?.toFixed(2)}</H3>
        </HStack>
      </HStack>

      <Hidden from={'lg'}>
        <Text {...styles.bottomText}>{t('NO_PAYMENT_IS_NEEDED')}</Text>
      </Hidden>

      <Hidden till={'lg'}>
        <View {...styles.buttonView}>
          <Button
            disabled={disabled}
            children={t('CONFIRM_BOOKING')}
            onPress={() => {
              setDisabled(true);
              setLoader(true);
              handleReservation();
            }}
            {...styles.stickyBarForDesktop}
          />
        </View>
      </Hidden>

      <Hidden till={'lg'}>
        <Text {...styles.bottomText}>{t('NO_PAYMENT_IS_NEEDED')}</Text>
      </Hidden>
    </View>
  );
};

export default PaymentBlock;
