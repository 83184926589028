import { FC, useMemo } from 'react';
import { Box, Button, KeyboardAvoidingView, ScrollView, Text, View, Hidden } from 'native-base';
import { ActivityIndicator, Platform } from 'react-native';
import { Formik } from 'formik';
import { SignUpFormProps, SignUpFormValues } from '../Login/login.d';
import { EmailInput } from '../../components/SignUp/EmailInput';
import { FirstNameInput } from '../../components/SignUp/FirstNameInput';
import { PhoneNumberInput } from '../../components/SignUp/PhoneNumberInput';
import { LastNameInput } from '../../components/SignUp/LastNameInput';
import { useTranslation } from 'react-i18next';
import NavPressable from '../../components/SignUp/NavPressable';
import * as yup from 'yup';
import { DestinationInput } from 'components/SignUp/DestinationInput';
import { ShadowLogo } from 'components/iconsXml/shadowLogo';
import CheckBoxTermsCondition from 'components/SignUp/CheckBoxTermsCondition';

const formGroupMainContainer = {
  mt: '18px',
  h: '65px',
};

const styles = {
  text: {
    marginTop: -1,
    color: 'styleSheet.shadow',
    fontSize: '2md',
    fontFamily: 'body',
    fontWeight: '400',
  },
  formContainer: {
    alignItems: 'center' as const,
    pt: '5%',
    pb: ['20%', '10%', '10%', '20%', '20%'],
    px: '16px',
    mb: ['20%', null, null, null, null],
    w: '100%',
  },
  mainView: {
    mt: ['60px', '60px', '70px', null, null],
    w: ['100%', '540px', '540px', '540px', '540px'],
    ml: ['0px', '430px', '430px', '430px', '430px'],
    mr: ['0px', '430px', '430px', '430px', '430px'],
  },
  formGroupContainer: {
    ...formGroupMainContainer,
    w: '100%',
  },
  applyButton: {
    w: '100%',
    h: '70px',
    mt: '40px',
    backgroundColor: 'styleSheet.shadow',
    borderRadius: '8px',
    textAlign: 'center',
  },
  applyButtonText: {
    color: 'styleSheet.canvas',
    fontSize: '2md',
    fontFamily: 'body600',
  },
  loginRedirect: {
    alignItems: 'center' as const,
  },
  hr: {
    w: '65px',
    ml: '10px',
    borderWidth: '1px',
    borderColor: 'styleSheet.shadow',
    mb: '-5px',
  },
  view: {
    h: '100%',
    w: '100%',
    backgroundColor: 'bgStayBox',
  },
  checkboxContainer: {
    pt: '20px',
    pl: '8px',
    pr: '8px',
    mt: '18px',
    w: '100%',
  },
  groupForFirstLast: {
    flexDirection: ['column' as const, 'column' as const, 'row' as const, 'row' as const, 'row' as const],
    w: '100%',
  },
  formGroupFirstName: {
    ...formGroupMainContainer,
    w: ['100%', '100%', '49%', '49%', '49%'],
  },
  formGroupLastName: {
    ...formGroupMainContainer,
    w: ['100%', '100%', '48%', '48%', '48%'],
    ml: [null, null, '16px', '16px', '16px'],
  },
  formGroupButton: {
    ...formGroupMainContainer,
    w: ['100%', '540px', '540px', '540px', '540px'],
    ml: ['0px', '430px', '430px', '430px', '430px'],
    mr: ['0px', '430px', '430px', '430px', '430px'],
  },
  horizontalLine: {
    borderWidth: '1px',
    borderColor: 'styleSheet.darkStain',
    mb: '23px',
  },
  titleStyle: {
    alignSelf: 'center' as const,
    fontSize: '2xl',
    fontWeight: '400',
    fontFamily: 'body',
  },
  logoContainer: {
    top: ['5%', '5%', '5%', null, null],
  },
  logoContainerWeb: {
    alignSelf: 'center' as const,
  },
};

export const SignUpForm: FC<SignUpFormProps> = ({ handleFormSubmit, loading, handleOnLogin }) => {
  const { t } = useTranslation();
  const initialValues: SignUpFormValues = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    emailAddress: '',
    destination: '',
    termsCondition: false,
  };
  const redirectText = useMemo(() => {
    return `${t('OR')} ${t('LOGIN')}`;
  }, []);

  const signUpValidationSchema = yup.object().shape({
    firstName: yup.string().required(t('INPUT_DEFAULT_ERROR')),
    lastName: yup.string().required(t('INPUT_DEFAULT_ERROR')),
    emailAddress: yup.string().required(t('INPUT_DEFAULT_ERROR')),
    phoneNumber: yup.string().required(t('INPUT_DEFAULT_ERROR')),
    destination: yup.string(),
    termsCondition: yup.bool().oneOf([true], t('INPUT_DEFAULT_ERROR')),
  });

  return (
    <KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'padding' : 'height'} {...styles.view}>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => handleFormSubmit(values)}
        validationSchema={signUpValidationSchema}
        validateOnBlur={false}
        validateOnChange={false}>
        {({ handleSubmit, setFieldValue, errors, validateField }) => (
          <ScrollView>
            <Box safeAreaBottom {...styles.formContainer}>
              <Hidden from="lg">
                <Box {...styles.logoContainer}>
                  <ShadowLogo />
                </Box>
              </Hidden>
              <View {...styles.mainView}>
                <Hidden till="lg">
                  <Box {...styles.logoContainerWeb}>
                    <ShadowLogo />
                  </Box>
                </Hidden>
                <View {...styles.horizontalLine} />
                <Text {...styles.titleStyle}>{t('SIGN_UP')}</Text>
                <View {...styles.groupForFirstLast}>
                  <View {...styles.formGroupFirstName}>
                    <FirstNameInput
                      handleFieldChange={setFieldValue}
                      label={t('FIRST_NAME_PLACEHOLDER')}
                      fieldName="firstName"
                      errorMsg={errors.firstName}
                      validateField={validateField}
                    />
                  </View>
                  <View {...styles.formGroupLastName}>
                    <LastNameInput
                      handleFieldChange={setFieldValue}
                      label={t('LAST_NAME_PLACEHOLDER')}
                      fieldName="lastName"
                      errorMsg={errors.lastName}
                      validateField={validateField}
                    />
                  </View>
                </View>
                <View {...styles.formGroupContainer}>
                  <EmailInput
                    handleFieldChange={setFieldValue}
                    label={t('EMAIL_PLACEHOLDER')}
                    fieldName="emailAddress"
                    errorMsg={errors.emailAddress}
                    validateField={validateField}
                  />
                </View>
                <View {...styles.formGroupContainer}>
                  <PhoneNumberInput
                    handleFieldChange={setFieldValue}
                    label={t('PHONE_NUMBER_PLACEHOLDER')}
                    fieldName="phoneNumber"
                    errorMsg={errors.phoneNumber}
                    validateField={validateField}
                  />
                </View>
                <View {...styles.formGroupContainer}>
                  <DestinationInput
                    handleFieldChange={setFieldValue}
                    label={t('DESTINATION_PLACEHOLDER')}
                    fieldName="destination"
                    errorMsg={errors.destination}
                    validateField={validateField}
                  />
                </View>
                <View {...styles.checkboxContainer}>
                  <CheckBoxTermsCondition
                    onChange={setFieldValue}
                    fieldName="termsCondition"
                    errorMsg={errors.termsCondition}
                    validateField={validateField}
                  />
                </View>
              </View>
              <View {...styles.formGroupButton}>
                <Button
                  //@ts-ignore
                  {...styles.applyButton}
                  onPress={handleSubmit}>
                  {!loading ? (
                    <Text {...styles.applyButtonText}>{t('SIGN_UP')}</Text>
                  ) : (
                    <ActivityIndicator color="customWhite" size="small" />
                  )}
                </Button>
                <View {...styles.loginRedirect}>
                  <NavPressable onPress={handleOnLogin} textStyle={styles.text}>
                    {redirectText}
                  </NavPressable>
                  <View {...styles.hr} />
                </View>
              </View>
            </Box>
          </ScrollView>
        )}
      </Formik>
    </KeyboardAvoidingView>
  );
};
