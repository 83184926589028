import { useState, useRef, useContext, useEffect } from 'react';
import { View, Button, Text, HStack, Box, CloseIcon, Pressable, Divider } from 'native-base';
import SectionedMultiSelect from 'react-native-sectioned-multi-select';
import { MaterialIcons } from '@expo/vector-icons';
import { useTranslation } from 'react-i18next';
import { AppTheme } from 'constants/Theme';
import { CitiesContext, City } from 'contexts/CitiesContext';
import DownArrowIcon from '../../assets/icons/Down-arrow.svg';

const styles = {
  button: {
    bg: 'styleSheet.canvas',
    _text: {
      color: 'styleSheet.overCast',
      fontSize: '2md',
      fontFamily: 'body',
      fontWeight: '400',
      textDecorationLine: 'underline',
    },
    _pressed: {
      bg: 'styleSheet.canvas',
      opacity: '0.3',
    },
  },

  icon: {
    color: 'pdp.shadow',
    alignSelf: 'center' as const,
    size: '4',
  },

  headertext: {
    position: 'absolute' as const,
    left: '35%',
    color: 'white',
    fontSize: 'md',
    alignSelf: 'center' as const,
  },

  text: {
    pt: '15px',
    fontSize: '2md',
    fontFamily: 'body',
    fontWeight: '400',
    color: 'styleSheet.shadow',
  },

  hstack: {
    ml: '14px',
    flex: 1,
    justifyContent: 'flex-start' as const,
    overflow: 'hidden',
    space: 2,
  },

  buttonStack: {
    mt: '24px',
    mb: '34px',
    space: '2',
    pl: '5px',
  },

  unSelectedBox: {
    w: '25px',
    h: '25px',
    borderRadius: '5px',
    borderWidth: 1,
    marginRight: '12px',
    borderColor: 'styleSheet.shadow',
    opacity: '0.7',
  },

  selectedBox: {
    w: '25px',
    h: '25px',
    borderRadius: '5px',
    borderWidth: 1,
    marginRight: '12px',
    justifyContent: 'center' as const,
    alignItems: 'center' as const,
    borderColor: 'styleSheet.shadow',
    bg: 'styleSheet.shadow',
    opacity: '0.7',
  },
  pressable: {
    h: '40px',
    w: '50px',
    justifyContent: 'center' as const,
  },
  divider: {
    variant: 'primary',
    w: '110%',
    ml: '-17px',
    mt: '10px',
  },
  title: {
    color: 'styleSheet.overCast',
    fontSize: '2md',
    fontFamily: 'body600',
    alignSelf: 'center' as const,
  },
  headerHstack: {
    w: '100%',
    mt: '20px',
    pl: '16px',
    justifyContent: 'space-between' as const,
  },
};

interface IItem {
  name: string;
  city: string;
}

interface IDropDownProps {
  selectedCities: City[];
  onSelectionChanged(selectedCities: any[]);
  isOpen: boolean;
  setIsOpen: (e: boolean) => void;
}

const DropDownPopup = ({ onSelectionChanged, selectedCities }: IDropDownProps) => {
  const { cities } = useContext(CitiesContext);
  const [items, setItems] = useState<IItem[]>([]);
  const [selectedCitiesName, setSelectedCitiesName] = useState<string[]>([]);
  const [originalSelectedCitiesNames, setOriginalSelectedCitiesNames] = useState<string[]>([]);

  const ref = useRef<any>(null);

  useEffect(() => {
    setItems(
      (cities || []).map((city) => ({
        name: city.full! === 'My Home' ? `${t('YOUR_HOME')} (${city.nickname})` : city.nickname!,
        city: city.full!,
      }))
    );
  }, [cities]);

  useEffect(() => {
    if (!!selectedCities) {
      setSelectedCitiesName(selectedCities.map((city) => city.full!));
    }
  }, [selectedCities]);

  const { t } = useTranslation();

  const onToggleSelector = (visible: boolean) => {
    if (visible) {
      setOriginalSelectedCitiesNames(selectedCitiesName);
    }
  };

  const _setSelectedCitiesName = (cityNames: string[]) => {
    if (cityNames.length === 0 && !!cities && cities?.length > 0) {
      setSelectedCitiesName([cities[0].full!]);
    } else {
      setSelectedCitiesName(cityNames);
    }
  };

  const getSelectedCities = () => (cities || []).filter((c) => selectedCitiesName.indexOf(c.full!) !== -1);

  const header = () => {
    return (
      <View bg="styleSheet.canvas">
        <HStack {...styles.headerHstack}>
          <Text {...styles.title}>{t('SELECT_LOCATIONS')}</Text>
          <Pressable
            onPress={() => {
              _setSelectedCitiesName(originalSelectedCitiesNames);
              ref && !!ref.current && ref.current._toggleSelector();
            }}
            {...styles.pressable}>
            <CloseIcon {...styles.icon} />
          </Pressable>
        </HStack>
        <Divider {...styles.divider} />
        <HStack {...styles.buttonStack}>
          <Button
            children="Select all"
            onPress={() => {
              _setSelectedCitiesName((cities || []).map((city) => city.full!));
            }}
            {...styles.button}
          />
          <Button
            children="Clear all"
            onPress={() => {
              _setSelectedCitiesName([]);
            }}
            {...styles.button}
          />
        </HStack>
      </View>
    );
  };

  const renderText = () => {
    const selectedCities = getSelectedCities();

    if (!selectedCities.length) return;

    const locationText = selectedCities.length > 1 ? t('LOCATIONS') : t('LOCATION');

    const getDisplayName = (city: City[]) => {
      if (city.length === 1) {
        return city[0].full! === 'My Home' ? `${t('YOUR_HOME')} (${city[0].nickname})` : city[0].nickname!;
      } else {
        return city[0].full! === 'My Home' ? `${t('YOUR_HOME')}` : city[0].nickname!;
      }
    };

    if (selectedCities && selectedCities.length === 1) {
      return (
        <HStack {...styles.hstack}>
          <Text {...styles.text}>{getDisplayName(selectedCities)}</Text>
        </HStack>
      );
    } else if (selectedCities && selectedCities.length > 1) {
      return (
        <HStack {...styles.hstack}>
          <Text {...styles.text}>{`${getDisplayName(selectedCities)} & ${
            selectedCities.length - 1
          } ${locationText}`}</Text>
        </HStack>
      );
    } else {
      return <HStack {...styles.hstack}></HStack>;
    }
  };

  const unSelectedIcon = () => {
    return <Box {...styles.unSelectedBox}></Box>;
  };

  const selectedIcon = () => {
    return (
      <Box {...styles.selectedBox}>
        <MaterialIcons name="check" size={17} color="white" />
      </Box>
    );
  };

  const toggleIcon = () => {
    return <DownArrowIcon height={30} width={30} />;
  };

  return (
    <View>
      <SectionedMultiSelect
        ref={ref}
        items={items}
        IconRenderer={MaterialIcons}
        selectToggleIconComponent={toggleIcon}
        uniqueKey="city"
        displayKey="name"
        showDropDowns={true}
        onToggleSelector={onToggleSelector}
        onSelectedItemsChange={_setSelectedCitiesName}
        selectedItems={selectedCitiesName}
        hideSearch={true}
        showChips={false}
        confirmText={`Add ${selectedCitiesName.length === 0 ? '' : selectedCitiesName.length} ${
          selectedCitiesName.length <= 1 ? t('LOCATION') : t('LOCATIONS')
        }`}
        renderSelectText={renderText}
        headerComponent={header}
        unselectedIconComponent={unSelectedIcon}
        selectedIconComponent={selectedIcon}
        selectedIconOnLeft={true}
        onConfirm={() => {
          onSelectionChanged(getSelectedCities());
        }}
        colors={{
          itemBackground: AppTheme.colors.styleSheet.canvas,
        }}
        styles={{
          container: {
            height: '130%',
            width: '100%',
            borderRadius: 0,
            alignSelf: 'center',
            bottom: -26,
            backgroundColor: AppTheme.colors.styleSheet.canvas,
          },
          item: {
            backgroundColor: AppTheme.colors.styleSheet.canvas,
            paddingLeft: 6,
            paddingBottom: 20,
          },
          separator: {
            display: 'none',
          },
          itemText: {
            color: AppTheme.colors.styleSheet.overCast,
            fontSize: 17,
            fontFamily: 'TWKL-400',
          },
          selectToggle: {
            width: '100%',
            height: 67,
            alignContent: 'center',
            paddingLeft: 2,
            paddingRight: 10,
          },
          button: {
            width: '100%',
            alignSelf: 'center',
            height: 65,
            backgroundColor: AppTheme.colors.styleSheet.shadow,
          },
          confirmText: {
            color: AppTheme.colors.styleSheet.canvas,
            fontFamily: 'TWKL-400',
          },
          selectToggleText: { alignSelf: 'flex-start' },
        }}
      />
    </View>
  );
};

export default DropDownPopup;
