import { FC, useContext, useMemo, useState, useEffect } from 'react';
import { ReservationContext } from 'contexts/reservationContext';
import {
  Box,
  ScrollView,
  View,
  Button,
  Hidden,
  NativeBaseProvider,
  useBreakpointValue,
  Text,
  Divider,
  VStack,
  Pressable,
  CloseIcon,
  HStack,
  WarningOutlineIcon,
} from 'native-base';
import { RootStackScreenProps } from 'types';
import { H3 } from 'components/libs/heading/Heading';
import { useTranslation } from 'react-i18next';
import HouseTitleBox from 'components/ConfirmationPage/HouseTitleBox';
import DateConfirmationBox from 'components/ConfirmationPage/DateConfirmationBox';
import GuestBox from 'components/ConfirmationPage/GuestBox';
import { useCreateReservationMutation } from './mutation/createReservation.generated';
import { useGetUserQuery } from './queries/getUser.generated';
import { useGetPriceCalculationQuery } from './queries/getPriceCalculation.generated';
import SubscriptionOverviews from 'components/SubscriptionDetails/SubscriptionOverview';
import { Loading } from 'components/Loading';
import { NavigationContext } from 'contexts/NavigationContext';
import { SubscriptionContext } from 'contexts/SubscriptionContext';
import UpdateGuestBox from 'components/Guests/UpdateGuestBox';
import { AppTheme } from 'constants/Theme';
import ProcessToast from 'components/ConfirmationPage/ProcessToast';
import BackArrow from 'components/ConfirmationPage/BackArrow';
import CancelationPolicy from 'components/ConfirmationPage/CancelationPolicy';
import PaymentBlock from 'components/ConfirmationPage/PaymentBlock';
import Modal from 'react-native-modal';
import { CalendarProvider } from 'providers/CalendarProvider';
import BookCalendar from 'components/Calendars/BookCalendar';
import moment from 'moment';
import { usePersistentValue } from 'hooks/usePersistentValue';
import AlertBox from 'components/ConfirmationPage/AlertBox';
import Layout from 'constants/Layout';
import { PropertyDetailContext } from 'contexts/PropertyDetailContext';

const windowWidth = Layout.window.width;
const windowHeight = Layout.window.height;

const styles = {
  box: {
    bg: 'bgHeader',
    pt: '12px',
    pl: '16px',
    pr: '16px',
    pb: '40px',
  },
  stickyBar: {
    position: 'absolute' as const,
    bottom: '0',
    bg: 'styleSheet.shadow',
    w: '100%',
    right: 0,
    h: '70px',
    borderRadius: '0',
    zIndex: 1,
    _text: {
      color: 'styleSheet.canvas',
      fontFamily: 'body',
      fontSize: '2md',
    },
    _pressed: {
      bg: 'styleSheet.shadow',
      opacity: '0.6',
      _text: { color: 'styleSheet.canvas' },
    },
    _hover: {
      bg: 'styleSheet.darkStain',
      _text: { color: 'styleSheet.shadow' },
    },
    _focus: {
      bg: 'styleSheet.shadow',
    },
  },
  h3: {
    fontSize: '1xl',
    color: 'styleSheet.shadow',
    fontFamily: 'body600',
    mt: '24px',
    mb: '24px',
  },
  page: {
    ml: '16px',
    mr: '16px',
    mt: '16px',
    alignItems: 'center' as const,
  },
  mainView: {
    flexDirection: ['column' as const, 'column' as const, 'column' as const, 'row' as const, 'row' as const],
    w: ['100%', '100%', '100%', windowWidth - windowWidth * 0.35, windowWidth - windowWidth * 0.25],
    alignSelf: 'center' as const,
  },
  detailView: {
    w: ['100%', '100%', windowWidth, '60%', '60%'],
    pr: ['0px', '0px', '20%', '80px', '100px'],
    pl: ['0px', '0px', '20%', '0px', '0px'],
  },
  paymentView: {
    w: ['100%', '100%', '100%', '40%', '40%'],
    pl: ['0px', '0px', '20%', '0px', '0px'],
    pr: ['0px', '0px', '20%', '0px', '0px'],
  },
  container: {
    h: '100%',
    w: '100%',
    bg: 'styleSheet.canvas',
  },
  overLay: {
    position: 'absolute' as const,
    w: windowWidth,
    h: windowHeight,
    top: '10%',
    borderRadius: 20,
    borderWidth: '1px',
    borderColor: 'styleSheet.canvas',
    zIndex: 100,
  },
  calendarView: {
    bg: 'styleSheet.canvas',
    w: ['100%', '100%', '100%', '80%', '70%'],
    h: [null, null, '60%', windowHeight - 100, windowHeight - 100],
    borderRadius: [0, 0, 20, 20, 20],
    borderWidth: ['0px', '0px', '1px', '1px', '1px'],
    borderColor: 'styleSheet.canvas',
    alignSelf: 'center' as const,
  },
  title: {
    color: 'pdp.shadow',
    fontSize: '2md',
    fontFamily: 'body600',
    ml: '40px',
    mt: '24px',
    mb: '24px',
  },
  divider: {
    w: '100%',
    color: 'pdp.shadow',
    borderWidth: '1px',
    opacity: '0.15',
  },
  buttonBox: {
    w: '30%',
    h: '70px',
    alignSelf: 'center' as const,
    borderRadius: '8px',
    bg: 'styleSheet.shadow',
    position: 'absolute' as const,
    right: '40px',
    _text: {
      color: 'styleSheet.canvas',
      fontSize: '2md',
      fontFamily: 'body',
    },
    _pressed: {
      bg: 'styleSheet.shadow',
      opacity: '0.6',
      _text: { color: 'styleSheet.canvas' },
    },
    _hover: {
      bg: 'styleSheet.darkStain',
      _text: { color: 'styleSheet.shadow' },
    },
    _focus: {
      bg: 'styleSheet.shadow',
    },
  },
  buttonBoxDisabled: {
    position: 'absolute' as const,
    right: '40px',
    alignSelf: 'center' as const,
    opacity: '0.6',
    w: '30%',
    h: '70px',
    borderRadius: '8px',
    bg: 'styleSheet.shadow',
    _text: {
      color: 'styleSheet.canvas',
      fontSize: '2md',
      fontFamily: 'body',
    },
    _pressed: {
      bg: 'styleSheet.shadow',
      opacity: '0.6',
      _text: { color: 'styleSheet.canvas' },
    },
    _hover: {
      bg: 'styleSheet.darkStain',
      _text: { color: 'styleSheet.shadow' },
    },
    _focus: {
      bg: 'styleSheet.shadow',
    },
  },
  pressable: {
    h: '40px',
    w: '40px',
    position: 'absolute' as const,
    right: 2,
    alignSelf: 'center' as const,
    justifyContent: 'center' as const,
  },
  icon: {
    color: 'pdp.shadow',
    size: '4',
    alignSelf: 'cenre' as const,
  },
  textMsg: {
    fontFamily: 'body',
    fontSize: 'md',
    color: 'styleSheet.shadow',
    ml: '4px',
    alignSelf: 'center' as const,
  },
  hstackCal: {
    pl: '40px',
    alignItems: 'center' as const,
  },
  calendarBoxBottom: {
    position: 'absolute' as const,
    bottom: 0,
    w: '100%',
  },
  calBottom: {
    h: '100px',
  },
  calendarBox: {
    mr: 30,
    ml: 30,
    mt: [null, null, 10, 10, 10],
  },
};

interface IUpdateBoxModal extends RootStackScreenProps<'BookingCalendar'> {}

const Confirmation: FC<RootStackScreenProps<'Confirmation'>> = ({ route }, Iupdate: IUpdateBoxModal) => {
  let breakpointTypeValue = useBreakpointValue({ base: 1, sm: 2, md: 3, lg: 4, xl: 5 });
  const { t } = useTranslation();
  const { useNavigation } = useContext(NavigationContext);
  const { subscriptionBalance, refetch } = useContext(SubscriptionContext);
  const { checkInDate, checkOutDate, guests, setCheckInDate, setCheckOutDate, setGuests } =
    useContext(ReservationContext);
  const { listing, listingId, setListingId, setMinNightsRequired, validateNightSelection } =
    useContext(PropertyDetailContext);
  const [loader, setLoader] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [nickname, setNickname] = useState<string | null | undefined>(null);
  const [isTextDisplay, setIsTextDisplay] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [calendarModal, setCalendarModal] = useState<boolean>(false);
  const [widthDims, setWidthDims] = useState(0);
  const [disabledButton, setDisabledButton] = useState<boolean>(false);
  const [displayMsgBox, setDisplayMsgBox] = useState<boolean>(false);
  const [msg, setMsg] = useState<string>('');
  const [checkIn, setCheckIn] = useState(route.params?.checkInDate);
  const [checkOut, setCheckOut] = useState(route.params?.checkOutDate);
  const [storedSubscriptionId, setstoredSubscriptionId] = usePersistentValue<string | null>(
    'storedSubscriptionId',
    null
  );
  const [reservationError, setReservationError] = useState(false);

  const { data: user, loading: userDetailLoading } = useGetUserQuery();

  const { data: price, loading } = useGetPriceCalculationQuery({
    variables: {
      from: checkInDate,
      to: checkOutDate,
      guestyListingId: listingId ? listingId : route.params?.listingId!,
    },
    skip: !checkInDate || !checkOutDate,
  });

  useEffect(() => {
    if (!!route.params?.checkInDate && !!route.params?.checkOutDate) {
      setCheckInDate(route.params.checkInDate);
      setCheckOutDate(route.params.checkOutDate);
    }
  }, [route.params?.checkInDate, route.params?.checkOutDate]);

  useEffect(() => {
    if (!!route.params?.listingId) {
      setListingId(route.params.listingId);
    }
  }, [route.params?.listingId]);

  const nightSelection = useMemo(() => moment(checkOut).diff(moment(checkIn), 'days'), [checkIn, checkOut]);

  useEffect(() => {
    const { isValid, errorMessage } = validateNightSelection(nightSelection);
    if (isValid) {
      setMsg('');
      setDisabledButton(false);
      setDisplayMsgBox(false);
    } else {
      setMsg(errorMessage);
      setDisabledButton(true);
      setDisplayMsgBox(true);
    }
  }, [nightSelection, validateNightSelection]);

  const [createReservation, { loading: creatingReservation, error: createReservationError }] =
    useCreateReservationMutation();

  const usedAmount = useMemo(() => subscriptionBalance?.usedAmount, [subscriptionBalance]);
  const remainingBalance = useMemo(() => subscriptionBalance?.remainingAmount, [subscriptionBalance]);
  const currentBookingPrice = useMemo(
    () => price?.getPriceCalculation.data?.totalPriceWithTaxAndDiscount,
    [price]
  );

  useEffect(() => {
    if (route.params?.adults || route.params?.children || route.params?.pets || route.params?.infants) {
      setGuests({
        adults: Number(route.params?.adults),
        children: Number(route.params?.children),
        infants: Number(route.params?.infants),
        pets: Number(route.params?.pets),
      });
    }
  }, [route.params?.adults, route.params?.children, route.params?.pets, route.params?.infants]);

  useEffect(() => {
    let propNickname: any = [];
    if (listing?.customFields) {
      propNickname = listing?.customFields?.filter((res) => res.key === 'DND_Summer App Nickname');
      setNickname(propNickname[0]?.value);
    }
  }, [listing]);
  const email = user?.getUser.data?.email;
  const areaTitle = `${listing?.address?.city}, ${listing?.address?.state}`;

  const handleUpdateGuest = () => {
    if (breakpointTypeValue == 3 || breakpointTypeValue == 4 || breakpointTypeValue == 5) {
      setShowModal(true);
    } else {
      setShowModal(false);
      useNavigation('UpdateGuests');
    }
  };

  const getProjectedRemainingBalance = () => {
    if (!remainingBalance || !currentBookingPrice) {
      return null;
    }

    return remainingBalance - currentBookingPrice;
  };

  const handleReservation = async () => {
    await createReservation({
      variables: {
        data: {
          guestyListingId: listingId ? listingId : route.params?.listingId,
          subscriptionId: storedSubscriptionId!,
          checkInDateLocalized: checkInDate ? checkInDate : route.params?.checkInDate,
          checkOutDateLocalized: checkOutDate ? checkOutDate : route.params?.checkOutDate,
          status: 'confirmed',
          guestGroupComposition: {
            adults: guests.adults,
            children: guests.children,
            pets: guests.pets,
            infants: guests.infants,
          },
        },
      },
      onCompleted: (data: any) => {
        refetch().then(() => {
          if (data.createReservation.status.code === 1000) {
            setReservationError(false);
            setLoader(false);
            useNavigation('ThankYou', { emailId: email });
          } else {
            setReservationError(true);
            setLoader(false);
          }
        });
      },
      onError: () => {
        //TODO will do the error handling later when mocks are ready
        if (__DEV__) console.log(createReservationError);
      },
    });
  };

  if (loading || userDetailLoading) {
    return <Loading />;
  }
  const handleCalendarPress = () => {
    if (breakpointTypeValue == 3 || breakpointTypeValue == 4 || breakpointTypeValue == 5) {
      setCalendarModal(true);
    } else {
      setCalendarModal(false);
      useNavigation('BookingCalendar', {
        screenName: 'Confirmation',
        checkInDate,
        checkOutDate,
        listingId,
      });
    }
  };

  const handleCloseModal = () => {
    setCheckIn(checkInDate);
    setCheckOut(checkOutDate);
    setCalendarModal(false);
  };

  const btnBoxStyle = disabledButton ? styles.buttonBoxDisabled : styles.buttonBox;

  return (
    <Box safeArea {...styles.container}>
      {reservationError ? (
        <AlertBox show={reservationError} setShow={setReservationError} setDisabled={setDisabled} />
      ) : null}

      <Hidden till={'md'}>
        <Box display={showModal ? 'flex' : 'none'} {...styles.overLay}>
          <NativeBaseProvider theme={AppTheme}>
            <Modal isVisible={showModal}>
              <UpdateGuestBox navigation={Iupdate.navigation} route={Iupdate.route} setShowModal={setShowModal} />
            </Modal>
          </NativeBaseProvider>
        </Box>
      </Hidden>

      <Hidden till={'md'}>
        <Box display={calendarModal ? 'flex' : 'none'} {...styles.overLay}>
          <NativeBaseProvider theme={AppTheme}>
            <Modal isVisible={calendarModal}>
              <VStack {...styles.calendarView}>
                <HStack>
                  <Text {...styles.title}>{t('CHANGE_DATES')}</Text>
                  <Pressable onPress={handleCloseModal} {...styles.pressable}>
                    <CloseIcon {...styles.icon} />
                  </Pressable>
                </HStack>

                <Divider {...styles.divider} />
                <Box
                  {...styles.calendarBox}
                  onLayout={(event) => {
                    const { width } = event.nativeEvent.layout;
                    setWidthDims(width);
                  }}>
                  <CalendarProvider listingId={route.params?.listingId}>
                    <BookCalendar
                      isLargeScreen={true}
                      isFromBooking={true}
                      weekVerticalMargin={6}
                      setCalendarWidth={widthDims / 2.09}
                      selectedDates={[checkIn, checkOut].filter((d) => !!d) as string[]}
                      pastScrollRange={0}
                      futureScrollRange={12}
                      setMinNight={setMinNightsRequired}
                      onSelectedDatesChanged={(checkInDate1, checkOutDate1) => {
                        if (checkInDate1 && checkOutDate1) {
                          setCheckIn(moment(checkInDate1).format('YYYY-MM-DD'));
                          setCheckOut(moment(checkOutDate1).format('YYYY-MM-DD'));
                        }
                      }}
                    />
                  </CalendarProvider>
                </Box>

                <Box {...styles.calendarBoxBottom}>
                  <Divider {...styles.divider} />
                  <HStack {...styles.calBottom}>
                    {disabledButton && displayMsgBox ? (
                      <HStack {...styles.hstackCal}>
                        <WarningOutlineIcon {...styles.icon} />
                        <Text {...styles.textMsg}>{msg}</Text>
                      </HStack>
                    ) : (
                      <HStack {...styles.hstackCal}>
                        <Text {...styles.textMsg}>
                          {moment(checkIn).format('dddd, MMMM DD')} - {moment(checkOut).format('dddd, MMMM DD')}
                        </Text>
                      </HStack>
                    )}

                    <Button
                      {...btnBoxStyle}
                      disabled={disabledButton}
                      children={t('CONFIRMATION.CHANGE_DATES')}
                      onPress={() => {
                        setCalendarModal(false);
                        setCheckInDate(moment(checkIn).format('YYYY-MM-DD'));
                        setCheckOutDate(moment(checkOut).format('YYYY-MM-DD'));
                      }}
                    />
                  </HStack>
                </Box>
              </VStack>
            </Modal>
          </NativeBaseProvider>
        </Box>
      </Hidden>

      <ScrollView>
        {loader && <ProcessToast />}
        <BackArrow />
        <View {...styles.page}>
          <View>
            <View {...styles.mainView}>
              <View {...styles.detailView}>
                <H3 {...styles.h3}>{t('TRIP_DETAILS')}</H3>
                <HouseTitleBox homeTitle={nickname} areaTitle={areaTitle} />
                <DateConfirmationBox Checkin={checkInDate} Checkout={checkOutDate} onPress={handleCalendarPress} />
                <GuestBox onPress={handleUpdateGuest} />
                <SubscriptionOverviews
                  usedAmount={usedAmount}
                  currentBooking={currentBookingPrice}
                  remainingBalance={getProjectedRemainingBalance()}
                  text={isTextDisplay}
                  setTextDisplay={setIsTextDisplay}
                />
                <CancelationPolicy />
              </View>
              <View {...styles.paymentView}>
                <PaymentBlock
                  disabled={disabled}
                  handleReservation={handleReservation}
                  setDisabled={setDisabled}
                  setLoader={setLoader}
                  days={price?.getPriceCalculation.data?.noOfDays}
                  basePrice={price?.getPriceCalculation.data?.basePrice}
                  discount={price?.getPriceCalculation.data?.discount}
                  cleaningFee={price?.getPriceCalculation.data?.cleaningFees}
                  totalTax={price?.getPriceCalculation.data?.totalTax}
                  totalPrice={price?.getPriceCalculation.data?.totalPriceWithTaxAndDiscount}
                />
              </View>
            </View>
          </View>
        </View>
      </ScrollView>
      <Hidden from={'lg'}>
        <Button
          disabled={disabled}
          children={t('CONFIRM_BOOKING')}
          onPress={() => {
            setDisabled(true);
            setLoader(true);
            handleReservation();
          }}
          {...styles.stickyBar}
        />
      </Hidden>
    </Box>
  );
};

export default Confirmation;
