import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomInputProps } from 'src/styles/input';
import { CustomTextInput } from './CustomTextInput';

const styles = {
  style: { width: '100%' },
};
export const PhoneNumberInput: FC<CustomInputProps> = ({
  handleFieldChange,
  label,
  fieldName,
  errorMsg,
  validateField,
  placeholder,
  backgroundColor
}) => {
  const { t } = useTranslation();
  return (
    <CustomTextInput
      style={styles.style}
      label={label}
      handleFieldChange={handleFieldChange}
      fieldName={fieldName}
      errorMsg={errorMsg}
      keyboardType="phone-pad"
      placeholder={placeholder ?? t('PHONE_NUMBER_PLACEHOLDER')}
      validateField={validateField}
      backgroundColor={backgroundColor}
    />
  );
};
