import { NavigationContext } from 'contexts/NavigationContext';
import { Button, Avatar } from 'native-base';
import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PersonalInfoIcon from 'assets/icons/PersonalInfo.svg';

const styles = {
  personalInfoButton: {
    variant: 'primaryMobile',
    bg: 'styleSheet.lightStain',
    _text: {
      fontSize: '2md',
      fontFamily: 'body',
      color: 'styleSheet.shadow',
    },
    h: '70px',
    w: '100%',
    justifyContent: 'flex-start' as const,
    mt: '32px',
    _hover: {
      bg: 'styleSheet.hover',
      _text: { color: 'styleSheet.shadow' },
    },
  },
  avatar: {
    bg: 'styleSheet.canvas',
  },
};

const ProfileInfo: FC = () => {
  const { t } = useTranslation();
  const { useNavigation } = useContext(NavigationContext);

  const handleProfileInfo = () => {
    useNavigation('Profile', {
      screen: 'ProfileScreen',
    });
  };

  return (
    <Button
      onPress={handleProfileInfo}
      {...styles.personalInfoButton}
      startIcon={
        <Avatar {...styles.avatar}>
          <PersonalInfoIcon />
        </Avatar>
      }>
      {t('PROFILE.PERSONAL_INFO')}
    </Button>
  );
};
export default ProfileInfo;
