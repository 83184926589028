import { UserContext } from 'contexts/UserContext';
import moment from 'moment';
import { Box, Text } from 'native-base';
import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';

const styles = {
  avtarForInitial: {
    bg: 'styleSheet.lightShadow',
    alignSelf: 'center' as const,
    mt: '40px',
  },
  initials: {
    color: 'styleSheet.canvas',
    fontSize: '4xl',
    fontFamily: 'body',
  },
  name: {
    fontSize: '2xl',
    fontFamily: 'body',
    color: 'styleSheet.shadow',
    alignSelf: 'center' as const,
    textTransform: 'capitalize' as const,
    mt: '12px',
  },
  memberSince: {
    fontSize: 'sm',
    fontFamily: 'body',
    color: 'styleSheet.overCast',
    alignSelf: 'center' as const,
    mt: '4px',
  },
};
const UserDetail: FC = () => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const memberDate = moment(user?.consentDate).year();
  return (
    <Box>
      <Text {...styles.name}>
        {user?.given_name} {user?.family_name}
      </Text>
      <Text {...styles.memberSince}> {t('PROFILE.MEMBER_SINCE', { year: memberDate })} </Text>
    </Box>
  );
};
export default UserDetail;
