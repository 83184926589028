import { useContext, useState, useEffect, useMemo, FC } from 'react';
import { Platform } from 'react-native';
import {
  Text,
  View,
  VStack,
  Box,
  HStack,
  CloseIcon,
  Pressable,
  Divider,
  Hidden,
  useBreakpointValue,
  NativeBaseProvider,
  Button,
} from 'native-base';
import { useTranslation } from 'react-i18next';
import DateInput from 'components/Calendars/DateInput';
import CTAButton from 'components/libs/button/CTAButton';
import { ReservationContext } from 'contexts/reservationContext';
import { RootStackScreenProps } from 'types';
import { AppTheme } from 'constants/Theme';
import { useGetPriceCalculationQuery } from 'screens/Booking/queries/getPriceCalculation.generated';
import ReservationAmount from 'components/PropertyDetail/ReservationAmount';
import Layout from 'constants/Layout';
import { PropertyDetailContext } from 'contexts/PropertyDetailContext';
import moment from 'moment';
import CTABooking from '../CTABooking';
import { NavigationContext } from 'contexts/NavigationContext';
import UpdateGuestBox from 'components/Guests/UpdateGuestBox';
import Modal from 'react-native-modal';
import DownArrowIcon from '../../../assets/icons/Down-arrow.svg';
import { Loading } from 'components/Loading';

const windowHeight = Layout.window.height;
const windowWidth = Layout.window.width;

const styles = {
  box: {
    backgroundColor: 'customBlack',
    height: '48px',
    justifyContent: 'center' as const,
  },
  icon: {
    color: 'pdp.shadow',
    alignSelf: 'center' as const,
    size: '4',
  },
  page: {
    h: '100%',
    ml: [null, null, '20%', null, null],
    mr: [null, null, '20%', null, null],
  },
  divider: {
    variant: 'primary',
    w: '110%',
    ml: '-17px',
    mt: '20px',
  },

  vstack: {
    mt: '38px',
  },

  stickyBar: {
    position: 'absolute' as const,
    top: [0, 0, 50, 0, 0],
    bg: 'pdp.shadow',
    left: ['-36px', '-36px', '0px', '0px', '0px'],
    w: ['108%', '108%', '100%', '100%', '100%'],
    h: '70px',
    zIndex: 1,
    _text: {
      color: 'styleSheet.canvas',
      fontFamily: 'body600',
      fontSize: '2md',
    },
    _pressed: {
      bg: 'styleSheet.overCast',
      _text: { color: 'styleSheet.canvas' },
    },
  },
  buttonBox: {
    position: ['absolute' as const, 'absolute' as const, 'relative' as const, 'relative' as const, 'relative' as const],
    bottom: '15px',
    bg: ['black', 'black', 'black', 'black', 'styleSheet.canvas'],
    left: ['-17px', '-17px', '-60px', null, null],
    w: ['118%', '118%', '118%', '100%', '100%'],
    h: ['125px', '125px', '125px', '70px', '70px'],
    mb: ['30px', '30px', '30px', '0px', '0px'],
    mt: ['0px', '0px', '0px', '40px', '40px'],
  },
  title: {
    color: 'styleSheet.shadow',
    fontSize: '2md',
    fontFamily: 'body600',
    alignSelf: 'center' as const,
    pl: [null, null, '40px', null, null],
  },

  pressable: {
    h: '40px',
    w: '50px',
    p: '8px',
    justifyContent: 'center' as const,
    alignSelf: 'center' as const,
  },
  innerBox: {
    ml: '16px',
    mr: '16px',
    h: '100%',
    bg: 'styleSheet.canvas',
  },
  hstackHeader: {
    w: '100%',
    mt: '31px',
    justifyContent: 'space-between' as const,
  },
  bottonText: {
    color: 'pdp.shadow',
    fontSize: 'sm',
    fontFamily: 'body',
    alignSelf: 'center' as const,
  },
  textBox: {
    position: 'absolute' as const,
    top: windowHeight * 0.65,
    left: '10%',
  },
  checkIn: {
    fontSize: 'sm',
    ml: '15px',
    color: 'styleSheet.lightShadow',
    opacity: '0.55',
  },

  checkOut: {
    fontSize: 'sm',
    ml: Platform.OS === 'ios' ? '110px' : '118px',
    color: 'styleSheet.lightShadow',
    opacity: '0.55',
    pl: ['0px', '0px', '0px', '22px'],
  },
  hstack: {
    backgroundColor: 'styleSheet.canvas',
    mt: '14px',
  },
  dateBox: {
    mt: '32px',
    mb: '28px',
    borderWidth: '2',
    borderColor: 'styleSheet.darkStain',
    backgroundColor: 'styleSheet.canvas',
    h: '160px',
    w: '100%',
    overflow: 'hidden',
    borderRadius: '12px',
  },
  boxDivider: {
    borderWidth: '1',
    borderColor: 'styleSheet.darkStain',
    mt: '10px',
  },
  upperText: {
    fontFamily: 'body600',
    opacity: '0.55',
    fontSize: 'sm',
  },
  lowerText: {
    fontFamily: 'body',
    fontSize: '2md',
  },
  boxHstack: {
    alignItems: 'center' as const,
    justifyContent: 'space-between' as const,
    ml: '15px',
    mt: '15px',
  },
  msg: {
    alignItems: 'center' as const,
    justifyContent: 'center' as const,
    bg: 'pdp.buttonBg',
    borderRadius: '8px',
    mt: '35px',
    w: '100%',
  },
  msgBox: {
    mt: '10px',
  },
  textMsg: {
    p: '8px',
    fontFamily: 'body',
    fontSize: 'md',
    color: 'styleSheet.shadow',
  },
  bottomText: {
    fontSize: 'sm',
    color: 'styleSheet.shadow',
    fontFamily: 'body',
    textAlign: 'center' as const,
    mb: ['-30px', '-30px', null, '-30px', '-30px'],
    mt: [null, null, '40px', null, null],
  },
  mainView: {
    bg: 'styleSheet.canvas',
    h: '100%',
  },
  overLay: {
    position: 'absolute' as const,
    w: windowWidth,
    h: windowHeight,
    top: '10%',
    borderRadius: 20,
    borderWidth: '1px',
    borderColor: 'styleSheet.canvas',
    zIndex: 100,
  },
  loader: {
    mt: '50px',
  },
};

interface IPdpGuestModalProps extends RootStackScreenProps<'PdpGuestModal'> {
  listingID: string;
  checkInDatePdp: string | null;
  checkOutDatePdp: string | null;
  setShowModal: (state: boolean) => void;
  onShowCalendar: () => void;
}

interface IUpdateBoxModal extends RootStackScreenProps<'BookingCalendar'> {}
const PdpGuestModal: FC<IPdpGuestModalProps> = (
  { navigation, route, listingID, checkInDatePdp, checkOutDatePdp, setShowModal, onShowCalendar },
  Iupdate: IUpdateBoxModal
) => {
  let breakpointTypeValue = useBreakpointValue({ base: 1, sm: 2, md: 3, lg: 4, xl: 5 });
  const { t } = useTranslation();
  const { guests, setCheckInDate, setCheckOutDate, checkInDate, checkOutDate } = useContext(ReservationContext);
  const [price, setPrice] = useState<any>();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [msg, setMsg] = useState<string>('');
  const { listingId, setListingId, validateNightSelection } = useContext(PropertyDetailContext);
  const { useNavigation } = useContext(NavigationContext);
  const [showModal, setShowGuestModal] = useState<boolean>(false);

  useEffect(() => {
    setListingId(route ? route.params?.listingId : listingID);
    setCheckInDate(route.params?.checkInDate! ? route.params?.checkInDate! : checkInDatePdp!);
    setCheckOutDate(route.params?.checkOutDate! ? route.params?.checkOutDate! : checkOutDatePdp!);
  }, [
    route.params?.checkInDate!,
    route.params?.checkOutDate!,
    checkInDatePdp!,
    checkOutDatePdp!,
    listingID,
    route.params?.listingId,
  ]);

  const nightSelection = useMemo(
    () => moment(checkOutDate).diff(moment(checkInDate), 'days'),
    [checkInDate, checkOutDate]
  );
  useEffect(() => {
    const { isValid, errorMessage } = validateNightSelection(nightSelection);
    if (isValid) {
      setMsg('');
      setDisabled(false);
    } else {
      setMsg(errorMessage);
      setDisabled(true);
    }
  }, [nightSelection, validateNightSelection]);

  const { data, loading } = useGetPriceCalculationQuery({
    variables: {
      from: checkInDate,
      guestyListingId: listingId!,
      to: checkOutDate,
    },
    skip: !checkInDate || !checkOutDate || !listingId,
  });

  useEffect(() => {
    setPrice(data);
  }, [data]);

  const adults = guests.adults != 0 ? t('PDP_GUEST.ADULT', { count: guests.adults }) : '';

  const childrens = guests.children != 0 ? t('PDP_GUEST.CHILD', { count: guests.children }) : '';

  const infants = guests.infants != 0 ? t('PDP_GUEST.INFANT', { count: guests.infants }) : '';

  const pets = guests.pets != 0 ? t('PDP_GUEST.PET', { count: guests.pets }) : '';

  const guestCount = [adults, childrens, infants, pets].filter((value) => !!value).join(', ');

  const onSeeAvailableDates = () => {
    onShowCalendar();
  };

  const onBooking = () => {
    if (breakpointTypeValue == 3 || breakpointTypeValue == 4 || breakpointTypeValue == 5) {
      setShowModal(false);
    }
    useNavigation('BookingSubNavigator', {
      screen: 'Confirmation',
      params: {
        listingId: listingId,
        checkInDate: checkInDate,
        checkOutDate: checkOutDate,
        adults: guests.adults,
        children: guests.children,
        pets: guests.pets,
        infants: guests.infants,
      },
    });
  };

  const handleUpdateGuest = () => {
    if (breakpointTypeValue == 4 || breakpointTypeValue == 5) {
      setShowModal(true);
    } else if (breakpointTypeValue == 3) {
      setShowGuestModal(true);
    } else {
      navigation.navigate('UpdateGuests');
    }
  };

  const handleCloseModal = () => {
    if (breakpointTypeValue == 3) {
      setShowModal(false);
    } else {
      navigation.goBack();
    }
  };

  if (loading) {
    return (
      <Box {...styles.loader}>
        <Loading />
      </Box>
    );
  }

  return (
    <Box {...styles.mainView} safeAreaTop safeAreaBottom>
      <Hidden only={['base', 'sm', 'lg', 'xl']}>
        <Box display={showModal ? 'flex' : 'none'} {...styles.overLay}>
          <NativeBaseProvider theme={AppTheme}>
            <Modal isVisible={showModal}>
              <UpdateGuestBox setShowModal={setShowGuestModal} navigation={Iupdate.navigation} route={Iupdate.route} />
            </Modal>
          </NativeBaseProvider>
        </Box>
      </Hidden>

      <Box {...styles.innerBox}>
        <HStack {...styles.hstackHeader}>
          <Text {...styles.title}>{t('YOUR_STAY')}</Text>
          <Hidden from={'lg'}>
            <Pressable onPress={handleCloseModal} {...styles.pressable}>
              <CloseIcon {...styles.icon} />
            </Pressable>
          </Hidden>
        </HStack>
        <Hidden from={'lg'}>
          <Divider {...styles.divider} />
        </Hidden>
        <View {...styles.page}>
          <Box {...styles.dateBox}>
            <Pressable onPress={onShowCalendar}>
              <DateInput checkIn={checkInDate || ''} checkOut={checkOutDate || ''} />
            </Pressable>
            <Divider {...styles.boxDivider} />
            <HStack {...styles.boxHstack}>
              <VStack>
                <Text {...styles.upperText}>{t('PROPERTY_DETAIL.GUEST')}</Text>
                <Text {...styles.lowerText}>{guestCount}</Text>
              </VStack>
              <Pressable {...styles.pressable} onPress={handleUpdateGuest}>
                <DownArrowIcon />
              </Pressable>
            </HStack>
          </Box>

          {checkInDate && checkOutDate ? <ReservationAmount price={price} /> : null}

          {disabled ? (
            <Hidden till={'lg'}>
              <Box {...styles.msg}>
                <Text {...styles.textMsg}>{msg}</Text>
              </Box>
            </Hidden>
          ) : null}

          <Hidden till={'lg'}>
            <Box {...styles.buttonBox}>
              <CTABooking
                disabled={disabled}
                checkInDate={checkInDate}
                checkOutDate={checkOutDate}
                onSeeAvailableDates={onSeeAvailableDates}
                onBooking={onBooking}
                listingId={listingId!}
              />
            </Box>
          </Hidden>

          {checkInDate && checkOutDate && !disabled ? (
            <Hidden till={'md'}>
              <Text {...styles.bottomText}>{t('NO_PAYMENT_IS_NEEDED')}</Text>
            </Hidden>
          ) : null}

          <Hidden from={'lg'}>
            <Box>
              <Hidden only={['base', 'sm']}>
                <Button disabled={disabled} children={t('CONFIRM_BOOKING')} onPress={onBooking} {...styles.stickyBar} />
              </Hidden>
            </Box>
          </Hidden>

          <Hidden from={'md'}>
            <Box {...styles.buttonBox}>
              <CTAButton children={t('PDP_GUEST.BOOK_HOME')} onPress={onBooking} {...styles.stickyBar} />
            </Box>
          </Hidden>
        </View>
      </Box>
    </Box>
  );
};

export default PdpGuestModal;
