import { useContext, useMemo, useRef, useState, useEffect, FC } from 'react';
import CalendarDay from 'components/Calendars/CalendarDay';
import { CalendarList, LocaleConfig, Calendar } from 'react-native-calendars';
import { DateData, Theme } from 'react-native-calendars/src/types';
import { MarkingProps } from 'react-native-calendars/src/calendar/day/marking';
import moment from 'moment';
import { AppTheme } from 'constants/Theme';
import { CalendarContext } from 'contexts/CalendarContext';
import { AntDesign } from '@expo/vector-icons';
import { HStack, Pressable, useBreakpointValue } from 'native-base';

const CALENDAR_DATE_DATA_FORMAT = 'YYYY-MM-DD';

LocaleConfig.locales[LocaleConfig.defaultLocale].dayNamesShort = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

type MarkedDatesType = {
  [key: string]: MarkingProps;
};

interface BookCalendarProps {
  isLargeScreen?: boolean;
  isFromBooking?: boolean;
  setCalendarWidth?: number;
  pastScrollRange?: number;
  futureScrollRange?: number;
  list?: boolean;
  selectedDates: string[];
  weekVerticalMargin?: number;
  setMinNight?(nights: number);
  onSelectedDatesChanged?(checkInDate: Date, checkOutDate: Date);
}

const BookCalendar: FC<BookCalendarProps> = (props) => {
  const { periodAvailableStart, periodAvailableEnd, isSelectionValid, dayValues } = useContext(CalendarContext);
  const [selectedDates, setSelectedDates] = useState<Date[]>([]);
  const [markedDates, setMarkedDates] = useState<MarkedDatesType | any>({});
  const [oneSelectedDate, setOneSelectedDate] = useState<boolean>(false);
  const calendarListRef = useRef<any>();
  const calendarRef = useRef<any>();
  const initialDate = useMemo(
    () =>
      moment((props.selectedDates?.length && props.selectedDates[0]) || new Date()).format(CALENDAR_DATE_DATA_FORMAT),
    []
  );
  let breakpointTypeValue = useBreakpointValue({ base: 1, sm: 2, md: 3, lg: 4, xl: 5 });

  useEffect(() => {
    const selectedDateObjects = (props.selectedDates || []).map((d) => moment(d).toDate());
    setSelectedDates(selectedDateObjects);

    if (selectedDateObjects.length > 0) {
      const sorted = sortDates([...selectedDateObjects]);
      const firstDateStr = moment(sorted[0]).format('YYYY-MM-DD');
      if (props.setMinNight && dayValues[firstDateStr]) {
        props.setMinNight?.(dayValues[firstDateStr].minNights);
      }
    }
  }, [props.selectedDates]);

  const onSelectedDatesChanged = () => {
    const selectedDateRange = enumerateDaysBetweenDates(selectedDates[0], selectedDates[1]);
    const newMarkedDates = dateRangeToMarkedDates(selectedDateRange, false, oneSelectedDate);

    if (selectedDates && selectedDateRange.length === 2 && selectedDates[0] === selectedDates[1]) {
    } else if (selectedDates && selectedDateRange.length === 1 && selectedDates[0]) {
      setOneSelectedDate(true);
    } else {
      setOneSelectedDate(false);
    }
    setMarkedDates(newMarkedDates);
  };

  const _setSelectedDates = (dates: Date[]) => {
    if (!!isSelectionValid && !isSelectionValid(dates[0], dates[1])) {
      return;
    }
    setSelectedDates(dates);
    if (!!props.onSelectedDatesChanged) {
      props.onSelectedDatesChanged(dates[0], dates[1]);
    }
  };

  const onDayPress = (date: DateData) => {
    const selectedDate = dateDataToDate(date);

    if (selectedDates.length == 1 && selectedDates[0].getTime() == selectedDate.getTime()) {
      //nothing, skip this action to avoid a display issue
      _setSelectedDates([]);
    } else if (selectedDates.length == 0 || selectedDates.length >= 2) {
      _setSelectedDates([selectedDate]);
    } else {
      _setSelectedDates(sortDates([selectedDates[0], selectedDate]));
    }
  };

  const forwardMonth = () => {
    if (breakpointTypeValue == 1 || breakpointTypeValue == 2) {
      calendarRef.current?.addMonth(1);
    } else if (breakpointTypeValue == 3) {
      calendarRef.current?.addMonth(1);
      calendarListRef.current?.addMonth(2);
    } else if (breakpointTypeValue == 4 || (breakpointTypeValue == 5 && props.isFromBooking)) {
      calendarListRef.current?.addMonth(2);
      calendarRef.current?.addMonth(2);
    } else if (breakpointTypeValue == 5) {
      calendarListRef.current?.addMonth(3);
    }
  };
  const backwardMonth = () => {
    if (breakpointTypeValue == 1 || breakpointTypeValue == 2) {
      calendarRef.current?.addMonth(-1);
    } else if (breakpointTypeValue == 3) {
      calendarRef.current?.addMonth(-1);
      calendarListRef.current?.addMonth(-2);
    } else if (breakpointTypeValue == 4 || (breakpointTypeValue == 5 && props.isFromBooking)) {
      calendarListRef.current?.addMonth(-2);
      calendarRef.current?.addMonth(-2);
    } else if (breakpointTypeValue == 5) {
      calendarListRef.current?.addMonth(-3);
    }
  };

  useEffect(onSelectedDatesChanged, [selectedDates, oneSelectedDate]);

  if (props.list) {
    return (
      <Calendar
        current={initialDate}
        minDate={periodAvailableStart.format('YYYY-MM-DD')}
        maxDate={periodAvailableEnd.format('YYYY-MM-DD')}
        theme={calendarTheme}
        monthFormat={'MMMM yyyy'}
        onDayPress={onDayPress}
        markingType={'custom'}
        hideExtraDays={true}
        markedDates={markedDates}
        dayComponent={CalendarDay}
        ref={calendarRef}
        renderArrow={(direction) => {
          if (direction == 'left')
            return <AntDesign name="left" size={20} color="black" onPress={backwardMonth} style={{ marginTop: 10 }} />;
          if (direction == 'right')
            return <AntDesign name="right" size={20} color="black" onPress={forwardMonth} style={{ marginTop: 10 }} />;
        }}
      />
    );
  }
  if (props.isLargeScreen) {
    return (
      <HStack>
        <AntDesign name="left" size={20} color="black" onPress={backwardMonth} style={{ marginTop: '16px' }} />
        <CalendarList
          current={initialDate}
          minDate={periodAvailableStart ? periodAvailableStart.format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')}
          maxDate={
            periodAvailableEnd
              ? periodAvailableEnd.format('YYYY-MM-DD')
              : moment().add(12, 'months').format('YYYY-MM-DD')
          }
          pastScrollRange={props.pastScrollRange}
          futureScrollRange={props.futureScrollRange}
          theme={{
            textMonthFontWeight: 'bold',
            textMonthFontSize: 18,
            textDayHeaderFontWeight: '400',
            monthTextColor: AppTheme.colors.calendar.monthTextColor,
            calendarBackground: AppTheme.colors.bgPage,
            backgroundColor: AppTheme.colors.bgPage,
            weekVerticalMargin: props.weekVerticalMargin,
            dayTextColor: AppTheme.colors.styleSheet.darkStain,
          }}
          monthFormat={'MMMM yyyy'}
          onDayPress={onDayPress}
          markingType={'custom'}
          markedDates={markedDates}
          dayComponent={CalendarDay}
          horizontal={true}
          calendarHeight={50}
          calendarWidth={props.setCalendarWidth}
          ref={calendarListRef}
        />
        <Pressable onPress={forwardMonth} testID="arrow">
          <AntDesign name="right" size={20} color="black" style={{ marginTop: '16px' }} />
        </Pressable>
      </HStack>
    );
  }

  return (
    <CalendarList
      current={initialDate}
      minDate={periodAvailableStart.format('YYYY-MM-DD')}
      pastScrollRange={props.pastScrollRange}
      futureScrollRange={props.futureScrollRange}
      theme={calendarTheme}
      monthFormat={'MMMM yyyy'}
      onDayPress={onDayPress}
      markingType={'custom'}
      markedDates={markedDates}
      dayComponent={CalendarDay}
    />
  );
};

const enumerateDaysBetweenDates = (startDate: Date, endDate: Date) => {
  if (!startDate && !endDate) return [];
  if (!!startDate && !endDate) return [startDate];
  const dates = [];
  const currDate = moment(startDate).startOf('day');
  const lastDate = moment(endDate).startOf('day');

  while (currDate.add(1, 'days').diff(lastDate) < 0) {
    dates.push(currDate.clone().toDate() as never);
  }
  return [moment(startDate).startOf('day').toDate(), ...dates, moment(endDate).startOf('day').toDate()];
};

const sortDates = (dates: Date[]) => dates.sort((a, b) => a.toISOString().localeCompare(b.toISOString()));

const markedDate = (startingDay: boolean, endingDay: boolean) => ({
  selected: true,
  disableTouchEvent: false,
  startingDay,
  endingDay,
});

const dateDataToDate = (dataDate: DateData) =>
  moment(`${dataDate.year}-${dataDate.month}-${dataDate.day}`, 'YYYY-MM-DD').toDate();

const dateRangeToMarkedDates = (selectedDateRange: Date[], defaultDate: boolean, oneSelectedDate: boolean) =>
  selectedDateRange.reduce<MarkedDatesType>((result, date, i) => {
    const key = moment(date).format(CALENDAR_DATE_DATA_FORMAT);
    var length = selectedDateRange.length > 1 ? selectedDateRange.length - 1 : selectedDateRange.length;
    result[key] =
      defaultDate && !oneSelectedDate
        ? markedDate(i === 1, i === 1)
        : oneSelectedDate && !defaultDate
        ? markedDate(true, true)
        : markedDate(i === 0, i === length);
    return result;
  }, {});

const calendarTheme: Theme = {
  textMonthFontWeight: 'bold',
  textMonthFontSize: 18,
  textDayHeaderFontWeight: '400',
  monthTextColor: AppTheme.colors.calendar.monthTextColor,
  calendarBackground: AppTheme.colors.bgPage,
  backgroundColor: AppTheme.colors.bgPage,
  arrowColor: AppTheme.colors.pdp.shadow,
  dayTextColor: AppTheme.colors.styleSheet.darkStain,
};
export default BookCalendar;
