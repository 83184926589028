import { useContext, FC } from 'react';
import { Box, Button, Text } from 'native-base';
import { useTranslation } from 'react-i18next';
import { ReservationContext } from 'contexts/reservationContext';

const styles = {
  box: {
    bg: 'styleSheet.lightStain',
    borderRadius: '12px',
    pl: '26px',
    mt: '16px',
  },

  linkButton: {
    position: 'absolute' as const,
    right: '16px',
    top: '16px',
    w: '131px',
    bg: 'styleSheet.lightShadow',
    _text: {
      color: 'styleSheet.canvas',
      fontSize: 'sm',
      fontFamily: 'body',
      fontWeight: '400',
    },
    _pressed: {
      bg: 'styleSheet.shadow',
      opacity: '0.6',
      _text: { color: 'styleSheet.canvas' },
    },
    _hover: {
      bg: 'styleSheet.darkStain',
      _text: { color: 'styleSheet.shadow' },
    },
    _focus: {
      bg: 'styleSheet.shadow',
    },

    borderRadius: '8px',
    zIndex: 1,
  },

  text: {
    mt: '24px',
    mb: '8px',
    fontFamily: 'body600',
    fontSize: 'sm',
    color: 'styleSheet.lightOverCast',
  },

  innerText: {
    fontFamily: 'body600',
    fontSize: '1xl',
    color: 'styleSheet.shadow',
  },

  detail: {
    mt: '2px',
    mb: '24px',
    fontFamily: 'body',
    fontWeight: '400',
    fontSize: 'sm',
    color: 'styleSheet.shadow',
  },
};

interface IGuestBoxProps {
  onPress: () => void;
}

const GuestBox: FC<IGuestBoxProps> = ({ onPress }) => {
  const { guests } = useContext(ReservationContext);

  const { t } = useTranslation();

  const guestsNumber = guests.adults + guests.children;

  const adults = t('PDP_GUEST.ADULT', { count: guests.adults });

  const childrens = t('PDP_GUEST.CHILD', { count: guests.children });

  const infants = t('PDP_GUEST.INFANT', { count: guests.infants });

  const pets = t('PDP_GUEST.PET', { count: guests.pets });

  const guestCount = [adults, childrens, infants, pets].filter((value) => !!value).join(', ');

  const content =
    guests.adults === 1 && guests.children === 0 && guests.infants === 0 && guests.pets === 0 ? (
      <Text {...styles.detail}>{t('LET_US_KNOW')}</Text>
    ) : (
      <Text {...styles.detail}>{guestCount}</Text>
    );
  return (
    <Box {...styles.box}>
      <Button children={t('CHANGE_GUESTS')} onPress={onPress} {...styles.linkButton} />
      <Text {...styles.text}>{t('CONFIRMATION.GUESTS')}</Text>
      <Text {...styles.innerText}>
        {guestsNumber === 1 ? `${t('GUEST_NUMBER', { guestsNumber })}` : `${t('GUESTS_NUMBER', { guestsNumber })}`}
      </Text>
      {content}
    </Box>
  );
};

export default GuestBox;
