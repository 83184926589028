import { FC, useEffect, useState } from 'react';
import { Checkbox, HStack, Text, View } from 'native-base';
import { useTranslation } from 'react-i18next';
import { getErrorStyle } from '../../styles/baseStyles';
import { RequiredIcon } from 'components/SignUp/RequiredIcon';
import TermsConditionWebview from './TermsConditionWebview';
import { Platform } from 'react-native';

const customErrorDefault = {
  color: 'notification',
  fontSize: 'sm',
  fontWeight: '400',
  fontFamily: 'body',
  h: '20px',
  m: '0px',
};

const checkBoxStyles = {
  mainview: {
    mr: '16px',
  },
  agreementText: {
    color: 'styleSheet.overCast',
    fontSize: 'sm',
    fontWeight: '400',
    fontFamily: 'body',
    alignSelf: 'flex-end',
  },
  termText: {
    fontSize: 'sm',
    fontWeight: '400',
    fontFamily: 'body',
    color: 'hyperlink',
    alignSelf: 'flex-end',
  },
  hr: {
    w: '123px',
    borderWidth: '0.5px',
    borderColor: 'styleSheet.overCast',
    marginLeft: '3px',
  },
  checkboxColor: {
    borderColor: 'rgba(49, 44, 44, 0.7)',
    borderWidth: '1px',
    colorScheme: 'rgba(49, 44, 44, 0.7)',
  },
  requiredError: {
    ...customErrorDefault,
    ml: '8px',
    mt: ['2px', '-2px', '0px', '-3px'],
  },
  noError: {
    display: 'none',
  },
  errorContainer: {
    alignContent: 'center' as const,
    justifyContent: 'center' as const,
    w: '110',
    mt: '10px',
    ml: '10px',
  },
  text: {
    ml: '12px',
    mt: '-5px',
    lineHeight: 20,
  },
  checkBgWeb: {
    bg: 'pdp.border',
  },
  checkBgMob: {
    backgroundColor: 'pdp.border',
  },
  unCheckBgWeb: {
    bg: 'bgStayBox',
  },
  unCheckBgMob: {
    backgroundColor: 'bgStayBox',
  },
};

interface ITermsConditionProps {
  errorMsg: string | undefined;
  onChange: (field: string, v: boolean) => void;
  fieldName: string;
  validateField: (fieldName: string) => void;
}

const TermsAndConditionModal: FC<ITermsConditionProps> = ({ onChange, errorMsg, fieldName, validateField }) => {
  const { t } = useTranslation();
  const [isError, setIsError] = useState<boolean>(false);
  const [isChecked, setIsCheckedTerm] = useState<boolean>(false);
  const [uri, setUri] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isErrorMsg, setIsErrorMsg] = useState<string>('');

  useEffect(() => {
    if (errorMsg !== undefined && !isChecked) {
      setIsError(true);
      setIsErrorMsg(t('INPUT_DEFAULT_ERROR'));
    }
  }, [errorMsg]);

  const handleChange = (v: boolean) => {
    validateField(fieldName);
    setIsCheckedTerm(!isChecked);
    onChange(`${fieldName}`, v);
    if (v) {
      setIsError(false);
      setIsErrorMsg('');
    } else {
      setIsError(true);
      setIsErrorMsg(t('INPUT_DEFAULT_ERROR'));
    }
  };

  const errorStyle = isError ? checkBoxStyles.requiredError : checkBoxStyles.noError;
  const checkStyle =
    isChecked && Platform.OS === 'web'
      ? { ...checkBoxStyles.checkBgWeb }
      : !isChecked && Platform.OS === 'web'
      ? { ...checkBoxStyles.unCheckBgWeb }
      : isChecked && Platform.OS !== 'web'
      ? { ...checkBoxStyles.checkBgMob }
      : { ...checkBoxStyles.unCheckBgMob };

  return (
    <View>
      <HStack {...checkBoxStyles.mainview}>
        <Checkbox
          {...checkBoxStyles.checkboxColor}
          {...checkStyle}
          value={t('TERM_AND_CONDITION.TERM_CONDITION')}
          isChecked={isChecked}
          onChange={(v) => handleChange(v)}
          testID="checkboxId"
        />
        <Text {...checkBoxStyles.text}>
          <Text {...checkBoxStyles.agreementText}>{t('TERM_AND_CONDITION.MSG')}</Text>
          <Text
            {...checkBoxStyles.termText}
            underline
            testID="consentId"
            onPress={() => {
              setShowModal(true);
              setUri('https://www.gosummer.com/consent-to-electronic-communications');
              setTitle(t('TERM_AND_CONDITION.CONSENT'));
            }}>
            {t('TERM_AND_CONDITION.CONSENT')}
            {t('TERM_AND_CONDITION.COMMA')}
          </Text>
          <Text {...checkBoxStyles.agreementText}> {t('TERM_AND_CONDITION.OUR')}</Text>
          <Text
            {...checkBoxStyles.termText}
            underline
            testID="termOfuseId"
            onPress={() => {
              setShowModal(true);
              setUri('https://www.gosummer.com/terms-of-use');
              setTitle(t('TERM_AND_CONDITION.TERM_OF_USE'));
            }}>
            {t('TERM_AND_CONDITION.TERM_OF_USE')}
            {t('TERM_AND_CONDITION.COMMA')}
          </Text>
          <Text {...checkBoxStyles.agreementText}> {t('TERM_AND_CONDITION.AND_OUR')}</Text>
          <Text
            {...checkBoxStyles.termText}
            underline
            testID="privacyId"
            onPress={() => {
              setShowModal(true);
              setUri('https://www.gosummer.com/privacy-policy');
              setTitle(t('TERM_AND_CONDITION.PRIVACY_POLICY'));
            }}>
            {t('TERM_AND_CONDITION.PRIVACY_POLICY')}
          </Text>
        </Text>
      </HStack>

      <HStack {...checkBoxStyles.errorContainer}>
        <RequiredIcon style={getErrorStyle(isErrorMsg)} size={13} color="rgb(255, 69, 58)" />
        <Text {...errorStyle}>{isErrorMsg}</Text>
      </HStack>

      <TermsConditionWebview
        open={showModal}
        close={() => {
          setShowModal(false);
        }}
        uri={uri}
        title={title}
      />
    </View>
  );
};
export default TermsAndConditionModal;
