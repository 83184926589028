import { FC, useContext } from 'react';
import { Modal, VStack, Text, Button } from 'native-base';
import { useTranslation } from 'react-i18next';
import { AppVersionUpdateContext } from 'contexts/AppVersionUpdateContext';

const styles = {
  content: {
    width: '90%',
    backgroundColor: 'styleSheet.canvas',
    h: '357px',
    borderTopRightRadius: '16px',
    borderTopLeftRadius: '16px',
    borderBottomRightRadius: '16px',
    borderBottomLeftRadius: '16px',
  },
  body: {
    ml: '24px',
    mr: '24px',
    mt: '40px',
    mb: '40px',
    justifyContent: 'center',
  },
  title: {
    fontFamily: 'body',
    fontSize: '2.5xl',
    lineHeight: '30.8px',
  },
  message: {
    h: '104px',
    fontFamily: 'body',
    fontSize: '2md',
    lineHeight: 26,
    color: 'styleSheet.shadow',
  },
  text: {
    mt: '20px',
    lineHeight: 25,
  },
  button: {
    w: '100%',
    h: '54px',
    top: '40px',
    backgroundColor: 'styleSheet.shadow',
    borderRadius: '8px',
    textAlign: 'center',
    _text: {
      fontSize: '2md',
      fontFamily: 'body600',
      color: 'styleSheet.canvas',
    },
  },
};

interface AppUpdateModalProps {
  isOpen: boolean;
}
const AppUpdateModal: FC<AppUpdateModalProps> = ({ isOpen }) => {
  const { t } = useTranslation();
  const { updateApp } = useContext(AppVersionUpdateContext);
  return (
    <Modal isOpen={isOpen} animationPreset="slide">
      <Modal.Content {...styles.content}>
        <Modal.Body>
          <VStack {...styles.body}>
            <Text {...styles.title}>{t('APP_UPDATE.UPDATE_APP_MODAL_TITLE')}</Text>
            <Text {...styles.text}>
              <Text {...styles.message}>{t('APP_UPDATE.UPDATE_TEXT')}</Text>
            </Text>
            <Button {...styles.button} onPress={updateApp}>
              {t('APP_UPDATE.UPDATE_APP')}
            </Button>
          </VStack>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};
export default AppUpdateModal;
