import BookCalendar from 'components/Calendars/BookCalendar';
import { useState, useContext, useMemo, useEffect, FC } from 'react';
import { RootStackScreenProps } from 'types';
import { HStack, Box, Text, CloseIcon, Pressable, Divider, VStack } from 'native-base';
import { useTranslation } from 'react-i18next';
import CTAButton from 'components/libs/button/CTAButton';
import moment from 'moment';
import layout from '../../../constants/Layout';
import { NavigationContext } from 'contexts/NavigationContext';
import { CalendarProvider } from 'providers/CalendarProvider';
import { PropertyDetailContext } from 'contexts/PropertyDetailContext';

const customWidth = layout.window.width * 0.085;

const styles = {
  box: {
    backgroundColor: 'styleSheet.canvas',
    ml: '16px',
    mr: '16px',
    justifyContent: 'center',
  },
  divider: {
    variant: 'primary',
    w: '110%',
    ml: '-17px',
    mt: '20px',
  },
  icon: {
    color: 'pdp.shadow',
    alignSelf: 'center',
    size: '4',
  },

  text: {
    position: 'absolute' as const,
    left: '40%',
    color: 'white',
    fontSize: 'sm',
    alignSelf: 'center',
  },

  stickyBar: {
    position: 'absolute' as const,
    bg: 'styleSheet.shadow',
    left: '-16px',
    w: '108%',
    h: '75px',
    zIndex: 1,
    _pressed: {
      bg: 'styleSheet.overCast',
      _text: { color: 'styleSheet.canvas' },
    },
    _hover: {
      bg: 'styleSheet.darkStain',
      _text: { color: 'styleSheet.shadow' },
    },
    _text: {
      fontFamily: 'body600',
      color: 'styleSheet.canvas',
      fontSize: '2md',
    },
  },

  buttonBox: {
    position: 'absolute' as const,
    bottom: 0,
    bg: 'black',
    w: '100%',
    h: '75px',
  },

  textDate: {
    fontSize: '2md',
    fontFamily: 'body',
    fontWeight: '400',
    color: 'styleSheet.overCast',
  },

  checkIn: {
    fontSize: 'sm',
    fontFamily: 'body600',
    color: 'styleSheet.shadow',
    opacity: '0.55',
  },

  checkOut: {
    fontSize: 'sm',
    fontFamily: 'body600',
    color: 'styleSheet.shadow',
    opacity: '0.55',
  },

  hstack: {
    backgroundColor: 'styleSheet.canvas',
    alignItems: 'center',
    justifyContent: 'space-around',
    mt: '14px',
    mb: '14px',
    mr: `${customWidth}px`,
    ml: `${customWidth}px`,
  },

  dateBox: {
    backgroundColor: 'styleSheet.canvas',
    borderBottomWidth: '2',
    borderBottomColor: 'borderColor',
  },

  pressable: {
    h: '40px',
    w: '50px',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  upperHstack: {
    w: '100%',
    mt: '31px',
    justifyContent: 'space-between',
  },
  title: {
    color: 'pdp.shadow',
    fontSize: '2md',
    fontFamily: 'body600',
    alignSelf: 'center',
  },
  vstack: {
    alignItems: 'center',
  },
  HDivider: {
    variant: 'primary',
  },
  stickyBarDisabled: {
    position: 'absolute' as const,
    bg: 'calendar.disableButtonColor',
    left: '-16px',
    w: '108%',
    h: '75px',
    zIndex: 1,
    _text: {
      fontFamily: 'body600',
      color: 'styleSheet.canvas',
      fontSize: '2md',
      mb: '15px',
    },
  },
  buttonBoxDisable: {
    position: 'absolute' as const,
    bottom: 0,
    bg: 'calendar.disableButtonColor',
    w: '100%',
    h: '75px',
  },
  msg: {
    bg: 'styleSheet.canvas',
    position: 'absolute' as const,
    bottom: '70px',
    w: '100%',
  },
  textMsg: {
    p: '12px',
    fontFamily: 'body',
    fontWeight: '400',
    fontSize: 'md',
    left: '16px',
    color: 'styleSheet.shadow',
  },
  iconMsg: {
    color: 'pdp.shadow',
    size: '4',
  },
  pressableClose: {
    position: 'absolute' as const,
    right: 4,
    alignSelf: 'center' as const,
  },
  dividerMsgBox: {
    borderWidth: '0.5px',
    borderColor: 'styleSheet.overCast',
    opacity: '0.20',
    w: '100%',
  },
  mainView: {
    bg: 'styleSheet.canvas',
    h: '100%',
    w: '100%',
  },
};

const CalendarModalScreen: FC<RootStackScreenProps<'CalendarModal'>> = ({ navigation, route }) => {
  const [checkInDate, setCheckInDate] = useState<string | null>(null);
  const [checkOutDate, setCheckOutDate] = useState<string | null>(null);
  const { useNavigation } = useContext(NavigationContext);
  const { setMinNightsRequired, validateNightSelection } = useContext(PropertyDetailContext);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [displayMsgBox, setDisplayMsgBox] = useState<boolean>(false);
  const [msg, setMsg] = useState<string>('');

  const { t } = useTranslation();

  const nightSelection = useMemo(
    () => moment(checkOutDate).diff(moment(checkInDate), 'days'),
    [checkOutDate, checkInDate]
  );

  useEffect(() => {
    const { isValid, errorMessage } = validateNightSelection(nightSelection);
    if (isValid) {
      setMsg('');
      setDisabled(false);
      setDisplayMsgBox(false);
    } else {
      setMsg(errorMessage);
      setDisabled(true);
      setDisplayMsgBox(true);
    }
  }, [nightSelection, validateNightSelection]);

  const btnBox = disabled ? styles.buttonBoxDisable : styles.buttonBox;
  const stickyBarStyle = disabled ? styles.stickyBarDisabled : styles.stickyBar;

  const checkoutText =
    !!checkInDate && !!checkOutDate ? (
      <VStack {...styles.vstack}>
        <Text {...styles.checkOut}>{t('BOOK_A_STAY.CHECK_OUT')}</Text>
        <Text {...styles.textDate}>{moment(checkOutDate).format('ddd, MMM D')}</Text>
      </VStack>
    ) : !!checkInDate ? (
      <VStack {...styles.vstack}>
        <Text {...styles.checkOut}>{t('BOOK_A_STAY.CHECK_OUT')}</Text>
        <Text {...styles.textDate}>{t('SELECT_DATE')}</Text>
      </VStack>
    ) : (
      <Text {...styles.textDate}>{t('BOOK_A_STAY.CHECK_OUT')}</Text>
    );

  return (
    <Box {...styles.mainView} safeAreaTop safeAreaBottom>
      <Box {...styles.box}>
        <HStack {...styles.upperHstack}>
          <Text {...styles.title}>{t('SELECT_DATES')}</Text>
          <Pressable onPress={() => useNavigation()} {...styles.pressable}>
            <CloseIcon {...styles.icon} />
          </Pressable>
        </HStack>
        <Divider {...styles.divider} />
      </Box>
      <Box {...styles.dateBox}>
        <HStack {...styles.hstack}>
          <VStack {...styles.vstack}>
            <Text {...styles.checkIn}>{t('BOOK_A_STAY.CHECK_IN')}</Text>
            <Text {...styles.textDate}>
              {!!checkInDate ? moment(checkInDate).format('ddd, MMM D') : t('SELECT_DATE')}
            </Text>
          </VStack>
          <Divider orientation="vertical" {...styles.HDivider} />
          {checkoutText}
        </HStack>
      </Box>

      <CalendarProvider listingId={route.params.listingId}>
        <BookCalendar
          pastScrollRange={0}
          futureScrollRange={12}
          selectedDates={[checkInDate, checkOutDate].filter((d) => !!d) as string[]}
          setMinNight={setMinNightsRequired}
          onSelectedDatesChanged={(_checkin, _checkout) => {
            setCheckInDate(!!_checkin ? moment(_checkin).format('YYYY-MM-DD') : null);
            setCheckOutDate(!!_checkout ? moment(_checkout).format('YYYY-MM-DD') : null);
          }}
        />
      </CalendarProvider>

      {checkInDate && checkOutDate && (
        <Box {...btnBox}>
          {disabled && displayMsgBox ? (
            <Box {...styles.msg}>
              <Divider {...styles.dividerMsgBox} />
              <HStack>
                <Text {...styles.textMsg}>{msg}</Text>
                <Pressable onPress={() => setDisplayMsgBox(false)} {...styles.pressableClose}>
                  <CloseIcon {...styles.iconMsg} />
                </Pressable>
              </HStack>
              <Divider {...styles.divider} />
            </Box>
          ) : null}

          <CTAButton
            children={t('ADD_DATES')}
            {...stickyBarStyle}
            disabled={disabled}
            onPress={() => {
              navigation.goBack();
              navigation.navigate('BookingSubNavigator', {
                screen: 'PdpGuestModal',
                params: {
                  checkInDate,
                  checkOutDate,
                  listingId: route.params!.listingId!,
                },
              });
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default CalendarModalScreen;
