// See: https://github.com/expo/expo/issues/7996
import 'setimmediate';

import * as Sentry from '@sentry/react-native';
import { SummerLogo } from 'components/iconsXml/SummerLogo';
import { AppVersionUpdate } from 'components/LandingPage/AppVersionUpdate';
import { Loading } from 'components/Loading';
import { ToastContainer } from 'components/Toast/ToastContainer';
import { AppTheme } from 'constants/Theme';
import Constants from 'expo-constants';
import * as Font from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';
import { StatusBar } from 'expo-status-bar';
import { Box, NativeBaseProvider } from 'native-base';
import Navigation from 'navigation';
import { AppVersionUpdateProvider } from 'providers/AppVersionUpdateProvider';
import { MultiSubscriptionsProvider } from 'providers/MultiSubscriptionsProvider';
import { UserProvider } from 'providers/UserProvider';
import { Suspense, useCallback, useEffect, useState } from 'react';
import { RootSiblingParent } from 'react-native-root-siblings';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import 'react-native-url-polyfill/auto';

import { AuthProvider } from './src/providers/AuthProvider';
import { CoreApiClientProvider } from './src/providers/CoreApiClientProvider';
import './src/services/i18Service';

Sentry.init({
  dsn: 'https://5e7a432962164eaeb626d6811ff58cf9@o1141649.ingest.sentry.io/6213987',
  debug: false,
  environment: Constants.expoConfig?.extra?.environment,
  maxBreadcrumbs: 500,
});

const fetchFonts = async () => {
  await Font.loadAsync({
    'TWKL-300': require('./src/assets/fonts/TWKLausanne-300.otf'),
    'TWKL-400': require('./src/assets/fonts/TWKLausanne-400.otf'),
    'TWKL-600': require('./src/assets/fonts/TWKLausanne-600.otf'),
    'TWKL-800': require('./src/assets/fonts/TWKLausanne-800.otf'),
    'SpaceMono-Regular': require('./src/assets/fonts/SpaceMono-Regular.ttf'),
    'EDLavonia-Regular': require('./src/assets/fonts/EDLavonia-Regular.ttf'),
  });
};

SplashScreen.preventAutoHideAsync();

function App() {
  const [isAppReady, setIsAppReady] = useState(false);

  useEffect(() => {
    async function prepare() {
      try {
        await fetchFonts();
      } catch (e) {
        console.warn(e);
      } finally {
        setIsAppReady(true);
      }
    }

    prepare();
  }, []);

  const onLayoutRootView = useCallback(async () => {
    if (isAppReady) {
      await SplashScreen.hideAsync();
    }
  }, [isAppReady]);

  if (!isAppReady) {
    return null;
  }

  return (
    <NativeBaseProvider theme={AppTheme}>
      <Box flex={1} onLayout={onLayoutRootView}>
        <Suspense fallback={<Loading />}>
          <AppVersionUpdateProvider>
            <AppVersionUpdate>
              <RootSiblingParent>
                <AuthProvider>
                  <CoreApiClientProvider>
                    <UserProvider>
                      <MultiSubscriptionsProvider>
                        <SafeAreaProvider>
                          <Navigation />
                          <SummerLogo />
                          <StatusBar />
                          <ToastContainer />
                        </SafeAreaProvider>
                      </MultiSubscriptionsProvider>
                    </UserProvider>
                  </CoreApiClientProvider>
                </AuthProvider>
              </RootSiblingParent>
            </AppVersionUpdate>
          </AppVersionUpdateProvider>
        </Suspense>
      </Box>
    </NativeBaseProvider>
  );
}

export default Sentry.wrap(App);
