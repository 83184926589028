import * as Types from '../../../generated/types.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ReadSubscriptionBalanceBySubscriptionIdQueryVariables = Types.Exact<{
  subscriptionId: Types.Scalars['String'];
}>;


export type ReadSubscriptionBalanceBySubscriptionIdQuery = { __typename?: 'Query', readSubscriptionBalanceBySubscriptionId: { __typename?: 'SubscriptionBalanceResponse', status: { __typename?: 'DatabaseStatus', code: number, message: string }, data?: { __typename?: 'SubscriptionBalance', id?: string | null, memberId?: string | null, totalAmount?: number | null, usedAmount?: number | null, remainingAmount?: number | null, expiryDate?: string | null } | null } };


export const ReadSubscriptionBalanceBySubscriptionIdDocument = gql`
    query readSubscriptionBalanceBySubscriptionId($subscriptionId: String!) {
  readSubscriptionBalanceBySubscriptionId(subscriptionId: $subscriptionId) {
    status {
      code
      message
    }
    data {
      id
      memberId
      totalAmount
      usedAmount
      remainingAmount
      expiryDate
    }
  }
}
    `;

/**
 * __useReadSubscriptionBalanceBySubscriptionIdQuery__
 *
 * To run a query within a React component, call `useReadSubscriptionBalanceBySubscriptionIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadSubscriptionBalanceBySubscriptionIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadSubscriptionBalanceBySubscriptionIdQuery({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *   },
 * });
 */
export function useReadSubscriptionBalanceBySubscriptionIdQuery(baseOptions: Apollo.QueryHookOptions<ReadSubscriptionBalanceBySubscriptionIdQuery, ReadSubscriptionBalanceBySubscriptionIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadSubscriptionBalanceBySubscriptionIdQuery, ReadSubscriptionBalanceBySubscriptionIdQueryVariables>(ReadSubscriptionBalanceBySubscriptionIdDocument, options);
      }
export function useReadSubscriptionBalanceBySubscriptionIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadSubscriptionBalanceBySubscriptionIdQuery, ReadSubscriptionBalanceBySubscriptionIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadSubscriptionBalanceBySubscriptionIdQuery, ReadSubscriptionBalanceBySubscriptionIdQueryVariables>(ReadSubscriptionBalanceBySubscriptionIdDocument, options);
        }
export type ReadSubscriptionBalanceBySubscriptionIdQueryHookResult = ReturnType<typeof useReadSubscriptionBalanceBySubscriptionIdQuery>;
export type ReadSubscriptionBalanceBySubscriptionIdLazyQueryHookResult = ReturnType<typeof useReadSubscriptionBalanceBySubscriptionIdLazyQuery>;
export type ReadSubscriptionBalanceBySubscriptionIdQueryResult = Apollo.QueryResult<ReadSubscriptionBalanceBySubscriptionIdQuery, ReadSubscriptionBalanceBySubscriptionIdQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    