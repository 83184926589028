import { UserContext } from 'contexts/UserContext';
import moment from 'moment';
import { Box, Text, Divider, HStack, VStack, Pressable, View, Button } from 'native-base';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BirthDatePicker from './BirthDatePicker';
import { useUpdateUserMutation } from 'screens/Booking/queries/updateUser.generated';
import LoadingToast from './LoadingToast';

const styles = {
  textKey: {
    textAlign: 'justify' as const,
    fontSize: ['sm', 'sm', '2md', '2md', '2md'],
    fontFamily: 'body600',
    color: 'styleSheet.shadow',
    opacity: '0.55',
  },
  textValue: {
    fontSize: '2md',
    fontFamily: 'body',
    color: 'styleSheet.shadow',
  },
  divider: {
    borderWidth: 0.5,
    borderColor: 'styleSheet.darkStain',
    mt: '16px',
  },
  hr: {
    borderWidth: '0.5px',
    borderColor: 'styleSheet.lightShadow',
    mt: '-2px',
  },
  linkButton: {
    fontFamily: 'body',
    color: 'styleSheet.shadow',
    fontSize: '2md',
  },
  hstack: {
    alignItems: 'center' as const,
    justifyContent: 'space-between' as const,
  },
  box: (v: boolean) => ({
    opacity: v ? 1 : 0.5,
    mt: '16px',
  }),
  button: {
    variant: 'primaryMobile',
    _text: {
      fontFamily: 'body600',
      color: 'styleSheet.canvas',
      fontSize: '2md',
    },
    w: ['100%', '100%', '100%', '25%', '25%'],
    mt: '16px',
  },
};

const ProfileBirthDateBox = () => {
  const { t } = useTranslation();
  const { user, profileInfo, setProfileInfo, refetch } = useContext(UserContext);
  const [editBirthdate, setEditBirthdate] = useState<boolean>(false);
  const [birthdate, setBirthdate] = useState<Date>(moment().toDate());
  const [updateUserData, { error: updateUserDataError }] = useUpdateUserMutation();
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (user?.primaryMemberBirthdate) {
      setBirthdate(moment(user?.primaryMemberBirthdate, 'DD-MM-YYYY').toDate());
    }
  }, [user?.primaryMemberBirthdate]);

  const handleEdit = () => {
    setProfileInfo((prev) => {
      return { ...prev, email: false, phone: false, switch: false, gender: false, name: false, address: false };
    });
    setEditBirthdate(true);
  };

  const handleViewVisibility = () => {
    setEditBirthdate(false);
    setProfileInfo((prev) => {
      return {
        ...prev,
        email: true,
        phone: true,
        switch: true,
        gender: true,
        name: true,
        address: true,
        dateOfBirth: true,
      };
    });
  };
  const genderStyle = editBirthdate ? styles.linkButton : styles.textKey;

  const handleUpdateDOB = async () => {
    setLoader(true);
    await updateUserData({
      variables: {
        data: {
          primaryMemberBirthdate: moment(birthdate, 'MMMM D, YYYY').format('DD-MM-YYYY'),
        },
      },
      onCompleted: () => {
        setLoader(false);
        refetch();
        handleViewVisibility();
      },
      onError: () => {
        //TODO will do the error handling later when mocks are ready
        if (__DEV__) console.log(updateUserDataError);
      },
    });
    [updateUserData];
  };

  return (
    <Box {...styles.box(profileInfo.dateOfBirth)}>
      <HStack {...styles.hstack}>
        <VStack>
          {!editBirthdate ? (
            <Box>
              <Text {...genderStyle}>{t('PROFILE.BIRTH')}</Text>
              <Text {...styles.textValue}>
                {moment(user?.primaryMemberBirthdate, 'DD-MM-YYYY').format('MMMM D, YYYY')}
              </Text>
            </Box>
          ) : (
            <HStack>
              <Text {...styles.linkButton}>{t('PROFILE.EDIT')}</Text>
              <Text {...genderStyle} style={{ marginLeft: 2 }}>
                {t('PROFILE.BIRTHDATE')}
              </Text>
            </HStack>
          )}
        </VStack>

        {!editBirthdate ? (
          <Pressable onPress={handleEdit} isDisabled={!profileInfo.dateOfBirth}>
            <Text {...styles.linkButton}>{t('PROFILE.EDIT')}</Text>
            <View {...styles.hr} />
          </Pressable>
        ) : (
          <Pressable onPress={handleViewVisibility}>
            <Text {...styles.linkButton}>{t('PROFILE.CANCEL')}</Text>
            <View {...styles.hr} />
          </Pressable>
        )}
      </HStack>

      {editBirthdate ? (
        <View>
          <BirthDatePicker setBirthdate={setBirthdate} birthdate={birthdate} />
          {loader ? (
            <LoadingToast />
          ) : (
            <Button {...styles.button} onPress={handleUpdateDOB}>
              {t('PROFILE.SAVE_CHANGES')}
            </Button>
          )}
        </View>
      ) : null}

      <Divider {...styles.divider} />
    </Box>
  );
};

export default ProfileBirthDateBox;
