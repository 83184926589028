import { Box, Text } from 'native-base';
import { useTranslation } from 'react-i18next';
import Constants from 'expo-constants';
import LoginButton from './LoginButton';
import SignupButton from './SignupButton';

const styles = {
  container: {
    mt: [null, null, '44px', '36px', '48px'],
    position: ['absolute' as const, 'absolute' as const, null, null, null],
    bottom: ['60px', '60px', null, null, null],
    maxW: '100%',
    w: '100%',
    px: ['32px', '32px', null, null, null],
  },
  buttonContainer: {
    flexDirection: [
      'column-reverse' as const,
      'column-reverse' as const,
      'row' as const,
      'row' as const,
      'row' as const,
    ],
  },
  version: {
    color: [
      'styleSheet.canvas',
      'styleSheet.canvas',
      'styleSheet.overCast',
      'styleSheet.overCast',
      'styleSheet.overCast',
    ],
    fontFamily: 'body600',
    fontSize: '2md',
    mt: ['24px', '24px', '-10px', '12px', '12px'],
    alignSelf: [
      'center' as const,
      'center' as const,
      'flex-start' as const,
      'flex-start' as const,
      'flex-start' as const,
    ],
  },
};

const LandingButtonsSection = () => {
  const { t } = useTranslation();
  let appVersion = '';
  if (Constants.expoConfig?.version) {
    appVersion = Constants.expoConfig.version;
  }

  return (
    <Box {...styles.container}>
      <Box {...styles.buttonContainer}>
        <SignupButton />
        <LoginButton />
      </Box>
      <Text {...styles.version}>
        {t('VERSION')}
        {appVersion}
      </Text>
    </Box>
  );
};

export default LandingButtonsSection;
