import { ProfileInfoTypes, UserContext } from 'contexts/UserContext';
import { Formik } from 'formik';
import { Box, Button, HStack, Input, Pressable, Text, View, VStack } from 'native-base';
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingToast from './LoadingToast';
import * as yup from 'yup';
import { useUpdateUserMutation } from 'screens/Booking/queries/updateUser.generated';
import { RequiredIcon } from 'components/SignUp/RequiredIcon';

const styles = {
  formGroupName: (error: string) => ({
    mt: '18px',
    h: '75px',
    w: ['100%', '100%', '100%', '50%', '50%'],
    mr: ['0px', '0px', '0px', '10px', '10px'],
    borderRadius: '12px',
    borderWidth: '1',
    borderTopColor: error ? 'notification' : 'styleSheet.darkStain',
    borderBottomColor: error ? 'notification' : 'styleSheet.darkStain',
    borderLeftColor: error ? 'notification' : 'styleSheet.darkStain',
    borderRightColor: error ? 'notification' : 'styleSheet.darkStain',
    pt: '8px',
    pb: '8px',
    pl: '20px',
  }),
  applyButton: {
    w: ['100%', '100%', '100%', '25%', '25%'],
    h: '70px',
    mt: '16px',
    backgroundColor: 'styleSheet.shadow',
    borderRadius: '8px',
    textAlign: 'center',
  },
  applyButtonText: {
    color: 'styleSheet.canvas',
    fontSize: '2md',
    fontFamily: 'body600',
  },
  editModeVstack: {
    w: '100%',
  },
  hstack: {
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  textKey: {
    textAlign: 'justify' as const,
    fontSize: ['sm', 'sm', '2md', '2md', '2md'],
    fontFamily: 'body600',
    color: 'styleSheet.shadow',
    opacity: '0.55',
  },
  hr: {
    borderWidth: '0.5px',
    borderColor: 'styleSheet.lightShadow',
    mt: '-2px',
  },
  linkButton: {
    fontFamily: 'body',
    fontWeight: '400',
    color: 'styleSheet.shadow',
    fontSize: '2md',
  },
  box: (v: boolean) => ({
    opacity: v ? 1 : 0.5,
    w: '100%',
  }),
  inputContainer: {
    flexDirection: ['column' as const, 'column' as const, 'column' as const, 'row' as const, 'row' as const],
  },
  fieldText: {
    fontFamily: 'body',
    fontWeight: '600',
    color: 'styleSheet.shadow',
    fontSize: 'sm',
    opacity: '0.55',
    mt: [1, 1, 2, 2, 2],
  },
  text: {
    pt: [1, 1, 0, 0, 0],
    pl: 0,
    borderWidth: 1,
    borderColor: 'styleSheet.canvas',
    _focus: {
      borderColor: 'styleSheet.canvas',
    },
    fontSize: '2md',
    fontFamily: 'body',
    color: 'styleSheet.shadow',
    w: '95%',
  },
  error: {
    fontSize: 'sm',
    fontWeight: '400',
    fontFamily: 'body',
    color: 'notification',
    ml: '6px',
  },
  hstackError: {
    position: 'absolute' as const,
    right: 5,
    alignItems: 'center' as const,
    pb: ['25px', '25px', '25px', '30px', '30px'],
  },
};

type ProfileNameEditorProps = {
  setEditMode: Dispatch<SetStateAction<boolean>>;
  profileInfo: ProfileInfoTypes;
  userFirstName: string;
  userLastName: string;
};

const ProfileNameEditor = ({ setEditMode, profileInfo, userFirstName, userLastName }: ProfileNameEditorProps) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [updateUserData, { loading: updatingUserData, error: updateUserDataError }] = useUpdateUserMutation();
  const { refetch } = useContext(UserContext);
  const initialProfileNameValues = {
    firstName: userFirstName,
    lastName: userLastName,
  };

  useEffect(() => {
    if (!updatingUserData && isLoading) {
      setIsLoading(false);
      setEditMode(false);
    }
  }, [updatingUserData]);

  interface IUpdateUserDataProps {
    firstName: string | null | undefined;
    lastName: string | null | undefined;
  }

  const handleUpdateUserData = async (values: IUpdateUserDataProps) => {
    await updateUserData({
      variables: {
        data: {
          ...values,
        },
      },
      onCompleted: () => {
        refetch();
        close();
      },
      onError: () => {
        if (__DEV__) console.log('Unable to update user profile name. Error: ', updateUserDataError);
      },
    });
    [updateUserData];
  };

  const editProfileNameValidationSchema = yup.object().shape({
    firstName: yup.string().required(t('INPUT_DEFAULT_ERROR')),
    lastName: yup.string().required(t('INPUT_DEFAULT_ERROR')),
  });

  const handleEditMode = () => {
    setEditMode(false);
  };
  return (
    <Formik
      initialValues={initialProfileNameValues}
      onSubmit={(value) => {
        if (value.firstName && value.lastName) {
          setIsLoading(true);
          try {
            handleUpdateUserData({
              firstName: value.firstName,
              lastName: value.lastName,
            });
          } catch (e) {
            console.log('Unable to update user data', e);
            setIsLoading(false);
          }
        }
        value.firstName = '';
        value.lastName = '';
      }}
      validationSchema={editProfileNameValidationSchema}>
      {({ handleChange, handleBlur, handleSubmit, values, errors }) => (
        <Box {...styles.box(profileInfo.name)}>
          <HStack {...styles.hstack}>
            <VStack {...styles.editModeVstack}>
              <HStack {...styles.hstack}>
                <Text {...styles.textKey}>{t('PROFILE.EDIT_NAME')}</Text>
                <Pressable onPress={handleEditMode} isDisabled={!profileInfo.name} testID="cancel_pressed">
                  <Text {...styles.linkButton}>{t('PROFILE.CANCEL')}</Text>
                  <View {...styles.hr} />
                </Pressable>
              </HStack>
              <Box {...styles.inputContainer}>
                <Box {...styles.formGroupName(errors.firstName!)}>
                  <Text {...styles.fieldText}>{t('FIRST_NAME_PLACEHOLDER')}</Text>
                  <HStack>
                    <Input
                      placeholder={t('FIRST_NAME_PLACEHOLDER')}
                      onChangeText={handleChange('firstName')}
                      onBlur={handleBlur('firstName')}
                      value={values.firstName}
                      _hover={{ bg: 'styleSheet.canvas' }}
                      {...styles.text}
                    />
                    {errors.firstName && (
                      <HStack {...styles.hstackError} style={{ alignSelf: 'center' as const }}>
                        <RequiredIcon size={13} color="rgb(255, 69, 58)" />
                        <Text {...styles.error}>{errors.firstName}</Text>
                      </HStack>
                    )}
                  </HStack>
                </Box>
                <Box {...styles.formGroupName(errors.lastName!)}>
                  <Text {...styles.fieldText}>{t('LAST_NAME_PLACEHOLDER')}</Text>
                  <HStack>
                    <Input
                      placeholder={t('LAST_NAME_PLACEHOLDER')}
                      onChangeText={handleChange('lastName')}
                      onBlur={handleBlur('lastName')}
                      value={values.lastName}
                      _hover={{ bg: 'styleSheet.canvas' }}
                      {...styles.text}
                    />
                    {errors.lastName && (
                      <HStack {...styles.hstackError} style={{ alignSelf: 'center' as const }}>
                        <RequiredIcon size={13} color="rgb(255, 69, 58)" />
                        <Text {...styles.error}>{errors.lastName}</Text>
                      </HStack>
                    )}
                  </HStack>
                </Box>
              </Box>
              <View>
                {isLoading ? (
                  <LoadingToast />
                ) : (
                  <Button
                    //@ts-ignore
                    {...styles.applyButton}
                    onPress={handleSubmit}>
                    <Text {...styles.applyButtonText}>{t('PROFILE.SAVE_CHANGES')}</Text>
                  </Button>
                )}
              </View>
            </VStack>
          </HStack>
        </Box>
      )}
    </Formik>
  );
};

export default ProfileNameEditor;
