import { MaterialIcons } from '@expo/vector-icons';
import { Feather } from '@expo/vector-icons';
import { AntDesign } from '@expo/vector-icons';
import { Ionicons } from '@expo/vector-icons';
import { MaterialCommunityIcons } from '@expo/vector-icons';

interface IconsProps {
  size: number;
  color: string;
  onPress?: (v: any) => void;
}

export const ArrowLeft = ({ size, color }: IconsProps) => {
  return <Ionicons name="arrow-back" size={size} color={color} />;
};
export const KeyBoardArrowDown = ({ size, color }: IconsProps) => {
  return <MaterialIcons name="keyboard-arrow-down" size={size} color={color} />;
};

export const KeyBoardArrowUp = ({ size, color }: IconsProps) => {
  return <MaterialIcons name="keyboard-arrow-up" size={size} color={color} />;
};

export const SupportIcon = ({ size, color }: IconsProps) => {
  return <Feather name="message-circle" size={size} color={color} />;
};

export const ClockIcon = ({ size, color }: IconsProps) => {
  return <AntDesign name="clockcircleo" size={size} color={color} />;
};

export const UploadIcon = ({ size, color }: IconsProps) => {
  return <Feather name="upload" size={size} color={color} />;
};

export const CheckMarkIcon = ({ size, color }: IconsProps) => {
  return <Ionicons name="checkmark" size={size} color={color} />;
};

export const CopyIcon = ({ size, color }: IconsProps) => {
  return <Feather name="copy" size={size} color={color} />;
};

export const HeartIcon = ({ size, color }: IconsProps) => {
  return <Feather name="heart" size={size} color={color} />;
};

export const StarIcon = ({ size, color }: IconsProps) => {
  return <Feather name="star" size={size} color={color} />;
};

export const PropertyIcon = ({ size, color }: IconsProps) => {
  return <MaterialCommunityIcons name="home-city-outline" size={size} color={color} />;
};

export const LocationIcon = ({ size, color }: IconsProps) => {
  return <Feather name="map-pin" size={size} color={color} />;
};

export const ShareIcon = ({ size, color }: IconsProps) => {
  return <Feather name="share" size={size} color={color} />;
};

export const MinusIcon = ({ size, color, onPress }: IconsProps) => {
  return <AntDesign name="minus" size={size} onPress={onPress} color={color} />;
};

export const PlusIcon = ({ size, color, onPress }: IconsProps) => {
  return <AntDesign name="plus" size={size} onPress={onPress} color={color} />;
};
