import { FC, useContext } from 'react';
import { Box, CloseIcon, HStack, Pressable, ScrollView, Text, useBreakpointValue, View, VStack } from 'native-base';
import { useTranslation } from 'react-i18next';
import { RootStackScreenProps } from 'types';
import { PropertyDetailContext } from 'contexts/PropertyDetailContext';

const styles = {
  container: {
    flex: 1,
    bgColor: 'styleSheet.canvas',
  },
  top: {
    alignItems: 'center',
  },
  icon: {
    color: 'styleSheet.shadow',
    alignSelf: 'center',
    size: '4',
  },
  pressable: {
    p: '20px',
    _hover: {
      bg: 'styleSheet.canvas',
      borderColor: 'styleSheet.darkStain',
      borderWidth: '1px',
      borderRadius: '12px',
    },
    mr: ['16px', '16px', '16px', '64px', '64px'],
    position: 'absolute' as const,
    right: '0',
    zIndex: 1,
    justifyContent: 'center',
  },
  text: {
    fontFamily: 'body600',
    color: 'styleSheet.shadow',
    pl: ['16px', '16px', '40px', '40px', '64px'],
    pt: ['28px', '28px', '40px', '40px', '40px'],
    pb: ['24px', '28px', '40px', '40px', '40px'],
    fontSize: ['2md', '2md', '1xl', '1xl', '1xl'],
  },
  textArea: {
    pl: ['16px', '16px', '80px', '80px', '25%'],
    pr: ['16px', '16px', '80px', '80px', '25%'],
    pt: ['40px', '40px', '40px', '80px', '80px'],
    pb: '16px',
  },
  areaDetail: {
    fontWeight: '400',
    fontSize: '2md',
    textColor: 'styleSheet.shadow',
    fontFamily: 'body',
  },
  hr: {
    w: '100%',
    borderWidth: '1px',
    borderColor: 'styleSheet.overCast',
    opacity: '0.15',
  },
};

interface IAboutAreaModalProps extends RootStackScreenProps<'AboutHomeModal'> {
  setShowModal: (state: boolean) => void;
}

const AboutAreaModal: FC<IAboutAreaModalProps> = ({ navigation, setShowModal }) => {
  let breakpointTypeValue = useBreakpointValue({ base: 1, sm: 2, md: 3, lg: 4, xl: 5 });
  const { t } = useTranslation();
  const { listing } = useContext(PropertyDetailContext);

  const handleCloseModal = () => {
    if (breakpointTypeValue == 3 || breakpointTypeValue == 4 || breakpointTypeValue == 5) {
      setShowModal(false);
    } else {
      navigation.goBack();
    }
  };

  return (
    <Box {...styles.container} safeAreaTop>
      <HStack {...styles.top}>
        <Text {...styles.text}>{t('ABOUT_THE_AREA')}</Text>
        <Pressable onPress={handleCloseModal} {...styles.pressable}>
          <CloseIcon {...styles.icon} />
        </Pressable>
      </HStack>
      <View {...styles.hr} />
      <ScrollView>
        <VStack {...styles.textArea}>
          <Text {...styles.areaDetail}>{listing?.publicDescription?.neighborhood}</Text>
        </VStack>
      </ScrollView>
    </Box>
  );
};

export default AboutAreaModal;
