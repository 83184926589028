import { FC, useState, useCallback } from 'react';
import * as Updates from 'expo-updates';
import { UpdateCheckResult } from 'expo-updates/src/Updates.types';
import { Platform } from 'react-native';
import { checkVersion, CheckVersionResponse } from 'react-native-check-version';

import { AppVersionUpdateContext } from 'contexts/AppVersionUpdateContext';
import { SentryService } from '../services/SentryService';

interface AppVersionProviderProps {
  children: React.ReactNode;
}

export const AppVersionUpdateProvider: FC<AppVersionProviderProps> = ({ children }) => {
  const [isUpdateModalOpen, setUpdateModalOpen] = useState<boolean>(false);

  const checkAppStoreUpdate = async (): Promise<boolean> => {
    const appStoreVersionResponse: CheckVersionResponse = await checkVersion();

    if (appStoreVersionResponse && appStoreVersionResponse.needsUpdate) {
      SentryService.addBreadcrumb({
        message: 'checkAppStoreUpdate() - App has an update pending.',
        category: 'app.update',
        data: {
          updateType: appStoreVersionResponse.updateType,
          currentVersion: appStoreVersionResponse.version,
        },
      });

      setUpdateModalOpen(true);
      return true;
    }

    setUpdateModalOpen(false);
    return false;
  };

  const checkExpoUpdate = async () => {
    try {
      let res: UpdateCheckResult = await Updates.checkForUpdateAsync();
      if (!res || !res.isAvailable) {
        return;
      }

      SentryService.addBreadcrumb({
        message: 'checkExpoUpdate() - Update available.',
        category: 'expo.update',
      });

      // Begin fetching update in the background.
      await Updates.fetchUpdateAsync();
      setUpdateModalOpen(true);
    } catch (e) {
      SentryService.captureMessage(`Problem fetching latest Expo update: ${e}`);
    }
  };

  const checkForUpdate = useCallback(async () => {
    if (__DEV__ || Platform.OS === 'web') {
      console.log('Update checking skipped in development mode and on web.');
      return;
    }

    const hasAppUpdate = await checkAppStoreUpdate();
    if (!hasAppUpdate) {
      checkExpoUpdate();
    }
  }, [checkAppStoreUpdate, checkExpoUpdate]);

  const handleUpdating = useCallback(async () => {
    setUpdateModalOpen(false);
    await Updates.reloadAsync();
  }, []);

  return (
    <AppVersionUpdateContext.Provider
      value={{
        updateAvailable: isUpdateModalOpen,
        lookForAppStoreUpdate: checkForUpdate,
        updateApp: handleUpdating,
      }}>
      {children}
    </AppVersionUpdateContext.Provider>
  );
};
