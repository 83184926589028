import { FC, useContext } from 'react';
import { View, Divider, Text, HStack } from 'native-base';
import { BookingDetailContext } from 'contexts/BookingDetailContext';
import { H3 } from 'components/libs/heading/Heading';
import { useTranslation } from 'react-i18next';

const styles = {
  divider: {
    variant: 'primary',
    mt: '40px',
    mb: '40px',
  },
  title: {
    fontSize: '1xl',
    color: 'styleSheet.shadow',
    fontFamily: 'body',
  },

  hstack: {
    justifyContent: 'space-between' as const,
    mt: '24px',
  },
  secondHstack: {
    justifyContent: 'space-between' as const,
    mt: '4px',
  },
  rates: {
    color: 'styleSheet.shadow',
    fontFamily: 'body',
    fontSize: '2md',
  },
  rateAmount: {
    color: 'styleSheet.shadow',
    fontFamily: 'body800',
    fontSize: '2md',
  },
  amount: {
    justifyContent: 'space-between' as const,
    mb: '48px',
  },
  textAmount: {
    fontSize: 'sm',
    color: 'styleSheet.shadow',
    fontFamily: 'body',
    textDecorationLine: 'line-through',
    mr: '8px',
  },
  amountDivider: {
    variant: 'primary',
    mt: '24px',
    mb: '16px',
  },
  mainView: {
    w: '100%',
    mt: ['0px', '0px', '0px', '24px', '24px'],
  },
};

interface PaymentDetailsProps {}

const PaymentDetails: FC<PaymentDetailsProps> = () => {
  const { t } = useTranslation();
  const { reservation } = useContext(BookingDetailContext);

  const discount = reservation?.discount;

  return (
    <View {...styles.mainView}>
      <H3 {...styles.title}>{t('PAYMENT_DETAILS')}</H3>
      <HStack {...styles.hstack}>
        <Text {...styles.rates}>{`${reservation?.nightsCount} nights`}</Text>
        <Text {...styles.rates}>${reservation?.basePrice}</Text>
      </HStack>
      {discount !== 0 && (
        <HStack {...styles.secondHstack}>
          <Text {...styles.rates}>{t('MEMBER_DISCOUNT')}</Text>
          <Text {...styles.rates}>-${discount?.toFixed(2)}</Text>
        </HStack>
      )}
      <HStack {...styles.secondHstack}>
        <Text {...styles.rates}>{t('CLEANING_FEE')}</Text>
        <Text {...styles.rates}>${reservation?.actualCleaningFees || 0}</Text>
      </HStack>
      <HStack {...styles.secondHstack}>
        <Text {...styles.rates}>{t('TAXES')}</Text>
        <Text {...styles.rates}>${reservation?.totalTax?.toFixed(2)}</Text>
      </HStack>
      <Divider {...styles.amountDivider} />
      <HStack {...styles.amount}>
        <H3 {...styles.rateAmount}>{t('TOTAL_AMOUNT')}</H3>
        <HStack alignItems="flex-end">
          <H3 {...styles.rateAmount}>${reservation?.totalPriceWithTaxAndDiscount?.toFixed(2)}</H3>
        </HStack>
      </HStack>
    </View>
  );
};

export default PaymentDetails;
