import { FC, useContext } from 'react';
import { Box, CloseIcon, HStack, Pressable, ScrollView, Text, useBreakpointValue, View, VStack } from 'native-base';
import { useTranslation } from 'react-i18next';
import { RootStackScreenProps } from 'types';
import { PropertyDetailContext } from 'contexts/PropertyDetailContext';

const styles = {
  container: {
    flex: 1,
    bgColor: 'styleSheet.canvas',
  },
  top: {
    alignItems: 'center',
  },
  text: {
    pl: ['16px', '16px', '40px', '40px', '64px'],
    pt: ['28px', '28px', '40px', '40px', '40px'],
    pb: ['24px', '28px', '40px', '40px', '40px'],
    fontSize: ['2md', '2md', '1xl', '1xl', '1xl'],
    fontFamily: 'body600',
    color: 'styleSheet.shadow',
  },
  hr: {
    w: '100%',
    borderWidth: '1px',
    borderColor: 'styleSheet.overCast',
    opacity: '0.15',
  },
  page: {
    h: '100%',
    pl: ['16px', '16px', '80px', '80px', '25%'],
    pr: ['16px', '16px', '80px', '80px', '25%'],
    pt: ['40px', '40px', '40px', '80px', '80px'],
    pb: '16px',
  },
  icon: {
    color: 'styleSheet.shadow',
    alignSelf: 'center',
    size: '4',
  },
  pressable: {
    mr: ['16px', '16px', '48px', '48px', '64px'],
    position: 'absolute' as const,
    right: '0',
    zIndex: 1,
    justifyContent: 'center',
    p: '20px',
    _hover: {
      bg: 'styleSheet.canvas',
      borderColor: 'styleSheet.darkStain',
      borderWidth: '1px',
      borderRadius: '12px',
    },
  },
  cancellation: {
    fontSize: '2md',
    fontFamily: 'body',
    fontWeight: '400',
    color: 'pdp.shadow',
  },

  header: {
    fontSize: '1xl',
    fontFamily: 'body',
    fontWeight: '400',
  },

  about: {
    fontSize: '2md',
    fontFamily: 'body',
    fontWeight: '400',
    color: 'pdp.shadow',
  },
  vstack: {
    space: 2,
    mt: '24px',
  },
};

interface IAboutHomeModalProps extends RootStackScreenProps<'AboutHomeModal'> {
  setShowModal: (state: boolean) => void;
}

const AboutHomeModal: FC<IAboutHomeModalProps> = ({ navigation, setShowModal }) => {
  const { t } = useTranslation();
  const { listing } = useContext(PropertyDetailContext);
  let breakpointTypeValue = useBreakpointValue({ base: 1, sm: 2, md: 3, lg: 4, xl: 5 });

  const handleCloseModal = () => {
    if (breakpointTypeValue == 3 || breakpointTypeValue == 4 || breakpointTypeValue == 5) {
      setShowModal(false);
    } else {
      navigation.goBack();
    }
  };

  return (
    <Box {...styles.container} safeAreaTop>
      <HStack {...styles.top}>
        <Text {...styles.text}>{t('PROPERTY_DETAIL.ABOUT_THE_HOME')}</Text>
        <Pressable onPress={handleCloseModal} {...styles.pressable}>
          <CloseIcon {...styles.icon} />
        </Pressable>
      </HStack>
      <View {...styles.hr} />

      <ScrollView>
        <View {...styles.page}>
          <Text {...styles.cancellation}>{listing?.publicDescription?.summary}</Text>
          <View>
            {listing?.publicDescription?.access !== '' && (
              <VStack {...styles.vstack}>
                <Text {...styles.header}>{t('PROPERTY_DETAIL.ACCESS')}</Text>
                <Text {...styles.about}>{listing?.publicDescription?.access}</Text>
              </VStack>
            )}
          </View>
          <View>
            {listing?.publicDescription?.space !== '' && (
              <VStack {...styles.vstack}>
                <Text {...styles.header}>{t('PROPERTY_DETAIL.SPACE')}</Text>
                <Text {...styles.about}>{listing?.publicDescription?.space}</Text>
              </VStack>
            )}
          </View>
          <View>
            {listing?.publicDescription?.neighborhood !== '' && (
              <VStack {...styles.vstack}>
                <Text {...styles.header}>{t('PROPERTY_DETAIL.NEIGHBORHOOD')}</Text>
                <Text {...styles.about}>{listing?.publicDescription?.neighborhood}</Text>
              </VStack>
            )}
          </View>
          <View>
            {listing?.publicDescription?.notes !== '' && (
              <VStack {...styles.vstack}>
                <Text {...styles.header}>{t('PROPERTY_DETAIL.NOTES')}</Text>
                <Text {...styles.about}>{listing?.publicDescription?.notes}</Text>
              </VStack>
            )}
          </View>
        </View>
      </ScrollView>
    </Box>
  );
};

export default AboutHomeModal;
