import { cloneElement, forwardRef, memo, useState } from 'react';
import { StyleSheet, ViewStyle, Platform } from 'react-native';
import { Box, IAspectRatioProps, usePropsResolution } from 'native-base';

/*
 **
 **  THIS COMPONNENT WAS COPIED FROM NATIVE-BASE BECAUSE OF A BUG
 **  RELATED TO WITH ONLAYPUT BEING TRIGGERED WITH ALL VALUES SET TO 0
 **  WE WILL PROBABLY NEED TO REMOVE IT IN THE FUTURE AND USE THE REAL NATIVE-BASE COMPONENT
 */
const AspectView = forwardRef((props: any, ref?: any) => {
  const [layout, setLayout] = useState();
  const aspectViewStyle = [StyleSheet.flatten(props.style) || {}];
  if (layout) {
    // @ts-ignore
    let { width = 0, height = 0 } = layout;
    if (width === 0) {
      aspectViewStyle.push({ width: height * props.aspectRatio, height });
    } else {
      aspectViewStyle.push({ width, height: width / props.aspectRatio });
    }
  }

  return (
    <Box
      ref={ref}
      {...props}
      style={aspectViewStyle}
      onLayout={({ nativeEvent: { layout: inLayout } }: any) => {
        // THIS IF IS THE FIX FOR WHICH I CLONED THE COMPONENT
        if (!!inLayout.x || !!inLayout.y || !!inLayout.width || !!inLayout.height) {
          setLayout(inLayout);
        }
      }}
    />
  );
});

const AspectRatio = (props: IAspectRatioProps, ref?: any) => {
  const {
    ratio,
    children = <></>,
    ...resolvedProps
  } = usePropsResolution('AspectRatio', props, {}, { resolveResponsively: ['ratio'] });
  let computedStyle: ViewStyle | undefined = resolvedProps.style;
  const newChildWithProps = cloneElement(
    children,
    {
      ...children?.props,
      style: StyleSheet.absoluteFillObject,
    },
    children?.props?.children
  );

  // DOC:  It uses a aspectRatio property of React Native and manually calculate on Web
  if (Platform.OS === 'web') {
    return (
      <AspectView aspectRatio={ratio} {...resolvedProps} ref={ref}>
        {newChildWithProps}
      </AspectView>
    );
  } else {
    computedStyle = StyleSheet.flatten([{ style: resolvedProps.style }, { aspectRatio: ratio }]);
    return (
      <Box {...resolvedProps} style={computedStyle} ref={ref}>
        {newChildWithProps}
      </Box>
    );
  }
};

export default memo(forwardRef(AspectRatio));
