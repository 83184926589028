import { FC, useEffect } from 'react';
import { useReadSubscriptionLazyQuery } from 'screens/Booking/queries/readSubscription.generated';
import { HomeEquityContext } from 'contexts/HomeEquityContext';
import { usePersistentValue } from 'hooks/usePersistentValue';

interface HomeEquityProviderProps {
  children: React.ReactNode;
}

export const HomeEquityProvider: FC<HomeEquityProviderProps> = ({ children }) => {
  const [storedSubscriptionId, , isStoredSubscriptionIdLoaded] = usePersistentValue<string | null>(
    'storedSubscriptionId',
    null
  );

  const [getSubscription, { data, loading, refetch, called }] = useReadSubscriptionLazyQuery({
    variables: {
      id: storedSubscriptionId || '',
    },
  });

  useEffect(() => {
    if (isStoredSubscriptionIdLoaded && storedSubscriptionId) {
      getSubscription();
    }
  }, [isStoredSubscriptionIdLoaded, storedSubscriptionId]);

  return (
    <HomeEquityContext.Provider
      value={{
        equityDetails: data?.readSubscription?.data,
        isLoading: !called || loading,
        refetch,
      }}>
      {children}
    </HomeEquityContext.Provider>
  );
};
