import { RootTabScreenProps } from '../../../types';
import { Box, ScrollView } from 'native-base';
import { FC, useContext } from 'react';
import ProfileNameBox from 'components/Profile/ProfileNameBox';
import ProfileEmailBox from 'components/Profile/ProfileEmailBox';
import ProfilePhoneNumberBox from 'components/Profile/ProfilePhoneNumebrBox';
import SwitchBox from 'components/Profile/SwitchBox';
import ProfileGenderBox from 'components/Profile/ProfileGenderBox';
import ProfileBirthDateBox from 'components/Profile/ProfileBirthDateBox';
import ProfileAddressBox from 'components/Profile/ProfileAddressBox';
import ProfileHeader from 'components/Profile/ProfileHeader';
import { MultiSubscriptionsContext } from 'contexts/MultiSubscriptionsContext';
import { Loading } from 'components/Loading';

const styles = {
  innerBox: {
    mt: ['40px', '40px', '10px', '10px', '10px'],
    pr: '16px',
    pl: '16px',
    pb: '36px',
    h: '100%',
    w: ['100%', '100%', '60%', '55%', '55%'],
    alignSelf: 'center' as const,
  },
};

const ProfileScreen: FC<RootTabScreenProps<'Profile'>> = () => {
  const { subscriptionsList, isLoading: isSubscriptionLoading } = useContext(MultiSubscriptionsContext);

  if (isSubscriptionLoading) return <Loading />;

  return (
    <Box safeAreaTop bg="bgPage" height="100%">
      <ScrollView>
        <ProfileHeader />
        <Box {...styles.innerBox}>
          <ProfileNameBox />
          <ProfileEmailBox />
          <ProfilePhoneNumberBox />
          {subscriptionsList?.length! > 1 ? <SwitchBox /> : null}
          <ProfileGenderBox />
          <ProfileBirthDateBox />
          <ProfileAddressBox />
        </Box>
      </ScrollView>
    </Box>
  );
};
export default ProfileScreen;
