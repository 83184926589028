import DateInput from '../Calendars/DateInput';
import DropDownPopup from 'components/LocationDropdown/DropDownPopup';
import { NavigationContext } from 'contexts/NavigationContext';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Hidden, HStack, Pressable, Text, View } from 'native-base';
import { City } from 'contexts/CitiesContext';
import { useContext, useEffect, useRef, useState } from 'react';
import DropDownPopupLarger from 'components/LocationDropdown/DropDownPopupLarger';
import { CalendarProvider } from 'providers/CalendarProvider';
import CalendarPopupLarger from 'components/Calendars/CalendarPopupLarger';
import { Platform } from 'react-native';

const styles = {
  location: {
    w: ['100%', '100%', '100%', '45%', '45%'],
  },
  divider: {
    borderWidth: '1',
    borderColor: 'styleSheet.darkStain',
  },
  box: {
    mb: '24px',
    borderWidth: '2',
    borderColor: 'styleSheet.darkStain',
    h: ['150px', '150px', '75px', '75px', '75px'],
    w: ['100%', '100%', '80%', '80%', '80%'],
    overflow: 'hidden',
    borderRadius: '12px',
    borderBottomLeftRadius: '12px',
    flexDirection: ['column' as const, 'column' as const, 'row' as const, 'row' as const, 'row' as const],
    alignSelf: 'center' as const,
    mt: ['0px', '0px', '40px', '40px', '40px'],
  },
  hstack: {
    display: 'flex',
    backgroundColor: 'styleSheet.canvas',
    mt: '14px',
  },

  checkIn: {
    flexGrow: 1,
    flexShrink: 1,
    fontSize: 'sm',
    ml: '15px',
    color: 'styleSheet.lightShadow',
    opacity: '0.55',
  },
  checkOut: {
    flexGrow: 1,
    flexShrink: 1,
    fontSize: 'sm',
    color: 'styleSheet.lightShadow',
    opacity: '0.55',
    mr: '12px',
  },
  boxLocationCal: (isOpen: boolean, isOpenCal: boolean) => ({
    borderWidth: '2',
    borderColor: 'styleSheet.darkStain',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomLeftRadius: isOpen || isOpenCal ? 0 : 10,
    borderBottomRightRadius: isOpenCal ? 0 : 10,
    borderRightWidth: '2',
    w: '80%',
    alignSelf: 'center' as const,
  }),
  pressable: {
    h: '75px',
    w: '100%',
    display: 'flex',
  },
  headerText: {
    position: 'absolute' as const,
    fontSize: 'sm',
    top: '10px',
    left: '16px',
    color: 'styleSheet.lightShadow',
    fontFamily: 'body',
    fontWeight: '400',
    opacity: '0.55',
  },
};

interface ReservationSelectBoxProps {
  checkInDate: string;
  checkOutDate: string;
  selectedCities: City[];
  setSelectedCities: (e: City[]) => void;
  setCheckOut: (s: string) => void;
  setCheckIn: (s: string) => void;
}

const ReservationSelectBox = ({
  checkInDate,
  checkOutDate,
  selectedCities,
  setSelectedCities,
  setCheckIn,
  setCheckOut,
}: ReservationSelectBoxProps) => {
  const { t } = useTranslation();
  const { useNavigation } = useContext(NavigationContext);
  const [isOpen, setIsOpen] = useState(false);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [widthDims, setWidthDims] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (Platform.OS === 'web') {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsCalendarOpen(false);
          setIsOpen(false);
        }
      };
      document.addEventListener('click', handleClickOutside, true);
      return () => {
        document.removeEventListener('click', handleClickOutside, true);
      };
    }
  }, []);

  return (
    <Box ref={ref}>
      <Hidden from={'md'}>
        <Box {...styles.box}>
          <View {...styles.location}>
            <Text {...styles.headerText}>{t('BOOK_A_STAY.LOCATION')}</Text>
            <DropDownPopup
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              selectedCities={selectedCities}
              onSelectionChanged={setSelectedCities}
            />
          </View>
          <Divider {...styles.divider} />
          <Pressable
            {...styles.pressable}
            onPress={() => {
              useNavigation('BookingCalendarModalScreen');
            }}>
            <DateInput
              checkIn={checkInDate}
              checkOut={checkOutDate}
              onPressIn={() => {
                useNavigation('BookingCalendarModalScreen', {
                  checkInDate,
                  checkOutDate,
                });
              }}
            />
          </Pressable>
        </Box>
      </Hidden>

      <Hidden till={'md'}>
        <HStack
          onLayout={(event) => {
            const { width } = event.nativeEvent.layout;
            setWidthDims(width);
          }}
          {...styles.boxLocationCal(isOpen, isCalendarOpen)}>
          <View style={{ width: '50%' }}>
            <DropDownPopupLarger
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              isOpenCal={isCalendarOpen}
              setIsOpenCal={setIsCalendarOpen}
              selectedCitiesList={selectedCities}
              onSelectionChanged={setSelectedCities}
            />
          </View>

          <View style={{ width: '50%' }}>
            <CalendarProvider>
              <CalendarPopupLarger
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                isOpenCal={isCalendarOpen}
                setIsOpenCal={setIsCalendarOpen}
                checkIn={checkInDate}
                checkOut={checkOutDate}
                popoverWidth={widthDims}
                setCheckIn={setCheckIn}
                setCheckOut={setCheckOut}
              />
            </CalendarProvider>
          </View>
        </HStack>
      </Hidden>
    </Box>
  );
};

export default ReservationSelectBox;
