import { createContext } from 'react';
import { noop } from 'lodash';
import { GetAllListingsQuery } from 'screens/Booking/queries/getAllListings.generated';
import { City } from 'contexts/CitiesContext';

export type GetAllListingsType = GetAllListingsQuery['getAllListings']['data'];

export interface IGuests {
  adults: number;
  children: number;
  infants: number;
  pets: number;
}

export type ListingsInitialState = {
  checkIn: string;
  checkOut: string;
  guestInput: IGuests;
  // selectedCities: City[] | undefined;
  APISelectedCities: City[] | undefined;
  setCheckIn: (date: string) => void;
  setCheckOut: (date: string) => void;
  // setSelectedCities: (cities: City[]) => void;
  setAPISelectedCities: (cities: City[]) => void;
  addAdultHandler: (v: number) => void;
  addChildrenHandler: (v: number) => void;
  addInfantHandler: (v: number) => void;
  addPetHandler: (v: number) => void;
  removeAdultHandler: (v: number) => void;
  removeChildrenHandler: (v: number) => void;
  removeInfantHandler: (v: number) => void;
  removePetHandler: (v: number) => void;
  listings: GetAllListingsType;
  isLoading: boolean;
  filterGuests: IGuests;
  guestsComposition: IGuests;
  setGuestsComposition: (e) => void;
  setFilterGuests: (e) => void;
};

export const ListingsContext = createContext<ListingsInitialState>({
  checkIn: '',
  checkOut: '',
  APISelectedCities: [],
  setAPISelectedCities: noop,
  guestInput: { adults: 1, children: 0, infants: 0, pets: 0 },
  guestsComposition: { adults: 1, children: 0, infants: 0, pets: 0 },
  setGuestsComposition: noop,
  setCheckIn: noop,
  setCheckOut: noop,
  listings: [],
  isLoading: false,
  filterGuests: { adults: 1, children: 0, infants: 0, pets: 0 },
  setFilterGuests: noop,
  addAdultHandler: noop,
  addChildrenHandler: noop,
  addInfantHandler: noop,
  addPetHandler: noop,
  removeAdultHandler: noop,
  removeChildrenHandler: noop,
  removeInfantHandler: noop,
  removePetHandler: noop,
});
