import { FC, useContext } from 'react';
import { Box, Pressable, HStack, CloseIcon, Text, Divider, FlatList, useBreakpointValue } from 'native-base';
import { RootStackScreenProps } from 'types';
import { useTranslation } from 'react-i18next';
import iconAmenities from 'utils/iconAmenities';
import { PropertyDetailContext } from 'contexts/PropertyDetailContext';

const styles = {
  container: {
    flex: 1,
    bgColor: 'styleSheet.canvas',
  },
  icon: {
    color: 'pdp.shadow',
    alignSelf: 'center' as const,
    size: '4',
  },
  innerBox: {
    h: '100%',
    w: '100%',
    bg: 'bgPage',
  },

  pressable: {
    p: '20px',
    _hover: {
      bg: 'styleSheet.canvas',
      borderColor: 'styleSheet.darkStain',
      borderWidth: '1px',
      borderRadius: '12px',
    },
    mr: ['16px', '16px', '48px', '48px', '64px'],
    position: 'absolute' as const,
    right: '0',
    justifyContent: 'center' as const,
  },
  divider: {
    variant: 'primary',
    w: '110%',
    ml: '-17px',
  },
  title: {
    color: 'pdp.shadow',
    fontSize: ['2md', '2md', '1xl', '1xl', '1xl'],
    fontFamily: 'body600',
    alignSelf: 'center',
    pl: ['16px', '16px', '40px', '40px', '64px'],
    pt: ['28px', '28px', '40px', '40px', '40px'],
    pb: ['16px', '28px', '40px', '40px', '40px'],
  },
  hstack: {
    alignItems: 'center' as const,
  },
  listWrapper: {
    pb: '120px',
    h: '100%',
    alignItems: [
      'flex-start' as const,
      'flex-start' as const,
      'flex-start' as const,
      'center' as const,
      'center' as const,
    ],
  },
  flatList: {
    pl: ['16px', '28px', '80px', '80px', '80px'],
    pr: ['16px', '28px', '80px', '80px', '80px'],
    pt: ['32px', '32px', '80px', '80px', '40px'],
    w: '100%',
  },
  listItem: {
    mb: ['43px', '43px', '43px', '43px', '30px'],
    w: ['100%', '100%', '50%', '40%', '25%'],
    mt: ['0px', '0px', '0px', '43px', '43px'],
  },
  listBox: {
    mt: '25px',
  },
  header: {
    fontSize: '1xl',
    fontFamily: 'body600',
    textTransform: 'capitalize' as const,
  },
  text: {
    color: 'pdp.shadow',
    fontSize: '2md',
    fontFamily: 'body',
  },
  innerHstack: {
    mb: '21px',
    alignItems: 'center' as const,
    space: 2,
  },
};

interface IAmenitiesModalProps extends RootStackScreenProps<'AmenitiesModal'> {
  setShowModal: (state: boolean) => void;
}

const shouldRenderTwoColumns = (breakpointValue: number) => {
  return breakpointValue >= 4;
};

const AmenitiesModal: FC<IAmenitiesModalProps> = ({ navigation, setShowModal }) => {
  let breakpointTypeValue = useBreakpointValue({ base: 1, sm: 2, md: 3, lg: 4, xl: 5 });
  const { t } = useTranslation();
  const { listing } = useContext(PropertyDetailContext);
  let amenities = listing?.mappedAmenities;
  let amenitiesList: { name: string; id: string[] }[] = [];
  amenities &&
    Object.entries(amenities).forEach((e) => {
      if (e[1] !== null && e[0] !== '__typename') {
        switch (e[0]) {
          case 'homeFeatures':
            return amenitiesList.push({ name: 'Home Features', id: e[1] } as never);
          case 'kitchenAndDining':
            return amenitiesList.push({ name: 'Kitchen And Dining', id: e[1] } as never);
          case 'livingRoom':
            return amenitiesList.push({ name: 'Living Room', id: e[1] } as never);
          default:
            return amenitiesList.push({ name: e[0], id: e[1] } as never);
        }
      }
    });

  const numCols = shouldRenderTwoColumns(breakpointTypeValue) ? 2 : 1;

  const handleCloseModal = () => {
    if (breakpointTypeValue == 3 || breakpointTypeValue == 4 || breakpointTypeValue == 5) {
      setShowModal(false);
    } else {
      navigation.goBack();
    }
  };

  return (
    <Box {...styles.container} safeAreaTop>
      <Box {...styles.innerBox}>
        <HStack {...styles.hstack}>
          <Text {...styles.title}>{t('PROPERTY_DETAIL.HOME_AMENITIES')}</Text>
          <Pressable onPress={handleCloseModal} {...styles.pressable}>
            <CloseIcon {...styles.icon} />
          </Pressable>
        </HStack>

        <Divider {...styles.divider} />

        <Box {...styles.listWrapper}>
          <FlatList
            {...styles.flatList}
            data={amenitiesList}
            numColumns={numCols}
            // columnWrapperStyle is only allowed with multi-column layouts
            columnWrapperStyle={shouldRenderTwoColumns(breakpointTypeValue) && { justifyContent: 'center' }}
            showsVerticalScrollIndicator={false}
            renderItem={({ item }) => {
              return (
                <Box {...styles.listItem}>
                  <Text {...styles.header}>{item.name}</Text>
                  <Box {...styles.listBox}>
                    {iconAmenities(item.id).map((el) => {
                      return (
                        <HStack {...styles.innerHstack} key={el.name}>
                          <Box>{el.icon}</Box>
                          <Text {...styles.text}>{el.name}</Text>
                        </HStack>
                      );
                    })}
                  </Box>
                </Box>
              );
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default AmenitiesModal;
