import React from 'react';
import { noop } from 'lodash';

interface IGuests {
  adults: number;
  children: number;
  infants: number;
  pets: number;
}

interface ICities {
  name: string;
  id: string;
  city: string;
}

export type ReservationInitialSate = {
  totalGuest: number;
  setTotalGuest: (v: number) => void;
  guests: IGuests;
  setGuests: (v: IGuests) => void;
  selectedItem: ICities[];
  addAdultHandler: (v: number) => void;
  addChildrenHandler: (v: number) => void;
  addInfantHandler: (v: number) => void;
  addPetHandler: (v: number) => void;
  removeAdultHandler: (v: number) => void;
  removeChildrenHandler: (v: number) => void;
  removeInfantHandler: (v: number) => void;
  removePetHandler: (v: number) => void;
  setSelectedItem: (v: ICities[]) => void;
  checkInDate: string;
  checkOutDate: string;
  setCheckInDate: (v: string) => void;
  setCheckOutDate: (v: string) => void;
};

//context
export const ReservationContext = React.createContext<ReservationInitialSate>({
  totalGuest: 0,
  guests: { adults: 1, children: 0, infants: 0, pets: 0 },
  setGuests: noop,
  selectedItem: [{ name: '', id: '', city: '' }],
  addAdultHandler: noop,
  addChildrenHandler: noop,
  addInfantHandler: noop,
  addPetHandler: noop,
  removeAdultHandler: noop,
  removeChildrenHandler: noop,
  removeInfantHandler: noop,
  removePetHandler: noop,
  setSelectedItem: noop,
  checkInDate: '',
  checkOutDate: '',
  setCheckInDate: noop,
  setCheckOutDate: noop,
  setTotalGuest: noop,
});
