import { Image, Text, View } from 'native-base';
import icon from 'assets/images/icon.png';
import { useTranslation } from 'react-i18next';

const styles = {
  h2: {
    mb: ['24px', '24px', '24px', '40px', '40px'],
    fontSize: ['2.5xl', '2.5xl', '5.5xl', '5.5xl', '5.5xl'],
    color: 'styleSheet.overCast',
    textAlign: 'center' as const,
    lineHeight: ['31px', '31px', '55px', '58px', '58px'],
    fontFamily: 'body',
    fontWeight: '300',
  },
  logo: {
    mt: '22px',
    alignSelf: 'center',
  },
};

interface TopHeaderProps {
  firstName: string;
}

const TopHeader = ({ firstName }: TopHeaderProps) => {
  const { t } = useTranslation();
  const CapFirstName = firstName?.charAt(0).toUpperCase() + firstName?.slice(1);
  return (
    <View>
      <Image source={icon} size="lg" alt="summer logo" {...styles.logo} />
      <Text {...styles.h2}>
        {CapFirstName}
        {t('BOOK_A_STAY.LETS_FIND')}
      </Text>
    </View>
  );
};

export default TopHeader;
