export enum direction {
  row = 'row',
  column = 'column',
  column_reverse = 'column-reverse',
  row_reverse = 'row-reverse',
}
export enum contentAlignment {
  center = 'center',
  flex_start = 'flex_start',
  flex_end = 'flex_end',
  stretch = 'stretch',
  space_between = 'space_between',
  space_around = 'space_around',
}
