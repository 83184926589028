import { Box, CheckIcon, HStack, Select, Text, Button, Input, Hidden } from 'native-base';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { useUpdateUserMutation } from 'screens/Booking/queries/updateUser.generated';
import LoadingToast from './LoadingToast';
import * as yup from 'yup';
import { RequiredIcon } from 'components/SignUp/RequiredIcon';
import { UserAddressInput } from 'src/generated/types';
import { UserContext } from 'contexts/UserContext';
import DownArrowIcon from '../../assets/icons/Down-arrow.svg';
import Layout from 'constants/Layout';

const windowWidth = Layout.window.width;

const mainBox = {
  borderRadius: '12px',
  borderWidth: '1',
  borderColor: 'styleSheet.darkStain',
  pt: '8px',
  pb: '8px',
  pl: '20px',
  mt: '16px',
  h: '75px',
};

const styles = {
  formGroup: (error: string) => ({
    h: '75px',
    borderRadius: '12px',
    borderWidth: '1',
    borderTopColor: error ? 'notification' : 'styleSheet.darkStain',
    borderBottomColor: error ? 'notification' : 'styleSheet.darkStain',
    borderLeftColor: error ? 'notification' : 'styleSheet.darkStain',
    borderRightColor: error ? 'notification' : 'styleSheet.darkStain',
    pt: '8px',
    pb: '8px',
    pl: '20px',
    mt: '16px',
  }),
  fieldText: {
    fontFamily: 'body600',
    color: 'styleSheet.shadow',
    fontSize: 'sm',
    opacity: '0.55',
  },
  mainBox: {
    ...mainBox,
  },
  hstackState: (widthDims: number) => ({
    ...mainBox,
    h: '75px',
    w: [windowWidth / 2.25, windowWidth / 2.25, windowWidth / 3.7, widthDims / 2.05, widthDims / 2.05],
  }),
  hstackZipCode: (widthDims: number, error: string) => ({
    ...mainBox,
    borderTopColor: error ? 'notification' : 'styleSheet.darkStain',
    borderBottomColor: error ? 'notification' : 'styleSheet.darkStain',
    borderLeftColor: error ? 'notification' : 'styleSheet.darkStain',
    borderRightColor: error ? 'notification' : 'styleSheet.darkStain',
    ml: '16px',
    w: [windowWidth / 2.25, windowWidth / 2.25, windowWidth / 3.7, widthDims / 2.05, widthDims / 2.05],
  }),
  dropdownIcon: {
    position: 'absolute' as const,
    right: 5,
    bottom: [3, 3, 4, 4, 4],
  },
  dropdown: {
    borderBottomColor: 'transparent',
    borderTopColor: 'transparent',
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    borderRadius: 1,
    fontSize: '2md',
    pl: '-4px',
    mr: ['-5px', '-5px', '-5px', '5px', '5px'],
    _hover: { bgColor: 'styleSheet.canvas' },
  },
  checkIcon: {
    size: '4',
    marginLeft: '75%',
  },
  button: {
    variant: 'primaryMobile',
    _text: {
      fontFamily: 'body600',
      color: 'styleSheet.canvas',
      fontSize: '2md',
    },
    w: ['100%', '100%', '30%', '25%', '25%'],
    mt: '16px',
  },
  error: {
    fontSize: 'sm',
    fontFamily: 'body',
    color: 'notification',
    ml: '6px',
  },
  hstackError: {
    position: 'absolute' as const,
    right: 5,
    alignItems: 'center' as const,
    pb: '25px',
  },
  text: {
    pt: [1, 1, 0, 0, 0],
    pl: 0,
    borderWidth: 1,
    borderColor: 'styleSheet.canvas',
    _focus: {
      borderColor: 'styleSheet.canvas',
    },
    fontSize: '2md',
    fontFamily: 'body',
    color: 'styleSheet.shadow',
  },
  hstackErrorLarge: {
    alignItems: 'center' as const,
    mt: '10px',
  },
};

interface AddrerssProps {
  streetAddress: string | null | undefined;
  city: string | null | undefined;
  state: string | null | undefined;
  zipcode: string | null | undefined;
  country: string | null | undefined;
  setEditAddress: (s: boolean) => void;
}

const UpdateAddressBox = ({ streetAddress, city, state, zipcode, country, setEditAddress }: AddrerssProps) => {
  const { t } = useTranslation();
  const [widthDims, setWidthDims] = useState(0);
  const [selectedState, setSelectedState] = useState(state);
  const [loader, setLoader] = useState(false);
  const [updateUserData, { error: updateUserDataError }] = useUpdateUserMutation();
  const { refetch, setProfileInfo } = useContext(UserContext);

  const getStatesOfCountry = require('country-state-city/lib/state').getStatesOfCountry('US');
  const getStatesOfCountryList = Array.isArray(getStatesOfCountry) ? getStatesOfCountry.map((element) => element) : [];

  const handleVisibility = () => {
    setLoader(false);
    setEditAddress(false);
    setProfileInfo((prev) => {
      return { ...prev, email: true, phone: true, switch: true, gender: true, dateOfBirth: true, name: true };
    });
  };

  const handleUpdateUserData = async (values) => {
    setLoader(true);
    const updatedValues = {
      city: values.city,
      country: country,
      state: selectedState,
      street: values.streetAddress,
      zipcode: values.zipcode,
    } as UserAddressInput;

    await updateUserData({
      variables: {
        data: {
          address: updatedValues,
        },
      },
      onCompleted: () => {
        refetch();
        handleVisibility();
      },
      onError: () => {
        if (__DEV__) console.log('Unable to update the address. Error: ', updateUserDataError);
      },
    });
    [updateUserData];
  };

  const validationSchema = yup.object().shape({
    streetAddress: yup.string().required(t('INPUT_DEFAULT_ERROR')),
    city: yup.string().required(t('INPUT_DEFAULT_ERROR')),
    state: yup.string().required(t('INPUT_DEFAULT_ERROR')),
    zipcode: yup
      .string()
      .required(t('INPUT_DEFAULT_ERROR'))
      .min(5, t('PROFILE.INVALID_ZIPCODE'))
      .max(5, t('PROFILE.INVALID_ZIPCODE')),
  });

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{ streetAddress: streetAddress, city: city, state: state, zipcode: zipcode, country: country }}
      onSubmit={(values) => handleUpdateUserData(values)}>
      {({ handleChange, handleBlur, handleSubmit, values, errors, isValid }) => (
        <>
          <Box {...styles.formGroup(errors.streetAddress!)}>
            <Text {...styles.fieldText}>{t('PROFILE.UPDATE_ADDRESS.STREET_NAME')}</Text>
            <HStack>
              <Input
                placeholder={t('PROFILE.UPDATE_ADDRESS.STREET_NAME')}
                onChangeText={handleChange('streetAddress')}
                onBlur={handleBlur('streetAddress')}
                value={values.streetAddress}
                _hover={{ bg: 'styleSheet.canvas' }}
                {...styles.text}
              />
              {errors.streetAddress && (
                <HStack {...styles.hstackError}>
                  <RequiredIcon size={13} color="rgb(255, 69, 58)" />
                  <Text {...styles.error}>{errors.streetAddress}</Text>
                </HStack>
              )}
            </HStack>
          </Box>

          <Box {...styles.formGroup(errors.city!)}>
            <Text {...styles.fieldText}>{t('PROFILE.UPDATE_ADDRESS.CITY')}</Text>
            <HStack>
              <Input
                placeholder={t('PROFILE.UPDATE_ADDRESS.CITY')}
                onChangeText={handleChange('city')}
                onBlur={handleBlur('city')}
                value={values.city}
                _hover={{ bg: 'styleSheet.canvas' }}
                {...styles.text}
              />
              {errors.city && (
                <HStack {...styles.hstackError}>
                  <RequiredIcon size={13} color="rgb(255, 69, 58)" />
                  <Text {...styles.error}>{errors.city}</Text>
                </HStack>
              )}
            </HStack>
          </Box>

          <HStack
            onLayout={(event) => {
              const { width } = event.nativeEvent.layout;
              setWidthDims(width);
            }}>
            <Box {...styles.hstackState(widthDims)}>
              <Text {...styles.fieldText}>{t('PROFILE.UPDATE_ADDRESS.STATE')}</Text>
              <Select
                selectedValue={selectedState!}
                placeholderTextColor="styleSheet.shadow"
                dropdownIcon={
                  <Box {...styles.dropdownIcon}>
                    <DownArrowIcon height={30} width={30} />
                  </Box>
                }
                {...styles.dropdown}
                _selectedItem={{
                  background: 'styleSheet.lightStain',
                  rightIcon: <CheckIcon {...styles.checkIcon} />,
                }}
                onValueChange={(itemValue) => setSelectedState(itemValue)}>
                {getStatesOfCountryList.map((state: any) => (
                  <Select.Item key={state.isoCode} label={state.name} value={state.name} />
                ))}
              </Select>
            </Box>

            <Box {...styles.hstackZipCode(widthDims, errors.zipcode!)}>
              <Text {...styles.fieldText}>{t('PROFILE.UPDATE_ADDRESS.ZIPCODE')}</Text>
              <HStack>
                <Input
                  placeholder={t('PROFILE.UPDATE_ADDRESS.ZIPCODE')}
                  onChangeText={handleChange('zipcode')}
                  onBlur={handleBlur('zipcode')}
                  value={values.zipcode}
                  _hover={{ bg: 'styleSheet.canvas' }}
                  {...styles.text}
                />
                {errors.zipcode && (
                  <Hidden till={'lg'}>
                    <HStack {...styles.hstackError} style={{ alignSelf: 'center' as const }}>
                      <RequiredIcon size={13} color="rgb(255, 69, 58)" />
                      <Text {...styles.error}>{errors.zipcode}</Text>
                    </HStack>
                  </Hidden>
                )}
              </HStack>
            </Box>
          </HStack>

          {errors.zipcode && (
            <Hidden from={'lg'}>
              <HStack {...styles.hstackErrorLarge} style={{ alignSelf: 'center' as const }}>
                <RequiredIcon size={13} color="rgb(255, 69, 58)" />
                <Text {...styles.error}>{errors.zipcode}</Text>
              </HStack>
            </Hidden>
          )}

          {loader ? (
            <LoadingToast />
          ) : (
            <Button {...styles.button} onPress={handleSubmit}>
              {t('PROFILE.SAVE_CHANGES')}
            </Button>
          )}
        </>
      )}
    </Formik>
  );
};

export default UpdateAddressBox;
