import { UserContext } from 'contexts/UserContext';
import { Box, Text, Divider, HStack, VStack, Pressable, View, Select, CheckIcon, Button } from 'native-base';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUpdateUserMutation } from 'screens/Booking/queries/updateUser.generated';
import LoadingToast from './LoadingToast';
import DownArrowIcon from '../../assets/icons/Down-arrow.svg';

const styles = {
  textKey: {
    textAlign: 'justify' as const,
    fontSize: ['sm', 'sm', '2md', '2md', '2md'],
    fontFamily: 'body600',
    color: 'styleSheet.shadow',
    opacity: '0.55',
  },
  textValue: {
    fontSize: '2md',
    fontFamily: 'body',
    color: 'styleSheet.shadow',
  },
  divider: {
    borderWidth: 0.5,
    borderColor: 'styleSheet.darkStain',
    mt: '16px',
  },
  hr: {
    borderWidth: '0.5px',
    borderColor: 'styleSheet.lightShadow',
    mt: '-2px',
  },
  linkButton: {
    fontFamily: 'body',
    color: 'styleSheet.shadow',
    fontSize: '2md',
  },
  hstack: {
    alignItems: 'center' as const,
    justifyContent: 'space-between' as const,
  },
  box: (v: boolean) => ({
    opacity: v ? 1 : 0.5,
    mt: '16px',
  }),
  dropdownIcon: {
    position: 'absolute' as const,
    right: 18,
    bottom: [5, 5, 4, 3, 3],
    w: [5, 5, 5, 30, 30],
    h: [5, 5, 5, 30, 30],
  },
  button: {
    variant: 'primaryMobile',
    _text: {
      fontFamily: 'body600',
      color: 'styleSheet.canvas',
      fontSize: '2md',
    },
    w: ['100%', '100%', '40%', '40%', '40%'],
    mt: '16px',
  },
  icon: {
    position: 'absolute' as const,
    right: 10,
  },
  dropdown: {
    borderWidth: 0,
    fontSize: '2md',
    pt: ['4px', '4px', '4px', '-10px', '-10px'],
    bg: 'styleSheet.canvas',
    mr: [null, null, 5, null, null],
    _hover: { bgColor: 'transparent' },
  },
  dropdownBox: {
    w: ['100%', '100%', '100%', '40%', '40%'],
    borderColor: 'styleSheet.lightOverCast',
    borderBottomColor: 'styleSheet.lightOverCast',
    borderTopColor: 'styleSheet.lightOverCast',
    borderLeftColor: 'styleSheet.lightOverCast',
    borderRightColor: 'styleSheet.lightOverCast',
    borderRadius: 12,
    borderWidth: 1,
    pl: '20px',
    pt: '12px',
    h: '75px',
    mt: 3,
  },
  checkIcon: {
    size: '4',
    marginLeft: '80%',
  },
  genderSubText: {
    fontFamily: 'body600',
    color: 'styleSheet.shadow',
    opacity: '0.55',
    fontSize: 'sm',
    pl: '5px',
  },
};

const ProfileGenderBox = () => {
  const { t } = useTranslation();
  const { user, profileInfo, setProfileInfo, refetch } = useContext(UserContext);
  const [editGender, setEditGender] = useState<boolean>(false);
  const listOfGender = [
    t('PROFILE.PRONOUNSLIST.SHE/HER'),
    t('PROFILE.PRONOUNSLIST.HE/HIM'),
    t('PROFILE.PRONOUNSLIST.THEY'),
    t('PROFILE.PRONOUNSLIST.PREFER_NOT_TO_SAY'),
  ];

  const [selectedGender, setSelectedGender] = useState(user?.gender!);

  const [loader, setLoader] = useState(false);
  const [updateUserData, { error: updateUserDataError }] = useUpdateUserMutation();

  const handleEdit = () => {
    setProfileInfo((prev) => {
      return { ...prev, email: false, phone: false, switch: false, name: false, dateOfBirth: false, address: false };
    });
    setEditGender(true);
  };

  const handleCancel = () => {
    setSelectedGender(user?.gender!);
    handleVisibility();
  };

  const handleVisibility = (data?: any) => {
    setEditGender(false);
    setProfileInfo((prev) => {
      return { ...prev, email: true, phone: true, switch: true, name: true, dateOfBirth: true, address: true };
    });
  };
  const genderStyle = editGender ? styles.linkButton : styles.textKey;

  const handleUpdateGender = async () => {
    setLoader(true);
    await updateUserData({
      variables: {
        data: {
          Gender__c: selectedGender,
        },
      },
      onCompleted: () => {
        setLoader(false);
        refetch();
        handleVisibility();
      },
      onError: () => {
        //TODO will do the error handling later when mocks are ready
        if (__DEV__) console.log(updateUserDataError);
      },
    });
    [updateUserData];
  };

  return (
    <Box {...styles.box(profileInfo.gender)}>
      <HStack {...styles.hstack}>
        <VStack>
          {!editGender ? (
            <Box>
              <Text {...genderStyle}>{t('PROFILE.PRONOUNS')}</Text>
              <Text {...styles.textValue}>{selectedGender}</Text>
            </Box>
          ) : (
            <HStack>
              <Text {...styles.linkButton}>{t('PROFILE.EDIT')}</Text>
              <Text {...genderStyle} style={{ marginLeft: 2 }}>
                {t('PROFILE.PRONOUNS')}
              </Text>
            </HStack>
          )}
        </VStack>

        {!editGender ? (
          <Pressable onPress={handleEdit} isDisabled={!profileInfo.gender}>
            <Text {...styles.linkButton}>{t('PROFILE.EDIT')}</Text>
            <View {...styles.hr} />
          </Pressable>
        ) : (
          <Pressable onPress={handleCancel}>
            <Text {...styles.linkButton}>{t('PROFILE.CANCEL')}</Text>
            <View {...styles.hr} />
          </Pressable>
        )}
      </HStack>
      {editGender ? (
        <Box>
          <Box {...styles.dropdownBox}>
            <Text {...styles.genderSubText}>{t('PROFILE.PRONOUNS')}</Text>
            <Select
              selectedValue={selectedGender}
              accessibilityLabel={t('PROFILE.PRONOUNSLIST.SELECT_PRONOUNS')}
              placeholder={t('PROFILE.PRONOUNSLIST.SELECT_PRONOUNS')}
              placeholderTextColor="styleSheet.shadow"
              dropdownIcon={
                <Box {...styles.dropdownIcon}>
                  <DownArrowIcon />
                </Box>
              }
              {...styles.dropdown}
              _selectedItem={{
                background: 'styleSheet.lightStain',
                width: '100%',
                rightIcon: <CheckIcon {...styles.checkIcon} />,
              }}
              onValueChange={(itemValue) => setSelectedGender(itemValue)}>
              {listOfGender.map((gender) => (
                <Select.Item key={gender} label={gender} value={gender} />
              ))}
            </Select>
          </Box>

          {loader ? (
            <LoadingToast />
          ) : (
            <Button {...styles.button} onPress={handleUpdateGender}>
              {t('PROFILE.SAVE_CHANGES')}
            </Button>
          )}
        </Box>
      ) : null}

      <Divider {...styles.divider} />
    </Box>
  );
};

export default ProfileGenderBox;
