import { Button, Pressable, Text, View } from 'native-base';
import { NavigationContext } from 'contexts/NavigationContext';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { City } from 'contexts/CitiesContext';

const styles = {
  linkButton: {
    fontFamily: 'body',
    fontWeight: '400',
    color: 'styleSheet.shadow',
    fontSize: '2md',
  },
  view: {
    alignItems: 'center' as const,
    justifyContent: 'center' as const,
    mb: '40px',
    mt: '24px',
  },
  hr: {
    borderWidth: '0.5px',
    borderColor: 'styleSheet.lightShadow',
    mt: '-2px',
  },

  button: {
    variant: 'primaryMobile',
    _text: {
      fontFamily: 'body600',
      color: 'styleSheet.canvas',
      fontSize: '2md',
    },
    w: ['100%', '100%', '45%', '35%'],
    alignSelf: 'center' as const,
    mt: ['0px', '0px', '24px', '40px'],
    _pressed: {
      bg: 'styleSheet.shadow',
      opacity: '0.6',
      _text: { color: 'styleSheet.canvas' },
    },
    _hover: {
      bg: 'styleSheet.darkStain',
      _text: { color: 'styleSheet.shadow' },
    },
    _focus: {
      bg: 'styleSheet.shadow',
    },
  },
};

interface FooterButtonsProps {
  checkInDate: string;
  checkOutDate: string;
  selectedCities: City[];
  onPressHandler: () => void;
}

const FooterButtons = ({ checkInDate, checkOutDate, selectedCities, onPressHandler }: FooterButtonsProps) => {
  const { t } = useTranslation();
  const { useNavigation } = useContext(NavigationContext);

  const isYourHomeTheOnlySelection = () => selectedCities?.length === 1 && selectedCities[0].full === 'My Home';
  return (
    <>
      <Button
        children={isYourHomeTheOnlySelection() ? t('BOOK_A_STAY.BOOK_MY_HOME') : t('BOOK_A_STAY.SEARCH_FOR_HOMES')}
        {...styles.button}
        onPress={() => {
          useNavigation('ListingNavigator', {
            screen: 'ListingResults',
            params: {
              checkInDate,
              checkOutDate,
              selectedCities: selectedCities.map((c) => c.homeId || c.nickname),
              APISelectedCities: selectedCities,
            },
          });
        }}
      />
      <View {...styles.view}>
        <Pressable onPress={onPressHandler}>
          <Text {...styles.linkButton}>{t('BOOK_A_STAY.OR_BROWSE_ALL_HOMES')}</Text>
          <View {...styles.hr} />
        </Pressable>
      </View>
    </>
  );
};

export default FooterButtons;
