import { FC, useEffect, useState } from 'react';
import { Box, Hidden, Pressable, Text, useBreakpointValue } from 'native-base';
import BookCalendar from 'components/Calendars/BookCalendar';
import CTAButton from 'components/libs/button/CTAButton';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Dimensions } from 'react-native';
const styles = {
  box: {
    bg: 'bgPage',
  },

  title: {
    mt: '48px',
    color: 'pdp.shadow',
    fontSize: '1xl',
    fontFamily: 'body600',
  },
  dates: {
    color: 'pdp.shadow',
    fontSize: 'sm',
    mb: '32px',
  },
  button: {
    borderWidth: '1',
    bg: 'styleSheet.canvas',
    opacity: '0.7',
    h: '46px',
    borderRadius: '8px',
    borderColor: 'pdp.border',
    _pressed: {
      bg: 'styleSheet.shadow',
      opacity: '0.6',
      _text: { color: 'styleSheet.canvas' },
    },
    _hover: {
      bg: 'styleSheet.overCast',
      _text: { color: 'styleSheet.lightStain' },
    },
    _focus: {
      bg: 'styleSheet.canvas',
    },
    _text: {
      color: 'pdp.shadow',
      fontSize: 'sm',
    },
  },
  buttonClickZone: {
    mt: '32px',
    p: '8px',
  },
  mainView: {
    flexDirection: ['column' as const, 'column' as const, 'column' as const, 'row' as const, 'row' as const],
    w: '100%',
  },
  largeScreenButton: {
    position: 'absolute' as const,
    right: 0,
  },
};

interface CalendarProps {
  checkIn: string | null;
  checkOut: string | null;
  setMinNight(nights: number);
  setSelectedDates(checkInDate: string | null, checkOutDate: string | null);
}

const Calendar: FC<CalendarProps> = ({ setSelectedDates, checkIn, checkOut, setMinNight }) => {
  const { t } = useTranslation();
  let breakpointTypeValue = useBreakpointValue({ base: 1, sm: 2, md: 3, lg: 4, xl: 5 });
  const [calendarWidth, setCalendarWidth] = useState<number>(0);

  const button = (
    <Pressable
      onPress={() => {
        setSelectedDates(null, null);
      }}
      {...styles.buttonClickZone}>
      <CTAButton
        onPress={() => {
          setSelectedDates(null, null);
        }}
        {...styles.button}>
        {t('PROPERTY_DETAIL.CLEAR_DATES')}
      </CTAButton>
    </Pressable>
  );

  useEffect(() => {
    if (breakpointTypeValue == 5) {
      setCalendarWidth((Dimensions.get('window').width - Dimensions.get('window').width * 0.25) / 3.15);
    }
    if (breakpointTypeValue == 4) {
      setCalendarWidth((Dimensions.get('window').width - Dimensions.get('window').width * 0.15) / 2.05);
    }
  }, []);

  return (
    <Box {...styles.box}>
      <Box {...styles.mainView}>
        <Box>
          <Text {...styles.title}>{t('PROPERTY_DETAIL.YOUR_SUMMER_STAY')}</Text>
          <Text {...styles.dates}>
            {!!checkIn && moment(checkIn).format('ddd, MMM D')}{' '}
            {!!checkOut && ' - ' + moment(checkOut).format('ddd, MMM D')}
          </Text>
        </Box>
        <Hidden till={'md'}>
          <Box {...styles.largeScreenButton}>{button}</Box>
        </Hidden>
      </Box>

      {breakpointTypeValue == 4 || breakpointTypeValue == 5 ? (
        <BookCalendar
          isLargeScreen={true}
          setCalendarWidth={calendarWidth}
          weekVerticalMargin={8}
          pastScrollRange={0}
          futureScrollRange={12}
          selectedDates={[checkIn, checkOut].filter((d) => !!d) as string[]}
          setMinNight={setMinNight}
          onSelectedDatesChanged={(_checkIn, _checkout) => {
            setSelectedDates(
              !!_checkIn ? moment(_checkIn).format('YYYY-MM-DD') : null,
              !!_checkout ? moment(_checkout).format('YYYY-MM-DD') : null
            );
          }}
        />
      ) : (
        <BookCalendar
          list={true}
          selectedDates={[checkIn, checkOut].filter((d) => !!d) as string[]}
          setMinNight={setMinNight}
          onSelectedDatesChanged={(_checkIn, _checkout) => {
            setSelectedDates(
              !!_checkIn ? moment(_checkIn).format('YYYY-MM-DD') : null,
              !!_checkout ? moment(_checkout).format('YYYY-MM-DD') : null
            );
          }}
        />
      )}
      <Hidden from={'md'}>
        <Box>{button}</Box>
      </Hidden>
    </Box>
  );
};

export default Calendar;
