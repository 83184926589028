import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetListingDetailQuery } from 'screens/PropertyDetails/queries/getListingDetail.generated';
import { ListingType, PropertyDetailContext } from '../contexts/PropertyDetailContext';

const DEFAULT_MIN_NIGHTS = 2;

interface PropertyDetailProviderProps {
  children: React.ReactNode;
}

export interface NightSelectionValidationResult {
  isValid: boolean;
  errorMessage: string;
}

export const PropertyDetailProvider: FC<PropertyDetailProviderProps> = ({ children }) => {
  const [listingId, setListingId] = useState<string | null>(null);
  const [listing, setListing] = useState<ListingType | null>(null);
  const [minNightsRequired, setMinNightsRequired] = useState(DEFAULT_MIN_NIGHTS);
  const { data, loading } = useGetListingDetailQuery({
    variables: { guestyListingId: listingId! },
    skip: !listingId,
  });
  const { t } = useTranslation();

  useEffect(() => {
    if (!!data?.getListing?.data) {
      const listingData = data.getListing.data;
      setListing(listingData);

      if (listingData.terms?.minNights) {
        setMinNightsRequired(listingData.terms.minNights);
      }
    }
  }, [data]);

  const validateNightSelection = useCallback(
    (selectedNights: number): NightSelectionValidationResult => {
      const maxNightsAllowed = listing?.terms?.maxNights;

      if (selectedNights < minNightsRequired) {
        return {
          isValid: false,
          errorMessage: t('PROPERTY_DETAIL.MIN_NIGHT', { minNightsRequired }),
        };
      }

      if (maxNightsAllowed && selectedNights > maxNightsAllowed) {
        return {
          isValid: false,
          errorMessage: t('PROPERTY_DETAIL.MAX_NIGHT', { maxNightsAllowed }),
        };
      }

      return { isValid: true, errorMessage: '' };
    },
    [listing, minNightsRequired]
  );

  return (
    <PropertyDetailContext.Provider
      value={{
        listing,
        listingId,
        setMinNightsRequired,
        setListingId,
        validateNightSelection,
        loading,
      }}>
      {children}
    </PropertyDetailContext.Provider>
  );
};
