import { useContext, useEffect, FC } from 'react';
import { SafeAreaView } from 'react-native';
import { PageView } from 'components/styled';
import { RootStackScreenProps } from 'types';
import { Spinner } from 'native-base';
import { AuthContext } from 'contexts/authContext';
import * as Linking from 'expo-linking';

const Auth: FC<RootStackScreenProps<'Login'>> = ({ route }) => {
  const { processAuth0Code } = useContext(AuthContext);

  useEffect(() => {
    if (!!route.params?.code) {
      processAuth0Code(route.params!.code);
    } else {
      Linking.openURL('/');
    }
  }, [route.params?.code]);

  return (
    <PageView style={{ backgroundColor: '#f2f2f2ff' }}>
      <SafeAreaView style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
        <Spinner />
      </SafeAreaView>
    </PageView>
  );
};

export default Auth;
