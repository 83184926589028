import { FC, useContext, useEffect } from 'react';
import { Text } from 'native-base';
import { ErrorToastContext } from '../../contexts/ErrorToastContext';
import Toast from 'react-native-root-toast';
import { useTranslation } from 'react-i18next';

const styles = {
  text: {
    color: 'styleSheet.canvas',
    lineHeight: '18.2px',
    fontSize: 'sm',
    textAlign: 'center' as const,
  },
};
export const ToastContainer: FC = () => {
  const { visibility, errorMsg, setVisibility } = useContext(ErrorToastContext);
  const { t } = useTranslation();

  useEffect(() => {
    if (errorMsg !== null && errorMsg !== '') {
      setVisibility(true);
      setTimeout(() => {
        setVisibility(false);
      }, 3000);
    }
  }, [errorMsg]);
  return (
    <Toast
      containerStyle={{ width: '92%' }}
      visible={visibility}
      animation={true}
      hideOnPress={true}
      position={50}
      shadow={false}
      opacity={0.9}>
      <Text {...styles.text}>{t('ERROR_TOAST.ERROR_MSG')}</Text>
    </Toast>
  );
};
