import * as Types from '../../../generated/types.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetUserSubscriptionsQueryVariables = Types.Exact<{
  memberId: Types.Scalars['String'];
}>;


export type GetUserSubscriptionsQuery = { __typename?: 'Query', getUserSubscriptions: { __typename?: 'SubscriptionWithListingResponse', status: { __typename?: 'CoreStatus', code: number, message: string }, data?: Array<{ __typename?: 'SubscriptionWithListing', memberId?: string | null, subscriptionId?: string | null, picture?: string | null, nickName?: string | null, remainingBalance?: string | null }> | null } };


export const GetUserSubscriptionsDocument = gql`
    query getUserSubscriptions($memberId: String!) {
  getUserSubscriptions(memberId: $memberId) {
    status {
      code
      message
    }
    data {
      memberId
      subscriptionId
      picture
      nickName
      remainingBalance
    }
  }
}
    `;

/**
 * __useGetUserSubscriptionsQuery__
 *
 * To run a query within a React component, call `useGetUserSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserSubscriptionsQuery({
 *   variables: {
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useGetUserSubscriptionsQuery(baseOptions: Apollo.QueryHookOptions<GetUserSubscriptionsQuery, GetUserSubscriptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserSubscriptionsQuery, GetUserSubscriptionsQueryVariables>(GetUserSubscriptionsDocument, options);
      }
export function useGetUserSubscriptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserSubscriptionsQuery, GetUserSubscriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserSubscriptionsQuery, GetUserSubscriptionsQueryVariables>(GetUserSubscriptionsDocument, options);
        }
export type GetUserSubscriptionsQueryHookResult = ReturnType<typeof useGetUserSubscriptionsQuery>;
export type GetUserSubscriptionsLazyQueryHookResult = ReturnType<typeof useGetUserSubscriptionsLazyQuery>;
export type GetUserSubscriptionsQueryResult = Apollo.QueryResult<GetUserSubscriptionsQuery, GetUserSubscriptionsQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    