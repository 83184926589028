import { HStack, Text, Spinner } from 'native-base';
import { useTranslation } from 'react-i18next';

const styles = {
  toastHstack: {
    position: 'absolute' as const,
    zIndex: 1,
    left: ['30%', '30%', '30%', '30%', '45%'],
    top: ['50px', '50px', '20%', '20%', '20%'],
    borderRadius: '10px',
    px: '10px',
    mt: '10px',
    bg: 'styleSheet.shadow',
    w: '160px',
    h: '60px',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
};

const ProcessToast = () => {
  const { t } = useTranslation();
  return (
    <HStack {...styles.toastHstack}>
      <Text color="styleSheet.canvas">{t('PROCESSING')}</Text>
      <Spinner color="styleSheet.canvas" size="lg" />
    </HStack>
  );
};

export default ProcessToast;
