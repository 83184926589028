import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { Box, View, Divider, ScrollView, Hidden, Text, VStack, useTheme } from 'native-base';
import FinancialOverviews from 'components/SubscriptionDetails/FinancialOverview';
import { Loading } from 'components/Loading';
import { NavigationContext } from 'contexts/NavigationContext';
import FirstLoginTermsConModal from 'components/BookAStayPage/modals/FirstLoginTermsConModal';
import { CitiesContext, City } from 'contexts/CitiesContext';
import { UserContext } from 'contexts/UserContext';
import { SubscriptionContext } from 'contexts/SubscriptionContext';
import { AppVersionUpdateContext } from 'contexts/AppVersionUpdateContext';
import AppUpdateModal from 'components/LandingPage/modal/AppVersionUpdateModal';
import TopHeader from 'components/BookAStayPage/TopHeader';
import ReservationSelectBox from 'components/BookAStayPage/ReservationSelectBox';
import FooterButtons from 'components/BookAStayPage/FooterButtons';
import { RootStackScreenProps } from 'types';
import { HomeEquityContext } from 'contexts/HomeEquityContext';
import { useTranslation } from 'react-i18next';
import separator from 'utils/separator';

const styles = {
  header: {
    pl: '16px',
    pr: '16px',
    w: ['100%', '100%', '100%', '65%', '65%'],
    justifyContent: 'center' as const,
    zIndex: 1,
    h: [null, null, '70%', null, null],
  },
  chartContainer: {
    alignItems: 'center',
    space: ['24px', '24px', '56px', '56px', '56px'],
    // TODO: Move padding out of this container when whole screen
    // is refactored.
    px: '36px',
    py: '40px',
  },
  chartHeader: {
    color: 'styleSheet.shadow',
    fontFamily: 'body600',
    fontSize: ['2md', '2md', '1xl', '1xl', '1xl'],
    // TODO: Move padding out of header when whole screen
    // is refactored.
    pt: [0, 0, 0, '40px', '40px'],
  },
  headerText: {
    position: 'absolute' as const,
    fontSize: 'sm',
    top: ['10px', '10px', '14px', '10px', '10px'],
    left: '16px',
    color: 'styleSheet.lightShadow',
    fontFamily: 'body',
    fontWeight: '400',
    opacity: '0.55',
  },

  mainView: {
    flexDirection: ['column' as const, 'column' as const, 'column' as const, 'row' as const, 'row' as const],
    width: '100%',
    mb: [null, null, '30%', '30%', '30%'],
  },
  dividerBottom: {
    borderWidth: '1',
    borderColor: 'styleSheet.darkStain',
    mt: ['24px', '24px', '40px', '0px', '0px'],
  },
  dividerVertical: {
    borderWidth: '1',
    borderColor: 'styleSheet.darkStain',
    h: 'auto',
    mr: '23px',
  },
  box: {
    h: '100%',
    bg: 'styleSheet.canvas',
    mt: [0, '20px', '80px', '80px', '80px'],
  },
};

const BookAStay: FC<RootStackScreenProps<'BookAStay'>> = ({ route }) => {
  const { useNavigation } = useContext(NavigationContext);
  const { cities, isLoading: isCitiesLoading } = useContext(CitiesContext);
  const { updateAvailable } = useContext(AppVersionUpdateContext);
  const [checkInDate, setCheckIn] = useState('');
  const [checkOutDate, setCheckOut] = useState('');
  const [showModal, setShowModal] = useState<boolean | undefined>(false);
  const [selectedCities, setSelectedCities] = useState<City[]>([]);

  useEffect(() => {
    if (!!route.params?.checkInDate && !!route.params?.checkOutDate) {
      setCheckIn(route.params?.checkInDate || '');
      setCheckOut(route.params?.checkOutDate || '');
    }
  }, [route.params?.checkInDate, route.params?.checkOutDate]);

  useEffect(() => {
    if (cities && cities.length) {
      setSelectedCities([cities[0]]);
    }
  }, [cities]);

  const { user, isLoading: isUserLoading } = useContext(UserContext);
  const { subscriptionBalance, isLoading: isSubscriptionLoading } = useContext(SubscriptionContext);

  const firstName = useMemo(() => user?.given_name, [user]);
  const lastName = useMemo(() => user?.family_name, [user]);
  const email = useMemo(() => user?.email, [user]);
  const firstLoginModal = useMemo(() => user?.isFirstLogin, [user]);

  useEffect(() => {
    if (firstLoginModal) {
      setShowModal(firstLoginModal);
    }
  }, [firstLoginModal]);

  const handleBrowseAllHome = () => {
    useNavigation('ListingNavigator', {
      screen: 'ListingResults',
      params: { selectedCities: cities?.map((c) => c.homeId || c.nickname) },
    });
  };

  const usedAmount = useMemo(() => subscriptionBalance?.usedAmount, [subscriptionBalance]);
  const remainingBalance = useMemo(() => subscriptionBalance?.remainingAmount, [subscriptionBalance]);
  const startingBalance = useMemo(() => subscriptionBalance?.totalAmount, [subscriptionBalance]);
  const remainingData = Math.round(remainingBalance as number).toString();
  const startingBalanceData = Math.round(startingBalance as number).toString();
  const usedData = Math.round(usedAmount as number).toString();

  const theme = useTheme();
  const { t } = useTranslation();
  const { equityDetails } = useContext(HomeEquityContext);
  const userType = useMemo(() => equityDetails?.type, [equityDetails]);

  const chartHeader = userType === 'immediate'
    ? t('FINANCIALS.SUBSCRIPTION_CREDIT_OVERVIEW')
    : t('FINANCIALS.SUBSCRIPTION_OVERVIEW');
  const chartLabel = t('MULTI_HOME.REMAINING');
  const chartAmount = remainingBalance;
  const graphicColors = [theme.colors.donutChart2, theme.colors.donutChart3];
  const graphicData = [usedData, remainingData];
  const secondaryValues = [
    {
      label: t('FINANCIALS.STARTING_BALANCE'),
      value: `$${separator(startingBalanceData)}`
    },
    {
      label: t('FINANCIALS.USED_AMOUNT'),
      value: `$${separator(usedData)}`
    },
    {
      label: t('FINANCIALS.REMAINING_BALANCE'),
      value: `$${separator(remainingData)}`
    },
  ];

  if (isUserLoading || isSubscriptionLoading || isCitiesLoading) return <Loading />;

  return (
    <Box safeAreaTop {...styles.box}>
      <AppUpdateModal isOpen={updateAvailable} />
      <ScrollView>
        <Box {...styles.mainView}>
          <Box {...styles.header}>
            <TopHeader firstName={firstName!} />
            <View style={{ zIndex: 1, width: '100%' }}>
              <ReservationSelectBox
                selectedCities={selectedCities}
                setSelectedCities={setSelectedCities}
                setCheckIn={setCheckIn}
                setCheckOut={setCheckOut}
                checkInDate={checkInDate}
                checkOutDate={checkOutDate}
              />
            </View>
            <FooterButtons
              checkInDate={checkInDate}
              checkOutDate={checkOutDate}
              selectedCities={selectedCities}
              onPressHandler={handleBrowseAllHome}
            />
          </Box>
          <Hidden from={'lg'}>
            <Divider {...styles.dividerBottom} />
          </Hidden>
          <Hidden till={'lg'}>
            <Divider orientation="vertical" {...styles.dividerVertical} />
          </Hidden>

          <VStack {...styles.chartContainer}>
            <Text {...styles.chartHeader}>
              {chartHeader}
            </Text>
            <FinancialOverviews
              condensed
              graphicColors={graphicColors}
              graphicData={graphicData}
              mainAmount={chartAmount!}
              mainLabel={chartLabel}
              secondaryValues={secondaryValues}
            />
          </VStack>
        </Box>

        <FirstLoginTermsConModal
          memberId={user?.id || ''}
          email={email}
          firstName={firstName}
          lastName={lastName}
          open={showModal}
          close={() => {
            setShowModal(false);
          }}
        />
      </ScrollView>
    </Box>
  );
};

export default BookAStay;
