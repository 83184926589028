import { FC, useContext, useEffect, useState } from 'react';
import { useGetUserSubscriptionsQuery } from 'screens/Booking/queries/getUserSubscriptions.generated';
import { UserContext } from 'contexts/UserContext';
import { MultiSubscriptionsContext } from 'contexts/MultiSubscriptionsContext';
import { usePersistentValue } from 'hooks/usePersistentValue';

interface MultiSubscriptionsProviderProps {
  children: React.ReactNode;
}

export const MultiSubscriptionsProvider: FC<MultiSubscriptionsProviderProps> = ({ children }) => {
  const { user } = useContext(UserContext);
  const [, setStoredSubscriptionId] = usePersistentValue<string | null>(
    'storedSubscriptionId',
    null
  );
  const [, setStoredSelectedHome] = usePersistentValue<string | null>(
    'storedSelectedHome',
    null
  );
  const { data, loading } = useGetUserSubscriptionsQuery({
    variables: {
      memberId: user?.id || '',
    },
    skip: !user,
  });

  useEffect(() => {
    if (data?.getUserSubscriptions?.data?.length === 1) {
      setStoredSubscriptionId(data?.getUserSubscriptions?.data![0].subscriptionId!);
      setStoredSelectedHome(data?.getUserSubscriptions?.data![0].nickName!);
    }
  }, [data]);

  return (
    <MultiSubscriptionsContext.Provider
      value={{
        subscriptionsList: data?.getUserSubscriptions.data,
        isLoading: loading,
      }}>
      {!loading && children}
    </MultiSubscriptionsContext.Provider>
  );
};
